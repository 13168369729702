import { useLang } from "src/components/useLang";
import { IconButton, RemoveTicketBtn } from "src/styling/globalStyling";
import { TicketHeader, TicketWrapper, TicketInfo } from "./styledComponents";

const ShapeTicketsControls = (props) => {
  const { item, handleRemoveBtn, handleSelectedTicketsInput, currency } = props;
  console.log("selected shape object", item);
  const lang = useLang();

  const handleInc = () => {
    handleSelectedTicketsInput(item.id, item.numberOfTickets++);
  };

  const handleDec = () => {
    if (item.numberOfTickets === 1) {
      return;
    }
    handleSelectedTicketsInput(item.id, item.numberOfTickets--);
  };
  return (
    <TicketWrapper>
      <TicketHeader>
        <div>{item.objectName || item.ticketTypeInfo?.title}</div>
        <RemoveTicketBtn onClick={() => handleRemoveBtn(item)}>
          <i className="fa fa-trash"></i>
        </RemoveTicketBtn>
      </TicketHeader>
      <div>
        {lang.price_per_seat}: {item.ticketTypeInfo.ticketPrice} {currency}
      </div>
      <TicketInfo>
        <span>{lang.number_of_tickets_selected}: </span>
        <span>{item.numberOfTickets}</span>
        <IconButton onClick={handleDec}>-</IconButton>
        <IconButton onClick={handleInc}>+</IconButton>
      </TicketInfo>
    </TicketWrapper>
  );
};

export default ShapeTicketsControls;
