import { getSeatingData, dispatchSeatingData } from '../seatingMapReducer';
import { fabric } from 'fabric';

class AddTextObject {
  constructor(options, canvas, uuid, values) {
    this.text = options.text;
    this.canvas = canvas;
    this.id = uuid; // Use passed uuid
    this.objectSubtype = 'text';
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.angle = values.angle;
    this.top = values.top;
    this.left = values.left;
    this.fontSize = values.fontSize;
    this.fontWeight = values.fontWeight;
    this.textColor = values.textColor;
    this.textGroup = null;
    this.currTop = 0;
    this.currLeft = 0;
    this.fontFamily = 'Poppins, sans-serif';
  }

  create() {
    // Ensure font is loaded before creating text
    this.textGroup = new fabric.Text(this.text, {
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      fontFamily: this.fontFamily,
      top: this.top,
      left: this.left,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      id: this.id,
      objectSubtype: this.objectSubtype,
      originX: 'center',
      originY: 'center',
    });

    this.canvas.add(this.textGroup);
    this.save();
  }

  editText(value) {
    this.textGroup.set({
      text: value,
    });
    this.text = value;
    this.save();
    this.canvas.renderAll();
  }

  setFontSize(value) {
    this.textGroup.set({
      fontSize: value,
    });
    this.fontSize = value;
    this.save();
    this.canvas.renderAll();
  }

  setFontWeight(value) {
    this.textGroup.set({
      fontWeight: value,
    });
    this.fontWeight = value;
    this.save();
    this.canvas.renderAll();
  }

  textColorChange(color) {
    this.textGroup.set({
      fill: color,
    });
    this.textColor = color;
    this.save();
    this.canvas.renderAll();
  }

  delete() {
    this.canvas.remove(this.textGroup);
    this.canvas.renderAll();
    const seatingData = getSeatingData();
    seatingData.objects.delete(this.id);

    seatingData.canvasJSON = this.canvas.toJSON();
    dispatchSeatingData(seatingData);
  }

  save() {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    const dataOptions = {
      text: this.text,
      id: this.id,
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      top: this.textGroup.top,
      left: this.textGroup.left,
      scaleX: this.textGroup.scaleX,
      scaleY: this.textGroup.scaleY,
      angle: this.textGroup.angle,
      textColor: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      fontFamily: this.fontFamily,
    };
    this.currTop = this.textGroup.top;
    this.currLeft = this.textGroup.left;

    seatingData.updatedAt = formattedDate;

    seatingData.objects.set(this.id, {
      dataOptions,
      dataValues,
    });

    seatingData.canvasJSON = this.canvas.toJSON();
    dispatchSeatingData(seatingData);
  }

  updateDimensions(obj) {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    const dataOptions = {
      text: this.text,
      id: this.id,
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      top: obj.top,
      left: obj.left,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      angle: obj.angle,
      textColor: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      fontFamily: this.fontFamily,
    };
    this.currTop = obj.top;
    this.currLeft = obj.left;
    seatingData.updatedAt = formattedDate;

    seatingData.objects.set(this.id, {
      dataOptions,
      dataValues,
    });

    seatingData.canvasJSON = this.canvas.toJSON();
    dispatchSeatingData(seatingData);
  }

  copy() {
    const dataOptions = {
      text: this.text,
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      top: this.currTop,
      left: this.currLeft,
      scaleX: this.textGroup.scaleX,
      scaleY: this.textGroup.scaleY,
      angle: this.textGroup.angle,
      textColor: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      fontFamily: this.fontFamily,
    };

    const copiedObject = {
      type: 'add text object',
      dataOptions: dataOptions,
      dataValues: dataValues,
    };

    return copiedObject;
  }

  setSelectable(value) {
    this.textGroup.set({
      selectable: value,
    });
    this.canvas.renderAll();
  }

  bringToFront() {
    this.canvas.bringToFront(this.textGroup);
    const seatingData = getSeatingData();

    const element = seatingData.objects.get(this.id);
    seatingData.objects.delete(this.id);
    seatingData.objects.set(this.id, element);

    seatingData.canvasJSON = this.canvas.toJSON();
    dispatchSeatingData(seatingData);
  }
}

export default AddTextObject;
