import { fabric } from "fabric";
import { DEFAULT_DISABLED_COLOR } from "../../constants";

class BookPolygonsClass {
  constructor(options, canvas, uuid, values) {
    this.blockName = options.text;
    this.name = options.name;
    this.canvas = canvas;
    this.id = uuid; // Use passed uuid
    this.shapeColor = values.shapeColor;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.angle = values.angle;
    this.top = values.top;
    this.left = values.left;
    this.ticketType = values.ticketType;
    this.polygon = null;
    this.line = null;
    this.points = [];
    this.taken = false;
    this.booked = false;
    this.initialPoints = {};
  }

  load(valuesJSON) {
    this.points = valuesJSON.points;
    this.shapeColor = valuesJSON.shapeColor;
    this.top = valuesJSON.top;
    this.left = valuesJSON.left;
    this.scaleX = valuesJSON.scaleX;
    this.scaleY = valuesJSON.scaleY;
    this.angle = valuesJSON.angle;
    this.ticketType = valuesJSON.ticketType;

    this.polygon = new fabric.Polygon(this.points, {
      fill: this.taken ? DEFAULT_DISABLED_COLOR : this.shapeColor,
      stroke: "#333333",
      strokeWidth: 1,
      seatType: "shape",
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      top: this.top,
      left: this.left,
      angle: this.angle,
      hasControls: false,
      selectable: false,
      evented: true,
      ticketType: this.ticketType,
      color: this.shapeColor,
      booked: this.booked,
      taken: this.taken,
      _corner: 0,
      id: this.id, // Use passed uuid
    });

    // this.polygon.on("mousedown", () => {
    //   this.toggleShapeColor();
    // });
    this.canvas.add(this.polygon);
    this.canvas.renderAll();
  }

  toggleShapeColor() {
    // if (this.taken || !this.ticketType) return;
    if (this.booked) {
      this.polygon.set({
        fill: "#666666",
      });
    } else {
      this.polygon.set({
        fill: this.shapeColor,
      });
    }
    this.canvas.renderAll();
  }

  toggleShape() {
    this.booked = !this.booked;
    this.polygon.set({
      fill: this.shapeColor,
    });
    this.canvas.renderAll();
  }

  toggleBooked() {
    this.booked = !this.booked;
    this.toggleShapeColor();
  }

  bookAll() {
    if (!this.booked) return;

    this.polygon.set({
      fill: DEFAULT_DISABLED_COLOR,
    });
    this.canvas.renderAll();
  }

  save() {
    if (!this.booked) return;

    this.polygon.taken = true;
    this.taken = true;

    const newObj = {
      id: this.id, // Use passed uuid
      taken: true,
      ticketType: this.ticketType,
    };

    return newObj;
  }
}

export default BookPolygonsClass;
