import { fabric } from "fabric";
import { Canvas, IPoint } from "fabric/fabric-impl";
import mitt from "mitt";
import { gestureDetector } from "./gestureDetector";

const EventBus = mitt();

export function initGestures(c: Canvas) {
  const upperCanvasEl = (c as any).upperCanvasEl;
  if (!upperCanvasEl) {
    return;
  }
  enableZoomAndPan(c, upperCanvasEl);
}

export function enableZoomAndPan(c: any, upperCanvasEl: any) {
  if (isMobile()) enableMobileGestures(c, upperCanvasEl);
  // else enablePCGestures(c);
}

export function isMobile() {
  return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  );
}

export function enableMobileGestures(c: any, upperCanvasEl: any) {
  console.log("enableMobileGestures called"); // Debug log
  let isRotating = false;

  gestureDetector(upperCanvasEl, {
    onGestureStart: () => {
      EventBus.emit("gesture"); // used by bucket tool
    },
    onZoom: (scale: number, previousScale: number, center: IPoint) => {
      if (Math.abs(scale - previousScale) < 0.005) return;
      handleZoom(scale, center.x, center.y, c, previousScale);
      c.requestRenderAll();
    },
    onRotate: (angleDifference: number) => {
      const rotationThreshold = 0.8; // Adjust the threshold as needed
      const obj = c.getActiveObject();

      isRotating = Math.abs(angleDifference) > rotationThreshold;

      EventBus.emit("rotating");
      obj.rotate((obj.angle! + angleDifference) % 360);
      obj.setCoords();
      c.requestRenderAll();
    },
    onDrag: (
      dx: number,
      dy: number,
      previousDx: number,
      previousDy: number
    ) => {
      const delta = {
        x: 2 * (dx - previousDx),
        y: 2 * (dy - previousDy),
      };
      handlePan(delta, c);
      c.requestRenderAll();
    },
    onGestureEnd: (fingers: number) => {
      c.requestRenderAll();
    },
  });
}

// export function enablePCGestures(c: any) {
//   const { subscribe } = useEventManager();
//   const { setCanZoomOut } = useDrawStore();
//   let panStartPoint: any = null;

//   const events: FabricEvent[] = [
//     {
//       on: "mouse:wheel",
//       handler: (e: any) => {
//         const deltaY = e.e.deltaY;

//         // Convert deltaY into a zoom factor
//         const zoomFactor = Math.exp(-deltaY / 50);
//         handleZoom(zoomFactor, e.e.offsetX, e.e.offsetY, c);
//         setCanZoomOut(c.getZoom() > 1);

//         c.requestRenderAll();
//         e.e.preventDefault();
//         e.e.stopPropagation();
//       },
//       type: DrawEvent.Gesture,
//     },
//     {
//       on: "mouse:down",
//       handler: (o: any) => {
//         const event = o.e;
//         // Check if the middle button is pressed
//         if (event.button === 1) {
//           // If it is, start the panning
//           panStartPoint = { x: event.pageX, y: event.pageY };
//           event.preventDefault();
//           event.stopPropagation();
//         }
//       },
//       type: DrawEvent.Gesture,
//     },
//     {
//       on: "mouse:move",
//       handler: (o: any) => {
//         if (panStartPoint) {
//           // If we are panning, calculate the delta and pan the canvas
//           const event = o.e;
//           const deltaX = event.pageX - panStartPoint.x;
//           const deltaY = event.pageY - panStartPoint.y;
//           panStartPoint = { x: event.pageX, y: event.pageY };
//           handlePan(new fabric.Point(deltaX, deltaY), c);
//           c.requestRenderAll();
//         }
//       },
//       type: DrawEvent.Gesture,
//     },
//     {
//       on: "mouse:up",
//       handler: (o: any) => {
//         // If we were panning, stop it
//         if (panStartPoint) {
//           panStartPoint = null;
//           o.e.preventDefault();
//           o.e.stopPropagation();
//         }
//       },
//       type: DrawEvent.Gesture,
//     },
//   ];

//   events.forEach((e) => subscribe(e));
// }

const PANMARGIN = isMobile() ? 80 : 0;

export const checkCanvasBounds = (c: Canvas) => {
  const vpt = c.viewportTransform!;
  const canvasWidth = c.getWidth();
  const canvasHeight = c.getHeight();
  const zoomFactor = c.getZoom();

  const pm = PANMARGIN;

  // Check left boundary
  if (vpt[4] >= pm) {
    vpt[4] = pm;
  } else if (vpt[4] < canvasWidth - canvasWidth * zoomFactor - pm) {
    vpt[4] = canvasWidth - canvasWidth * zoomFactor - pm;
  }

  // Check top boundary
  if (vpt[5] >= pm) {
    vpt[5] = pm;
  } else if (vpt[5] < canvasHeight - canvasHeight * zoomFactor - pm) {
    vpt[5] = canvasHeight - canvasHeight * zoomFactor - pm;
  }

  c.setViewportTransform(vpt);
};

export const handleZoom = (
  scale: number,
  centerX: number,
  centerY: number,
  c: Canvas,
  previousScale?: number
) => {
  const newZoom = previousScale
    ? c.getZoom() * (scale / previousScale)
    : c.getZoom() * scale;

  // Limit zoom between 0.2 and 8
  const limitedZoom = Math.min(Math.max(newZoom, 0.15), 8);

  // Get the center point of the gesture
  const gestureCenter = new fabric.Point(centerX, centerY);
  // Zoom the canvas to the new zoom level while maintaining the gesture center point
  c.zoomToPoint(gestureCenter, limitedZoom);
};

export const handlePan = (delta: IPoint, c: Canvas) => {
  c.relativePan(delta);
  // checkCanvasBounds(c);
};
