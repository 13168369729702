import { fabric } from "fabric";
import { getSeatingData, dispatchSeatingData } from "../seatingMapReducer";

class PolygonObject {
  constructor(options, canvas, uuid, values) {
    this.text = options.text || "";
    this.name = options.name || "";
    this.canvas = canvas;
    this.id = uuid; // Use passed uuid
    this.objectSubtype = "polygon";
    this.shapeColor = values.shapeColor;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.angle = values.angle;
    this.top = values.top;
    this.left = values.left;
    this.ticketType = values.ticketType;
    this.polygon = null;
    this.line = null;
    this.points = [];
    this.initialPoints = {};
    this.currTop = 0;
    this.currLeft = 0;

    // Adding event listener for ESC key
    this.handleKeyUp = this.handleKeyUp.bind(this);
    document.addEventListener("keyup", this.handleKeyUp);
  }

  create() {
    this.canvas.off("mouse:up");

    this.canvas.on("mouse:up", (event) => {
      this.handleMouseClick(event);
    });
  }

  handleMouseClick(event) {
    var pointer = this.canvas.getPointer(event.e);

    let initialPoints = {
      x: pointer.x,
      y: pointer.y,
    };

    this.points.push(initialPoints);

    let currentTime = new Date().getTime();
    let timeDiff = currentTime - this.lastClickTime;
    this.lastClickTime = currentTime;

    const tolerance = 10;

    if (
      (Math.abs(this.points[0].x - this.points[this.points.length - 1].x) <
        tolerance &&
        Math.abs(this.points[0].y - this.points[this.points.length - 1].y) <
          tolerance &&
        this.points.length > 2) ||
      timeDiff < 300
    ) {
      this.polygon.set({
        fill: "#cccccc",
        selectable: true,
        opacity: 1,
        hasControls: true,
        evented: true,
        stroke: "black",
        id: this.id, // Use passed uuid
        objectSubtype: this.objectSubtype,
        ticketType: this.ticketType,
      });
      this.canvas.renderAll();
      this.canvas.off("mouse:up");
      this.canvas.off("mouse:move");
      this.canvas.remove(this.line);
      this.save();
      return;
    }

    this.canvas.remove(this.polygon);
    this.canvas.remove(this.line);

    this.polygon = new fabric.Polygon(this.points, {
      stroke: "#333333",
      strokeWidth: 1,
      fill: "#cccccc",
      opacity: 0.1,
      selectable: false,
      hasControls: false,
      evented: false,
    });

    this.canvas.add(this.polygon);

    this.canvas.on("mouse:move", (event) => {
      var pointer = this.canvas.getPointer(event.e);
      let finalPoints = {
        x: pointer.x,
        y: pointer.y,
      };

      this.canvas.remove(this.line);
      this.line = new fabric.Line(
        [initialPoints.x, initialPoints.y, finalPoints.x, finalPoints.y],
        {
          strokeWidth: 1,
          fill: "#999999",
          stroke: "#999999",
          originX: "center",
          originY: "center",
          selectable: false,
          hasBorders: false,
          hasControls: false,
          evented: false,
        }
      );

      this.canvas.add(this.line);
      this.canvas.renderAll();
    });
  }

  handleKeyUp(event) {
    if (event.key === "Escape") {
      this.stopDrawing();
    }
  }

  stopDrawing() {
    this.canvas.off("mouse:up");
    this.canvas.off("mouse:move");
    this.canvas.remove(this.line);
    this.canvas.remove(this.polygon);
    this.points = [];
    this.polygon = null;
    this.line = null;
    this.canvas.renderAll();
  }

  save() {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    const dataOptions = {
      text: this.text,
      name: this.name,
      id: this.id,
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      top: this.polygon.top,
      left: this.polygon.left,
      scaleX: this.polygon.scaleX,
      scaleY: this.polygon.scaleY,
      angle: this.polygon.angle,
      shapeColor: this.shapeColor,
      ticketType: this.ticketType,
      points: this.points,
    };

    this.currTop = this.polygon.top;
    this.currLeft = this.polygon.left;

    seatingData.updatedAt = formattedDate;
    seatingData.objects.set(this.id, {
      dataOptions,
      dataValues,
    });

    seatingData.canvasJSON = this.canvas.toJSON();

    dispatchSeatingData(seatingData);
  }

  load(valuesJSON) {
    this.points = valuesJSON.points;
    this.shapeColor = valuesJSON.shapeColor;
    this.top = valuesJSON.top;
    this.left = valuesJSON.left;
    this.scaleX = valuesJSON.scaleX;
    this.scaleY = valuesJSON.scaleY;
    this.angle = valuesJSON.angle;
    this.ticketType = valuesJSON.ticketType;

    this.polygon = new fabric.Polygon(this.points, {
      fill: this.shapeColor,
      stroke: "#333333",
      strokeWidth: 1,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      top: this.top,
      left: this.left,
      angle: this.angle,
      hasControls: true,
      selectable: true,
      evented: true,
      _corner: 0,
      id: this.id, // Use passed uuid
      objectSubtype: this.objectSubtype,
      name: this.name,
      text: this.text,
    });
    this.currTop = this.top;
    this.currLeft = this.left;

    this.canvas.add(this.polygon);
    this.canvas.renderAll();
    this.save();
  }

  copy() {
    const dataOptions = {
      text: this.text,
      name: this.name,
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      top: this.currTop,
      left: this.currLeft,
      scaleX: this.polygon.scaleX,
      scaleY: this.polygon.scaleY,
      angle: this.polygon.angle,
      shapeColor: this.shapeColor,
      ticketType: this.ticketType,
      points: this.points,
    };

    const copiedObject = {
      type: "polygon object",
      dataOptions: dataOptions,
      dataValues: dataValues,
    };

    return copiedObject;
  }

  editText(value) {
    this.text = value;
    this.save();
    this.canvas.renderAll();
  }

  editName(value) {
    this.name = value;
    this.save();
  }

  colorChange(color) {
    this.polygon.set({
      fill: color,
    });
    this.shapeColor = color;
    this.save();
    this.canvas.renderAll();
  }

  delete() {
    this.canvas.remove(this.polygon);
    this.canvas.renderAll();
    const seatingData = getSeatingData();

    seatingData.objects.delete(this.id);

    seatingData.canvasJSON = this.canvas.toJSON();
    dispatchSeatingData(seatingData);
  }

  updateDimensions(obj) {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    const dataOptions = {
      text: this.text,
      name: this.name,
      id: this.id, // Use passed uuid
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      top: obj.top,
      left: obj.left,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      angle: obj.angle,
      shapeColor: this.shapeColor,
      ticketType: this.ticketType,
      points: this.points,
    };

    seatingData.updatedAt = formattedDate;
    seatingData.objects.set(this.id, {
      dataOptions,
      dataValues,
    });

    dispatchSeatingData(seatingData);
  }

  setSelectable(value) {
    this.polygon.set({
      selectable: value,
    });
    this.canvas.renderAll();
  }

  bringToFront() {
    this.canvas.bringToFront(this.polygon);
    const seatingData = getSeatingData();

    const element = seatingData.objects.get(this.id);
    seatingData.objects.delete(this.id);
    seatingData.objects.set(this.id, element);

    seatingData.canvasJSON = this.canvas.toJSON();
    dispatchSeatingData(seatingData);
  }

  assignAll(value) {
    this.ticketType = value;
    this.save();
  }
}

export default PolygonObject;
