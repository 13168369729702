import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { ShowType } from 'src/models';
import styled from 'styled-components';
import { setPTR } from 'src/pages/profile/profileActions';
import AssignEditor from '../assignEditor/AssignEditor';
import CanvasContext from '../canvasContext';
import BookTicketsSetter from './BookTicketsSetter';

const BookTickets = ({ show }: { show: ShowType }) => {
  const [canvasVal, setCanvasVal] = useState();
  const dispatch = useDispatch();

  const setCanvas = function (canv) {
    setCanvasVal(canv);
  };

  useEffect(() => {
    setPTR(dispatch, false);
  });

  return (
    <CanvasContext.Provider
      value={{
        canvas: canvasVal,
        setCanvas,
      }}
    >
      <BookWrapper>
        <AssignEditor />
        <BookTicketsSetter show={show} />
      </BookWrapper>
    </CanvasContext.Provider>
  );
};

export default BookTickets;

const BookWrapper = styled.div`
  border: 1px solid ${({ theme }) => theme.boxBorderColor};
  height: auto;
  width: 100%;
  display: flex;
  color: black;
  background-color: ${({ theme }) => theme.body};
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
