import { EditorState } from 'draft-js';
import moment from 'moment';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Select from 'react-select';
import { useTheme } from 'styled-components';

import AsyncSelect from 'react-select/async/dist/react-select.esm';
import FSwitch from 'src/components/Switch/FSwitch';
import TextEditor from 'src/components/textEditor';
import { stringifyContent } from 'src/components/textEditor/utils';
import GoBack from '../../components/goBack/goBack';
import Heading from '../../components/heading/heading';
import Input, { Description } from '../../components/input/input';
import NeedVerification from '../../components/NeedVerification';
import Popup from '../../components/popup/popup';
import Portal from '../../components/portal/portal';
import Spinner from '../../components/Spinner/Spinner';
import { UploadButton } from '../../components/verifyProfile/verifyProfile';
import {
  currencyMap,
  currencySelectOptions,
  dateFormat,
  getShowAgeRestriction,
  getShowTypeDescriptions,
  getShowTypeValues,
  HYBRID_SHOW,
  ONLINE_SHOW,
  SHOW,
  TICKET_FEE,
  timeFormat,
} from '../../constants';
import {
  Button,
  Container,
  Flex,
  Label,
  MiddleWrapper,
  SoftButton,
} from '../../styling/globalStyling';
import { getLocationObject, handleLoadAddressOptions } from '../../utils';
import {
  Content,
  InlineInput,
  InlineInputsContainer,
} from '../createFangout/createFangout';
import { colourStyles } from '../Login/utils';
import { setPTR } from '../profile/profileActions';
import { SettingsOption } from '../settings/settings';
import { SubscribeLocationButton } from '../subscriptions/subscriptions';
import {
  getShowDetails,
  publishShow,
  removeShow,
  saveShow,
  updateShow,
} from './showService';
import {
  AddTicketType,
  ButtonsContainer,
  Count,
  EventImage,
  LabelDesc,
  prepareShowObject,
  RemoveItem,
  thousandsSeparatorSymbol,
  TicketTypeComponent,
} from './styledComponents';

export interface IBookingData {
  busy: boolean;
  id: string;
  maxSeats: number;
  minSeats: number;
  ownerId: number;
  purchaseTime: string;
  seatsTaken: number;
  title: string;
}

export interface ITicketType {
  id?: number;
  show?: IShow;
  title: string;
  description: string;
  numberOfTickets: number;
  ticketPrice: number;
  currency: string;
  isStreaming?: boolean;
  soldTickets?: number;
  bookingData?: IBookingData;
}

export interface IShow {
  id?: number;
  showTitle: string;
  description: string;
  location: any;
  date: string;
  time: string;
  endDate: string;
  endTime: string;
  durationHours: number;
  streamingTicketPrice?: number;
  currency?: { label: string; value: string };
  ticketTypes: ITicketType[];
  isDraft?: boolean;
  showCode?: string;
  showDate?: string;
  eventImage?: string;
  showType: { label: string; value: string };
  ageRestriction?: number;
}

const NO_CANCEL_DAYS = 5;
const defaultStartDate = moment().add(1, 'd');
const defaultEndDate = moment().add(2, 'd');
export const DEFAULT_TICKET_TYPE = {
  title: '',
  description: '',
  numberOfTickets: 0,
  ticketPrice: 0,
  allowedInvalidations: 1, // this is number of validations in 12hr window, for multiple days events
  isDraft: false,
};

const defaultStreamingTicketType = {
  title: '',
  description: '',
  numberOfTickets: 0,
  ticketPrice: 0,
  isStreaming: true,
};

const CreateShow = () => {
  const history = useHistory();
  const { showId } = useParams<{ showId: string }>();
  const dispatch = useDispatch();
  const theme = useTheme();
  const lang = useSelector((state: any) => state.home.language);
  const userData = useSelector((state: any) => state.profile.userData);
  const containerRef: any = useRef(null);

  const [editorState, setEditorState] = useState(EditorState.createEmpty());

  const [show, setShow] = useState<IShow | any>({
    showTitle: '',
    date: defaultStartDate.format(dateFormat),
    time: defaultStartDate.format(timeFormat),
    endDate: defaultEndDate.format(dateFormat),
    endTime: defaultEndDate.format(timeFormat),
    showType: getShowTypeValues(lang)[0],
    currency: currencySelectOptions[0],
    ticketTypes: [DEFAULT_TICKET_TYPE],
    ageRestriction: { label: lang.show_no_restriction, value: 0 },
    isPrivate: false,
  });

  const [disabledFlags, setDisabledFlags] = useState<any>({});
  const [statistics, setStatistics] = useState<{
    count?: number;
    goal?: number;
    profit?: number;
  }>({});
  const [page, setPage] = useState(0);
  const selectStyles = colourStyles(theme);
  const [confirmPublish, setConfirmPublish] = useState(false);
  const [confirmRemoveShow, setConfirmRemoveShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const hiddenFileInput = useRef<HTMLInputElement | null>(null);
  const [imageDirty, setImageDirty] = useState(false);

  const updateShowDetails = (res) => {
    if (res.data) {
      if (!res.data.isDraft) {
        history.push(`/show/${res.data.id}`);
      }
      const showObj = prepareShowObject(res.data, lang);
      setShow(showObj);
      setEditorState(showObj.description);
    }
  };

  const eventStartsInDays = () => {
    const startsIn = moment(show.date).diff(moment(), 'd');
    if (startsIn < NO_CANCEL_DAYS) {
      return 0;
    } else if (startsIn < 10) {
      return 1;
    } else {
      return 2;
    }
  };

  const setShowDetails = (sId) => {
    getShowDetails(sId)
      .then(updateShowDetails)
      .catch((e) => {
        toast.error('Error fetching Show details.');
      });
  };

  useEffect(() => {
    setPTR(dispatch, false);
  }, []);

  useEffect(() => {
    if (showId) {
      // handleChange('id', params.showId)
      setShowDetails(showId);
    }
  }, [showId]);

  const handleChange = (key: string, value: any) => {
    if (key === 'showType') {
      if (value.value === SHOW) {
        // remove streaming ticket if it exits
        const newTicketTypes = [...show.ticketTypes];
        if (show.ticketTypes[0]?.isStreaming) {
          newTicketTypes.shift();
        }
        if (!newTicketTypes.length) {
          newTicketTypes.push(DEFAULT_TICKET_TYPE);
        }
        setShow(
          Object.assign(
            {},
            show,
            { [key]: value },
            { ticketTypes: newTicketTypes }
          )
        );
      } else if (value.value === HYBRID_SHOW) {
        const newTicketTypes = [...show.ticketTypes];
        if (!show.ticketTypes[0]?.isStreaming) {
          newTicketTypes.splice(0, 0, defaultStreamingTicketType);
        }
        if (newTicketTypes.length === 1) {
          newTicketTypes.push(DEFAULT_TICKET_TYPE);
        }
        setShow(
          Object.assign(
            {},
            show,
            { [key]: value },
            { ticketTypes: newTicketTypes }
          )
        );
      } else if (value.value === ONLINE_SHOW) {
        setShow(
          Object.assign(
            {},
            show,
            { [key]: value },
            { ticketTypes: [defaultStreamingTicketType] }
          )
        );
      } else {
        setShow(Object.assign({}, show, { [key]: value }));
      }
    } else {
      setShow(Object.assign({}, show, { [key]: value }));
    }
  };

  const validateLength = (condition, value) => {
    if (!value) return undefined;
    return condition ? 'success' : 'error';
  };

  const currencyIcon = useMemo(() => {
    return currencyMap[show.currency.value];
  }, [show]);

  const getDisabledFlags = useCallback(() => {
    const next1 =
      !show.showTitle?.length ||
      validateLength(show.showTitle?.length > 2, show.showTitle) === 'error' ||
      isInPast() ||
      !show.location;

    const areTicketsRight = show.ticketTypes.some((t: ITicketType) => {
      return (
        !t.title ||
        !t.ticketPrice ||
        !t.numberOfTickets ||
        (t.title && t.title.length < 2)
      );
    });
    const publish =
      next1 ||
      !show.showType ||
      (show.showType.value !== ONLINE_SHOW && areTicketsRight);
    setDisabledFlags({
      next1,
      publish,
    });
  }, [show]);

  const isInPast = () => {
    if (!show.date || !show.time) return;
    const timeArray = show?.time.split(':');
    const mdate = moment(show.date).hours(timeArray[0]).minutes(timeArray[1]);
    return moment().isAfter(mdate);
  };

  const isEndBeforeStart = () => {
    if (!show.endDate || !show.endTime) return;
    const timeArray = show?.time.split(':');
    const startDate = moment(show.date)
      .hours(timeArray[0])
      .minutes(timeArray[1]);
    const endTimeArray = show?.endTime.split(':');
    const endDate = moment(show.endDate)
      .hours(endTimeArray[0])
      .minutes(endTimeArray[1]);
    return startDate.isAfter(endDate);
  };

  const handleUploadClick = () => {
    hiddenFileInput?.current?.click();
  };

  const handleFile = (e) => {
    if (e.target.files.length) {
      const fileReader = new FileReader();
      fileReader.onloadend = () => {
        setImageDirty(true);
        handleChange('eventImage', fileReader.result);
      };
      fileReader.readAsDataURL(e.target.files[0]);
    }
  };

  const handleTicketChange = (change: any, index: number, field: string) => {
    const newTickets = [...show.ticketTypes];
    newTickets.splice(index, 1, {
      ...show.ticketTypes[index],
      [field]: change,
    });
    handleChange('ticketTypes', newTickets);
  };

  const addTicketType = () => {
    handleChange('ticketTypes', [...show.ticketTypes, DEFAULT_TICKET_TYPE]);
  };

  const removeTicketType = (i: number) => {
    const newTickets = [...show.ticketTypes];
    newTickets.splice(i, 1);
    handleChange('ticketTypes', newTickets);
  };

  const getStatistics = useCallback(() => {
    let goal = 0;
    let count = 0;
    show.ticketTypes.forEach((t: ITicketType) => {
      goal +=
        parseInt(
          String(t.ticketPrice).replaceAll(thousandsSeparatorSymbol, '')
        ) * parseInt(String(t.numberOfTickets));
      count += parseInt(String(t.numberOfTickets));
    });
    setStatistics({
      goal,
      profit: parseInt(((goal * (100 - TICKET_FEE)) / 100).toFixed(0)),
      count,
    });
  }, [show]);

  useEffect(() => {
    getStatistics();
    getDisabledFlags();
  }, [show]);

  const save = () => {
    setLoading(true);
    const data = getPrepareObject();
    if (data.id) {
      return updateShow(data, imageDirty)
        .then((res) => {
          updateShowDetails(res);
          toast.success('Saved successfully.');
          setImageDirty(false);
        })
        .catch((e: Error) => {
          toast.error('Saving has failed.');
        })
        .finally(() => setLoading(false));
    } else {
      return saveShow(data)
        .then((res: any) => {
          toast.success('Saved successfully.');
          setImageDirty(false);
          history.push(`/editshow/${res.data.id}`);
        })
        .catch((e: Error) => {
          toast.error('Saving has failed.');
        })
        .finally(() => setLoading(false));
    }
  };

  const getPrepareObject = () => {
    const {
      id,
      showTitle,
      date,
      time,
      endDate,
      endTime,
      ticketTypes,
      eventImage,
      showType,
      ageRestriction,
      currency,
      isPrivate,
      seatingMap,
    } = show;
    const timeArray = time.split(':');
    const mdate = moment(date).hours(timeArray[0]).minutes(timeArray[1]);
    const utcTime = mdate.utc().format();

    const endTimeArray = endTime.split(':');
    const endMDate = moment(endDate)
      .hours(endTimeArray[0])
      .minutes(endTimeArray[1]);
    const endUtcTime = endMDate.utc().format();

    const description = stringifyContent(editorState);

    const data = {
      id,
      showTitle,
      description,
      showType: showType.value,
      currency: currency.value,
      location: getLocationObject(show.location?.value),
      showDate: utcTime,
      endDate: endUtcTime,
      ticketTypes,
      eventImage: eventImage?.split(',')[1], // pure base64 content
      ageRestriction: ageRestriction.value,
      isPrivate,
      seatingMap: seatingMap ? JSON.stringify(seatingMap) : null,
    };
    return data;
  };

  const publish = () => {
    setLoading(true);
    save().then(() => {
      publishShow(show.id)
        .then(() => {
          history.push(`/show/${show.id}`);
        })
        .catch((e) => {
          toast.error('Publishing went wrong.');
        })
        .finally(() => setLoading(false));
    });
  };

  const handleRemoveShow = () => {
    removeShow(showId)
      .then(() => {
        history.push('/');
      })
      .catch((e) => {
        toast.error('Something went wrong.');
      });
  };

  const nextPage = () => {
    containerRef.current?.scrollTo(0, 0);
    setPage(page + 1);
  };

  if (userData.verified !== 'VERIFIED') {
    return (
      <NeedVerification
        openModalDefault={true}
        cancelAction={() => history.push('/myshows')}
      />
    );
  }

  return (
    <Container>
      {!page && <GoBack />}
      <Heading
        title={`${showId ? lang.label_edit : lang.label_create} ${lang.event}`}
        help={['Create event']}
        trackingModalTitle={'help_new_show'}
      />
      {showId && (
        <RemoveItem
          className={'icon-remove'}
          onClick={() => setConfirmRemoveShow(true)}
        />
      )}
      <input
        type='file'
        style={{ display: 'none' }}
        ref={hiddenFileInput}
        // onChange={this.handleFileInputChange}
        onChange={handleFile}
        accept='image/*'
      />
      <Content className={`p016 noscroll`} ref={containerRef}>
        <MiddleWrapper>
          {page === 0 && (
            <>
              <Input
                type={'text'}
                label={lang.label_title}
                name={'name'}
                value={show.showTitle}
                onChange={(e) => handleChange('showTitle', e.target.value)}
                isvalid={validateLength(
                  show.showTitle?.length > 2,
                  show.showTitle
                )}
              />

              <Label>{lang.description}</Label>

              <TextEditor
                editorState={editorState}
                onEditorStateChange={setEditorState}
              />

              <Label>{lang.label_location}</Label>
              <AsyncSelect
                id={'address'}
                value={show.location}
                onChange={(location) => handleChange('location', location)}
                loadOptions={handleLoadAddressOptions}
                defaultOptions={[]}
                className={'address'}
                styles={selectStyles}
                cacheOptions
                placeholder={lang.address + '...'}
              />
              <InlineInputsContainer className={''}>
                <InlineInput>
                  <Input
                    label={lang.label_date}
                    type={'date'}
                    value={show.date}
                    inputstyle={{
                      minWidth: '-webkit-fill-available',
                      paddingRight: '5px',
                      paddingLeft: '8px',
                    }}
                    onChange={(e) => handleChange('date', e.target.value)}
                    min={defaultStartDate.format(dateFormat)}
                    isvalid={isInPast() ? 'error' : 'success'}
                  />
                </InlineInput>
                <InlineInput>
                  <Input
                    hasLabelSpace
                    type={'time'}
                    value={show.time}
                    inputstyle={{
                      minWidth: '-webkit-fill-available',
                      paddingRight: '5px',
                      paddingLeft: '8px',
                    }}
                    onChange={(e) => handleChange('time', e.target.value)}
                    isvalid={isInPast() ? 'error' : 'success'}
                  />
                </InlineInput>
              </InlineInputsContainer>
              <InlineInputsContainer className={''}>
                <InlineInput>
                  <Input
                    label={lang.label_end_date}
                    type={'date'}
                    value={show.endDate}
                    inputstyle={{
                      minWidth: '-webkit-fill-available',
                      paddingRight: '5px',
                      paddingLeft: '8px',
                    }}
                    onChange={(e) => handleChange('endDate', e.target.value)}
                    min={moment(show.date).format(dateFormat)}
                    isvalid={isEndBeforeStart() ? 'error' : 'success'}
                  />
                </InlineInput>
                <InlineInput>
                  <Input
                    hasLabelSpace
                    type={'time'}
                    value={show.endTime}
                    inputstyle={{
                      minWidth: '-webkit-fill-available',
                      paddingRight: '5px',
                      paddingLeft: '8px',
                    }}
                    onChange={(e) => handleChange('endTime', e.target.value)}
                    isvalid={isEndBeforeStart() ? 'error' : 'success'}
                  />
                </InlineInput>
              </InlineInputsContainer>

              <Label>
                {lang.show_image}{' '}
                <LabelDesc> {lang.show_event_image_size}</LabelDesc>
              </Label>
              {show.eventImage ? (
                <EventImage
                  onClick={handleUploadClick}
                  src={show.eventImage}
                  alt={'event image'}
                />
              ) : (
                <UploadButton
                  className={'flexCenter column'}
                  onClick={handleUploadClick}
                >
                  <i className={'icon-upload_img'} />
                  <div>Upload</div>
                </UploadButton>
              )}
              <SoftButton
                className={'mt3 fullWidth mb3'}
                disabled={disabledFlags.next1}
                onClick={nextPage}
              >
                {lang.continue}
              </SoftButton>
            </>
          )}

          {page === 1 && (
            <div className={'mb1'}>
              <Label>{lang.label_type}</Label>
              <Select
                styles={selectStyles}
                value={show.showType}
                options={getShowTypeValues(lang)}
                defaultValue={getShowTypeValues(lang)[0]}
                onChange={(e) => handleChange('showType', e)}
              />
              <div className={'fs12 mt5'}>
                {getShowTypeDescriptions(lang)[show.showType?.value]}
              </div>

              {/* <Label>{lang.settings_currency}</Label>
              <Select
                styles={selectStyles}
                value={show.currency}
                options={currencySelectOptions}
                defaultValue={currencySelectOptions[0]}
                onChange={(e) => handleChange('currency', e)}
              /> */}

              {show.showType?.value !== 'SHOW' &&
                show.ticketTypes[0]?.isStreaming && (
                  <div className={'mt1'}>
                    <Label>{lang.show_streaming_ticket}</Label>
                    <TicketTypeComponent
                      i={0}
                      t={show.ticketTypes[0]}
                      handleTicketChange={handleTicketChange}
                      validateLength={validateLength}
                      lang={lang}
                      currencyIcon={currencyIcon}
                    />
                  </div>
                )}
              {show.showType?.value !== 'ONLINE_SHOW' && (
                <div>
                  <div
                    className={'relative'}
                    style={{ marginBottom: '1.5rem' }}
                  >
                    <Label>{lang.show_tickets}</Label>
                    <Description>
                      (Regular, VIP, Regular + TShirt...)
                    </Description>
                  </div>
                  {show.ticketTypes
                    .filter((t) => !t?.isStreaming)
                    .map((t: ITicketType, i) => {
                      const index =
                        show.showType?.value === 'HYBRID_SHOW' ? i + 1 : i;
                      return (
                        <TicketTypeComponent
                          key={`ticket-type-${i}`}
                          lang={lang}
                          i={index}
                          removeTicketType={removeTicketType}
                          handleTicketChange={handleTicketChange}
                          validateLength={validateLength}
                          t={t}
                          removable={
                            show.showType?.value === 'HYBRID_SHOW'
                              ? index > 1
                              : index > 0
                          }
                          currencyIcon={currencyIcon}
                        />
                      );
                    })}
                  <AddTicketType>
                    <SubscribeLocationButton
                      onClick={addTicketType}
                      className={'fas fa-plus-circle'}
                      style={{
                        fontSize: '20px',
                        width: '30px',
                        minWidth: '30px',
                      }}
                    />
                    <div onClick={addTicketType} className={'clickable'}>
                      {lang.show_add_ticket}
                    </div>
                  </AddTicketType>
                  <Count>
                    <Flex className='space-between' gap='2px'>
                      <label>{lang.show_count_total_tickets}:</label>
                      <div>{statistics.count}</div>
                    </Flex>
                    <Flex className='space-between' gap='2px'>
                      <label>{lang.show_count_funding_goal}:</label>
                      <div>
                        {statistics.goal} {currencyIcon}
                      </div>
                    </Flex>
                    <Flex className='space-between align-center' gap='2px'>
                      <Flex className='column'>
                        <label>{lang.show_count_profit}:</label>
                        <label style={{ fontSize: 12 }}>
                          {lang.show_count_profit_desc}
                        </label>
                      </Flex>
                      <div>
                        {statistics.profit} {currencyIcon}
                      </div>
                    </Flex>
                    {/* <InputNew
                      className={'countInput'}
                      style={{ flexGrow: 1 }}
                      type={'number'}
                      label={lang.show_count_total_tickets}
                      name={'totalTickets'}
                      value={statistics.count}
                      disabled
                      inputComponent={CurrencyInput}
                    />
                    <InputNew
                      className={'countInput'}
                      style={{ flexGrow: 1 }}
                      type={'number'}
                      label={lang.show_count_funding_goal}
                      name={'funding'}
                      icon={currencyIcon}
                      value={statistics.goal}
                      disabled
                      inputComponent={CurrencyInput}
                    />
                    <InputNew
                      className={'countInput'}
                      style={{ flexGrow: 1 }}
                      type={'number'}
                      label={lang.show_count_profit}
                      name={'profit'}
                      value={statistics.profit}
                      icon={currencyIcon}
                      disabled
                      inputComponent={CurrencyInput}
                    /> */}
                  </Count>
                </div>
              )}

              <Label>{lang.show_age_restriction}</Label>
              <Select
                styles={selectStyles}
                value={show.ageRestriction}
                options={getShowAgeRestriction(lang)}
                defaultValue={getShowAgeRestriction(lang)[0]}
                onChange={(e) => handleChange('ageRestriction', e)}
              />

              <Label>{lang.show_is_private}</Label>
              <SettingsOption>
                <span>{lang.show_private_event}</span>
                <FSwitch
                  onChange={(e) => handleChange('isPrivate', e)}
                  checked={show?.isPrivate}
                />
              </SettingsOption>
              <hr />
              <div className='flex space-between align-center flex-direction-column'>
                {show.seatingMap ? (
                  <Label className='noMargin xl mt1'>
                    {lang.show_seating_map}: {show.seatingMap.name}
                  </Label>
                ) : null}
                {show.isDraft === undefined ? (
                  <div className='mt2'>
                    {lang.show_save_to_select_seating_map}
                  </div>
                ) : (
                  <div className='flex flex-direction-column align-center'>
                    <Button
                      className='slim second fit-content'
                      onClick={() => history.push('/assignTickets/' + show.id)}
                    >
                      {show.seatingMap
                        ? lang.show_edit_seating_map
                        : lang.show_add_seating_map}
                    </Button>
                    <div>{lang.show_seating_map_description}</div>
                  </div>
                )}
              </div>
              <ButtonsContainer>
                {(show.isDraft === undefined || show.isDraft) && (
                  <Button
                    className={'mt2 slim'}
                    onClick={save}
                    disabled={disabledFlags.next1}
                  >
                    {lang.label_save}
                  </Button>
                )}
                {(show.isDraft !== undefined || show.isDraft) && (
                  <Button
                    className={'second'}
                    onClick={() => setConfirmPublish(true)}
                    disabled={disabledFlags.publish}
                  >
                    {lang.show_publish}
                  </Button>
                )}
              </ButtonsContainer>
            </div>
          )}
          {!!page && (
            <Button
              style={{ padding: 0 }}
              className={'second no-border mt1'}
              onClick={() => setPage(page - 1)}
            >
              Back
            </Button>
          )}
        </MiddleWrapper>
      </Content>
      {confirmPublish && (
        <Portal>
          <Popup
            title={lang.show_publish}
            content={lang.show_publish_message}
            submitAction={publish}
            submitText={lang.show_publish}
            cancelAction={() => setConfirmPublish(false)}
            cancelText={lang.close}
            trackingTitle={'show_publish'}
          />
        </Portal>
      )}
      {confirmRemoveShow && (
        <Portal>
          <Popup
            title={`${lang.delete} the ${lang.SHOW}`}
            content={lang.show_delete_desc}
            submitAction={handleRemoveShow}
            submitText={lang.delete}
            cancelAction={() => setConfirmRemoveShow(false)}
            cancelText={lang.close}
            trackingTitle={'show_publish'}
          />
        </Portal>
      )}
      {loading && <Spinner fullscreen />}
    </Container>
  );
};

export default CreateShow;
