import styled from 'styled-components';

export const TicketWrapper = styled.div`
  color: ${({ theme }) => theme.text};
  border: 1px solid ${({ theme }) => theme.gray};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.background};
  padding: 4px 8px;
`;

export const TicketHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 4px;
  font-weight: 500;
`;

export const TicketInfo = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
