import { useLayoutEffect, useContext } from "react";
import { fabric } from "fabric";
import CanvasContext from "src/pages/createSeatingMap/canvasContext";

import styles from "./AssignEditor.module.scss";
import { initGestures } from "../helpers/helpers";

const AssignEditor = () => {
  const { setCanvas } = useContext(CanvasContext);

  useLayoutEffect(() => {
    const isMobileDevice =
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
        navigator.userAgent
      );

    const container = document.getElementById("canvas")
      ?.parentNode as HTMLDivElement;

    const canvas = new fabric.Canvas("canvas", {
      height: container.clientHeight,
      width: container.clientWidth,
      fireRightClick: true,
      fireMiddleClick: true,
      stopContextMenu: true,
      backgroundColor: "#fff",
      backgroundImage: undefined,
      selection: !isMobileDevice,
    });

    const handleScroll = (opt) => {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.98 ** delta;
      if (zoom > 8) zoom = 8;
      if (zoom < 0.15) zoom = 0.15;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    };

    let isPanning = false;
    let isLongPress = false;
    let lastClientX = 0;
    let lastClientY = 0;
    let longPressTimer: NodeJS.Timeout | null = null;
    const LONG_PRESS_DURATION = 50;

    function isTouchEvent(e: MouseEvent | TouchEvent): e is TouchEvent {
      return "touches" in e;
    }

    canvas.on("mouse:down", function (opt) {
      const evt = opt.e as MouseEvent | TouchEvent;
      if (isTouchEvent(evt)) {
        lastClientX = evt.touches[0].clientX;
        lastClientY = evt.touches[0].clientY;
      } else {
        lastClientX = evt.clientX;
        lastClientY = evt.clientY;
      }

      longPressTimer = setTimeout(() => {
        isLongPress = true;
        isPanning = true;
        this.selection = false;
        this.discardActiveObject();
        this.requestRenderAll();
      }, LONG_PRESS_DURATION);
    });

    canvas.on("mouse:move", function (opt) {
      const e = opt.e as MouseEvent | TouchEvent;
      let clientX: number, clientY: number;

      if (isTouchEvent(e)) {
        clientX = e.touches[0].clientX;
        clientY = e.touches[0].clientY;
      } else {
        clientX = e.clientX;
        clientY = e.clientY;
      }

      if (
        longPressTimer &&
        (Math.abs(clientX - lastClientX) > 5 ||
          Math.abs(clientY - lastClientY) > 5)
      ) {
        clearTimeout(longPressTimer);
        longPressTimer = null;
      }

      if (isPanning && isLongPress) {
        const vpt = this.viewportTransform;
        vpt[4] += clientX - lastClientX;
        vpt[5] += clientY - lastClientY;
        this.requestRenderAll();
        lastClientX = clientX;
        lastClientY = clientY;
      }
    });

    canvas.on("mouse:up", function () {
      if (longPressTimer) {
        clearTimeout(longPressTimer);
        longPressTimer = null;
      }
      isPanning = false;
      isLongPress = false;
      this.setViewportTransform(this.viewportTransform);
    });

    canvas.on("selection:created", function (opt) {
      if (isPanning) {
        this.discardActiveObject();
        this.requestRenderAll();
      }
    });

    canvas.on("mouse:wheel", handleScroll);

    setCanvas(canvas);
    canvas.requestRenderAll();

    // Initialize gestures (this will only apply mobile gestures if on mobile device)
    initGestures(canvas);

    const fitCanvasToScreen = () => {
      if (!canvas) return;

      const objects = canvas.getObjects();
      if (objects.length === 0) return;

      canvas.calcOffset();
      const bounds = objects.reduce(
        (acc, obj) => {
          const coords = obj.getCoords(true);
          coords.forEach((point) => {
            acc.minX = Math.min(acc.minX, point.x);
            acc.minY = Math.min(acc.minY, point.y);
            acc.maxX = Math.max(acc.maxX, point.x);
            acc.maxY = Math.max(acc.maxY, point.y);
          });
          return acc;
        },
        { minX: Infinity, minY: Infinity, maxX: -Infinity, maxY: -Infinity }
      );

      const contentWidth = bounds.maxX - bounds.minX;
      const contentHeight = bounds.maxY - bounds.minY;

      // Calculate center based on the content width/height only
      const contentCenterX = contentWidth / 1.7;
      const contentCenterY = contentHeight / 2;

      // Calculate scale considering both dimensions
      const scaleX = container.clientWidth / contentWidth;
      const scaleY = container.clientHeight / contentHeight;
      const scale = Math.min(scaleX, scaleY);

      // Remove the scale limitation to allow content to fill the canvas
      const finalScale = scale;

      // Center the viewport
      const viewportCenterX = container.clientWidth / 2;
      const viewportCenterY = container.clientHeight / 2;

      const translateX = viewportCenterX - contentCenterX * finalScale;
      const translateY = viewportCenterY - contentCenterY * finalScale;

      // Apply the transformation
      canvas.setViewportTransform([
        finalScale,
        0,
        0,
        finalScale,
        translateX,
        translateY,
      ]);

      canvas.requestRenderAll();
    };

    // Add this function to handle initial loading
    const ensureProperFit = (attempts = 0, maxAttempts = 5) => {
      if (attempts >= maxAttempts) return;

      const objects = canvas?.getObjects() || [];
      if (objects.length === 0) {
        // If no objects are found, try again after a delay
        setTimeout(() => ensureProperFit(attempts + 1), 200);
        return;
      }

      // Check if any object has invalid dimensions
      const hasInvalidObjects = objects.some(
        (obj) =>
          !obj.width ||
          !obj.height ||
          isNaN(obj.left || 0) ||
          isNaN(obj.top || 0)
      );

      if (hasInvalidObjects) {
        // If objects haven't loaded properly, try again
        setTimeout(() => ensureProperFit(attempts + 1), 200);
        return;
      }

      fitCanvasToScreen();
    };

    // Replace the setTimeout call with our new function
    ensureProperFit();

    const handleResize = () => {
      const newWidth = container.clientWidth;
      const newHeight = container.clientHeight;

      canvas.setDimensions({
        width: newWidth,
        height: newHeight,
      });

      fitCanvasToScreen();
    };

    // Add the resize listener
    window.addEventListener("resize", handleResize);

    return () => {
      if (!isMobileDevice) {
        canvas.off("mouse:wheel", handleScroll);
      }
      window.removeEventListener("resize", handleResize);
      canvas.dispose();
    };
  }, []);

  // const handleDownloadImage = () => {
  //   var dataURL = canvas?.toDataURL({ format: "png", quality: 1.0 });

  //   var downloadLink = document.createElement("a") as HTMLAnchorElement;
  //   if (downloadLink && dataURL) {
  //     downloadLink.href = dataURL;
  //     downloadLink.download = `${seatingData[0].name}.png`;

  //     document.body.appendChild(downloadLink);

  //     downloadLink.click();

  //     document.body.removeChild(downloadLink);
  //   }
  // };

  // const handleDownloadJson = () => {
  //   const jsonString = JSON.stringify(seatingData, null, 2);

  //   const blob = new Blob([jsonString], { type: "application/json" });

  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = URL.createObjectURL(blob);
  //   downloadLink.download = "canvas_data.json";

  //   document.body.appendChild(downloadLink);

  //   downloadLink.click();

  //   document.body.removeChild(downloadLink);
  // };

  return (
    <div className={styles.view}>
      <canvas id="canvas" className={styles.canvas_div} />
    </div>
  );
};

export default AssignEditor;
