import React from 'react';
import { InputField } from '../../styling/globalStyling';
import styled from 'styled-components';

const defaultInputStyle = {
  width: '100%',
  height: '50px',
  lineHeight: '40px',
  paddingTop: '12px',
  paddingRight: '16px',
};

const InputNew = (props: any) => {
  const {
    label,
    description,
    inputStyle,
    reference,
    className,
    value,
    style,
    icon,
    inputComponent: InputComponent,
    onChange,
    ...propsToPass
  } = props;

  return (
    <InputContainer className={className} style={style}>
      {label && (
        <label className={`${value && 'filled'}`}>
          {label} <span>{description}</span>
        </label>
      )}
      {InputComponent ? (
        <InputComponent
          value={value ? value : ''}
          style={{ ...defaultInputStyle, ...inputStyle }}
          ref={reference}
          autoComplete='off'
          onChange={onChange}
        />
      ) : (
        <InputField
          value={value ? value : ''}
          style={{ ...defaultInputStyle, ...inputStyle }}
          ref={reference}
          autoComplete='off'
          onChange={onChange}
          {...propsToPass}
        />
      )}
      {icon && <Icon>{icon}</Icon>}
    </InputContainer>
  );
};

export default InputNew;

const InputContainer = styled.div`
  position: relative;
  input {
    width: 100%;
    height: 50px;
    line-height: 40px;
    padding-top: 12px;
  }

  label {
    position: absolute;
    pointer-events: none;
    font-weight: 500;
    font-size: 14px;
    text-transform: uppercase;
    transform: translate(0, 15px) scale(1);
    transform-origin: top left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    line-height: 1;
    left: 16px;
    white-space: nowrap;
    z-index: 1;
    min-width: 100%;
    color: ${({ theme }) => theme.text};
  }

  &:focus-within label,
  .filled {
    transform: translate(0, 9px) scale(0.7);
  }

  i {
    font-size: 18px;
    color: ${({ theme }) => theme.gray};
  }
`;
const Icon = styled.div`
  cursor: pointer;
  position: absolute;
  font-size: 14px;
  right: 15px;
  top: 20px;
  color: ${({ theme }) => theme.gray};
`;
