import { UseFormReturn } from 'react-hook-form';
import { Label } from 'src/styling/globalStyling';
import styled from 'styled-components';
import { useLang } from '../useLang';

export const CheckoutCheckboxes = ({
  form,
  className,
}: {
  form: UseFormReturn;
  className?: string;
}) => {
  const lang = useLang();
  const { register } = form;
  return (
    <div className={className}>
      <CheckboxWrapper>
        <input
          className='checkbox'
          type='checkbox'
          {...register('termsOfUse', { required: true })}
        />
        <LabelCheckbox>
          {lang.accept}{' '}
          <a
            href='https://bilet.rs/uslovi-koriscenja/'
            target='_blank'
            rel='noreferrer'
          >
            {lang.register_terms_of_use}
          </a>{' '}
          {lang.and}{' '}
          <a href='https://bilet.rs/politika-privatnosti/'>
            {lang.register_privacy_policy}
          </a>
        </LabelCheckbox>
      </CheckboxWrapper>
    </div>
  );
};

const CheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 14px;
`;

const LabelCheckbox = styled(Label)`
  margin: 0 !important;
  font-weight: 400;
  text-transform: initial;
  @media (max-width: 375px) {
    font-size: 10px;
  }
`;
