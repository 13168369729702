import { fabric } from "fabric";
import { getSeatingData, dispatchSeatingData } from "../seatingMapReducer";

class CustomShapeObject {
  constructor(options, canvas, id, values) {
    this.text = "";
    this.name = options.name || "";
    this.canvas = canvas;
    this.id = id; // Use passed uuid
    this.objectSubtype = "custom-shape";
    this.shapeGroup = null;
    this.shapeColor = values.shapeColor;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.angle = values.angle;
    this.top = values.top;
    this.left = values.left;
    this.ticketType = values.ticketType;
    this.fontSize = values.fontSize;
    this.fontWeight = values.fontWeight;
    this.textColor = values.textColor;
    this.isDrawing = true;
    this.points = [];
    this.currTop = 0;
    this.currLeft = 0;
  }

  create() {
    this.canvas.off("path:created");
    this.canvas.off("mouse:dblclick");

    this.canvas.set({
      isDrawingMode: true,
    });
    this.canvas.freeDrawingBrush.width = 2;
    this.canvas.freeDrawingBrush.color = "black";

    this.canvas.on("path:created", (e) => {
      this.handlePathCreated(e);
    });

    this.canvas.on("mouse:dblclick", () => {
      this.stopDrawing();
    });
  }

  handlePathCreated(event) {
    var path = event.path;
    this.points = path.path.map(function (point) {
      return { x: point[1], y: point[2] };
    });

    var polygon = new fabric.Polygon(this.points, {
      fill: this.shapeColor,
      stroke: "black",
      strokeWidth: 2,
    });

    var text = new fabric.Text(this.text, {
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      top: polygon.top + polygon.height / 2,
      left: polygon.left + polygon.width / 2,
      originX: "center",
      originY: "center",
    });

    this.shapeGroup = new fabric.Group([polygon, text], {
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      originX: "center",
      originY: "center",
      id: this.id, // Use passed uuid
      objectSubtype: this.objectSubtype,
      ticketType: this.ticketType,
    });

    this.canvas.add(this.shapeGroup);
    this.canvas.remove(path);

    this.save();
    this.stopDrawing();
  }

  stopDrawing() {
    this.canvas.set({
      isDrawingMode: false,
    });
    this.isDrawing = false;
  }

  colorChange(color) {
    this.shapeGroup._objects[0].set({
      fill: color,
    });
    this.shapeColor = color;
    this.save();
    this.canvas.renderAll();
  }

  textColorChange(color) {
    this.shapeGroup._objects[1].set({
      fill: color,
    });
    this.textColor = color;
    this.save();
    this.canvas.renderAll();
  }

  editText(value) {
    this.shapeGroup._objects[1].set({
      text: "",
    });
    this.text = value;
    this.save();
    this.canvas.renderAll();
  }

  editName(value) {
    this.name = value;
    this.save();
  }

  setFontSize(value) {
    this.shapeGroup._objects[1].set({
      fontSize: value,
    });
    this.fontSize = value;
    this.save();
    this.canvas.renderAll();
  }

  setFontWeight(value) {
    this.shapeGroup._objects[1].set({
      fontWeight: value,
    });
    this.fontWeight = value;
    this.save();
    this.canvas.renderAll();
  }

  save() {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const data = {
      dataOptions: {
        text: this.text,
        name: this.name,
        id: this.id,
        objectSubtype: this.objectSubtype,
      },
      dataValues: {
        top: this.shapeGroup.top,
        left: this.shapeGroup.left,
        scaleX: this.shapeGroup.scaleX,
        scaleY: this.shapeGroup.scaleY,
        angle: this.shapeGroup.angle,
        shapeColor: this.shapeColor,
        textColor: this.textColor,
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
        ticketType: this.ticketType,
        points: this.points,
      },
    };

    this.currTop = this.shapeGroup.top;
    this.currLeft = this.shapeGroup.left;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    seatingData.objects.set(this.id, data);

    seatingData.canvasJSON = this.canvas.toJSON();
    dispatchSeatingData(seatingData);
  }

  load(valuesJSON) {
    this.points = valuesJSON.points;
    this.shapeColor = valuesJSON.shapeColor;
    this.top = valuesJSON.top;
    this.left = valuesJSON.left;
    this.scaleX = valuesJSON.scaleX;
    this.scaleY = valuesJSON.scaleY;
    this.angle = valuesJSON.angle;
    this.fontSize = valuesJSON.fontSize;
    this.fontWeight = valuesJSON.fontWeight;
    this.textColor = valuesJSON.textColor;
    this.ticketType = valuesJSON.ticketType;

    var polygon = new fabric.Polygon(this.points, {
      fill: this.shapeColor,
      stroke: "black",
      strokeWidth: 2,
    });

    var text = new fabric.Text(this.text, {
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      top: polygon.top + polygon.height / 2,
      left: polygon.left + polygon.width / 2,
      originX: "center",
      originY: "center",
    });

    this.shapeGroup = new fabric.Group([polygon, text], {
      top: this.top,
      left: this.left,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      originX: "center",
      originY: "center",
      id: this.id, // Use passed uuid
      objectSubtype: this.objectSubtype,
      ticketType: this.ticketType,
      name: this.name,
      text: this.text,
    });

    // Update current position tracking
    this.currTop = this.top;
    this.currLeft = this.left;

    this.canvas.add(this.shapeGroup);
    this.canvas.renderAll();
    this.save();
  }

  delete() {
    this.canvas.remove(this.shapeGroup);
    this.canvas.renderAll();
    const seatingData = getSeatingData();
    seatingData.objects.delete(this.id);

    seatingData.canvasJSON = this.canvas.toJSON();
    dispatchSeatingData(seatingData);
  }

  copy() {
    const dataOptions = {
      text: "",
      name: this.name,
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      top: this.currTop,
      left: this.currLeft,
      scaleX: this.shapeGroup.scaleX,
      scaleY: this.shapeGroup.scaleY,
      angle: this.shapeGroup.angle,
      shapeColor: this.shapeColor,
      ticketType: this.ticketType,
      textColor: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      points: this.points,
    };

    const copiedObject = {
      type: "custom shape object",
      dataOptions: dataOptions,
      dataValues: dataValues,
    };

    return copiedObject;
  }

  updateDimensions(obj) {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    const dataOptions = {
      text: this.text,
      name: this.name,
      id: this.id, // Use passed uuid
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      top: obj.top,
      left: obj.left,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      angle: obj.angle,
      shapeColor: this.shapeColor,
      textColor: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      ticketType: this.ticketType,
      points: this.points,
    };

    this.currTop = obj.top;
    this.currLeft = obj.left;

    seatingData.updatedAt = formattedDate;

    seatingData.objects.set(this.id, {
      dataOptions: dataOptions,
      dataValues: dataValues,
    });

    dispatchSeatingData(seatingData);
  }

  setSelectable(value) {
    this.shapeGroup.set({
      selectable: value,
    });
    this.canvas.renderAll();
  }

  assignAll(value) {
    this.shapeGroup.set({
      ticketType: value,
    });
    this.ticketType = value;
    this.save();
    this.canvas.renderAll();
  }

  bringToFront() {
    this.canvas.bringToFront(this.shapeGroup);
    const seatingData = getSeatingData();

    const element = seatingData.objects.get(this.id);
    seatingData.objects.delete(this.id);
    seatingData.objects.set(this.id, element);

    seatingData.canvasJSON = this.canvas.toJSON();
    dispatchSeatingData(seatingData);
  }
}

export default CustomShapeObject;
