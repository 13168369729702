import styled from 'styled-components';
import visaCheck from '../../assets/images/paymentLogotips/visa-secure.jpg';
import mcIdCheck from '../../assets/images/paymentLogotips/mastercard_check.png';

import visa from '../../assets/images/paymentLogotips/Visa50.gif';
import dina from '../../assets/images/paymentLogotips/dina.jpg';
import maestro from '../../assets/images/paymentLogotips/maestro.png';
import mc from '../../assets/images/paymentLogotips/mastercard.png';
import wspay from '../../assets/images/paymentLogotips/wspay_logo.png';
import otp from '../../assets/images/paymentLogotips/OTP.jpg';

import styles from './PaymentLogotips.module.scss';
import { Flex } from 'src/styling/globalStyling';
import { HTMLAttributes } from 'react';

const PaymentLogotips = (props: HTMLAttributes<HTMLDivElement>) => {
  const { className, ...rest } = props;
  return (
    <Container className={`${styles.container} ${className}`} {...rest}>
      <img className={styles.logo} src={mc} alt='mastercard' />
      <img className={styles.logo} src={maestro} alt='maestro' />
      <img className={styles.logo} src={dina} alt='dina' />
      <img className={styles.logo} src={visa} alt='visa' />
      <a
        href='http://www.wspay.rs'
        title='Monri WSpay - Web Secure Payment Gateway'
        target='_blank'
        rel='noreferrer'
      >
        <img alt='Monri WSpay - Web Secure Payment Gateway' src={wspay} />
      </a>
      <a
        href='https://www.otpbanka.rs/'
        title='OTP Banka'
        target='_blank'
        rel='noreferrer'
      >
        <img alt='OTP Banka' src={otp} />
      </a>
      <Flex gap={'8px'}>
        <a
          href='https://rs.visa.com/pay-with-visa/security-and-assistance/protected-everywhere.html'
          target='_blank'
          rel='noreferrer'
        >
          <img className={styles.logo} src={visaCheck} alt='visa-secure' />
        </a>
        <a
          href='http://www.mastercard.com/rs/consumer/credit-cards.html'
          target='_blank'
          rel='noreferrer'
        >
          <img className={styles.logo} src={mcIdCheck} alt='mastercard' />
        </a>
      </Flex>
    </Container>
  );
};
export default PaymentLogotips;

const Container = styled.div`
  img {
    height: 38px;
  }
`;
