import { Flex, Link } from 'src/styling/globalStyling';
import styled from 'styled-components';
import { useLang } from 'src/components/useLang';
import IzjavaOKonverziji from './IzjavaOKonverziji';

const BiletFooter = () => {
  const lang = useLang();
  return (
    <BiletFooterContainer>
      <Flex className='fullWidth jusitfy-around'>
        <Flex gap={'8px'} className='column'>
          <Link href='/#/kontakt'>{lang.about_company}</Link>
          <Link href='/#/deliveryMethods'>{lang.delivery_methods}</Link>
        </Flex>
        <Flex gap='8px' className='column'>
          <Link href='https://bilet.rs/uslovi-koriscenja/' target='_blank'>
            {lang.terms_of_use}
          </Link>
          <Link href='https://bilet.rs/politika-privatnosti/' target='_blank'>
            {lang.privacy_policy}
          </Link>
          <Link href='/#/reklamacija'>{lang.reklamacija}</Link>
        </Flex>
      </Flex>
      <IzjavaOKonverziji />
    </BiletFooterContainer>
  );
};

export default BiletFooter;

const BiletFooterContainer = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  padding: 24px 12px;
  font-size: 14px;
  flex-direction: column;
  border-top: 1px solid ${({ theme }) => theme.gray};
`;
