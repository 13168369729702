import React, { useEffect, useState } from 'react';
import { TwitterPicker, ColorResult } from 'react-color';
import Input from 'src/components/input/input';
import { useLang } from 'src/components/useLang';
import { Flex } from 'src/styling/globalStyling';
import styles from './TableEditSection.module.scss';

interface Table {
  tableName: string;
  noOfSeats: number;
  seatColor: string;
  tableColor: string;
  tableRadius: number;
  seatRadius: number;
  seatSpacing: number;
  seatColorChange: (color: string) => void;
  tableColorChange: (color: string) => void;
  editName: (name: string) => void;
  adjustSize: (options: {
    tableRadius: number;
    seatRadius: number;
    seatSpacing: number;
  }) => void;
  setMinNoOfTickets: (value: number) => void;
  seatEditing: () => void;
  delete: () => void;
}

interface TableEditSectionProps {
  table: Table;
  cancel: () => void;
}

const TableEditSection: React.FC<TableEditSectionProps> = ({
  table,
  cancel,
}) => {
  const lang = useLang();
  const [tableName, setTableName] = useState<string>(table.tableName);
  const [seatSpacing, setSeatSpacing] = useState<number>(table.seatSpacing);
  const [seatRadius, setSeatRadius] = useState<number>(table.seatRadius);
  const [tableRadius, setTableRadius] = useState<number>(table.tableRadius);
  const [seatEditingEnabled, setSeatEditingEnabled] = useState<boolean>(false);
  const [seatColor, setSeatColor] = useState<string>('#6674a0');
  const [tableColor, setTableColor] = useState<string>('#95a8e0');
  const [seatColorPopup, setSeatColorPopup] = useState<boolean>(false);
  const [tableColorPopup, setTableColorPopup] = useState<boolean>(false);
  const [minNoOfTickets, setMinNoOfTickets] = useState<number>(5);

  useEffect(() => {
    setTableName(table.tableName);
    setSeatColor(table.seatColor);
    setTableColor(table.tableColor);
  }, [table]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === 'Backspace' &&
        (e.target as HTMLElement).tagName.toLowerCase() !== 'input'
      ) {
        handleDeleteSection();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [table]);

  const handleSeatColorChange = (newColor: ColorResult) => {
    setSeatColor(newColor.hex);
    table.seatColorChange(newColor.hex);
  };

  const handleTableColorChange = (newColor: ColorResult) => {
    setTableColor(newColor.hex);
    table.tableColorChange(newColor.hex);
  };

  const handleCancelBtn = () => {
    cancel();
  };

  const handleTableName = (value: string) => {
    setTableName(value);
    table.editName(value);
  };

  const handleSeatRadius = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeatRadius(parseInt(e.target.value));
    const opn = {
      tableRadius: tableRadius,
      seatRadius: parseInt(e.target.value),
      seatSpacing: seatSpacing,
    };
    table.adjustSize(opn);
  };

  const handleSeatSpacing = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSeatSpacing(parseInt(e.target.value));
    const opn = {
      tableRadius: tableRadius,
      seatRadius: seatRadius,
      seatSpacing: parseInt(e.target.value),
    };
    table.adjustSize(opn);
  };

  const handleTableRadius = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTableRadius(parseInt(e.target.value));
    const opn = {
      tableRadius: parseInt(e.target.value),
      seatRadius: seatRadius,
      seatSpacing: seatSpacing,
    };
    table.adjustSize(opn);
  };

  const handleMinNoOfTickets = (value: string) => {
    setMinNoOfTickets(parseInt(value));
    table.setMinNoOfTickets(parseInt(value));
  };

  const handleSeatEditing = () => {
    table.seatEditing();
    setSeatEditingEnabled((prevstate) => !prevstate);
  };

  const handleDeleteSection = () => {
    table.delete();
    cancel();
  };

  if (!table) {
    return null;
  }

  return (
    <div className={styles.table_edit_section}>
      <div className={styles.edit_section_header}>
        <div className={styles.edit_section_title}>{lang.map_editor_tbes}</div>
        <div
          className={styles.edit_section_cancel_btn}
          onClick={handleCancelBtn}
        >
          {lang.cancel}
        </div>
      </div>
      <Flex className='column' gap='8px'>
        <Flex className='column'>
          <label className={styles.label} htmlFor='tableName'>
            {lang.map_editor_tbes_name}:
          </label>
          <Input
            wrapperstyle={{ margin: 0 }}
            type='text'
            id='tableName'
            value={tableName}
            onChange={(e) => handleTableName(e.target.value)}
          />
        </Flex>
        <Flex className='column'>
          <label className={styles.label} htmlFor='noOfSeats'>
            {lang.map_editor_tbes_min_tickets}:
          </label>
          <Input
            wrapperstyle={{ margin: 0 }}
            type='number'
            min={0}
            max={table.noOfSeats}
            value={minNoOfTickets}
            onChange={(e) => handleMinNoOfTickets(e.target.value)}
          />
        </Flex>
        <Flex className='column'>
          <label className={styles.label} htmlFor='seatSpacing'>
            {lang.map_editor_tbes_seat_spacing}:
          </label>
          <input
            type='range'
            id='seatSpacing'
            min='1'
            max='30'
            step='1'
            value={seatSpacing}
            onChange={handleSeatSpacing}
          />
        </Flex>
        <Flex className='column'>
          <label className={styles.label} htmlFor='seatSize'>
            {lang.map_editor_tbes_seat_size}:
          </label>
          <input
            type='range'
            id='seatSize'
            min='3'
            max='20'
            step='1'
            value={seatRadius}
            onChange={handleSeatRadius}
          />
        </Flex>
        <Flex className='column'>
          <label className={styles.label} htmlFor='tableSize'>
            {lang.map_editor_tbes_table_size}:
          </label>
          <input
            type='range'
            id='tableSize'
            min='10'
            max='100'
            step='5'
            value={tableRadius}
            onChange={handleTableRadius}
          />
        </Flex>
        <div className={styles.table_seat_color_picker}>
          <div className={styles.color_picker}>
            <div>{lang.map_editor_tbes_seat_color}:</div>
            <div
              className={styles.color_display}
              onClick={() => {
                setSeatColorPopup(!seatColorPopup);
                setTableColorPopup(false);
              }}
            >
              <div
                className={styles.inner_color_display}
                style={{ backgroundColor: seatColor }}
              ></div>
            </div>
          </div>
          <div
            className={styles.colors}
            style={{ display: seatColorPopup ? 'flex' : 'none' }}
          >
            <TwitterPicker
              className={styles.c}
              color={seatColor}
              onChange={handleSeatColorChange}
              width='220px'
              triangle='top-right'
            />
          </div>
        </div>

        <div className={styles.table_color_picker}>
          <div className={styles.color_picker}>
            <div>{lang.map_editor_tbes_table_color}:</div>
            <div
              className={styles.color_display}
              onClick={() => {
                setTableColorPopup(!tableColorPopup);
                setSeatColorPopup(false);
              }}
            >
              <div
                className={styles.inner_color_display}
                style={{ backgroundColor: tableColor }}
              ></div>
            </div>
          </div>
          <div
            className={styles.colors}
            style={{ display: tableColorPopup ? 'flex' : 'none' }}
          >
            <TwitterPicker
              className={styles.c}
              color={tableColor}
              onChange={handleTableColorChange}
              width='220px'
              triangle='top-right'
            />
          </div>
        </div>
      </Flex>

      <div className={styles.edit_section_footer}>
        <div className={styles.del_sec_btn} onClick={handleDeleteSection}>
          {lang.map_delete_object}
        </div>
      </div>
    </div>
  );
};

export default TableEditSection;
