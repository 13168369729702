import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { stateToHTML } from 'draft-js-export-html';

export function parseContent(content) {
  return EditorState.createWithContent(convertFromRaw(JSON.parse(content)));
}

export function stringifyContent(editorState) {
  const contentState = editorState.getCurrentContent();
  const rawEditorState = convertToRaw(contentState);
  return JSON.stringify(rawEditorState);
}

export function editorContentToHtml(content) {
  if (!content) return;
  let options = {
    entityStyleFn: (entity) => {
      const entityType = entity.get('type').toLowerCase();
      if (entityType === 'link') {
        const data = entity.getData();
        return {
          element: 'a',
          attributes: {
            target: data.targetOption,
            href: data.url,
          },
        };
      }
    },
  };
  try {
    return stateToHTML(convertFromRaw(JSON.parse(content)), options);
  } catch (e) {
    console.error('Content is not json:', content);
    return '';
  }
}
