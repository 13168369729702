import welcomeImage from "../../assets/images/welcomeImage.jpg";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { useLang } from "src/components/useLang";
import { Link, CONTENT_WIDTH } from "src/styling/globalStyling";
import biletRsLogo from "../../assets/images/biletLogo.svg";

const Welcome = (props) => {
  const history = useHistory();
  const lang = useLang();
  return (
    <Wrapper>
      <Logo src={biletRsLogo} alt="biletRsLogo" />
      <Title>Bilet.rs</Title>
      <div className={"subtitle"}>{lang.welcome_note}</div>
      <div className={"subtitle"}>{lang.welcome_pharma_notes[0]}</div>
      <div className={"bottomBlock"}>
        <button
          onClick={() =>
            history.push({ pathname: "/register", state: props.location.state })
          }
        >
          {lang.register}
        </button>
        <div className={"bottomText"}>
          <span className={"text-medium"}>{lang.welcome_has_account}</span>{" "}
          <Link
            className={"text-bold underline"}
            onClick={() =>
              history.push({ pathname: "/login", state: props.location.state })
            }
          >
            {lang.login}
          </Link>
        </div>
      </div>
    </Wrapper>
  );
};

export default Welcome;

const Wrapper = styled.div`
	background: url(${welcomeImage}) no-repeat;
	background-size: cover;
	height: 100%;
	max-width: ${CONTENT_WIDTH};
	// margin: 0 -16px;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 0 32px;
	color: white;
	
	overflow-y: scroll;
	-webkit-scrollbar {
  	display: none;
	}
	-ms-overflow-style: none;
	::-webkit-scrollbar {
		width: 0px;
		background: transparent;
	}
	
	i {
	
		&.icon-logo {
			font-size: 270px;
			margin-top: auto;
			margin-bottom: -80px;
			justify-content: center;
			display: flex;
		}
	}
	.subtitle {
		text-align: center;
	}
	img {
		&.logo {
			margin-top: auto;
		}
		&.logoText {
			margin-top: 24px;
		}
	}
	.bottomBlock {
		margin-top: auto;
		display: flex;
		align-items: center;
		flex-direction: column;
		button {
			margin-top: 24px;
		}
		.bottomText {
			margin: 32px 0;
		}
	}
`;

const Logo = styled.img`
  max-width: 400px;
  margin-top: auto;
`;

const Title = styled.div`
  font-size: 50px;
  font-weight: 600;
  margin-top: -70px;
  color: #f4bf68;
  text-transform: uppercase;
  @media (max-width: 768px) {
    margin-top: -60px;
  }
`;
