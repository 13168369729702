import React from 'react';
import styled, { useTheme } from 'styled-components';
import { useTable, usePagination } from 'react-table';
import adminService from '../../adminService';
import Select from 'react-select';
import Input from '../../../../components/input/input';
import moment from 'moment';
import emptyAvatar from '../../../../assets/images/empty-avatar.jpg';
import { useQuery } from '@tanstack/react-query';

const CONTENT_WIDTH = '500px';

interface IUser {
  id: number;
  name?: string;
  fullName?: string;
  username: string;
  email: string;
  createdTime: string;
  accountStatus: 'ACTIVE' | 'INACTIVE';
  profilePhoto?: string;
  verified: 'VERIFIED' | 'UNVERIFIED' | 'PENDING';
  allowOnlyVerified: boolean;
  activityCounter: number;
  popularityCounter: number;
  isBusiness: boolean;
  idType?: string;
  idNumber?: string;
  verificationPhotoUrl?: string;
}

interface PageableSort {
  empty: boolean;
  sorted: boolean;
  unsorted: boolean;
}

interface Pageable {
  sort: PageableSort;
  offset: number;
  pageNumber: number;
  pageSize: number;
  paged: boolean;
  unpaged: boolean;
}

interface UsersResponse {
  content: IUser[];
  pageable: Pageable;
  last: boolean;
  totalPages: number;
  totalElements: number;
  size: number;
  number: number;
  sort: PageableSort;
  first: boolean;
  numberOfElements: number;
  empty: boolean;
}

// First, let's properly type the idTypesMAP
type IdType = 'ID' | 'PASSPORT';

const TableContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  gap: 20px;
`;

const TableContent = styled.div`
  display: flex;
  width: 100%;
  position: relative;
`;

export const UsersTab = () => {
  const theme = useTheme();
  const [pageSize, setPageSize] = React.useState(40);
  const [pageIndex, setPageIndex] = React.useState(0);
  const [selectedUser, setSelectedUser] = React.useState<IUser | null>(null);
  const [error, setError] = React.useState('');
  const [searchUserId, setSearchUserId] = React.useState('');
  const [filterUserId, setFilterUserId] = React.useState<number | null>(null);
  const [showPendingOnly, setShowPendingOnly] = React.useState(false);

  const {
    data: usersData,
    isLoading,
    refetch,
  } = useQuery<UsersResponse>(
    ['users', pageSize, pageIndex, filterUserId, showPendingOnly],
    async () => {
      const response = await adminService.getUsers(
        filterUserId || -1,
        pageSize,
        pageIndex,
        showPendingOnly ? 'PENDING' : undefined
      );
      return response.data;
    },
    {
      keepPreviousData: true,
      staleTime: 30000,
      onError: (err: unknown) => {
        const error = err as Error;
        setError(error.message);
      },
    }
  );

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchUserId(e.target.value);
  };

  const handleApplySearch = () => {
    const userId = parseInt(searchUserId, 10);
    if (isNaN(userId)) {
      setError('Please enter a valid user ID');
      return;
    }
    setFilterUserId(userId);
    setPageIndex(0); // Reset to first page when filtering
  };

  const handleClearSearch = () => {
    setSearchUserId('');
    setFilterUserId(null);
    setPageIndex(0);
  };

  const setIdType = (type: { value: string; label: string }) => {
    if (selectedUser) {
      setSelectedUser({ ...selectedUser, idType: type.value });
    }
  };

  const handleIdNumChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (selectedUser) {
      setSelectedUser({ ...selectedUser, idNumber: e.target.value });
    }
  };

  const verifyUser = (status: 'VERIFIED' | 'UNVERIFIED' | 'PENDING') => {
    if (!selectedUser) return;
    const data: any = {};

    if (status === 'VERIFIED') {
      data.idType = selectedUser.idType || '';
      data.idNumber = selectedUser.idNumber || '';
    }
    setError('');
    adminService
      .updateUser(selectedUser.id, status, data)
      .then(() => {
        refetch();
      })
      .catch((e: Error) => {
        setError(e.message);
      });
  };

  const setActivity = (val: number) => {
    if (selectedUser) {
      setSelectedUser({ ...selectedUser, activityCounter: val });
    }
  };

  const setPopularity = (val: number) => {
    if (selectedUser) {
      setSelectedUser({ ...selectedUser, popularityCounter: val });
    }
  };

  const saveActivity = () => {
    if (!selectedUser) return;

    setError('');
    adminService
      .setUserActivity(selectedUser.id, selectedUser.activityCounter)
      .then(() => {
        refetch();
      })
      .catch((e: Error) => {
        setError(e.message);
      });
  };

  const savePopularity = () => {
    if (!selectedUser) return;

    setError('');
    adminService
      .setUserPopularity(selectedUser.id, selectedUser.popularityCounter)
      .then(() => {
        refetch();
      })
      .catch((e: Error) => {
        setError(e.message);
      });
  };

  React.useEffect(() => {
    if (selectedUser?.id && usersData?.content) {
      const user = usersData.content.find((u) => u.id === selectedUser.id);
      if (user) {
        setSelectedUser(user);
      }
    }
  }, [usersData, selectedUser?.id]);
  return (
    <TableContainer>
      <FilterContainer>
        <Input
          wrapperstyle={{ margin: 0 }}
          type='number'
          placeholder='Enter User ID'
          value={searchUserId}
          onChange={handleSearchChange}
          style={{ maxWidth: '200px' }}
        />
        <button onClick={handleApplySearch} disabled={!searchUserId}>
          Apply
        </button>
        {filterUserId && <button onClick={handleClearSearch}>Clear</button>}
        <label style={{ display: 'flex', gap: '4px' }}>
          <input
            type='checkbox'
            onChange={() => setShowPendingOnly(!showPendingOnly)}
          />
          Show Pending
        </label>
      </FilterContainer>
      <TableContent>
        <TableWrapper>
          {error && <div className={'text-danger'}>{error}</div>}
          <Table
            columns={columns}
            data={usersData?.content || []}
            loading={isLoading}
            pageCount={usersData?.totalPages || 0}
            totalElements={usersData?.totalElements || 0}
            setSelectedUser={setSelectedUser}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageIndex={pageIndex}
            setPageIndex={setPageIndex}
          />
        </TableWrapper>
        <UserDetails>
          {selectedUser && (
            <div className={'userDetails'}>
              <div style={{ display: 'flex' }}>
                <div style={{ marginRight: '30px' }}>
                  <div className={'info'}>ID</div>
                  <label>
                    {selectedUser.id}{' '}
                    {selectedUser.isBusiness ? 'Business' : 'Regular'}
                  </label>
                  <br />
                  <div className={'info'}>Username</div>
                  <label>{selectedUser.username}</label>
                  <br />
                  <div className={'info'}>Full Name</div>
                  <label>{selectedUser.fullName}</label>
                  <br />
                  <div className={'info'}>Name</div>
                  <label>{selectedUser.name}</label>
                  <br />
                  <div className={'info'}>Email</div>
                  <label>{selectedUser.email}</label>
                  <br />
                  <div className={'info'}>Created</div>
                  <label>
                    {moment(selectedUser.createdTime).format(
                      'DD/MM/YYYY HH:mm'
                    )}
                  </label>
                  <br />
                  <div className={'info'}>Status</div>
                  <label>{selectedUser.accountStatus}</label>
                  <br />
                  <div className={'info'}>Verified</div>
                  <label>{selectedUser.verified}</label>
                </div>
                <div style={{ width: '300px' }}>
                  <label>ID Type</label>
                  <Select
                    value={
                      selectedUser.idType
                        ? idTypesMAP[selectedUser.idType as IdType]
                        : idTypesMAP.ID
                    }
                    className={'select'}
                    options={idTypes}
                    onChange={setIdType}
                  />
                  <Input
                    type={'text'}
                    label={'ID number'}
                    name={'idNumber'}
                    value={selectedUser.idNumber}
                    onChange={handleIdNumChange}
                    description={'(PUBLIC)'}
                    descriptionPositionUp
                  />
                </div>
              </div>
              <div className={'mt1'}>
                <InputGroup>
                  <Input
                    type={'number'}
                    label={'Activity'}
                    name={'activity'}
                    value={selectedUser.activityCounter}
                    onChange={(e) => setActivity(e.target.value)}
                  />
                  <button disabled={!selectedUser.id} onClick={saveActivity}>
                    SET
                  </button>
                </InputGroup>
                <InputGroup>
                  <Input
                    type={'number'}
                    label={'Popularity'}
                    name={'popularity'}
                    value={selectedUser.popularityCounter}
                    onChange={(e) => setPopularity(e.target.value)}
                  />
                  <button disabled={!selectedUser.id} onClick={savePopularity}>
                    SET
                  </button>
                </InputGroup>
              </div>
              <label className={'mt2 mb1'}>Set status</label>
              <div style={{ display: 'flex', flexDirection: 'row' }}>
                <button
                  disabled={!selectedUser.id}
                  className={'verification'}
                  onClick={() => verifyUser('VERIFIED')}
                >
                  VERIFIED
                </button>
                <button
                  disabled={!selectedUser.id}
                  className={'verification'}
                  onClick={() => verifyUser('PENDING')}
                  style={{ background: theme.yellow }}
                >
                  PENDING
                </button>
                <button
                  disabled={!selectedUser.id}
                  className={'verification'}
                  onClick={() => verifyUser('UNVERIFIED')}
                  style={{ background: theme.red }}
                >
                  UNVERIFIED
                </button>
              </div>
              {selectedUser.verificationPhotoUrl && (
                <div className={'idphoto'}>
                  <img
                    width={'100%'}
                    src={selectedUser.verificationPhotoUrl}
                    alt={'verification'}
                  />
                </div>
              )}
            </div>
          )}
        </UserDetails>
      </TableContent>
    </TableContainer>
  );
};

const columns = [
  {
    id: 'id',
    Header: 'ID',
    accessor: 'id',
    width: 40,
  },
  {
    id: 'profilePhoto',
    Header: '#',
    accessor: (d: IUser) => (
      <div>
        <img
          className={'profilePhoto'}
          src={d.profilePhoto || emptyAvatar}
          alt={'profile'}
          style={{ border: d.isBusiness ? '3px solid #00fff0' : '' }}
        />
      </div>
    ),
  },
  {
    id: 'verified',
    Header: 'Verified',
    accessor: (d: IUser) =>
      d.verified === 'VERIFIED' ? (
        <span className={'text-primary'}>Verified</span>
      ) : d.verified === 'UNVERIFIED' ? (
        <span className={'text-danger'}>Unverified</span>
      ) : (
        <span className={'text-warning'}>Pending</span>
      ),
  },
  {
    Header: 'Username/FullName',
    Cell: ({ row }: { row: { original: IUser } }) => (
      <span className='number'>
        <div>{row.original.username}</div>
        <div>{row.original.fullName}</div>
      </span>
    ),
  },
  {
    Header: 'Name',
    accessor: 'name',
    width: 200,
  },
  {
    Header: 'Email',
    accessor: 'email',
  },
];

const idTypes = [
  { value: 'ID' as IdType, label: 'Id' },
  { value: 'PASSPORT' as IdType, label: 'Passport' },
];

const idTypesMAP: Record<IdType, { value: IdType; label: string }> = {
  ID: { value: 'ID', label: 'Id' },
  PASSPORT: { value: 'PASSPORT', label: 'Passport' },
};

const FilterContainer = styled.div`
  padding-top: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: flex-start;
  input {
    margin: 0;
  }
  button {
    width: fit-content;
    max-width: unset !important;
    max-height: unset !important;
  }
`;

const TableWrapper = styled.div`
  width: calc(100% - ${CONTENT_WIDTH});
  min-width: 280px;
  overflow: scroll;
  table th {
    margin: 0;
    padding: 0 0.5rem;
    font-size: 14px;
    white-space: nowrap;
  }
`;

const UserDetails = styled.div`
  width: ${CONTENT_WIDTH};
  top: 0;
  right: 0;
  padding-top: 20px;
  padding-left: 10px;
  button.verification {
    max-width: 200px;
    max-height: 40px;
    padding: 10px;
    &:hover {
      opacity: 0.7;
    }
  }
  img {
    width: ${CONTENT_WIDTH};
  }
  td span.name {
    min-width: 200px;
  }
  .userDetails {
    .info {
      font-size: 12px;
      font-weight: 500;
      display: inline-block;
      width: 100px;
    }
    label {
      font-size: 14px;
      font-weight: 600;
      display: inline-block;
      min-width: 160px;
    }
    button.verification {
      max-width: 150px;
      margin-right: 15px;
    }
    .idphoto img {
      max-width: 100%;
    }
  }
`;

interface TableProps {
  columns: any[];
  data: IUser[];
  loading: boolean;
  pageCount: number;
  totalElements: number;
  setSelectedUser: (user: IUser) => void;
  pageSize: number;
  setPageSize: (size: number) => void;
  pageIndex: number;
  setPageIndex: (index: number) => void;
}

function Table({
  columns,
  data,
  loading,
  pageCount: controlledPageCount,
  setSelectedUser,
  pageSize,
  setPageSize,
  pageIndex,
  setPageIndex,
}: TableProps) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state,
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex, pageSize },
      manualPagination: true,
      pageCount: controlledPageCount,
    },
    usePagination
  );

  // Handle pagination changes
  const handlePageChange = (newPageIndex: number) => {
    setPageIndex(newPageIndex);
  };

  const handlePageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
    setPageIndex(0); // Reset to first page when changing page size
  };

  return (
    <>
      <table {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th {...column.getHeaderProps()}>
                  {column.render('Header')}
                  <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? ' 🔽'
                        : ' 🔼'
                      : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr
                {...row.getRowProps()}
                onClick={() => setSelectedUser(row.original)}
              >
                {row.cells.map((cell) => (
                  <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                ))}
              </tr>
            );
          })}
          <tr>
            {loading ? (
              <td colSpan={10000}>Loading...</td>
            ) : (
              <td colSpan={10000}>
                Showing {page.length} of ~{controlledPageCount * pageSize}{' '}
                results
              </td>
            )}
          </tr>
        </tbody>
      </table>
      <div className='pagination'>
        <button onClick={() => handlePageChange(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>
        <button
          onClick={() => handlePageChange(pageIndex - 1)}
          disabled={!canPreviousPage}
        >
          {'<'}
        </button>
        <button
          onClick={() => handlePageChange(pageIndex + 1)}
          disabled={!canNextPage}
        >
          {'>'}
        </button>
        <button
          onClick={() => handlePageChange(pageCount - 1)}
          disabled={!canNextPage}
        >
          {'>>'}
        </button>
        <span>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </span>
        <select
          value={pageSize}
          onChange={(e) => handlePageSizeChange(Number(e.target.value))}
        >
          {[10, 30, 50, 100].map((size) => (
            <option key={size} value={size}>
              Show {size}
            </option>
          ))}
        </select>
      </div>
    </>
  );
}

const InputGroup = styled.div`
  display: flex;
  align-items: flex-end;
  margin-top: 12px;

  > div {
    flex-grow: 1;
    margin-top: 0;
  }
  button {
    min-width: 60px;
    min-height: 44px;
    margin-left: 20px;
    margin-right: 20px;
    border-radius: 4px !important;
  }
`;
