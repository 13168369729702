import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import { useLang } from 'src/components/useLang';
import { Content } from 'src/pages/createFangout/createFangout';
import { Container } from 'src/styling/globalStyling';

const Reklamacija = () => {
  const lang = useLang();
  return (
    <Container>
      <Heading title={lang.reklamacija} />
      <GoBack />
      <Content>
        <p>
          Potrošač može da izjavi reklamaciju radi ostvarivanja svojih prava iz
          člana 51. i člana 80. Zakona o zaštiti potrošača ("Sl. glasnik RS",
          br. 88/2021), kao i zbog pogrešno obračunate cene i drugih
          nedostataka, usmeno na prodajnom mestu gde je ulaznica kupljena ili na
          sledeći način:
        </p>
        <p>- elektronskim putem – na mejl adresu office@bilet.rs,</p>
        <p>- poštom – na adresu sedišta Romanijska 7, 18000 Niš.</p>
        <p>
          Prilikom izjave reklamacije, potrošač je dužan da dostavi račun kao
          dokaz o kupovini (kopija računa, slip i sl.).
        </p>
      </Content>
    </Container>
  );
};

export default Reklamacija;
