import { HTMLAttributes } from 'react';
import MediaQuery from 'react-responsive';
import { useLang } from '../useLang';
import { BREAKING_POINT, Container, Flex } from 'src/styling/globalStyling';
import { Content } from 'src/pages/createFangout/createFangout';
import Heading from '../heading/heading';
import GoBack from '../goBack/goBack';

export const NeedBigScreen = (props: HTMLAttributes<HTMLDivElement>) => {
  const lang = useLang();
  const { children, ...rest } = props;
  return (
    <>
      <MediaQuery maxWidth={BREAKING_POINT}>
        <Container>
          <Heading title={lang.need_big_screen_title} />
          <GoBack />
          <Content className='p016'>
            <Flex className='full-screen-center'>
              <div>{lang.need_big_screen_message}</div>
            </Flex>
          </Content>
        </Container>
      </MediaQuery>
      <MediaQuery minWidth={BREAKING_POINT}>
        <div {...rest}>{children}</div>
      </MediaQuery>
    </>
  );
};
