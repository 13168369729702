import { useLang } from 'src/components/useLang';
import { ShowType } from 'src/models';
import { RemoveTicketBtn } from 'src/styling/globalStyling';
import { TicketHeader, TicketInfo, TicketWrapper } from './styledComponents';

export const SeatTicket = ({
  show,
  item,
  handleRemoveBtn,
}: {
  show: ShowType;
  item: any;
  handleRemoveBtn: (item: any) => void;
}) => {
  const lang = useLang();

  return (
    <TicketWrapper>
      <TicketHeader>
        <div className='section-name'>
          {lang.seat}: {item.objectName} - {item.seatName}
        </div>
        <RemoveTicketBtn onClick={() => handleRemoveBtn(item)}>
          <i className='fa fa-trash'></i>
        </RemoveTicketBtn>
      </TicketHeader>
      <TicketInfo>
        <div className='ticket-type-price'>
          {lang.ticket_price}: {item.ticketTypeInfo?.ticketPrice}{' '}
          {show.currency}
        </div>
      </TicketInfo>
    </TicketWrapper>
  );
};
