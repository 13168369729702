import React, { useState } from "react";
import styled from "styled-components";
import Input from "../../components/input/input";
import GoBack from "../../components/goBack/goBack";
import Heading from "../../components/heading/heading";
import { Container } from "../../styling/globalStyling";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import * as constants from "../../constants";
import { setUserData } from "../profile/profileActions";
import { store } from "../../index";
import { subscribeToFirebase } from "../../utils";
import authService from "./authService";

const Summary = styled.div`
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  margin-top: 32px;
`;

const SubmitButton = styled.button`
  margin-top: 14px;
  margin-bottom: 32px;
`;

function ResetPassword(props) {
  const history = useHistory();
  const { token } = useParams<{ token: string }>();

  //@ts-ignore
  const lang = useSelector((state) => state.home.language);
  const [password, setPassword] = useState("");
  const [eyeIcon, setEyeIcon] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [repPassEyeIcon, setRepPassEyeIcon] = useState(false);
  const [error, setError] = useState("");

  const save = () => {
    setError("");
    authService
      .resetNewPassword({ password, token })
      .then((res) => {
        localStorage.setItem(constants.SESSION_KEY, JSON.stringify(res.data));
        setUserData(store.dispatch, res.data.userProfile);
        subscribeToFirebase();
        history.push("/");
      })
      .catch((e) => {
        setError(e.message);
      });
  };

  return (
    <Container>
      <GoBack />
      <Heading title={lang.reset_pass_title} />
      <Container className={"p016"}>
        <Summary>{lang.reset_pass_summary}</Summary>
        <Input
          type={eyeIcon ? "text" : "password"}
          label={lang.reset_pass_new_password}
          name={"password"}
          icon={eyeIcon ? "icon-password_visible" : "icon-password_hidden"}
          iconClickHandler={() => setEyeIcon(!eyeIcon)}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <Input
          className={"mb2"}
          type={repPassEyeIcon ? "text" : "password"}
          label={lang.reset_pass_repeat_password}
          name={"repeatPassword"}
          value={repeatPassword}
          onChange={(e) => setRepeatPassword(e.target.value)}
          icon={
            repPassEyeIcon ? "icon-password_visible" : "icon-password_hidden"
          }
          iconClickHandler={() => setRepPassEyeIcon(!repPassEyeIcon)}
          isvalid={validateConfirmPass(password, repeatPassword)}
        />
        {error && <div className={"text-danger text-center"}>{error}</div>}
        <SubmitButton
          disabled={
            !password ||
            !repeatPassword ||
            validateConfirmPass(password, repeatPassword) === "error"
          }
          onClick={save}
        >
          {lang.label_save}
        </SubmitButton>
      </Container>
    </Container>
  );
}

export default ResetPassword;

function validateConfirmPass(pass, repeatPass) {
  if (!repeatPass) return undefined;
  if (pass === repeatPass) {
    return "success";
  } else {
    return "error";
  }
}
