import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import { useLang } from 'src/components/useLang';
import { Container, Link } from 'src/styling/globalStyling';
import styled from '@emotion/styled';

const BiletRsKontakt = () => {
  const lang = useLang();
  return (
    <Container className='p016'>
      <GoBack />
      <Heading title={lang.about_company} />
      <BilletContainer>
        <div>Naziv pravnog lica: BILET SOLUTIONS</div>
        <div>Adresa: Romanijska 7, Niš</div>
        <br />
        <div>Matični broj: 22061011</div>
        <div>PIB: 114721552</div>
        <div>
          Šifra delatnosti: 7990 Ostale usluge rezervacije i delatnosti povezane
          s njima
        </div>
        <br />
        <div>Telefon: +381(0)646705693</div>
        <div>
          Web adresa:{' '}
          <Link href='https://bilet.rs' target='_blank'>
            bilet.rs
          </Link>
        </div>
        <div>Email: prodaja@bilet.rs</div>
      </BilletContainer>
    </Container>
  );
};

export default BiletRsKontakt;

const BilletContainer = styled.div`
  font-size: 14px;
`;
