import React, { useState } from 'react';
import adminService from './adminService';
import styled from 'styled-components';
import Input from '../../components/input/input';
import moment from 'moment';
import { dateFormat } from '../../constants';
import Spinner from '../../components/Spinner/Spinner';
import { Tab } from './utils';
import Giveaway from './components/Giveaway';
import { UsersTab } from './components/UsersTab/UsersTab';

const now = moment().add(15, 'd');

const AdminHook = (props) => {
  const [error, setError] = useState('');
  // We'll start our table without any data

  const [tab, setTab] = useState(0);
  const [premiumValidTo, setPremiumValidTo] = useState(now.format(dateFormat));
  const [premiumDuration, setPremiumDuration] = useState(12);
  const [premiumKey, setPremiumKey] = useState('');
  const [spinner, setSpinner] = useState(false);

  const generatePremiumKey = () => {
    setSpinner(true);
    setPremiumKey('');
    setError('');
    const data = {
      activationCodeValidUntil: moment(premiumValidTo).utc().format(),
      validMonths: premiumDuration,
    };
    adminService
      .generatePremiumKey(data)
      .then((res: any) => {
        setPremiumKey(res.data?.premiumCode);
      })
      .catch((e) => setError(e.message))
      .finally(() => setSpinner(false));
  };

  const copyKey = () => {
    navigator.clipboard.writeText(premiumKey || 'No key');
  };

  return (
    <Styles>
      <Header className={'adminHeader'}>
        <Tab index={0} selectedTab={tab} setIndex={setTab}>
          Users
        </Tab>
        <Tab index={1} selectedTab={tab} setIndex={setTab}>
          Other
        </Tab>
        <Tab index={2} selectedTab={tab} setIndex={setTab}>
          Giveaway
        </Tab>
      </Header>
      <TabContent isActive={tab === 0}>
        <UsersTab />
      </TabContent>
      <TabContent isActive={tab === 1}>
        <div>
          <Input
            wrapperstyle={{ marginTop: '10px' }}
            label={'Date up to when key is valid'}
            type={'date'}
            value={premiumValidTo}
            inputstyle={{
              minWidth: '-webkit-fill-available',
              paddingRight: '5px',
              paddingLeft: '8px',
            }}
            onChange={(e) => setPremiumValidTo(e.target.value)}
            min={moment().format(dateFormat)}
            isvalid={moment().isAfter(premiumValidTo) ? 'error' : 'success'}
          />
          <div className={'flex align-end'}>
            <Input
              wrapperstyle={{ marginTop: '10px' }}
              value={premiumDuration}
              label={'Duration'}
              type={'number'}
              min={1}
              descriptionPositionUp
              description={`(months)`}
              onChange={(e) => setPremiumDuration(e.target.value)}
            />
            <button onClick={generatePremiumKey} className={'generateKey'}>
              <i className={'icon-logo_glass'} />
            </button>
          </div>
          {premiumKey && (
            <div className={'flex align-center space-between mt1 mb2'}>
              <PremiumKey>{premiumKey}</PremiumKey>
              <button className={'generateKey'} onClick={copyKey}>
                <i className={'fas fa-copy'} />
              </button>
            </div>
          )}
          {error && <div className={'text-danger'}>{error}</div>}
        </div>
        {spinner && <Spinner fullscreen />}
      </TabContent>
      <TabContent isActive={tab === 2}>
        <Giveaway />
      </TabContent>
    </Styles>
  );
};

export default AdminHook;

const PremiumKey = styled.div`
  font-size: 18px;
  font-weight: bold;
  user-select: all;
  line-height: 44px;
  margin-right: 20px;
`;
const Header = styled.div`
  height: 30px;
  display: flex;
`;

const TabContent = styled.div`
  display: ${({ isActive }) => (isActive ? 'flex' : 'none')};
  overflow: scroll;
  input {
    font-family: ${({ theme }) => theme.fontFamily};
    font-size: 16px;
    &::-webkit-calendar-picker-indicator {
      filter: invert(1);
      outline: unset;
      margin-left: 2px;
      &:active,
      &:focus {
        opacity: 0.7;
      }
    }
  }
  .generateKey {
    margin-left: 20px;
    padding: 14px;
    min-height: 44px;
    min-width: 44px;
    border-radius: 4px;
  }
`;

export const Styles = styled.div`
  // display: flex;
  background: #fff;
  color: #000;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: scroll;
  padding: 0.5rem;
  table {
    border-spacing: 0;
    border: 1px solid black;
    tr {
      :last-child {
        td {
          border-bottom: 0;
        }
      }
    }
    th,
    td {
      margin: 0;
      padding: 0.5rem;
      border-bottom: 1px solid black;
      border-right: 1px solid black;
      :last-child {
        border-right: 0;
      }
      img {
        width: 60px;
      }
    }
  }
  .pagination {
    padding: 0.5rem;
  }
  button {
    max-width: 50px;
    max-height: 20px;
    padding-top: unset;
    padding-bottom: unset;
    border-radius: unset;
  }
`;
