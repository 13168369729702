import { fabric } from 'fabric';
import { getSeatingData, dispatchSeatingData } from '../seatingMapReducer';
import { ARC_CONSTANTS } from '../constants';
class SeatingObject {
  constructor(options, canvas, uuid, values) {
    this.rows = options.rows;
    this.rowLength = options.rowLength;
    this.seatRadius = options.seatRadius;
    this.seatSpacing = options.seatSpacing;
    this.blockName = options.blockName;
    this.objectSubtype = 'seating-group';
    this.grpArr = [];
    this.seatGroups = [];
    this.rowNames = [];
    this.canvasRef = canvas;
    this.groupAdded = true;
    this.group = null;
    this.id = uuid; // Use passed uuid
    this.seatColor = values.seatColor;
    this.angle = values.angle;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.isBooking = false;
    this.currTop = 0;
    this.currLeft = 0;
    this.arcSettings = ARC_CONSTANTS;
    this.fontFamily = 'Poppins, sans-serif';
  }

  createSeats() {
    const { rowLength, rows } = this;
    this.grpArr = [];
    this.seatGroups = [];

    for (let row = 0; row < rows; row++) {
      for (let seat = 0; seat < rowLength; seat++) {
        const seatNumber = row * rowLength + seat + 1;
        const { x, y } = this.calculateArcPosition(row, seat);

        const seatCircle = new fabric.Circle({
          radius: this.seatRadius,
          fill: this.seatColor,
        });

        seatCircle.set('seatNumber', seatNumber);

        const seatLabel = new fabric.Text((seat + 1).toString(), {
          fontSize: this.seatRadius,
          fontFamily: this.fontFamily,
          fill: '#000',
          left: this.seatRadius,
          top: this.seatRadius,
          originX: 'center',
          originY: 'center',
        });

        const seatGroupItem = new fabric.Group([seatCircle, seatLabel], {
          left: x,
          top: y,
          selectable: false,
          seatNumber,
          ticketType: null,
          booked: false,
          blockName: this.blockName,
          seatName: '',
          seatType: 'Seating',
        });

        this.grpArr.push(seatGroupItem);
        this.seatGroups.push(seatGroupItem);

        seatGroupItem.on('mousedown', () => {
          this.toggleSeatSelection(seatGroupItem);
          this.modifySeatNums(seatNumber, rowLength);
        });
      }
    }

    this.addBlockHead();
    this.defultRowNames();
    this.groupSeats();
  }

  groupSeats() {
    const grpTop = this.group ? this.group.top : this.canvasRef.height / 2;
    const grpLeft = this.group ? this.group.left : this.canvasRef.width / 2;
    this.canvasRef.remove(this.group);

    // Set IDs for each seat group
    this.grpArr.forEach((seatGroup, index) => {
      if (seatGroup.type === 'group') {
        seatGroup.set('id', `${this.id}_seat_${index}`);
      }
    });

    this.group = new fabric.Group(this.grpArr, {
      top: grpTop,
      left: grpLeft,
      originX: 'center',
      originY: 'center',
      id: this.id, // Use the ID without 's' prefix
      angle: this.angle,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      objectSubtype: 'seating-group',
      blockName: this.blockName,
    });

    // Add custom properties to the group
    this.group.set({
      blockName: this.blockName,
      objectSubtype: 'seating-group',
      id: this.id,
      seatType: 'Seating',
    });

    this.canvasRef.add(this.group);
    this.groupAdded = true;
    this.save();
    this.canvasRef.renderAll();
  }

  ungroupSeats() {
    this.canvasRef.remove(this.group);
    this.grpArr.forEach((seatGroupItem) => {
      this.canvasRef.add(seatGroupItem);
    });

    this.groupAdded = false;
    this.canvasRef.renderAll();
  }

  clearUngroupSeats() {
    // this.canvasRef.remove(this.group);
    this.grpArr.forEach((seatGroupItem) => {
      this.canvasRef.remove(seatGroupItem);
    });

    // this.grpArr = [];
    // this.groupAdded = false;
    this.canvasRef.renderAll();
  }

  adjustSize(seatRadius, seatSpacing) {
    this.seatRadius = seatRadius;
    this.seatSpacing = seatSpacing;
    this.angle = this.group.angle;
    this.scaleX = this.group.scaleX;
    this.scaleY = this.group.scaleY;

    let rn = [];
    for (
      let i = this.rows * this.rowLength + 1;
      i < this.group._objects.length;
      i++
    ) {
      const y = {
        text: this.group._objects[i].text,
        left: this.group._objects[i].left,
        top: this.group._objects[i].top,
      };

      rn.push(y);
    }

    let sg = this.seatGroups;
    this.grpArr = [];
    this.seatGroups = [];
    this.rowNames = Array(this.rows).fill('');
    this.groupAdded = true;

    for (let row = 0; row < this.rows; row++) {
      for (let seat = 0; seat < this.rowLength; seat++) {
        const seatNumber = row * this.rowLength + seat + 1;

        const seatCircle = new fabric.Circle({
          radius: seatRadius,
          fill: this.seatColor,
        });

        seatCircle.set('seatNumber', seatNumber);

        const seatLabel = new fabric.Text((seat + 1).toString(), {
          fontSize: seatRadius,
          fill: '#000',
          left: seatRadius,
          top: seatRadius,
          originX: 'center',
          originY: 'center',
        });

        const seatGroupItem = new fabric.Group([seatCircle, seatLabel], {
          left: seat * (seatRadius * 2 + seatSpacing) + 30,
          top: row * (seatRadius * 2 + seatSpacing) + 30,
          selectable: false,
          seatNumber,
          ticketType: sg[seatNumber - 1].ticketType,
        });

        this.grpArr.push(seatGroupItem);
        this.seatGroups.push(seatGroupItem);

        seatGroupItem.on('mousedown', () => {
          this.toggleSeatSelection(seatGroupItem);
          this.modifySeatNums(seatNumber, this.rowLength);
        });
      }
    }

    this.addBlockHead();

    let idx = 0;
    for (let i = 0; i < rn.length; i++, idx += this.rowLength) {
      const top = this.seatGroups[idx].top;
      const left = this.seatGroups[idx].left;
      const text = new fabric.Text(rn[i].text, {
        fontSize: this.seatRadius,
        fontFamily: this.fontFamily,
        fill: 'black',
        top: top + this.seatSpacing,
        left: left - this.seatRadius,
        selectable: false,
      });
      this.rowNames.push(text);
      this.grpArr.push(text);
    }

    this.groupSeats();
    this.save();
  }

  addBlockHead() {
    // Calculate the bounds of all seats
    let minTop = Infinity;
    let minLeft = Infinity;

    this.seatGroups.forEach((seat) => {
      minTop = Math.min(minTop, seat.top);
      minLeft = Math.min(minLeft, seat.left);
    });

    // Position the block name above the highest seat and aligned with the leftmost seat
    const text = new fabric.Text(`${this.blockName}`, {
      fill: 'black',
      fontSize: this.seatRadius,
      fontFamily: this.fontFamily,
      top: minTop + 5 - this.seatRadius * 2, // Position above the highest seat
      left: minLeft + 10 - this.seatRadius, // Align with the leftmost seat
      selectable: false,
    });

    this.grpArr.push(text);
  }

  editBlockHead(value) {
    this.grpArr[this.rows * this.rowLength].set({
      text: value,
      fontFamily: this.fontFamily,
    });
    this.blockName = value;
    this.save();
    this.canvasRef.renderAll();
  }

  defultRowNames() {
    if (this.rowNames.length > 0) return;
    let idx = 0;
    for (let i = 0; i < this.rows; i++, idx += this.rowLength) {
      const top = this.seatGroups[idx].top;
      const left = this.seatGroups[idx].left;
      const text = new fabric.Text(`${i + 1}`, {
        fontSize: this.seatRadius,
        fontFamily: this.fontFamily,
        fill: 'black',
        top: top + this.seatSpacing,
        left: left - this.seatRadius,
        selectable: false,
      });
      this.rowNames.push(text);
      this.grpArr.push(text);
    }
  }

  addRowNames(i, value) {
    const isTextLengthShorter =
      this.grpArr[this.rows * this.rowLength + i + 1].text.length <
      value.length;

    const left =
      this.seatGroups[0].left -
      (isTextLengthShorter
        ? this.grpArr[this.rows * this.rowLength + i + 1].width +
          this.seatRadius
        : this.grpArr[this.rows * this.rowLength + i + 1].width +
          this.seatSpacing);

    const top = this.grpArr[i * this.rowLength].top;

    const targetElement = this.grpArr[this.rows * this.rowLength + i + 1];
    targetElement.set({
      top: top,
      text: value,
      left: left,
      fontSize: this.seatRadius,
      fontFamily: this.fontFamily,
    });

    // this.rowNames[i] = value;

    this.angle = this.group.angle;
    this.scaleX = this.group.scaleX;
    this.scaleY = this.group.scaleY;

    this.groupSeats();
    this.save();
    this.canvasRef.renderAll();
  }

  seatEditing() {
    if (this.groupAdded) {
      this.canvasRef.remove(this.group);
      this.ungroupSeats();
    } else {
      // this.canvasRef.add(this.group);
      this.groupSeats();
      this.clearUngroupSeats();
    }
    this.canvasRef.renderAll();
  }

  toggleSeatSelection(seatGroupItem) {
    if (this.isBooking) {
      this.toggleBookTickets(seatGroupItem);
      return;
    }
    const seatCircle = seatGroupItem.item(0);
    const isDiscarded = seatGroupItem.discarded;

    seatGroupItem.discarded = !isDiscarded;
    seatCircle.set({
      fill: isDiscarded ? this.seatColor : '#fff',
    });

    this.save();
  }

  toggleBookTickets(seatGroupItem) {
    const seatCircle = seatGroupItem.item(0);
    const isSelected = !seatCircle.get('selected');

    for (let i = 0; i < this.rows * this.rowLength; i++) {
      if (!this.group._objects[i].booked) {
        this.group._objects[i].item(0).set({
          fill: this.seatColor,
        });
      }
    }

    if (!seatGroupItem.booked) {
      seatCircle.set({
        fill: 'lightGreen',
      });
    } else {
      seatCircle.set({
        fill: this.seatColor,
      });
    }
    this.save();
  }

  bookAll() {
    for (let i = 0; i < this.rows * this.rowLength; i++) {
      if (this.group._objects[i].booked) {
        this.group._objects[i].item(0).set({
          fill: '#ccc',
        });
      }
    }
    this.canvasRef.renderAll();
  }

  modifySeatNums(seatNumber, cols) {
    if (this.isBooking) return;
    const seatLabel = this.seatGroups[seatNumber - 1].item(1);
    if (seatLabel.text === '') {
      this.addSeats(seatNumber, cols);
    } else {
      this.removeSeats(seatNumber, cols);
    }
  }

  removeSeats(seatNumber, cols) {
    seatNumber = parseInt(seatNumber);
    cols = parseInt(cols);
    let start = seatNumber % cols;
    if (start === 0) start = cols;
    let j = seatNumber;
    let no = this.seatGroups[seatNumber - 1].item(1).text;
    for (let i = start; i < cols; i++, j++) {
      if (this.seatGroups[j].item(1).text === '') continue;
      else {
        this.seatGroups[j].item(1).set({ text: `${no}` });
        no++;
      }
    }

    this.seatGroups[seatNumber - 1].item(1).set({ text: '' });
    this.save();
  }

  addSeats(seatNumber, cols) {
    seatNumber = parseInt(seatNumber);
    cols = parseInt(cols);
    let start = seatNumber % cols;
    if (start === 0) start = cols;
    let no = 1;
    let j = seatNumber - start;

    for (let i = 0; i < cols; i++, j++) {
      if (this.seatGroups[j].item(1).text === '' && i !== start - 1) {
        continue;
      } else {
        this.seatGroups[j].item(1).set({ text: `${no}` });
        no++;
      }
    }
    this.save();
  }

  save() {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
    const seatGroups = [];

    for (let i = 0; i < this.rows * this.rowLength; i++) {
      const seatGroup = {
        seat: {
          radius: this.group._objects[i]._objects[0].radius,
          left: this.group._objects[i]._objects[0].left,
          top: this.group._objects[i]._objects[0].top,
          fill: this.group._objects[i]._objects[0].fill,
          seatNumber: this.group._objects[i]._objects[0].seatNumber,
          discarded: this.group._objects[i].discarded,
        },
        seatLabel: {
          text: this.group._objects[i]._objects[1].text,
          left: this.group._objects[i]._objects[1].left,
          top: this.group._objects[i]._objects[1].top,
        },
        ticketType: this.group._objects[i].ticketType,
      };
      seatGroups.push(seatGroup);
    }

    let i = this.rows * this.rowLength;

    const blockHeader = {
      text: this.group._objects[i].text,
      left: this.group._objects[i].left,
      top: this.group._objects[i].top,
    };

    const rowNumbers = [];
    for (
      let i = this.rows * this.rowLength + 1;
      i < this.group._objects.length;
      i++
    ) {
      const y = {
        text: this.group._objects[i].text,
        left: this.group._objects[i].left,
        top: this.group._objects[i].top,
      };

      rowNumbers.push(y);
    }

    const dataOptions = {
      id: this.id,
      rows: this.rows,
      rowLength: this.rowLength,
      seatRadius: this.seatRadius,
      seatSpacing: this.seatSpacing,
      blockName: this.blockName,
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      sg: seatGroups,
      rn: rowNumbers,
      bn: blockHeader,
      top: this.group.top,
      left: this.group.left,
      seatColor: this.seatColor,
      angle: this.group.angle,
      scaleX: this.group.scaleX,
      scaleY: this.group.scaleY,
      arcSettings: this.arcSettings,
    };
    this.currTop = this.group.top;
    this.currLeft = this.group.left;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    // Use passed uuid
    seatingData.objects.set(this.id, {
      dataOptions,
      dataValues,
    });

    seatingData.canvasJSON = this.canvasRef.toJSON();

    dispatchSeatingData(seatingData);
  }

  updateDimensions(obj) {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;
    let sg = [];

    for (let i = 0; i < this.rows * this.rowLength; i++) {
      const y = {
        seat: {
          radius: this.group._objects[i]._objects[0].radius,
          left: this.group._objects[i]._objects[0].left,
          top: this.group._objects[i]._objects[0].top,
          fill: this.group._objects[i]._objects[0].fill,
          seatNumber: this.group._objects[i]._objects[0].seatNumber,
          discarded: this.group._objects[i].discarded,
        },
        seatLabel: {
          text: this.group._objects[i]._objects[1].text,
          left: this.group._objects[i]._objects[1].left,
          top: this.group._objects[i]._objects[1].top,
        },
        ticketType: this.group._objects[i].ticketType,
      };
      sg.push(y);
    }

    let i = this.rows * this.rowLength;

    let bn = {
      text: this.group._objects[i].text,
      left: this.group._objects[i].left,
      top: this.group._objects[i].top,
    };

    let rn = [];
    for (
      let i = this.rows * this.rowLength + 1;
      i < this.group._objects.length;
      i++
    ) {
      const y = {
        text: this.group._objects[i].text,
        left: this.group._objects[i].left,
        top: this.group._objects[i].top,
      };

      rn.push(y);
    }

    const dataOptions = {
      id: this.id,
      rows: this.rows,
      rowLength: this.rowLength,
      seatRadius: this.seatRadius,
      seatSpacing: this.seatSpacing,
      blockName: this.blockName,
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      sg: sg,
      bn: bn,
      rn: rn,
      top: obj.top,
      left: obj.left,
      seatColor: this.seatColor,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      angle: obj.angle,
      arcSettings: this.arcSettings,
    };

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    seatingData.objects.set(this.id, {
      dataOptions: dataOptions,
      dataValues: dataValues,
    });

    seatingData.canvasJSON = this.canvasRef.toJSON();
    dispatchSeatingData(seatingData);
  }

  delete() {
    this.canvasRef.remove(this.group);
    this.canvasRef.renderAll();
    this.grpArr = [];

    const seatingData = getSeatingData();
    seatingData.objects.delete(this.id);

    seatingData.canvasJSON = this.canvasRef.toJSON();
    dispatchSeatingData(seatingData);
  }

  loadSeats(valuesJSON) {
    // At the beginning of the method, restore arc settings if they exist
    if (valuesJSON.arcSettings) {
      this.arcSettings = valuesJSON.arcSettings;
    }

    const { rowLength, rows, seatRadius, seatSpacing } = this;

    this.seatColor = valuesJSON.seatColor;
    this.angle = valuesJSON.angle;
    this.scaleX = valuesJSON.scaleX;
    this.scaleY = valuesJSON.scaleY;

    let i = 0;
    for (let row = 0; row < rows; row++) {
      for (let seat = 0; seat < rowLength; seat++, i++) {
        const seatNumber = valuesJSON.sg[i].seat.seatNumber;

        const seatCircle = new fabric.Circle({
          radius: valuesJSON.sg[i].seat.radius,
          fill: valuesJSON.sg[i].seat.fill,
        });

        seatCircle.set('seatNumber', seatNumber);

        const seatLabel = new fabric.Text(
          valuesJSON.sg[i].seatLabel.text.toString(),
          {
            fontSize: seatRadius,
            fontFamily: 'Poppins, sans-serif',
            fill: '#000',
            left: seatRadius,
            top: seatRadius,
            originX: 'center',
            originY: 'center',
          }
        );

        // Calculate position using arc settings if enabled
        const { x, y } = this.calculateArcPosition(row, seat);

        const seatGroupItem = new fabric.Group([seatCircle, seatLabel], {
          left: x, // Use calculated x position
          top: y, // Use calculated y position
          selectable: false,
          seatNumber,
          booked: false,
          seatType: 'Seating',
          ticketType: valuesJSON.sg[i].ticketType,
          blockName: this.blockName,
          discarded: valuesJSON.sg[i].seat.discarded,
        });

        this.grpArr.push(seatGroupItem);
        this.seatGroups.push(seatGroupItem);

        seatGroupItem.on('mousedown', () => {
          this.toggleSeatSelection(seatGroupItem);
          this.modifySeatNums(seatNumber, rowLength);
        });
      }
    }

    this.addBlockHead();

    let idx = 0;
    for (let i = 0; i < valuesJSON.rn.length; i++, idx += this.rowLength) {
      const top = this.seatGroups[idx].top;
      const left = this.seatGroups[idx].left;
      const text = new fabric.Text(valuesJSON.rn[i].text, {
        fontSize: this.seatRadius,
        fontFamily: this.fontFamily,
        fill: 'black',
        top: top + this.seatSpacing,
        left: left - this.seatRadius,
        selectable: false,
      });
      this.rowNames.push(text);
      this.grpArr.push(text);
    }

    const grpTop = valuesJSON.top;
    const grpLeft = valuesJSON.left;
    this.canvasRef.remove(this.group);
    this.group = new fabric.Group(this.grpArr, {
      top: grpTop,
      left: grpLeft,
      originX: 'center',
      originY: 'center',
      id: this.id,
      objectSubtype: this.objectSubtype,
      blockName: this.blockName,
    });
    this.canvasRef.add(this.group);
    this.groupAdded = true;
    this.group.set({
      angle: this.angle,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
    });

    this.save();
    this.canvasRef.renderAll();
  }

  copy() {
    let sg = [];

    for (let i = 0; i < this.rows * this.rowLength; i++) {
      const y = {
        seat: {
          radius: this.group._objects[i]._objects[0].radius,
          left: this.group._objects[i]._objects[0].left,
          top: this.group._objects[i]._objects[0].top,
          fill: this.group._objects[i]._objects[0].fill,
          seatNumber: this.group._objects[i]._objects[0].seatNumber,
          discarded: this.group._objects[i].discarded,
        },
        seatLabel: {
          text: this.group._objects[i]._objects[1].text,
          left: this.group._objects[i]._objects[1].left,
          top: this.group._objects[i]._objects[1].top,
        },
        ticketType: this.group._objects[i].ticketType,
      };
      sg.push(y);
    }

    let i = this.rows * this.rowLength;

    let bn = {
      text: this.group._objects[i].text,
      left: this.group._objects[i].left,
      top: this.group._objects[i].top,
    };

    let rn = [];
    for (
      let i = this.rows * this.rowLength + 1;
      i < this.group._objects.length;
      i++
    ) {
      const y = {
        text: this.group._objects[i].text,
        left: this.group._objects[i].left,
        top: this.group._objects[i].top,
      };

      rn.push(y);
    }

    const dataOptions = {
      rows: this.rows,
      rowLength: this.rowLength,
      seatRadius: this.seatRadius,
      seatSpacing: this.seatSpacing,
      blockName: this.blockName,
      objectSubtype: this.objectSubtype,
    };

    const dataValues = {
      sg: sg,
      bn: bn,
      rn: rn,
      top: this.currTop,
      left: this.currLeft,
      seatColor: this.seatColor,
      angle: this.group.angle,
      scaleX: this.group.scaleX,
      scaleY: this.group.scaleY,
      arcSettings: this.arcSettings,
    };

    const copiedObject = {
      type: 'seating object',
      dataOptions: dataOptions,
      dataValues: dataValues,
    };

    // navigator.clipboard.writeText(JSON.stringify(copiedObject));
    return copiedObject;
  }

  colorChange(color) {
    for (let row = 0; row < this.rows; row++) {
      for (let seat = 0; seat < this.rowLength; seat++) {
        const seatNumber = row * this.rowLength + seat;
        const seatCircle = this.grpArr[seatNumber]._objects[0];
        seatCircle.set({
          fill: seatCircle.fill == '#ffffff' ? '#ffffff' : color,
        });
      }
    }
    this.seatColor = color;
    this.angle = this.group.angle;
    this.scaleX = this.group.scaleX;
    this.scaleY = this.group.scaleY;

    this.groupSeats();
    this.save();
    this.canvasRef.renderAll();
  }

  setSelectable(value) {
    this.group.set({
      selectable: value,
    });
    this.canvasRef.renderAll();
  }

  assignAll(value) {
    for (let i = 0; i < this.rows * this.rowLength; i++) {
      this.group._objects[i].set({
        ticketType: value,
      });
    }
    this.save();
    this.canvasRef.renderAll();
  }

  bringToFront() {
    this.canvasRef.bringToFront(this.group);
    const seatingData = getSeatingData();

    const element = seatingData.objects.get(this.id);
    seatingData.objects.delete(this.id);
    seatingData.objects.set(this.id, element);

    seatingData.canvasJSON = this.canvasRef.toJSON();
    dispatchSeatingData(seatingData);
  }

  updateArcSettings(settings) {
    this.arcSettings = settings;
    this.seatRadius = settings.seatSize / 2;
    this.seatSpacing = settings.yPadding;

    // Store current properties
    const currentProps = {
      top: this.group ? this.group.top : this.canvasRef.height / 2,
      left: this.group ? this.group.left : this.canvasRef.width / 2,
      angle: this.group ? this.group.angle : 0,
      scaleX: this.group ? this.group.scaleX : 1,
      scaleY: this.group ? this.group.scaleY : 1,
    };

    // Remove existing group temporarily
    if (this.group) {
      this.canvasRef.remove(this.group);
    }

    // Track bounds for block name positioning
    let minTop = Infinity;
    let minLeft = Infinity;

    // Update positions of existing seats
    for (let row = 0; row < this.rows; row++) {
      for (let seat = 0; seat < this.rowLength; seat++) {
        const seatIndex = row * this.rowLength + seat;
        const { x, y } = this.calculateArcPosition(row, seat);

        // Update seat group position
        this.seatGroups[seatIndex].set({
          left: x,
          top: y,
        });

        // Track minimum positions for block name
        minTop = Math.min(minTop, y);
        minLeft = Math.min(minLeft, x);

        // If this is the first seat in the row, update row name position
        if (seat === 0) {
          const rowNameIndex = this.rows * this.rowLength + 1 + row;
          if (this.grpArr[rowNameIndex]) {
            this.grpArr[rowNameIndex].set({
              top: y + this.seatSpacing,
              left: x - this.seatRadius,
            });
          }
        }
      }
    }

    // Update block name position (index is rows * rowLength)
    const blockNameIndex = this.rows * this.rowLength;
    if (this.grpArr[blockNameIndex]) {
      this.grpArr[blockNameIndex].set({
        top: minTop + 5 - this.seatRadius * 2,
        left: minLeft + 10 - this.seatRadius,
      });
    }

    // Regroup seats
    this.groupSeats();

    // Restore properties
    if (this.group) {
      this.group.set(currentProps);
    }

    this.save();
    this.canvasRef.renderAll();
  }

  calculateArcPosition(row, seat) {
    if (!this.arcSettings.isEnabled) {
      return {
        x: seat * (this.seatRadius * 2 + this.seatSpacing) + 30,
        y: row * (this.seatRadius * 2 + this.seatSpacing) + 30,
      };
    }

    const { degree, radius, reversed } = this.arcSettings;
    const angleInRadians = (degree * Math.PI) / 180;

    // Calculate base position for the row
    const baseRadius = radius + row * (this.seatRadius * 2 + this.seatSpacing);

    // Calculate angle for this seat (-angleInRadians/2 to angleInRadians/2)
    const seatAngle =
      -angleInRadians / 2 + (angleInRadians * seat) / (this.rowLength - 1);

    // Calculate position using polar coordinates
    // If reversed is true, multiply y by -1 to flip the arc
    return {
      x: baseRadius * Math.sin(seatAngle) + radius,
      y: (reversed ? -1 : 1) * baseRadius * Math.cos(seatAngle),
    };
  }

  recreateSeats() {
    // Store current properties
    const currentProps = {
      top: this.group ? this.group.top : this.canvasRef.height / 2,
      left: this.group ? this.group.left : this.canvasRef.width / 2,
      angle: this.group ? this.group.angle : 0,
      scaleX: this.group ? this.group.scaleX : 1,
      scaleY: this.group ? this.group.scaleY : 1,
    };

    // Clear existing seats
    if (this.group) {
      this.canvasRef.remove(this.group);
    }
    // this.grpArr = [];
    // this.seatGroups = [];
    // this.rowNames = [];

    // Create new seats
    this.createSeats();

    // Restore properties
    if (this.group) {
      this.group.set(currentProps);
    }
  }
}

export default SeatingObject;
