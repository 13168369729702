import { useState, useEffect, useRef } from 'react';
import {
  Container,
  Flex,
  MiddleWrapper,
  NarrowWrapper,
} from 'src/styling/globalStyling';
import { useUserData } from 'src/hooks/useUserData';
import axios from 'axios';
import config from 'src/config';
import { useHistory } from 'react-router-dom';
import Heading from 'src/components/heading/heading';
import { Content } from '../createFangout/createFangout';
import { useLang } from 'src/components/useLang';
import Spinner from 'src/components/Spinner/Spinner';

interface WSPayFormData {
  ShopID: string;
  ShoppingCartID: string;
  Version: string;
  TotalAmount: string;
  ReturnURL: string;
  ReturnErrorURL: string;
  CancelURL: string;
  Signature: string;
  CustomerFirstName: string;
  CustomerLastName: string;
  CustomerEmail: string;
  CustomerPhone: string;
  CustomerAddress: string | null;
  CustomerCity: string | null;
  CustomerZIP: string | null;
}

// const mockFormData = {
//   ShopID: SHOP_ID,
//   ShoppingCartID: '',
//   Version: '2.0',
//   TotalAmount: '',
//   ReturnURL: `${BASE_URL}/success`,
//   ReturnErrorURL: `${BASE_URL}/error`,
//   CancelURL: `${BASE_URL}/cancel`,
//   Signature: '',
//   CustomerFirstName: firstName,
//   CustomerLastName: lastName,
//   CustomerEmail: userData?.email,
//   CustomerPhone: '06012334455',
//   CustomerAddress: 'Neka adresa',
//   CustomerCity: 'Beograd',
//   CustomerZIP: '11000',
// };

const WSPay = () => {
  const history = useHistory();
  const { data } = (history.location.state as { data?: any }) || {};
  if (!data) {
    history.push('/');
    return null;
  }
  return <WsPayWithData data={data} />;
};

const WsPayWithData = ({ data }: { data: any }) => {
  const userData = useUserData();
  const formRef = useRef<HTMLFormElement>(null);
  const lang = useLang();

  const [formData, setFormData] = useState<WSPayFormData>();
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const initializeForm = async () => {
      const ticketList = data.tickets.map((t) => {
        return {
          ticketTypeId: t.id,
          numberOfTickets: t.numberOfTickets,
          bookingData: [
            {
              id: t.objId || t.id,
              title:
                t.type === 'seating'
                  ? `${t.objectName} - ${t.seatName}`
                  : t.type === 'table'
                  ? t.objectName
                  : t.ticketTypeInfo?.title || t.title,
              minSeats:
                t.type === 'table'
                  ? t.minSeats
                  : t.type === 'seating'
                  ? 1
                  : undefined,
              maxSeats:
                t.type === 'table'
                  ? t.maxSeats
                  : t.type === 'seating'
                  ? 1
                  : undefined,
              seatsTaken:
                t.type === 'table'
                  ? t.noOfTableSeats
                  : t.type === 'seating'
                  ? 1
                  : t.numberOfTickets,
            },
          ],
        };
      });

      const payload = {
        userName: data.userName || undefined,
        email: data.userEmail || undefined,
        showId: data.showId,
        ticketList,
      };
      try {
        let formDataResponse;
        if (!userData.id) {
          const url =
            config.baseApi + '/v1/public/wspay/generate-form/unauthenticated';
          formDataResponse = await axios.post(url, payload);
        } else {
          const url = config.baseApi + '/v1/payments/wspay/generate-form';
          formDataResponse = await axios.post(url, payload);
        }
        // Ged form data from backend
        if (formDataResponse.data) {
          setFormData(formDataResponse.data);
        }
      } catch (e) {
        setError(e.message);
      } finally {
        setLoading(false);
      }
    };

    initializeForm();
  }, [data]);

  useEffect(() => {
    // Submit form when formData is ready and signature is generated
    if (formData?.Signature && formRef.current) {
      formRef.current.submit();
    }
  }, [formData]);

  return (
    <Container>
      <Content className={`p016`}>
        {loading && <Spinner fullscreen />}
        <MiddleWrapper style={{ flex: 1 }}>
          {error ? (
            <div className='text-center text-danger mt2'>
              <div>{lang.payment_error}</div>
              <div>{error}</div>
            </div>
          ) : (
            formData && (
              <form
                ref={formRef}
                method='POST'
                action='https://formtest.wspay.biz/authorization.aspx'
              >
                {Object.entries(formData).map(([key, value]) => (
                  <input key={key} type='hidden' name={key} value={value} />
                ))}
              </form>
            )
          )}
        </MiddleWrapper>
      </Content>
    </Container>
  );
};

export default WSPay;

const COUNTDOWN_TIME = 30_000;

export const WSPayError = () => {
  const history = useHistory();
  const lang = useLang();

  const getSearchParams = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const params: Record<string, string> = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  const paymentDetails = getSearchParams();

  return (
    <Container>
      <Heading title={lang.payment_failed_title} />
      <Content className={`p016 pb200`}>
        <NarrowWrapper style={{ wordWrap: 'break-word' }}>
          {Object.entries(paymentDetails).map(([key, value]) => (
            <div key={key}>
              {key}: {value}
            </div>
          ))}
          <button className='mt2' onClick={() => history.push('/')}>
            {lang.go_to_home}
          </button>
        </NarrowWrapper>
      </Content>
    </Container>
  );
};

export const WSPaySuccess = () => {
  const history = useHistory();
  const lang = useLang();
  const userData = useUserData();

  const getSearchParams = () => {
    const searchParams = new URLSearchParams(history.location.search);
    const params: Record<string, string> = {};

    searchParams.forEach((value, key) => {
      params[key] = value;
    });

    return params;
  };

  // Now you can use it like:
  const paymentDetails = getSearchParams();

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      if (userData.id) {
        history.push('/mytickets');
      } else {
        history.push('/');
      }
    }, COUNTDOWN_TIME);
    return () => clearTimeout(timeoutId);
  }, []);

  const [countdown, setCountdown] = useState(COUNTDOWN_TIME / 1000);
  useEffect(() => {
    const interval = setInterval(() => {
      setCountdown((prev) => prev - 1);
    }, 1000);
    return () => clearInterval(interval);
  }, []);
  return (
    <Container>
      <Heading title={lang.payment_success_title} />
      <Content className={`p016`}>
        <NarrowWrapper style={{ wordWrap: 'break-word' }}>
          <div className='mt2 mb1'>
            {lang.payment_redirection_message} {countdown}s
          </div>
          {/** print all search params */}
          {Object.entries(paymentDetails).map(([key, value]) => (
            <div key={key}>
              {key}: {value}
            </div>
          ))}
          <div className='mt2'>{lang.payment_success_message}</div>
          {userData.id ? (
            <Flex gap='16px' className='mt1 column pb200'>
              <div>{lang.payment_success_message_etickets}</div>
              <button onClick={() => history.push('/mytickets')}>
                {lang.tickets}
              </button>
            </Flex>
          ) : (
            <button onClick={() => history.push('/')}>Home</button>
          )}
        </NarrowWrapper>
      </Content>
    </Container>
  );
};

export const WSPayCancel = () => {
  const history = useHistory();
  history.push('/');
  return null;
};
