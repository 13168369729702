import { useLang } from '../useLang';
import styled from 'styled-components';
import { currencyMap, BANK_FEE } from 'src/constants';

export const TotalAmount = ({
  total,
  currency,
  className,
}: {
  total: number;
  currency: string;
  className?: string;
}) => {
  const lang = useLang();
  return (
    <Total className={className}>
      <div>
        {lang.tickets}: {total > 0 ? total : 0},00 {currencyMap[currency]}
      </div>
      <div>
        {lang.ticket_payment_bankFee}:{' '}
        {total > 0 ? (total * BANK_FEE) / 100 : 0},00 {currencyMap[currency]}
      </div>
      <div className='total'>
        {lang.total}: {total > 0 ? total + (total * BANK_FEE) / 100 : 0},00{' '}
        {currencyMap[currency]}
      </div>
    </Total>
  );
};

const Total = styled.div`
  //color: ${({ theme }) => theme.gray};
  width: 100%;
  text-align: right;
  padding-right: 6px;
  font-size: 14px;
  .total {
    font-weight: 600;
    font-size: 18px;
  }
`;
