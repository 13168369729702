export function colourStyles(theme) {
  return {
    container: (styles) => ({ ...styles, borderRadius: '4px' }),
    menuList: (styles) => ({ ...styles, background: theme.background }),
    control: (styles, props) => {
      return {
        ...styles,
        backgroundColor: theme.background,
        height: '44px',
        color: theme.text,
        fontWeight: 500,
        fontSize: '16px',
        borderRadius: '4px',
        letterSpacing: '1px',
        border: `1px solid ${
          props.isFocused && props.menuIsOpen
            ? theme.primary
            : theme.selectBorder
        }`,
        '&:hover': {
          border: `1px solid ${theme.primary}`,
        },
        boxShadow: 'unset',
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? theme.selectOptionBgrSelected
          : isFocused
          ? theme.selectOptionsBg
          : null,
        color: isDisabled
          ? theme.selectOptionText
          : isSelected
          ? theme.selectOptionTextSelected
          : isFocused
          ? theme.selectOptionText
          : theme.selectOptionText,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: theme.selectOptionBgrSelected,
        },
      };
    },
    input: (styles) => ({ ...styles, color: theme.text }),
    placeholder: (styles) => ({ ...styles, color: theme.selectBackground }),
    singleValue: (styles, { data }) => ({ ...styles, color: theme.text }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      color: theme.text,
    }),
    valueContainer: (styles) => ({ ...styles, color: theme.text }),
  };
}

export function locationInputColourStyles(theme, locationHasError) {
  return {
    container: (styles) => ({ ...styles, borderRadius: '4px' }),
    menuList: (styles) => ({ ...styles, background: theme.secondBackground }),
    control: (styles, props) => {
      return {
        ...styles,
        backgroundColor: theme.background,
        height: '44px',
        color: theme.text,
        fontWeight: 500,
        fontSize: '16px',
        borderRadius: '4px',
        letterSpacing: '1px',
        border: locationHasError
          ? `1px solid ${theme.red}`
          : `1px solid ${
              props.isFocused && props.menuIsOpen
                ? theme.background
                : theme.background
            }`,
        '&:hover': {
          border: `1px solid ${theme.primary}`,
        },
        boxShadow: 'unset',
      };
    },
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? theme.selectOptionBgrSelected
          : isFocused
          ? theme.selectOptionBgrFocused
          : null,
        color: isDisabled
          ? theme.selectOptionText
          : isSelected
          ? theme.selectOptionTextSelected
          : isFocused
          ? theme.selectOptionTextSelected
          : theme.selectOptionText,
        cursor: isDisabled ? 'not-allowed' : 'default',

        ':active': {
          ...styles[':active'],
          backgroundColor: theme.selectOptionBgrSelected,
        },
      };
    },
    input: (styles) => ({ ...styles, color: theme.text }),
    placeholder: (styles) => ({ ...styles, color: theme.selectBackground }),
    singleValue: (styles, { data }) => ({ ...styles, color: theme.text }),
    indicatorSeparator: (styles) => ({ ...styles, display: 'none' }),
    dropdownIndicator: (base, state) => ({
      ...base,
      transition: 'all .2s ease',
      transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
      color: theme.text,
    }),
    valueContainer: (styles) => ({ ...styles, color: theme.text }),
  };
}

export function debounce(func, wait, immediate) {
  let timeout;

  return function executedFunction() {
    let context = this;
    let args = arguments;

    let later = function () {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    let callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
}

/* Validators */
const passRegex =
  '^(?=.*[A-Za-z\\d!#@$%^&(){}[\\]\\\\:;<>,.?~_=|\\+-])(?=.*\\d)[A-Za-z\\d!#@$%^&(){}[\\]\\\\:;<>,.?~_=|\\+-]{8,32}$';
const usernameRegex = '^\\S*$';
const emailRegex =
  '^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:.[a-zA-Z0-9-]+)*$';
// const urlRegex = '(http(s)?:\\/\\/.)?(www\\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%_+.~#?&//=]*)'
const urlRegex = '^(https?|chrome):\\/\\/[^\\s$.?#].[^\\s]*$';

export function validateName(name) {
  if (!name) return undefined;
  if (name.length > 2) {
    return 'success';
  } else {
    return 'error';
  }
}

export function validateNameRegister(name) {
  if (!name) return undefined;
  if (name.length > 2) {
    return 'success';
  } else {
    return 'error';
  }
}
export function validateFullnameRegister(fullName) {
  if (!fullName) return undefined;
  if (fullName.length > 2) {
    return 'success';
  } else {
    return 'error';
  }
}

export function validateFullname(fullName) {
  if (!fullName) return undefined;
  if (fullName.length > 2) {
    return 'success';
  } else {
    return 'error';
  }
}

export function validateBio(bio) {
  if (!bio) return undefined;
  if (bio.length < 1000) {
    return 'success';
  } else {
    return 'error';
  }
}

export function validateUsername(username) {
  if (!username) return undefined;
  if (new RegExp(usernameRegex).test(username)) {
    return 'success';
  } else {
    return 'error';
  }
}

export function validateEmail(email) {
  if (!email) return undefined;
  if (email.match(emailRegex)) {
    return 'success';
  } else {
    return 'error';
  }
}

export function validatePass(pass) {
  if (!pass) return undefined;
  if (pass.match(passRegex)) {
    return 'success';
  } else {
    return 'error';
  }
}

export function validateConfirmPass(pass, repeatPass) {
  if (!repeatPass) return undefined;
  if (pass === repeatPass) {
    return 'success';
  } else {
    return 'error';
  }
}

export function validateSocNetUrl() {
  if (!this.state.socNetUrl) return undefined;
  if (this.state.socNetUrl.match(urlRegex)) {
    return 'success';
  } else {
    return 'error';
  }
}

export const options = [
  { value: 'Accountant', label: 'Accountant' },
  { value: 'Actor / Actress', label: 'Actor 	/ Actress' },
  { value: 'Architect', label: 'Architect' },
  { value: 'Artist', label: 'Artist' },
  { value: 'Astronaut', label: 'Astronaut' },
  { value: 'Baker', label: 'Baker' },
  { value: 'Ballet Dancer', label: 'Ballet Dancer' },
  { value: 'Bartender', label: 'Bartender' },
  { value: 'Bellboy', label: 'Bellboy' },
  { value: 'Biologist', label: 'Biologist' },
  { value: 'Boxer', label: 'Boxer' },
  { value: 'Bus Driver', label: 'Bus Driver' },
  { value: 'Businessman', label: 'Businessman' },
  { value: 'Butcher', label: 'Butcher' },
  { value: 'Cameraman', label: 'Cameraman' },
  { value: 'Caretaker', label: 'Caretaker' },
  { value: 'Carpenter', label: 'Carpenter' },
  { value: 'Cashier', label: 'Cashier' },
  { value: 'Chef', label: 'Chef' },
  { value: 'Computer Programmer', label: 'Computer Programmer' },
  { value: 'Customs Officer', label: 'Customs Officer' },
  { value: 'Delivery Man', label: 'Delivery Man' },
  { value: 'Dentist', label: 'Dentist' },
  { value: 'Diver', label: 'Diver' },
  { value: 'Doctor', label: 'Doctor' },
  { value: 'Electrician', label: 'Electrician' },
  { value: 'Engineer', label: 'Engineer' },
  { value: 'Entrepreneur', label: 'Entrepreneur' },
  { value: 'Factory Worker', label: 'Factory Worker' },
  { value: 'Farmer', label: 'Farmer' },
  { value: 'Fireman', label: 'Fireman' },
  { value: 'Fisherman', label: 'Fisherman' },
  { value: 'Flight Attendant', label: 'Flight Attendant' },
  { value: 'Florist', label: 'Florist' },
  { value: 'Gardener', label: 'Gardener' },
  { value: 'Gas Station Attendant', label: 'Gas Station Attendant' },
  { value: 'Guitarist', label: 'Guitarist' },
  { value: 'Hairdresser', label: 'Hairdresser' },
  { value: 'Influencer', label: 'Influencer' },
  { value: 'Judge', label: 'Judge' },
  { value: 'Lawyer', label: 'Lawyer' },
  { value: 'Librarian', label: 'Librarian' },
  { value: 'Lifeguard', label: 'Lifeguard' },
  { value: 'Miner', label: 'Miner' },
  { value: 'Model', label: 'Model' },
  { value: 'Musician', label: 'Musician' },
  { value: 'Nurse', label: 'Nurse' },
  { value: 'Office Worker', label: 'Office Worker' },
  { value: 'Optician', label: 'Optician' },
  { value: 'Paramedic', label: 'Paramedic' },
  { value: 'Pharmacist', label: 'Pharmacist' },
  { value: 'Photographer', label: 'Photographer' },
  { value: 'Pilot', label: 'Pilot' },
  { value: 'Plumber', label: 'Plumber' },
  { value: 'Police Officer', label: 'Police Officer' },
  { value: 'Politician', label: 'Politician' },
  { value: 'Priest', label: 'Priest' },
  { value: 'Real Estate Agent', label: 'Real Estate Agent' },
  { value: 'Receptionist', label: 'Receptionist' },
  { value: 'Repairman', label: 'Repairman' },
  { value: 'Scientist', label: 'Scientist' },
  { value: 'Singer', label: 'Singer' },
  { value: 'Speaker', label: 'Speaker' },
  { value: 'Student', label: 'Student' },
  { value: 'Starlet', label: 'Starlet' },
  { value: 'Surgeon', label: 'Surgeon' },
  { value: 'Tailor', label: 'Tailor' },
  { value: 'Taxi Driver', label: 'Taxi Driver' },
  { value: 'Teacher', label: 'Teacher' },
  { value: 'Veterinarian', label: 'Veterinarian' },
  { value: 'Violinist', label: 'Violinist' },
  { value: 'Waiter', label: 'Waiter' },
];
