import { fabric } from "fabric";
import { DEFAULT_DISABLED_COLOR } from "../../constants";

class BookCustomShapesClass {
  constructor(options, canvas, uuid, values) {
    this.text = "";
    this.name = options.name || "";
    this.canvas = canvas;
    this.id = uuid; // Use passed uuid
    this.objectSubtype = "custom-shape";
    this.shapeGroup = null;
    this.shapeColor = values.shapeColor;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.angle = values.angle;
    this.top = values.top;
    this.left = values.left;
    this.ticketType = values.ticketType;
    this.fontSize = values.fontSize;
    this.fontWeight = values.fontWeight;
    this.textColor = values.textColor;
    this.isDrawing = true;
    this.points = [];
    this.taken = false;
    this.booked = false;
  }

  load(valuesJSON) {
    this.points = valuesJSON.points;
    this.shapeColor = valuesJSON.shapeColor;
    this.top = valuesJSON.top;
    this.left = valuesJSON.left;
    this.scaleX = valuesJSON.scaleX;
    this.scaleY = valuesJSON.scaleY;
    this.angle = valuesJSON.angle;
    this.fontSize = valuesJSON.fontSize;
    this.fontWeight = valuesJSON.fontWeight;
    this.textColor = valuesJSON.textColor;
    this.ticketType = valuesJSON.ticketType;

    var polygon = new fabric.Polygon(this.points, {
      fill: this.taken ? DEFAULT_DISABLED_COLOR : this.shapeColor,
      stroke: "black",
      strokeWidth: 2,
    });

    var text = new fabric.Text(this.text, {
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
      top: polygon.top + polygon.height / 2,
      left: polygon.left + polygon.width / 2,
      originX: "center",
      originY: "center",
    });

    this.shapeGroup = new fabric.Group([polygon, text], {
      top: this.top,
      left: this.left,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      color: this.shapeColor,
      originX: "center",
      originY: "center",
      id: this.id, // Use passed uuid
      objectSubtype: this.objectSubtype,
      seatType: "shape",
      selectable: false,
      booked: this.booked,
      taken: this.taken,
      ticketType: this.ticketType,
    });

    // this.shapeGroup.on("mousedown", () => {
    //   this.toggleShapeColor();
    // });
    this.canvas.add(this.shapeGroup);
    this.canvas.renderAll();
  }

  toggleShapeColor() {
    // if (this.taken) return;
    if (this.booked) {
      this.shapeGroup._objects[0].set({
        fill: "#666666",
      });
    } else {
      this.shapeGroup._objects[0].set({
        fill: this.shapeColor,
      });
    }
    this.canvas.renderAll();
  }

  toggleShape() {
    this.booked = !this.booked;
    this.shapeGroup._objects[0].set({
      fill: this.shapeColor,
    });
    this.canvas.renderAll();
  }

  toggleBooked() {
    this.booked = !this.booked;
    this.toggleShapeColor();
  }

  bookAll() {
    if (!this.booked) return;

    this.shapeGroup._objects[0].set({
      fill: DEFAULT_DISABLED_COLOR,
    });
    this.shapeGroup.taken = true;
    this.canvas.renderAll();
  }

  save() {
    if (!this.booked) return;

    this.shapeGroup.taken = true;
    this.taken = true;

    const newObj = {
      id: this.id, // Use passed uuid
      taken: true,
      ticketType: this.ticketType,
    };

    return newObj;
  }
}

export default BookCustomShapesClass;
