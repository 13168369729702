import { useContext, useEffect, useRef, useState, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid'; // Importing uuid for generating UUIDs
import styles from './Setter.module.scss';
import EditSection from '../EditSections/SeatingEditSection/SeatingEditSection';
import TableEditSection from '../EditSections/TableEditSection/TableEditSection';
import TextEditSection from '../EditSections/TextEditSection/TextEditSection';
import seatingObject from '../Objects/seatingObject';
import TableObject from '../Objects/tableObject';
import ShapeObject from '../Objects/shapeObject';
import CustomShapeObject from '../Objects/customShapeObject';
import AddTextObject from '../Objects/addTextObject';
import PolygonObject from '../Objects/polygonObject';
import Portal from 'src/components/portal/portal';
import Popup from 'src/components/popup/popup';
import toast from 'react-hot-toast';

import ShapeEditSection from '../EditSections/ShapeEditSection/ShapeEditSection';
import CanvasContext from '../canvasContext';
import { useHistory, useParams } from 'react-router-dom';
import {
  IconRectangle,
  IconTriangle,
  IconChevronDown,
  IconCircle,
  IconPolygon,
  IconPencil,
} from '@tabler/icons-react';
import { useSelector } from 'react-redux';
import {
  convertSeatingDataToMaps,
  dispatchSeatingData,
  getSeatingDataAsArrays,
  initialState,
} from '../seatingMapReducer';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import config from 'src/config';
import Spinner from 'src/components/Spinner';
import { Button, Flex, SoftButton } from 'src/styling/globalStyling';
import {
  CUSTOM_SHAPE_COLOR,
  DEFAULT_SEATING_GROUP_COLOR,
  DEFAULT_SHAPE_COLORS,
  DEFAULT_TABLE_COLOR,
  DEFAULT_TABLE_DIMENSIONS,
} from '../constants';
import Input from 'src/components/input/input';
import InputNew from 'src/components/inputNew/InputNew';
import { AddSettingsCard } from '../styledComponents';
import { useLang } from 'src/components/useLang';

const shapesArray = ['Circle', 'Triangle', 'Rectangle', 'Square'];

const Setter = () => {
  const history = useHistory();
  const lang = useLang();
  const { mapId } = useParams<{ mapId: string }>();
  const { canvas } = useContext<any>(CanvasContext);
  const isNewMap = mapId === 'new';

  const { isLoading, error } = useQuery<
    AxiosResponse<any>,
    AxiosError,
    AxiosResponse<any>,
    string[]
  >(['seatingMaps'], () => axios.get(`${config.baseApi}/v1/maps/${mapId}`), {
    enabled: !isNewMap,
    onSuccess: (data) => {
      const mapObj = JSON.parse(data.data.map);
      const arraysToMaps = convertSeatingDataToMaps(mapObj);
      dispatchSeatingData(arraysToMaps);
      loadUnifiedObjects(arraysToMaps);
    },
  });

  const seatingData = useSelector(
    (state: any) => state.seatingMapReducer.seatingData
  );

  const {
    mutate: save,
    isLoading: isSaving,
    error: errorSaving,
  } = useMutation(() => {
    const url = `${config.baseApi}/v1/maps/${mapId}`;
    const data = {
      map: JSON.stringify(getSeatingDataAsArrays()),
      mapName: seatingData.name,
    };

    return axios.put(url, data).then(() => {
      toast.success('Map Saved Successfully', {
        duration: 2000,
        position: 'top-center',
      });
    });
  });

  const {
    mutate: create,
    isLoading: isCreating,
    error: errorCreating,
  } = useMutation(
    () => {
      const url = `${config.baseApi}/v1/maps/`;
      const data = {
        map: JSON.stringify(getSeatingDataAsArrays()),
        mapName: seatingData.name,
      };
      return axios.post(url, data);
    },
    {
      onSuccess: (data) => {
        history.push(`/seatingMaps/${data.data.id}`);
      },
    }
  );

  const objectsCollection = useRef<any>({});

  const [rows, setRows] = useState<number>(5);
  const [cols, setCols] = useState<number>(10);
  const [sectionName, setSectionName] = useState<string>('Block A');

  const [objects, setObjects] = useState<any>({});

  const [tableName, setTableName] = useState<string>('Table 1');
  const [noOfSeats, setNoOfSeats] = useState<number>(6);
  const [minNoOfTickets, setMinNoOfTickets] = useState<number>(4);
  const [objectType, setObjectType] = useState<string>('new');
  const [addText, setAddText] = useState<string>('');
  const [toggleShape, setToggleShape] = useState<boolean>(true);
  const [selectedObject, setSelectedObject] = useState<any>(null);

  const isSeatEditing = useSelector(
    (state: any) => state.seatingMapReducer.isSeatEditing
  );

  useEffect(() => {
    return () => {
      dispatchSeatingData(initialState.seatingData);
    };
  }, []);

  const handleCancel = () => {
    history.goBack();
  };

  const handleCancelEditSection = () => {
    const id = selectedObject?.id;
    if (
      objects[id] &&
      !objects[id].groupAdded &&
      objects[id].objectSubtype === 'seating'
    ) {
      objects[id].seatEditing();
    }
    canvas.discardActiveObject();
    canvas.renderAll();
    setSelectedObject(null);
    setObjectType('new');
  };

  const handleDeleteObjects = useCallback(() => {
    const activeObjects = canvas.getActiveObjects();
    if (activeObjects.length > 0) {
      // Handle multiple selected objects
      activeObjects.forEach((obj: any) => {
        const id = obj.get('id');
        if (objects[id]) {
          objects[id].delete();
          setObjects((prevObjects: any) => {
            const newObjects = { ...prevObjects };
            delete newObjects[id];
            return newObjects;
          });
        }
      });
      canvas.discardActiveObject();
      setSelectedObject(null);
      setObjectType('new');
    }
    canvas.renderAll();
  }, [canvas, objects, selectedObject]);

  const handleSeatPlanName = (e) => {
    if (seatingData) {
      const newSeatingData = { ...seatingData, name: e.target.value };
      dispatchSeatingData(newSeatingData);
    }
  };

  const handleSaveSeatPlan = () => {
    if (isNewMap) {
      create();
    } else {
      save();
    }
  };

  const handleAddSeating = () => {
    const setterOptions = {
      rowLength: Number(cols),
      rows: Number(rows),
      seatRadius: 10,
      seatSpacing: 5,
      blockName: sectionName,
      isArcAdded: false,
    };

    const values = {
      seatColor: DEFAULT_SEATING_GROUP_COLOR,
      angle: 0,
      scaleX: 1,
      scaleY: 1,
      arcRadius: 150,
      arcAngle: Math.PI / 4,
    };

    const uuid = uuidv4();

    if (canvas) {
      const seating = new seatingObject(setterOptions, canvas, uuid, values);
      seating.createSeats();
      canvas.renderAll();
      setObjects((prevObjects) => ({ ...prevObjects, [seating.id]: seating }));
      objectsCollection.current[seating.id] = seating;
    }
  };

  const handleAddTable = () => {
    const tableOptions = {
      ...DEFAULT_TABLE_DIMENSIONS,
      noOfSeats: noOfSeats,
      tableName,
      minNoOfTickets: minNoOfTickets,
    };

    const values = {
      tableColor: DEFAULT_TABLE_COLOR,
      angle: 0,
      scaleX: 1,
      scaleY: 1,
    };

    const uuid = uuidv4();

    if (canvas) {
      const table = new TableObject(tableOptions, canvas, uuid, values);
      table.createTable();
      setObjects((prevObjects) => ({ ...prevObjects, [table.id]: table }));
      objectsCollection.current[table.id] = table;
    }
  };

  const handleAddShape = (value) => {
    const options = {
      objectSubtype: value,
      text: '',
    };
    const values = {
      shapeColor: DEFAULT_SHAPE_COLORS[value],
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      left: canvas.width / 2,
      top: canvas.height / 2,
      ticketType: null,
      fontSize: 16,
      fontWeight: 400,
      textColor: '#000',
    };
    const uuid = uuidv4();
    const shape = new ShapeObject(options, canvas, uuid, values);
    shape.create();

    setObjects((prevObjects) => ({ ...prevObjects, [shape.id]: shape }));
    objectsCollection.current[shape.id] = shape;
  };

  // Function to add custom shapes
  const handleCustomShapeAdd = () => {
    const values = {
      shapeColor: CUSTOM_SHAPE_COLOR,
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      left: canvas.width / 2,
      top: canvas.height / 2,
      ticketType: null,
      fontSize: 16,
      fontWeight: 400,
      textColor: '#000',
    };
    const customShape = new CustomShapeObject({}, canvas, uuidv4(), values);
    customShape.create();

    setObjects((prevObjects) => ({
      ...prevObjects,
      [customShape.id]: customShape,
    }));
    objectsCollection.current[customShape.id] = customShape;
  };

  // Function to add text
  const handleAddText = () => {
    const values = {
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      left: canvas.width / 2,
      top: canvas.height / 2,
      fontSize: 16,
      fontWeight: 400,
      textColor: '#000',
    };
    const addTextObject = new AddTextObject(
      { text: addText },
      canvas,
      uuidv4(),
      values
    );
    addTextObject.create();
    setObjects((prevObjects) => ({
      ...prevObjects,
      [addTextObject.id]: addTextObject,
    }));
    objectsCollection.current[addTextObject.id] = addTextObject;

    // reset text input
    setAddText('');
  };

  const handlePolygonAdd = () => {
    const values = {
      shapeColor: '#cccccc',
      scaleX: 1,
      scaleY: 1,
      angle: 0,
      left: canvas.width / 2,
      top: canvas.height / 2,
      ticketType: null,
    };
    const polygon = new PolygonObject({}, canvas, uuidv4(), values);
    polygon.create();

    setObjects((prevObjects) => ({ ...prevObjects, [polygon.id]: polygon }));
    objectsCollection.current[polygon.id] = polygon;
  };

  // const loadMapData = (seatingData) => {
  //   // loadSeatingGroups(seatingData);
  //   // loadTables(seatingData);
  //   // loadShapes(seatingData);
  //   // loadCustomShapes(seatingData);
  //   // loadPolygons(seatingData);
  //   // loadTexts(seatingData);
  //   // loadLandmarks(seatingData);
  // };

  const loadUnifiedObjects = (seatingData) => {
    const newObjects = {};

    seatingData.objects.forEach(({ dataOptions, dataValues }) => {
      let object;
      switch (dataOptions.objectSubtype) {
        case 'seating-group':
          object = new seatingObject(
            dataOptions,
            canvas,
            dataOptions.id,
            dataValues
          );
          object.loadSeats(dataValues);
          break;
        case 'table':
          object = new TableObject(
            dataOptions,
            canvas,
            dataOptions.id,
            dataValues
          );
          object.loadTable(dataValues);
          break;
        case 'Circle':
        case 'Rectangle':
        case 'Triangle':
        case 'Square':
          object = new ShapeObject(
            dataOptions,
            canvas,
            dataOptions.id,
            dataValues
          );
          object.create();
          break;
        case 'custom-shape':
          object = new CustomShapeObject(
            dataOptions,
            canvas,
            dataOptions.id,
            dataValues
          );
          object.load(dataValues);
          break;
        case 'polygon':
          object = new PolygonObject(
            dataOptions,
            canvas,
            dataOptions.id,
            dataValues
          );
          object.load(dataValues);
          break;
        case 'text':
          object = new AddTextObject(
            dataOptions,
            canvas,
            dataOptions.id,
            dataValues
          );
          object.create();
          break;
      }

      if (object) {
        objectsCollection.current[object.id] = object;
        newObjects[object.id] = object;
      }
    });

    setObjects(newObjects);
  };

  const pasteObject = (obj) => {
    let newObject;
    const uuid = uuidv4();

    // Adjust position for the pasted object
    obj.dataValues.top += 25;
    obj.dataValues.left += 25;

    switch (obj.type) {
      case 'seating object':
        newObject = new seatingObject(
          obj.dataOptions,
          canvas,
          uuid,
          obj.dataValues
        );
        newObject.loadSeats(obj.dataValues);
        newObject.bringToFront();
        break;
      case 'table object':
        newObject = new TableObject(
          obj.dataOptions,
          canvas,
          uuid,
          obj.dataValues
        );
        newObject.loadTable(obj.dataValues);
        newObject.bringToFront();
        break;
      case 'shape object':
        newObject = new ShapeObject(
          obj.dataOptions,
          canvas,
          uuid,
          obj.dataValues
        );
        newObject.create();
        break;
      case 'custom shape object':
        newObject = new CustomShapeObject(
          obj.dataOptions,
          canvas,
          uuid,
          obj.dataValues
        );
        newObject.load(obj.dataValues);
        break;
      case 'add text object':
        newObject = new AddTextObject(
          obj.dataOptions,
          canvas,
          uuid,
          obj.dataValues
        );
        newObject.create();
        break;
      case 'polygon object':
        newObject = new PolygonObject(
          obj.dataOptions,
          canvas,
          uuid,
          obj.dataValues
        );
        newObject.load(obj.dataValues);
        break;
    }

    if (newObject) {
      objectsCollection.current[newObject.id] = newObject;
      setObjects((prevObjects) => ({
        ...prevObjects,
        [newObject.id]: newObject,
      }));
    }
  };

  // Function to handle pasting copied object
  const handlePasteObject = () => {
    navigator.clipboard.readText().then((clipboardData) => {
      try {
        let objs = JSON.parse(clipboardData);
        if (!Array.isArray(objs)) {
          objs = [objs];
        }

        objs.forEach(pasteObject);
      } catch (e) {
        console.error('Failed to parse clipboard data:', e);
      }
    });
  };

  const handleMouseDown = (e) => {
    if (isSeatEditing) {
      return;
    }
    if (!e.target) {
      setSelectedObject(null);
      handleCancelEditSection();
      return;
    }
    const clickedId = e.target.id;

    if (
      !clickedId ||
      (e.target.seatType === 'Seating' && e.target.id.includes('seat'))
    ) {
      return;
    }

    setObjectType(clickedId);
    setSelectedObject(e.target);
  };

  // handling the selected group move such as changing the values for each objects such as top, left, angle etc
  const handleSelectedGroupMove = () => {
    const canvasJSON = canvas.toJSON(['id']); // Include 'id' in the properties to serialize

    canvasJSON.objects.forEach((obj: any) => {
      const id = obj.id;
      if (objects[id]) {
        const newObj = {
          left: obj.left,
          top: obj.top,
          angle: obj.angle,
          scaleX: obj.scaleX,
          scaleY: obj.scaleY,
        };
        objects[id].updateDimensions(newObj);
      }
    });
  };

  // triggers whenever we move an object here and there
  const handleObjectModified = (e) => {
    if (!e.target) {
      handleCancelEditSection();
      return;
    }
    const clickedId = e.target.id;

    if (clickedId === undefined) {
      handleSelectedGroupMove();
      return;
    }

    const targetObject = e.target;
    const id = targetObject.get('id');

    objects[id]?.save();
    objects[id]?.bringToFront();
  };

  // initializing the mousedown and object modified functions
  useEffect(() => {
    if (canvas) {
      canvas.on('object:modified', handleObjectModified);
      canvas.on('mouse:down', handleMouseDown);
    }

    return () => {
      if (canvas) {
        canvas.off('object:modified', handleObjectModified);
        canvas.off('mouse:down', handleMouseDown);
      }
    };
  }, [canvas, objects, handleMouseDown]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        (e.key === 'Backspace' || e.key === 'Delete') &&
        (e.target as HTMLElement).tagName.toLowerCase() !== 'input'
      ) {
        handleDeleteObjects();
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'v') {
        handlePasteObject();
      }
      if ((e.ctrlKey || e.metaKey) && e.key === 'c') {
        copyObject();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleDeleteObjects]);

  const copyObjects = () => {
    const copiedObjects: any[] = [];

    const selectedObjects = canvas.getActiveObject();
    if (selectedObjects && selectedObjects._objects) {
      selectedObjects._objects.forEach(({ id }) => {
        const object = objects[id];
        if (object) {
          copiedObjects.push(object.copy());
        }
      });
      navigator.clipboard.writeText(JSON.stringify(copiedObjects));
    }
  };

  const copyObject = () => {
    const copiedObjects: any[] = [];

    if (!selectedObject) {
      copyObjects();
      return;
    }

    const id = selectedObject?.id;
    const object = objects[id];
    if (object) {
      copiedObjects.push(object.copy());
    }

    navigator.clipboard.writeText(JSON.stringify(copiedObjects));
  };

  const getEditSection = () => {
    const objectId = selectedObject?.get('id');
    const objectType = selectedObject?.get('objectSubtype');

    if (objects[objectId]) {
      if (objectType === 'seating-group') {
        return (
          <EditSection
            seating={objects[objectId]}
            cancel={handleCancelEditSection}
          />
        );
      }

      if (objectType === 'table') {
        return (
          <TableEditSection
            table={objects[objectId]}
            cancel={handleCancelEditSection}
          />
        );
      }

      if (shapesArray.includes(objectType)) {
        return (
          <ShapeEditSection
            shape={objects[objectId]}
            cancel={handleCancelEditSection}
          />
        );
      }

      if (objectType === 'custom-shape') {
        return (
          <ShapeEditSection
            shape={objects[objectId]}
            cancel={handleCancelEditSection}
          />
        );
      }

      if (objectType === 'polygon') {
        return (
          <ShapeEditSection
            shape={objects[objectId]}
            cancel={handleCancelEditSection}
          />
        );
      }

      if (objectType === 'text') {
        return (
          <TextEditSection
            shape={objects[objectId]}
            cancel={handleCancelEditSection}
          />
        );
      }
    }

    // Render default edit section
    return (
      <>
        <Flex className='column' gap={'16px'}>
          {isNewMap ? (
            <>
              <Portal>
                <Popup
                  title={lang.map_deditor_create_map}
                  content={
                    <>
                      <p>{lang.map_editor_name_label}:</p>
                      <input
                        type='text'
                        placeholder='Cinema Screen 3'
                        id='seatplanName'
                        value={seatingData.name}
                        onChange={handleSeatPlanName}
                        style={{
                          padding: '10px',
                          fontSize: '16px',
                          width: '100%',
                          borderRadius: '8px',
                          border: '1px solid #ccc',
                        }}
                      />
                    </>
                  }
                  submitAction={handleSaveSeatPlan}
                  submitText={lang.map_editor_create_button_label}
                  cancelAction={handleCancel}
                  cancelText={lang.cancel}
                  trackingTitle='map_create'
                />
              </Portal>
            </>
          ) : (
            // Only show Save button when isNewMap is false
            <Button className='small' onClick={handleSaveSeatPlan}>
              Save
            </Button>
          )}

          <InputNew
            label={lang.map_editor_name_label}
            value={seatingData.name || ''}
            onChange={handleSeatPlanName}
          />
        </Flex>
        <AddSettingsCard>
          <label htmlFor='sectionName'>{lang.map_editor_add_seating}:</label>
          <InputNew
            label={lang.map_editor_add_seats_name}
            type='text'
            value={sectionName}
            onChange={(e) => setSectionName(e.target.value)}
          />
          <Flex gap={'4px'}>
            <InputNew
              label={lang.map_editor_add_seats_rows}
              type='number'
              value={rows}
              min={0}
              onChange={(e) => setRows(parseInt(e.target.value))}
            />
            <InputNew
              label={lang.map_editor_add_seats_seats}
              type='number'
              value={cols}
              min={0}
              onChange={(e) => setCols(parseInt(e.target.value))}
            />
          </Flex>
          <SoftButton onClick={handleAddSeating}>
            {lang.map_editor_add_button_label}
          </SoftButton>
        </AddSettingsCard>

        <AddSettingsCard>
          <label>{lang.map_editor_add_table}:</label>

          <InputNew
            label={lang.map_editor_add_table_name}
            type='text'
            value={tableName}
            onChange={(e) => setTableName(e.target.value)}
          />
          <Flex gap={'4px'}>
            <InputNew
              label={lang.map_editor_add_table_seats}
              type='number'
              min={0}
              value={noOfSeats}
              onChange={(e) => setNoOfSeats(parseInt(e.target.value))}
            />
            <InputNew
              label={lang.map_editor_add_table_min_tickets}
              type='number'
              min={0}
              value={minNoOfTickets}
              onChange={(e) => setMinNoOfTickets(parseInt(e.target.value))}
            />
          </Flex>
          <SoftButton onClick={handleAddTable}>
            {lang.map_editor_add_button_label}
          </SoftButton>
        </AddSettingsCard>

        <AddSettingsCard>
          <label>{lang.map_editor_add_text}:</label>
          <Input
            wrapperstyle={{ margin: 0 }}
            type='text'
            placeholder={lang.map_editor_add_text_text}
            value={addText}
            onChange={(e) => setAddText(e.target.value)}
          />
          <SoftButton onClick={handleAddText}>
            {lang.map_editor_add_button_label}
          </SoftButton>
        </AddSettingsCard>
        <div className={styles.shapes_card}>
          <div
            className={styles.shapes_heading}
            onClick={(e) => setToggleShape(!toggleShape)}
          >
            <IconChevronDown
              className={!toggleShape ? 'rotate-right' : 'rotate-left'}
            />
            SHAPE
          </div>
          <div
            className={styles.shapes_body}
            style={{
              opacity: toggleShape ? 1 : 0,
              height: toggleShape ? 'auto' : 0,
            }}
          >
            <div onClick={handlePolygonAdd}>
              <IconPolygon />
              Polygon
            </div>
            <div onClick={handleCustomShapeAdd}>
              <IconPencil />
              Draw
            </div>
            <div onClick={() => handleAddShape('Circle')}>
              <IconCircle />
              Circle
            </div>
            <div onClick={() => handleAddShape('Triangle')}>
              <IconTriangle />
              Triangle
            </div>
            <div onClick={() => handleAddShape('Rectangle')}>
              <IconRectangle />
              Rectangle
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className={styles.contWrapper}>
      {error && <div className='text-danger'>{error.message}</div>}
      {!isNewMap && isLoading && <Spinner fullscreen />}
      <div className={styles.controller}>{getEditSection()}</div>
    </div>
  );
};

export default Setter;
