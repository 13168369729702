import React, { useEffect, useState } from 'react';
import { TwitterPicker, ColorResult } from 'react-color';
import styles from './TextEditSection.module.scss';
import { DEFAULT_TABLE_COLOR } from '../../constants';
import { useLang } from 'src/components/useLang';
import Input from 'src/components/input/input';

interface Shape {
  text: string;
  textColor: string;
  fontSize: number;
  fontWeight: number;
  textColorChange: (color: string) => void;
  editText: (text: string) => void;
  setFontSize: (fontSize: number) => void;
  setFontWeight: (fontWeight: number) => void;
  delete: () => void;
}

interface TextEditSectionProps {
  shape: Shape;
  cancel: () => void;
}

const TextEditSection: React.FC<TextEditSectionProps> = ({ shape, cancel }) => {
  const lang = useLang();
  const [shapeName, setShapeName] = useState<string>(shape.text);
  const [fontSize, setFontSize] = useState<number>(16);
  const [fontWeight, setFontWeight] = useState<number>(400);
  const [textColor, setTextColor] = useState<string>(DEFAULT_TABLE_COLOR);
  const [textColorPopup, setTextColorPopup] = useState<boolean>(false);

  useEffect(() => {
    setShapeName(shape.text);
    setTextColor(shape.textColor);
    setFontSize(shape.fontSize);
    setFontWeight(shape.fontWeight);
  }, [shape]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === 'Backspace' &&
        (e.target as HTMLElement).tagName.toLowerCase() !== 'input'
      ) {
        handleDeleteSection();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [shape]);

  const handleTextColorChange = (newColor: ColorResult) => {
    setTextColor(newColor.hex);
    shape.textColorChange(newColor.hex);
  };

  const handleShapeName = (value: string) => {
    setShapeName(value);
    shape.editText(value);
  };

  const handleFontSize = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFontSize(parseInt(e.target.value));
    shape.setFontSize(parseInt(e.target.value));
  };

  const handleFontWeight = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFontWeight(parseInt(e.target.value));
    shape.setFontWeight(parseInt(e.target.value));
  };

  const handleDeleteSection = () => {
    shape.delete();
    cancel();
  };

  const handleCancelBtn = () => {
    cancel();
  };

  return (
    <div className={styles.shape_edit_section}>
      <div className={styles.edit_section_header}>
        <div className={styles.edit_section_title}>{lang.map_editor_tes}</div>
        <div
          className={styles.edit_section_cancel_btn}
          onClick={handleCancelBtn}
        >
          {lang.cancel}
        </div>
      </div>
      <div className={styles.name}>
        <label htmlFor='shapeName'>{lang.map_editor_tes_text}:</label>
        <Input
          wrapperstyle={{ margin: 0 }}
          type='text'
          placeholder={lang.map_editor_tes_text}
          id='shapeName'
          value={shapeName}
          onChange={(e) => handleShapeName(e.target.value)}
        />
      </div>
      <div className={styles.shape_font_size}>
        <label htmlFor='fontSize'>{lang.map_editor_tes_font_size}:</label>
        <input
          type='range'
          id='fontSize'
          min='4'
          max='30'
          step='2'
          value={fontSize}
          onChange={handleFontSize}
        />
      </div>
      <div className={styles.shape_font_weight}>
        <label htmlFor='fontWeight'>{lang.map_editor_tes_font_weight}:</label>
        <input
          type='range'
          id='fontWeight'
          min='100'
          max='900'
          step='100'
          value={fontWeight}
          onChange={handleFontWeight}
        />
      </div>
      <div className={styles.table_color_picker}>
        <div className={styles.color_picker}>
          <div>{lang.map_editor_tes_color}:</div>
          <div
            className={styles.color_display}
            onClick={() => {
              setTextColorPopup(!textColorPopup);
            }}
          >
            <div
              className={styles.inner_color_display}
              style={{ backgroundColor: textColor }}
            ></div>
          </div>
        </div>
        <div
          className={styles.colors}
          style={{ display: textColorPopup ? 'flex' : 'none' }}
        >
          <TwitterPicker
            className={styles.c}
            color={textColor}
            onChange={handleTextColorChange}
            width='220px'
            triangle='top-right'
          />
        </div>
      </div>
      <div className={styles.edit_section_footer}>
        <div className={styles.del_sec_btn} onClick={handleDeleteSection}>
          {lang.map_delete_object}
        </div>
      </div>
    </div>
  );
};

export default TextEditSection;
