import { fabric } from "fabric";
import { DEFAULT_DISABLED_COLOR } from "../../constants";

class BookShapesClass {
  constructor(options, canvas, uuid, values) {
    this.objectSubtype = options.objectSubtype;
    this.text = options.text;
    this.name = options.name || "";
    this.canvasRef = canvas;
    this.id = uuid; // Use passed uuid
    this.grpArr = [];
    this.shapeGroup = null;
    this.shapeColor = values.shapeColor;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.angle = values.angle;
    this.grpTop = values.top;
    this.grpLeft = values.left;
    this.fontSize = values.fontSize;
    this.fontWeight = values.fontWeight;
    this.textColor = values.textColor;
    this.ticketType = values.ticketType;
    this.taken = false;
    this.booked = false;
  }

  create() {
    if (this.objectSubtype === "Circle") this.createCircle();
    else if (this.objectSubtype === "Triangle") this.createTriangle();
    else if (this.objectSubtype === "Rectangle") this.createRectangle();
  }

  createCircle() {
    const circle = new fabric.Circle({
      radius: 20,
      fill: this.taken ? DEFAULT_DISABLED_COLOR : this.shapeColor,
    });

    const circleLabel = new fabric.Text(`${this.text}`, {
      left: 20,
      top: 20,
      originX: "center",
      originY: "center",
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
    });

    this.shapeGroup = new fabric.Group([circle, circleLabel], {
      left: this.grpLeft,
      top: this.grpTop,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      originX: "center",
      originY: "center",
      selectable: false,
      seatType: "shape",
      color: this.shapeColor,
      booked: false,
      taken: this.taken,
      id: this.id, // Use passed uuid
      ticketType: this.ticketType,
    });

    // this.shapeGroup.on("mousedown", () => {
    //   this.toggleShapeColor();
    // });

    this.grpArr.push(this.shapeGroup);
    this.canvasRef.add(this.shapeGroup);
    this.canvasRef.renderAll();
  }

  createTriangle() {
    const triangle = new fabric.Triangle({
      width: 40,
      height: 40,
      fill: this.taken ? DEFAULT_DISABLED_COLOR : this.shapeColor,
    });

    const triangleLabel = new fabric.Text(`${this.text}`, {
      left: 20,
      top: 20,
      originX: "center",
      originY: "center",
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
    });

    this.shapeGroup = new fabric.Group([triangle, triangleLabel], {
      left: this.grpLeft,
      top: this.grpTop,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      originX: "center",
      originY: "center",
      selectable: false,
      seatType: "shape",
      color: this.shapeColor,
      booked: false,
      taken: this.taken,
      id: this.id, // Use passed uuid
      ticketType: this.ticketType,
    });

    // this.shapeGroup.on("mousedown", () => {
    //   this.toggleShapeColor();
    // });

    this.grpArr.push(this.shapeGroup);
    this.canvasRef.add(this.shapeGroup);
    this.canvasRef.renderAll();
  }

  createRectangle() {
    const rectangle = new fabric.Rect({
      width: 60,
      height: 40,
      fill: this.taken ? DEFAULT_DISABLED_COLOR : this.shapeColor,
    });

    const rectangleLabel = new fabric.Text(`${this.text}`, {
      left: 20,
      top: 20,
      originX: "center",
      originY: "center",
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
    });

    this.shapeGroup = new fabric.Group([rectangle, rectangleLabel], {
      left: this.grpLeft,
      top: this.grpTop,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      selectable: false,
      seatType: "shape",
      booked: this.booked,
      taken: this.taken,
      color: this.shapeColor,
      angle: this.angle,
      originX: "center",
      originY: "center",
      id: this.id, // Use passed uuid
      ticketType: this.ticketType,
    });

    // this.shapeGroup.on("mousedown", () => {
    //   this.toggleShapeColor();
    // });

    this.grpArr.push(this.shapeGroup);
    this.canvasRef.add(this.shapeGroup);
    this.canvasRef.renderAll();
  }

  toggleShapeColor() {
    // if (this.taken || !this.ticketType) return;
    if (this.booked) {
      this.shapeGroup._objects[0].set({
        fill: "#666666",
      });
    } else {
      this.shapeGroup._objects[0].set({
        fill: this.shapeColor,
      });
    }
    this.canvasRef.renderAll();
  }

  toggleShape() {
    this.booked = !this.booked;
    this.shapeGroup._objects[0].set({
      fill: this.shapeColor,
    });
    this.canvasRef.renderAll();
  }

  toggleBooked() {
    this.booked = !this.booked;
    this.toggleShapeColor();
  }

  bookAll() {
    if (!this.booked) return;

    this.shapeGroup._objects[0].set({
      fill: DEFAULT_DISABLED_COLOR,
    });
    this.canvasRef.renderAll();
  }

  save() {
    if (!this.booked) return;

    this.shapeGroup.taken = true;
    this.taken = true;

    const newObj = {
      id: this.id, // Use passed uuid
      taken: true,
      ticketType: this.ticketType,
    };

    return newObj;
  }
}

export default BookShapesClass;
