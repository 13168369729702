export const DEFAULT_TABLE_COLOR = '#00d084';
export const DEFAULT_SEAT_COLOR = '#e0ffe4';
export const DEFAULT_DISABLED_COLOR = '#708090';
export const DEFAULT_SEATING_GROUP_COLOR = '#FCB900';

export const DEFAULT_SHAPE_COLORS = {
  Circle: '#7564c8',
  Triangle: '#69b7d4',
  Rectangle: '#e0aa6c',
};

export const CUSTOM_SHAPE_COLOR = '#8ED1FC';

export const DEFAULT_TABLE_DIMENSIONS = {
  seatRadius: 6,
  tableRadius: 20,
  seatSpacing: 15,
};

export const ARC_CONSTANTS = Object.freeze({
  isEnabled: false,
  degree: 24,
  radius: 500,
  seatSize: 18,
  yPadding: 5,
  reversed: false,
});

export const FONT_FAMILY = 'Poppins, sans-serif';
