import styled from "styled-components";

export const ForgotPassword = styled.div`
  font-size: 12px;
  text-decoration: underline;
  margin-top: 8px;
  cursor: pointer;
`;

export const LoginButton = styled.button`
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
`;

export const RegisterWrapper = styled.div`
  text-align: center;
  margin-bottom: 32px;
`;

export const RegisterButton = styled.span`
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
  text-decoration: underline;
  color: ${({ theme }) => theme.primary};
`;

export const LoginButtonWrapper = styled.div`
  /* max-width: 200px; */
  display: flex;
  justify-content: center;
  button {
    justify-content: center;
    max-width: 240px;
  }
  .terms {
    color: ${({ theme }) => theme.primary};
  }
`;
