import React, { useLayoutEffect, useContext } from 'react';
import styles from './Editor.module.scss';
import { fabric } from 'fabric';
import CanvasContext from '../canvasContext';
// import { IconDownload } from '@tabler/icons-react';
import { useSelector } from 'react-redux';

const Editor: React.FC = () => {
  const seatingData = useSelector(
    (state: any) => state.seatingMapReducer.seatingData
  );
  // const [toggle, setToggle] = useState<boolean>(false);

  const { setCanvas } = useContext<any>(CanvasContext);

  useLayoutEffect(() => {
    const container = document.getElementById('canvas')
      ?.parentNode as HTMLElement;

    const canvas = new fabric.Canvas('canvas', {
      height: container.clientHeight,
      width: container.clientWidth,
      fireRightClick: true,
      fireMiddleClick: true,
      stopContextMenu: true,
      backgroundColor: 'white',
      backgroundImage: undefined,
    });

    setCanvas(canvas);
    canvas.requestRenderAll();

    const handleScroll = (opt: any) => {
      var delta = opt.e.deltaY;
      var zoom = canvas.getZoom();
      zoom *= 0.999 ** delta;
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;
      canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
      opt.e.preventDefault();
      opt.e.stopPropagation();
    };

    canvas.on('mouse:wheel', handleScroll);

    const handleResize = () => {
      const newWidth = 0.93 * container.clientWidth;
      const newHeight = 0.9 * container.clientHeight;

      canvas.setDimensions({
        width: newWidth,
        height: newHeight,
      });

      canvas.renderAll();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      canvas.off('mouse:wheel', handleScroll);
      window.removeEventListener('resize', handleResize);
      canvas.dispose();
    };
  }, []);

  const { canvas } = useContext<any>(CanvasContext);

  const handleZoomIn = () => {
    if (canvas) {
      canvas.setZoom(canvas.getZoom() + 0.1);
    }
  };

  const handleZoomOut = () => {
    if (canvas) {
      canvas.setZoom(canvas.getZoom() - 0.1);
    }
  };

  const handleDownloadImage = () => {
    var dataURL = canvas.toDataURL({ format: 'png', quality: 1.0 });

    var downloadLink = document.createElement('a');
    downloadLink.href = dataURL;
    downloadLink.download = `${seatingData[0].name}.png`;

    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  };

  const handleDownloadJson = () => {
    const jsonString = JSON.stringify(seatingData, null, 2);

    const blob = new Blob([jsonString], { type: 'application/json' });

    const downloadLink = document.createElement('a');
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = 'canvas_data.json';

    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  };

  return (
    <div className={styles.view}>
      <canvas id='canvas' className={styles.canvasDiv} />
      {/* <div className={styles.right_side}>
        <div className={styles.download_btn}>
          <div
            className={styles.download_icon}
            onClick={() => setToggle(!toggle)}
          >
            <IconDownload />
          </div>
          {toggle ? (
            <div className={styles.download_options}>
              <div
                className={styles.img_download}
                onClick={handleDownloadImage}
              >
                Image
              </div>
              <div
                className={styles.json_download}
                onClick={handleDownloadJson}
              >
                Json
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.zoom}>
          <div className={styles.zoom_in_btn} onClick={handleZoomIn}>
            +
          </div>
          <div className={styles.zoom_out_btn} onClick={handleZoomOut}>
            -
          </div>
        </div>
      </div> */}
    </div>
  );
};

export default Editor;
