import { useState, useContext, useEffect, useCallback } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import seatingObject from '../Objects/seatingObject';
import TableObject from '../Objects/tableObject';
import ShapeObject from '../Objects/shapeObject';
import CustomShapeObject from '../Objects/customShapeObject';
import PolygonObject from '../Objects/polygonObject';
import AddTextObject from '../Objects/addTextObject';
import CanvasContext from '../canvasContext';
import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Select from 'react-select';
import config from 'src/config';
import {
  dispatchSeatingData,
  getSeatingData,
  getSeatingDataAsArrays,
  convertSeatingDataToMaps,
} from '../seatingMapReducer';

import style from './AssignSetter.module.scss';
import { getShowDetails } from 'src/pages/createShow/showService';
import { useLang } from 'src/components/useLang';
import { Button, Flex, Label, SoftButton } from 'src/styling/globalStyling';
import { ShowType } from 'src/models';

const AssignSetter = () => {
  const history = useHistory();
  const { showId } = useParams<{ showId: string }>();
  const { canvas } = useContext(CanvasContext);

  const lang = useLang();

  const [objects, setObjects] = useState<any>({});
  const [selectedObject, setSelectedObject] = useState<any>(null);
  const [objectName, setObjectName] = useState();

  const [ticketTypeInfo, setTicketTypeInfo] = useState(
    lang.assign_setter_no_ticket_type_allocated
  );
  const [selectedTicketType, setSelectedTicketType] = useState<{
    value: any;
    label: string;
  }>();

  const { data: seatingMaps } = useQuery<
    AxiosResponse<any>,
    AxiosError,
    AxiosResponse<any>,
    string[]
  >(['seatingMaps'], () => axios.get(`${config.baseApi}/v1/maps`));

  const { data: show } = useQuery<
    AxiosResponse<ShowType>,
    AxiosError,
    AxiosResponse<ShowType>,
    string[]
  >(['show'], () => getShowDetails(showId), { enabled: !!showId });

  const [selectedMap, setSelectedMap] = useState<any>();

  const seatingData = getSeatingData();

  const {
    mutate: save,
    isLoading: isSaving,
    error: errorSaving,
  } = useMutation(
    () => {
      const url = `${config.baseApi}/v1/shows/${showId}`;
      const mapData = getSeatingDataAsArrays(); // turn Map data into arrays
      const data = {
        ...show?.data,
        seatingMap: JSON.stringify(mapData),
      };
      return axios.put(url, data);
    },
    {
      onSuccess: () => {
        history.push(`/editshow/${show?.data.id}`);
      },
    }
  );

  // mock ticket types
  // const options = ticketTypesData.map((obj) => obj.name);

  const options = show?.data.ticketTypes.map((ticketType) => ({
    label: ticketType.title,
    value: ticketType,
  }));

  const handleTicketAssign = () => {
    const type = selectedObject?.objectSubtype;

    if (!selectedTicketType) return;

    switch (type) {
      case 'seating-group':
      case 'table':
      case 'Circle':
      case 'Rectangle':
      case 'Triangle':
      case 'Square':
      case 'custom-shape':
      case 'polygon':
        (selectedObject as any).assignAll(selectedTicketType.value);
        break;
    }
    setTicketTypeInfo(selectedTicketType.label);
  };

  useEffect(() => {
    const handleMouseDown = (e) => {
      if (!e.target) {
        setSelectedObject(null);
        return;
      }
      const clickedId = e.target.id;
      if (clickedId === undefined) return;
      const object = objects[clickedId];
      setSelectedObject(object);
    };

    if (canvas) {
      canvas.on('mouse:down', handleMouseDown);
    }

    return () => {
      if (canvas) {
        canvas.off('mouse:down', handleMouseDown);
      }
    };
  }, [canvas, showId, objects]);

  useEffect(() => {
    const objectType = selectedObject?.objectSubtype;

    switch (objectType) {
      case 'seating-group':
        setObjectName((selectedObject as any).blockName);
        if ((selectedObject as any).seatGroups[0].ticketType)
          setTicketTypeInfo(
            (selectedObject as any).seatGroups[0].ticketType.title
          );
        else setTicketTypeInfo(lang.assign_setter_no_ticket_type_allocated);
        break;
      case 'table':
        setObjectName((selectedObject as any).tableName);
        if ((selectedObject as any).tableGroup._objects[1].ticketType)
          setTicketTypeInfo(
            (selectedObject as any).tableGroup._objects[1].ticketType.title
          );
        else setTicketTypeInfo(lang.assign_setter_no_ticket_type_allocated);
        break;
      case 'Circle':
      case 'Rectangle':
      case 'Triangle':
      case 'Square': {
        setObjectName((selectedObject as any).name);
        if ((selectedObject as any).ticketType) {
          setTicketTypeInfo((selectedObject as any).ticketType.title);
        } else {
          setTicketTypeInfo(lang.assign_setter_no_ticket_type_allocated);
        }
        break;
      }
      case 'polygon':
        setObjectName((selectedObject as any).name);
        if ((selectedObject as any).ticketType)
          setTicketTypeInfo((selectedObject as any).ticketType.title);
        else setTicketTypeInfo(lang.assign_setter_no_ticket_type_allocated);
        break;
      case 'custom-shape':
        setObjectName((selectedObject as any).name);
        if ((selectedObject as any).ticketType)
          setTicketTypeInfo((selectedObject as any).ticketType.title);
        else setTicketTypeInfo(lang.assign_setter_no_ticket_type_allocated);
        break;
    }
  }, [selectedObject]);

  const loadMapData = useCallback(
    (seatingData) => {
      canvas?.clear();
      const newObjects = {};

      seatingData.objects.forEach(({ dataOptions, dataValues }) => {
        let object;
        switch (dataOptions.objectSubtype) {
          case 'seating-group':
            object = new seatingObject(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.loadSeats(dataValues);
            break;
          case 'table':
            object = new TableObject(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.loadTable(dataValues);
            break;
          case 'Circle':
          case 'Rectangle':
          case 'Triangle':
          case 'Square':
            object = new ShapeObject(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.create();
            break;
          case 'custom-shape':
            object = new CustomShapeObject(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.load(dataValues);
            break;
          case 'polygon':
            object = new PolygonObject(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.load(dataValues);
            break;
          case 'text':
            object = new AddTextObject(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.create();
            break;
        }
        if (object) {
          object.setSelectable(false);
          newObjects[object.id] = object;
        }
      });
      setObjects(newObjects);
    },
    [canvas]
  );

  useEffect(() => {
    if (selectedMap?.value) {
      const mapObj = JSON.parse(selectedMap.value);
      const arraysToMaps = convertSeatingDataToMaps(mapObj);
      dispatchSeatingData(arraysToMaps);
      loadMapData(arraysToMaps);
    }
  }, [selectedMap, loadMapData]);

  return (
    <div className={style.ticketTypeSetterWrapper}>
      <SoftButton
        className='fitContent'
        onClick={() => history.push('/seatingMaps/new')}
      >
        {lang.assign_setter_create_new_map}
      </SoftButton>
      <div>
        <Label>1. {lang.show_select_seating_map}</Label>
        <Select
          // styles={selectStyles}
          value={selectedMap}
          options={seatingMaps?.data?.map((item) => ({
            value: item.map,
            label: item.mapName,
          }))}
          defaultValue={undefined}
          onChange={(e) => setSelectedMap(e)}
        />
      </div>
      <div className={style.ticketTypeSection}>
        <div className={style.ticketTypes}>
          <Label>2. {lang.assign_setter_select_ticket_type}</Label>
          <Select
            value={selectedTicketType}
            options={options}
            onChange={setSelectedTicketType}
          />
        </div>
        <div className={style.ticketTypeAddBtn} onClick={handleTicketAssign}>
          3. {lang.add}
        </div>
      </div>
      <Label>{lang.ticket_assign_selected_object}</Label>
      {objectName && <div className={style.objectName}>{objectName}</div>}
      {selectedObject ? (
        <div className={style.ticketTypeInfo}>{ticketTypeInfo}</div>
      ) : (
        <div style={{ fontSize: 12, textDecoration: 'italic' }}>
          {lang.assign_setter_no_select_object}
        </div>
      )}
      <Button className='fitContent' onClick={save}>
        {lang.ticket_assign_button_save} "{show?.data.showTitle}"
      </Button>
    </div>
  );
};

export default AssignSetter;
