import { store } from "src";

// Action Types
export const seatingMapAcitonTypes = {
  SET_IS_EDITING: "SET_IS_EDITING",
  SET_SEATING_DATA: "SET_SEATING_DATA",
} as const;

// Interfaces for object data structures
interface ObjectData {
  dataOptions: {
    id: string;
    objectSubtype?: string; // Added to distinguish between different object types
    [key: string]: any;
  };
  dataValues: {
    [key: string]: any;
  };
}

// Main seating data interface
export interface SeatingData {
  name: string;
  createdAt: Date;
  updatedAt: Date;
  canvasJSON: string;
  objects: Map<string, ObjectData>; // New unified objects map
  object: Map<string, ObjectData>;
  tableObject: Map<string, ObjectData>;
  landmarkObject: Map<string, ObjectData>;
  shapeObject: Map<string, ObjectData>;
  customShapeObject: Map<string, ObjectData>;
  addTextObject: Map<string, ObjectData>;
  polygonObject: Map<string, ObjectData>;
}

// State interface
interface SeatingMapState {
  isSeatEditing: boolean;
  seatingData: SeatingData;
}

// Action interfaces
interface SetSeatingDataAction {
  type: typeof seatingMapAcitonTypes.SET_SEATING_DATA;
  payload: SeatingData;
}
interface SetIsEditingAction {
  type: typeof seatingMapAcitonTypes.SET_IS_EDITING;
  payload: boolean;
}

// Initial state
export const initialState: SeatingMapState = Object.freeze({
  isSeatEditing: false,
  seatingData: {
    name: "",
    createdAt: new Date(),
    updatedAt: new Date(),
    canvasJSON: "",
    objects: new Map<string, ObjectData>(), // New unified objects map
    object: new Map<string, ObjectData>(), // seatingObject Map
    tableObject: new Map<string, ObjectData>(),
    landmarkObject: new Map<string, ObjectData>(),
    shapeObject: new Map<string, ObjectData>(),
    customShapeObject: new Map<string, ObjectData>(),
    addTextObject: new Map<string, ObjectData>(),
    polygonObject: new Map<string, ObjectData>(),
  },
});

// Reducer
export default function seatingMapReducer(
  state: SeatingMapState = initialState,
  action: SetSeatingDataAction | SetIsEditingAction
): SeatingMapState {
  switch (action.type) {
    case seatingMapAcitonTypes.SET_SEATING_DATA:
      return {
        ...state,
        seatingData: action.payload,
      };
    case seatingMapAcitonTypes.SET_IS_EDITING:
      return {
        ...state,
        isSeatEditing: action.payload,
      };
    default:
      return state;
  }
}

// Action creators and selectors
export function dispatchSeatingData(payload: SeatingData): void {
  store.dispatch({
    type: seatingMapAcitonTypes.SET_SEATING_DATA,
    payload,
  });
}

export function dispatchIsSeatEditing(payload: boolean): void {
  store.dispatch({
    type: seatingMapAcitonTypes.SET_IS_EDITING,
    payload,
  });
}

export function getSeatingData(): SeatingData {
  const state = store.getState();
  return state.seatingMapReducer.seatingData;
}

// Helper functions to convert Maps to arrays
export function convertMapToArray(map: Map<string, ObjectData>): ObjectData[] {
  return Array.from(map.values());
}

export function getSeatingDataAsArrays(): {
  object: ObjectData[];
  tableObject: ObjectData[];
  landmarkObject: ObjectData[];
  shapeObject: ObjectData[];
  customShapeObject: ObjectData[];
  addTextObject: ObjectData[];
  polygonObject: ObjectData[];
  objects: ObjectData[];
} {
  const seatingData = getSeatingData();
  return {
    ...seatingData,
    object: convertMapToArray(seatingData.object),
    tableObject: convertMapToArray(seatingData.tableObject),
    landmarkObject: convertMapToArray(seatingData.landmarkObject),
    shapeObject: convertMapToArray(seatingData.shapeObject),
    customShapeObject: convertMapToArray(seatingData.customShapeObject),
    addTextObject: convertMapToArray(seatingData.addTextObject),
    polygonObject: convertMapToArray(seatingData.polygonObject),
    objects: convertMapToArray(seatingData.objects),
  };
}

// Helper function to convert arrays back to Maps
export function convertArrayToMap(
  array: ObjectData[]
): Map<string, ObjectData> {
  const map = new Map<string, ObjectData>();
  array.forEach((item) => {
    if (item.dataOptions && item.dataOptions.id) {
      map.set(item.dataOptions.id, item);
    }
  });
  return map;
}

export function convertSeatingDataToMaps(data: any): SeatingData {
  return {
    ...data,
    object: Array.isArray(data.object)
      ? convertArrayToMap(data.object)
      : new Map<string, ObjectData>(),
    tableObject: Array.isArray(data.tableObject)
      ? convertArrayToMap(data.tableObject)
      : new Map<string, ObjectData>(),
    landmarkObject: Array.isArray(data.landmarkObject)
      ? convertArrayToMap(data.landmarkObject)
      : new Map<string, ObjectData>(),
    shapeObject: Array.isArray(data.shapeObject)
      ? convertArrayToMap(data.shapeObject)
      : new Map<string, ObjectData>(),
    customShapeObject: Array.isArray(data.customShapeObject)
      ? convertArrayToMap(data.customShapeObject)
      : new Map<string, ObjectData>(),
    addTextObject: Array.isArray(data.addTextObject)
      ? convertArrayToMap(data.addTextObject)
      : new Map<string, ObjectData>(),
    polygonObject: Array.isArray(data.polygonObject)
      ? convertArrayToMap(data.polygonObject)
      : new Map<string, ObjectData>(),
    objects: Array.isArray(data.objects)
      ? convertArrayToMap(data.objects)
      : new Map<string, ObjectData>(),
    createdAt: data.createdAt ? new Date(data.createdAt) : new Date(),
    updatedAt: data.updatedAt ? new Date(data.updatedAt) : new Date(),
  };
}
