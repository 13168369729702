import React, { ReactNode } from 'react';
import styled from 'styled-components';
import ReactGA from 'react-ga4';
import { SoftButton } from 'src/styling/globalStyling';

const Wrapper = styled.div`
  overflow: auto;
  padding: 3rem 32px 2rem 32px;
  background: rgba(0, 0, 0, 0.4);
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  justify-content: center;
  ${(props) =>
    props.position === 'top' &&
    `
    justify-content: flex-start;
    padding-top: 10%;
	`}
  backdrop-filter: blur(2px);
  flex-direction: column;
`;

const Container = styled.div`
  background: ${({ theme }) => theme.secondBackground};
  border-radius: 32px;
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-width: 300px;
  max-width: 500px;

  .title {
    font-size: 20px;
    font-weight: 500;
  }
  .content {
    margin-top: 16px;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    width: 100%;
  }
  .cancelButton {
    margin-top: 24px;
    margin-bottom: 8px;
    padding: 10px 20px;
    border-radius: 30px;
    background: ${({ theme }) => theme.secondaryButtonBackground};
    color: ${({ theme }) => theme.secondaryButtonColor};
    cursor: pointer;
  }
  button {
    margin-top: 32px;
  }
`;

type PopupProps = {
  position?: 'top';
  trackingTitle?: string;
  title?: string;
  content: string | ReactNode;
  submitAction?: () => void;
  submitDisabled?: boolean;
  submitStyle?: object;
  submitText?: string;
  cancelAction?: () => void;
  cancelText?: string;
  error?: string;
  style?: object;
  className?: string;
};

const Popup = (props: PopupProps) => {
  const { style, className } = props;
  if (props.trackingTitle) {
    ReactGA.event({
      action: 'Click',
      category: 'Modal',
      label: props.trackingTitle,
    });
  }
  return (
    <Wrapper position={props.position} className={className} style={style}>
      <Container
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <div className={'title mb1'}>{props.title}</div>
        {props.content &&
          (typeof props.content === 'string' ? (
            <div className={'content'}>{props.content}</div>
          ) : (
            props.content
          ))}
        {props.submitAction && (
          <button
            onClick={props.submitAction}
            disabled={props.submitDisabled || false}
            style={props.submitStyle}
          >
            {props.submitText || 'Okay'}
          </button>
        )}
        {props.cancelAction && (
          <SoftButton onClick={props.cancelAction}>
            {props.cancelText || 'Cancel'}
          </SoftButton>
        )}
        {props.error && <div className={'text-danger'}>{props.error}</div>}
      </Container>
    </Wrapper>
  );
};

export default Popup;
