import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import { Container, Link, TextContainer } from 'src/styling/globalStyling';

const UsloviKoriscenja = () => {
  return (
    <Container>
      <GoBack />
      <Heading title='Uslovi korišćenja' />
      <TextContainer>
        <ul>
          <li>
            <strong> VAŽNA INFORMACIJA</strong>
          </li>
        </ul>
        <p>
          Bilet Solutions DOO (u daljem tekstu: "<strong>Organizator</strong>").
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> KUPOVINA ULAZNICA</strong>
          </li>
        </ul>
        <ul>
          <ul>
            <li>2.1 Kupovina ulaznica na prodajnim mestima Bilet Solutions</li>
          </ul>
        </ul>
        <p>Pod prodajnim mestom Bilet Solutions DOO-a se podrazumevaju:</p>
        <ul>
          <li>- sopstvena prodajna mesta Bilet Solutions DOO-a,</li>
          <li>
            - internet prodavnica na veb-sajtu&nbsp;bilet.rs&nbsp;(u daljem
            tekstu: "<strong>Internet prodavnica</strong>"); i
          </li>
          <li>- prodajna mesta partnera.</li>
        </ul>
        <p>&nbsp;</p>
        <ul>
          <ul>
            <li>
              2.2 Kupovina ulaznica na prodajnim mestima Bilet Solutions i
              prodajnim mestima partnera
            </li>
          </ul>
        </ul>
        <p>
          Ulaznice je moguće kupiti na prodajnim mestima Bilet Solutions i
          njegovih partnera. Ulaznice za događaje se &scaron;tampaju i po
          isplati cene se izdaju kupcu.
        </p>
        <p>
          Kupovina ulaznica preko prodajnih mesta Bilet Solutions DOO-a je
          obavezujuća i kupac ne može odustati od izvr&scaron;ene kupovine u
          smislu člana 36 stav 1 tačka 12 Zakona o za&scaron;titi
          potro&scaron;ača ("Sl. glasnik RS", br. 88/2021), s obzirom na to da
          Bilet Solutions DOO prodajom ulaznica pruža usluge povezane sa
          slobodnim aktivnostima koje se odvijaju u tačno određeno vreme odnosno
          u određenom periodu (odnosno za koje postoji konkretni rok ili period
          izvr&scaron;enja). U skladu sa članom 26. stav 1. tačka 6. Zakona o
          za&scaron;titi potro&scaron;ača kupac prihvatanjem ovih Uslova
          kori&scaron;ćenja izričito izjavljuje da je obave&scaron;ten od strane
          Bilet Solutions DOO-a da ne može da koristi pravo na odustanak od
          ugovora, na &scaron;ta kupac pristaje.
        </p>
        <ul>
          <li>2.3 Kupovina ulaznica na Internet prodavnici&nbsp;bilet.rs</li>
        </ul>
        <p>
          Kupovina ulaznica na Internet prodavnici je obavezujuća i kupac ne
          može odustati od izvr&scaron;ene kupovine u smislu člana 36. stav 1
          tačke 12 i 13 Zakona o za&scaron;titi potro&scaron;ača, s obzirom na
          to da Bilet Solutions DOO prodajom ulaznica pruža usluge povezane sa
          slobodnim aktivnostima koje se odvijaju u tačno određeno vreme odnosno
          u određenom periodu (odnosno za koje postoji konkretni rok ili period
          izvr&scaron;enja), kao i da je reč o isporuci digitalnog sadržaja u
          vidu PDF ulaznica (PDF-TICKET i E-WALLET). U skladu sa članom 36 stav
          1 tačka 13 i članom 26. stav 1. tačka 6 Zakona o za&scaron;titi
          potro&scaron;ača, kupac prihvatanjem ovih Uslova kori&scaron;ćenja
          izričito izjavljuje da je obave&scaron;ten od strane Bilet Solutions
          DOO-a da ne može koristiti pravo na odustanak od ugovora, na
          &scaron;ta kupac pristaje.
        </p>
        <p>
          Kada izvr&scaron;ite porudžbinu na internet prodavnici bilet.rs
          izjavljujete da imate vi&scaron;e od 18 godina i da imate poslovnu
          sposobnost za zaključenje ovog pravnog posla.
        </p>
        <p>
          Za kupovinu ulaznica preko internet prodavnice bilet.rs pratite
          sledeće korake:
        </p>
        <p>
          korak 1 &ndash; upisivanje ličnih podataka i prihvatanje op&scaron;tih
          uslova;
        </p>
        <p>korak 2 - biranje događaja i ulaznica prema cenovnoj kategoriji;</p>
        <p>korak 3 &ndash; plaćanje;</p>
        <p>korak 4 &ndash; potvrda porudžbine.</p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> NAČINI ISPORUKE</strong>
          </li>
        </ul>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <ul>
          <li>PDF-TICKET</li>
        </ul>
        <p>
          Va&scaron;a PDF ulaznica će Vam biti poslata na e-mail. Ulaznicu je
          poželjno od&scaron;tampati na papiru A4 formata. Ulaznicu čuvajte.
          Od&scaron;tampanu ulaznicu donesite na događaj.
        </p>
        <ul>
          <li>E-WALLET (e novčanik)</li>
        </ul>
        <p>
          Va&scaron;a ulaznica će Vam biti poslata u Va&scaron;em e-novčaniku na
          portalu https://bilet.rs Ukoliko imate telefon pri sebi uvek možete
          pristupiti ulaznicama. Ulaznice iz e-novčanika možete deliti
          (sherovati) klikom na odgovarajuće share dugme.&nbsp;
        </p>
        <ul>
          <li>
            <strong> PLAĆANJE ULAZNICA</strong>
          </li>
        </ul>
        <p>
          Ulaznice kupljene na prodajnim mestima Bilet Solutions DOO-a mogu se
          platiti gotovinom ili platnim karticama.
        </p>
        <p>
          Ulaznice kupljene na Internet prodavnici plaćaju se isključivo platnom
          karticom.
        </p>
        <p>
          Svaka ulaznica postaje važeća nakon celokupne uplate cene ulaznica i
          tro&scaron;kova obrade prikazanih prilikom kupovine. PDV je uračunat u
          cenu i nema skrivenih tro&scaron;kova.
        </p>
        <p>
          Tro&scaron;kovi obrade za kupovinu ulaznica na Internet prodavnici
          obračunavaju se na nivou porudžbine i iznose 4% od cene ulaznice.
          Tro&scaron;kovi obrade se odnose na pokriće nužnih tro&scaron;kova
          obrade platnih transakcija putem platnih kartica, te administrativnih,
          materijalnih i ostalih operativnih tro&scaron;kova Bilet Solutions .
        </p>
        <ul>
          <li>- Op&scaron;ti uslovi prihvatanja platnih kartica</li>
        </ul>
        <p>&nbsp;</p>
        <p>
          <strong>Bilet Solutions DOO</strong>, sa sedi&scaron;tem na adresi
          Romanijska 7 Nis, matični broj:<strong> 22061011</strong>, PIB:
          <strong> 114721552 </strong>nastupa u pravnom prometu kao distributer
          za prodaju ulaznica na Internet prodavnici.
        </p>
        <p>
          Sva plaćanja biće izvr&scaron;ena u lokalnoj valuti Republike Srbije
          &ndash; dinar (RSD). Za informativni prikaz cena u drugim valutama
          koristi se srednji kurs Narodne Banke Srbije. Iznos za koji će biti
          zadužena Va&scaron;a platna kartica biće izražen u Va&scaron;oj
          lokalnoj valuti kroz konverziju u istu po kursu koji koriste
          kartičarske kompanije, a koji nama u trenutku kupovine ulaznice ne
          može biti poznat. Kao rezultat ove konverzije postoji mogućnost
          neznatne razlike u odnosu na originalne cene navedene na na&scaron;em
          sajtu. Hvala Vam na razumevanju.
        </p>
        <p>
          Prilikom uno&scaron;enja podataka o platnoj kartici, poverljive
          informacija se prenose putem javne mreže u za&scaron;tićenoj
          (kriptovanoj) formi upotrebom SSL protokola i PKI sistema, kao
          trenutno najsavremenije kriptografske tehnologije. Sigurnost podataka
          prilikom kupovine garantuje procesor platnih kartica, pa se tako
          kompletni proces naplate obavlja u sigurnom okruženju. U nijednom
          trenutku podaci o platnoj kartici nisu dostupni na&scaron;em sistemu.
        </p>
        <p>
          U slučaju povraćaja sredstava kupcu koji je prethodno platio ulaznicu
          nekom od platnih kartica, delimično ili u celosti, a bez obzira na
          razlog vraćanja, Bilet Solutions DOO povraćaj vr&scaron;i isključivo
          preko VISA, EC/MC i Maestro metoda plaćanja, &scaron;to znači da će
          banka obaviti povraćaj sredstava na račun korisnika kartice.
        </p>
        <ul>
          <li>- PRAVNA LICA - PLAĆANJE NA OSNOVU PREDRAČUNA</li>
        </ul>
        <p>
          Za poručivanje i plaćanje ulaznica putem predračuna za pravno lice,
          potrebno je na e-mail adresu prodaja@bilet.rs poslati sledeće podatke:
          naziv firme, adresu, PIB i e-mail adresu na koju na&scaron;a služba
          treba da po&scaron;alje predračun. Takođe je potrebno da se naglasi za
          koji događaj se poručuju ulaznice, količinu ulaznica kao i cenovnu
          kategoriju ulaznica za koje je potreban predračun.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> VRAĆANJE I ZAMENA ULAZNICA</strong>
          </li>
        </ul>
        <ul>
          <ul>
            <li>- PRAVO NA VRAĆANJE ULAZNICA</li>
          </ul>
        </ul>
        <p>
          Pravo na vraćanje ulaznica kupac ulaznice ima samo u sledećim
          slučajevima:
        </p>
        <ul>
          <li>- otkazivanje događaja;</li>
          <li>
            - odlaganje događaja na rok duži od 120 dana od dana planiranog
            održavanja događaja;
          </li>
          <li>
            - odlaganje događaja zbog nastupanja vi&scaron;e sile koja traje
            duže od 180 dana od dana planiranog održavanja događaja.
          </li>
        </ul>
        <p>&nbsp;</p>
        <p>
          Vi&scaron;a sila, tokom koje miruju obaveze Organizatora, nastupa kada
          Organizator događaja nije u stanju da ispuni svoju obavezu održavanja
          događaja zbog rata, pobune, terorističkih akata, &scaron;trajkova,
          nesreća, požara, blokade, poplave, odluke ili akcije
          epidemiolo&scaron;ke vlasti, prirodne katastrofe, te&scaron;kog
          poremećaja u snabdevanju energijom ili bilo čega drugog, iz razloga
          koji je van njegove moći i kontrole.
        </p>
        <p>
          Ako je događaj otkazan ili odložen zbog navedenih okolnosti, Bilet
          Solutions DOO će o tome obavestiti kupce &scaron;to je pre moguće
          putem veb-sajta bilet.rs. Ukoliko je događaj odložen za novi termin,
          povraćaj novca za kupljene ulaznice korisnicima kojima novi termin ne
          odgovara biće moguć do početka događaja u novom terminu, osim ukoliko
          organizator događaja ne odluči drugačije.
        </p>
        <p>
          U slučajevima navedenim u prethodnom stavu, ulaznica će biti
          stornirana, a novac vraćen kupcu na tekući račun ili na platnu karticu
          preko VISA, EC/MC i Maestro metoda plaćanja,
        </p>
        <p>
          U slučaju povraćaja novca za kupovinu obavljenu na Internet
          prodavnici, kupcu će biti vraćen iznos cene ulaznice za predmetni
          događaj. Kupcu neće biti izvr&scaron;en povraćaj tro&scaron;kova
          obrade od 4% od cene ulaznice, budući da se usluga Bilet Solutions
          smatra u celosti pruženom u trenutku kupovine ulaznice, o čemu je
          kupac obave&scaron;ten i na &scaron;ta pristaje.
        </p>
        <p>
          U slučaju otkazivanja ili odlaganja događaja ili drugih promena
          programa u vezi sa događajem, ni u kom slučaju neće biti
          izvr&scaron;en povraćaj novca za povezane tro&scaron;kove (npr.
          prevoz, hotelski sme&scaron;taj, tro&scaron;kovi isporuke itd.).
        </p>
        <ul>
          <li>- OBAVEZA VRAĆANJA NOVCA</li>
        </ul>
        <p>
          U slučajevima kada Bilet Solutions DOO ima obavezu povraćaja novca
          kupcima ulaznica, Bilet Solutions DOO će izvr&scaron;iti povraćaj
          novca na tekući račun kupca ili na prodajnom mestu gde su ulaznice
          kupljene, i to u roku od:
        </p>
        <ul>
          <li>- 90 dana od dana otkazivanja događaja,</li>
          <li>
            - 60 dana od dana planiranog održavanja događaja koji je odložen na
            rok duži od 120 dana, i
          </li>
          <li>
            - 240 dana od dana planiranog održavanja događaja u slučaju
            nastupanja vi&scaron;e sile u trajanju preko 180 dana usled koje
            Organizator nije u mogućnosti da održi događaj,
          </li>
        </ul>
        <p>a sve pod uslovom da je kupac podneo zahtev za vraćanje novca.</p>
        <ul>
          <li>
            <strong> ZA&Scaron;TITA PODATAKA</strong>
          </li>
        </ul>
        <p>
          Bilet Solutions DOO se obavezuje da čuva privatnost svih kupaca.
          Prikupljamo samo neophodne, osnovne podatke o kupcima/ korisnicima i
          podatke neophodne za poslovanje i informisanje korisnika u skladu sa
          dobrim poslovnim običajima i u cilju pružanja kvalitetne usluge.
          Dajemo kupcima mogućnost izbora uključujući mogućnost odluke da li
          žele ili ne da se izbri&scaron;u sa mailing lista koje se koriste za
          marketin&scaron;ke kampanje. Svi podaci o korisnicima/kupcima se
          strogo čuvaju i dostupni su samo zaposlenima kojima su ti podaci nužni
          za obavljanje posla. Svi zaposleni u Bilet Solutions DOO-u (i poslovni
          partneri) odgovorni su za po&scaron;tovanje načela za&scaron;tite
          privatnosti.
        </p>
        <p>
          Korisnik prihvata da lični podaci mogu biti elektronski obrađeni od
          strane Bilet Solutions DOO-a za potrebe izvr&scaron;enja usluge. U
          skladu s pravnim odredbama, korisnik prihvata da se njegovi podaci
          proslede partnerskim kompanijama (kompanijama kćerkama ili
          poslovnicama) kao i organizatorima događaja za koje su ulaznice
          kupljene.
        </p>
        <p>
          Banka ne učestvuje u prikupljanju ovih ličnih podataka, te se svi
          podaci isključivo distribuiraju Organizatoru događaja.
        </p>
        <p>
          Klikom na sledeći link možete videti na&scaron;u&nbsp;
          <Link href='#/politikaPrivatnosti'>Politiku privatnosti.</Link>
        </p>
        <p>&nbsp;&nbsp;</p>
        <ul>
          <li>
            <strong> OBAVE&Scaron;TENJE</strong>
          </li>
        </ul>
        <p>
          U cilju za&scaron;tite od zloupotrebe, Bilet Solutions DOO u određenim
          okolnostima (ukoliko se transakcija pri proveri pokaže kao sumnjiva)
          može zahtevati od kupca dodatne podatke &ndash; sken platne kartice sa
          vidljive četiri poslednje cifre. Ukoliko ne prihvatate gore navedene
          uslove poslovanja, možete zatražiti povraćaj novčanih sredstava koja
          će biti vraćena na račun sa koga je izvr&scaron;eno plaćanje.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> PRAVA KORI&Scaron;ĆENJA SOFTVERA</strong>
          </li>
        </ul>
        <p>
          Sistemsko re&scaron;enje web sajta bilet.rs je vlasni&scaron;tvo
          kompanije Bilet Solutions DOO koja je isključivi nosilac prava
          kori&scaron;ćenja softverskog re&scaron;enja (mape, ulaznice, sistem
          komunikacije&hellip;). Zabranjeno je svako kori&scaron;ćenje
          softverskog re&scaron;enja bez izričite prethodne saglasnosti vlasnika
          softvera i u skladu sa zakonom Republike Srbije, kao i važećim
          međunarodnim ugovorima. U slučaju kr&scaron;enja ove odredbe, Bilet
          Solutions DOO može da traži sudsku za&scaron;titu.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> FISKALNI RAČUN</strong>
          </li>
        </ul>
        <p>
          Kupac je saglasan da elektronski fiskalni račun dobije u istom e-mailu
          na adresu koja je uneta prilikom kupovine ulaznica. Fiskalni račun
          sadrži qr kod preko kojeg se može verifikovati.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> VANSUDSKO RE&Scaron;AVANJE SPOROVA</strong>
          </li>
        </ul>
        <p>
          Potro&scaron;ački spor može se re&scaron;iti postupkom vansudskog
          re&scaron;avanja potro&scaron;ačkih sporova. Kao trgovac smo dužni da
          vas obavestimo da smo po zakonu obavezni da učestvujemo u ovom
          postupku. Vansudsko re&scaron;avanje potro&scaron;ačkih sporova
          obavlja se na transparentan, efikasan, brz i pravičan način pred telom
          za vansudsko re&scaron;avanje potro&scaron;ačkih sporova.
        </p>
        <p>
          Ministarstvo sačinjava listu tela i javno je objavljuje. Dostupna je
          na adresi&nbsp;
          <Link href='https://vansudsko.mtt.gov.rs/adrbodies'>
            https://vansudsko.mtt.gov.rs/adrbodies&nbsp;
          </Link>
        </p>
        <p>
          Postupak pred telom može da pokrene potro&scaron;ač samo ukoliko je
          prethodno izjavio reklamaciju ili prigovor trgovcu. Potro&scaron;ač
          protekom jedne godine od dana (bezuspe&scaron;nog) podno&scaron;enja
          reklamacije gubi pravo na podno&scaron;enje predloga za vansudsko
          re&scaron;avanje spora.
        </p>
        <p>
          Vansudsko re&scaron;avanje potro&scaron;ačkog spora može da traje
          najduže 90 dana od dana podno&scaron;enja predloga.
        </p>
        <p>
          Vansudsko re&scaron;avanje potro&scaron;ačkih sporova, ne primenjuje
          se, pored ostalog:
        </p>
        <ul>
          <li>
            - u potro&scaron;ačkim sporovima koji su predmet Zakona o
            za&scaron;titi potro&scaron;ača, ako je vansudsko re&scaron;avanje
            sporova uređeno posebnim zakonom, a naročito u oblasti pružanja
            elektronskih komunikacionih usluga, po&scaron;tanskih usluga,
            finansijskih usluga, osim finansijskih pogodbi, usluga putovanja;
          </li>
          <li>
            - za re&scaron;avanje sporova po procedurama koje je ustanovio sam
            trgovac;
          </li>
          <li>- na neposredne pregovore između potro&scaron;ača i trgovca;</li>
          <li>
            - na poku&scaron;aj mirenja strana povodom spora u parničnom
            postupku;
          </li>
          <li>
            - u postupcima koje je trgovac pokrenuo protiv potro&scaron;ača.
          </li>
          <li>&nbsp;</li>
        </ul>
        <p>
          Svaka stranka u postupku vansudskog re&scaron;avanja
          potro&scaron;ačkog spora plaća svoje tro&scaron;kove (tro&scaron;kovi
          zastupanja, putni tro&scaron;kovi i sl.). Rad tela za vansudsko
          re&scaron;avanje potro&scaron;ačkog spora je besplatan za stranke u
          postupku vansudskog re&scaron;avanja potro&scaron;ačkog spora.
        </p>
        <p>&nbsp;</p>
        <ul>
          <li>
            <strong> SUDSKA NADLEŽNOST</strong>
          </li>
        </ul>
        <p>
          Za sve sporove koji ne mogu biti re&scaron;eni sporazumom, nadležan je
          sud u Ni&scaron;u.
        </p>
        <p>&nbsp;</p>
      </TextContainer>
    </Container>
  );
};

export default UsloviKoriscenja;
