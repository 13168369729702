import { fabric } from "fabric";
import { DEFAULT_DISABLED_COLOR } from "../../constants";

class BookSeatsClass {
  constructor(options, canvas, uuid, values) {
    this.rows = options.rows;
    this.rowLength = options.rowLength;
    this.seatRadius = options.seatRadius;
    this.seatSpacing = options.seatSpacing;
    this.blockName = options.blockName;
    this.objectSubtype = "seating-group";
    this.grpArr = [];
    this.seatGroups = [];
    this.rowNames = Array(this.rows).fill("");
    this.canvas = canvas;
    this.groupAdded = true;
    this.group = null;
    this.id = uuid; // Use passed uuid
    this.seatColor = values.seatColor;
    this.angle = values.angle;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.isArcAdded = options.isArcAdded;
    this.isBooking = false;
    this.arcRadius = values.arcRadius;
    this.arcAngle = values.arcAngle;
  }

  loadSeats(valuesJSON) {
    const { rowLength, rows, seatRadius, seatSpacing } = this;

    this.seatColor = valuesJSON.seatColor;
    this.angle = valuesJSON.angle;
    this.scaleX = valuesJSON.scaleX;
    this.scaleY = valuesJSON.scaleY;
    this.arcRadius = valuesJSON.arcRadius;
    this.arcAngle = valuesJSON.arcAngle;

    let i = 0;
    for (let row = 0; row < rows; row++) {
      for (let seat = 0; seat < rowLength; seat++, i++) {
        const { seatNumber, radius, fill, discarded } = valuesJSON.sg[i].seat;

        const seatCircle = new fabric.Circle({
          radius,
          fill: discarded ? "#fff" : fill,
        });

        seatCircle.set("seatNumber", seatNumber);

        const seatLabel = new fabric.Text(
          valuesJSON.sg[i].seatLabel.text.toString(),
          {
            fontSize: seatRadius,
            fill: "#000",
            left: seatRadius,
            top: seatRadius,
            originX: "center",
            originY: "center",
          }
        );

        const seatGroupItem = new fabric.Group([seatCircle, seatLabel], {
          left: seat * (seatRadius * 2 + seatSpacing) + 30,
          top: row * (seatRadius * 2 + seatSpacing) + 30,
          seatNumber,
          booked: false,
          taken: false,
          color: discarded ? "#fff" : this.seatColor,
          seatType: "Seating",
          ticketType: valuesJSON.sg[i].ticketType,
          blockName: this.blockName,
          seatName: `${
            valuesJSON.rn[Math.floor((seatNumber - 1) / this.rowLength)].text
          } ${seat + 1}`,
          discarded,
          selectable: false,
        });

        this.grpArr.push(seatGroupItem);
        this.seatGroups.push(seatGroupItem);

        seatGroupItem.on("mousedown", () => {
          if (!discarded) {
            // this.toggleBookTickets(seatGroupItem);
          }
        });
      }
    }

    this.addBlockHead();

    let idx = 0;
    for (let i = 0; i < valuesJSON.rn.length; i++, idx += this.rowLength) {
      const top = this.seatGroups[idx].top;
      const left = this.seatGroups[idx].left;
      const text = new fabric.Text(valuesJSON.rn[i].text, {
        fontSize: this.seatRadius,
        fill: "black",
        top: top + this.seatSpacing,
        left: left - this.seatRadius,
        selectable: false,
      });
      this.rowNames.push(text);
      this.grpArr.push(text);
    }

    const grpTop = valuesJSON.top;
    const grpLeft = valuesJSON.left;
    this.canvas.remove(this.group);
    this.group = new fabric.Group(this.grpArr, {
      top: grpTop,
      left: grpLeft,
      originX: "center",
      originY: "center",
      id: this.id, // Use passed uuid
      objectSubtype: "seating-group",
    });
    this.canvas.add(this.group);
    this.groupAdded = true;
    this.group.set({
      angle: this.angle,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
    });

    if (this.isArcAdded) {
      this.createArc();
      return;
    }

    this.canvas.renderAll();
  }

  addBlockHead() {
    const top = this.seatGroups[0].top - this.seatRadius;
    const left = this.seatGroups[0].left;
    const text = new fabric.Text(`${this.blockName}`, {
      fill: "black",
      fontSize: this.seatRadius,
      top: top,
      left: left,
    });
    this.grpArr.push(text);
  }

  toggleBookTickets(seatGroupItem) {
    if (seatGroupItem.taken || !seatGroupItem.ticketType) return;
    // Book or unbook seat
    seatGroupItem.booked = !seatGroupItem.booked;
    const seatCircle = seatGroupItem.item(0);

    // for (let i = 0; i < this.rows * this.rowLength; i++) {
    //   const seatObject = this.grpArr[i];
    //   if (!seatObject.booked && !seatObject.discarded) {
    //     if (seatObject.taken) {
    //       seatObject.item(0).set({
    //         fill: DEFAULT_DISABLED_COLOR,
    //       });
    //     } else {
    //       seatObject.item(0).set({
    //         fill: this.seatColor,
    //       });
    //     }
    //   }
    // }

    if (seatGroupItem.booked) {
      seatCircle.set({
        fill: "lightGreen",
      });
    } else {
      seatCircle.set({
        fill: this.seatColor,
      });
    }
    this.canvas.renderAll();
  }

  createArc() {
    let radius = this.arcRadius;
    const startAngle = 0;
    const endAngle = this.arcAngle;

    const angleStep = (endAngle - startAngle) / this.seatGroups.length;
    let currentAngle = startAngle;
    let seatRadius = this.seatRadius;
    this.angle = this.group.angle;
    this.scaleX = this.group.scaleX;
    this.scaleY = this.group.scaleY;
    const centerX = this.group.left;
    let centerY = this.group.top + this.rows * this.seatRadius;

    let rn = [];
    for (
      let i = this.rows * this.rowLength + 1;
      i < this.group._objects.length;
      i++
    ) {
      const y = {
        text: this.group._objects[i].text,
        left: this.group._objects[i].left,
        top: this.group._objects[i].top,
      };

      rn.push(y);
    }

    let sg = this.seatGroups;
    this.grpArr = [];
    this.seatGroups = [];
    this.rowNames = Array(this.rows).fill("");
    this.groupAdded = true;
    debugger;

    for (let row = 0; row < this.rows; row++) {
      for (let seat = 0; seat < this.rowLength; seat++) {
        const seatNumber = row * this.rowLength + seat + 1;
        const rowOffset = row * 20;
        const currentAngle =
          startAngle + (seat / (this.rowLength - 1)) * (endAngle - startAngle);

        const xOffset = centerX + Math.cos(currentAngle) * radius;
        const yOffset = centerY + Math.sin(currentAngle) * radius + rowOffset;

        const seatCircle = new fabric.Circle({
          radius: seatRadius,
          fill: this.seatColor,
        });

        seatCircle.set("seatNumber", seatNumber);

        const seatLabel = new fabric.Text((seat + 1).toString(), {
          fontSize: seatRadius,
          fill: "#000",
          left: seatRadius,
          top: seatRadius,
          originX: "center",
          originY: "center",
          angle: 90,
        });

        let seatName = "";
        if (
          this.rowNames[Math.floor((seatNumber - 1) / this.rowLength)].text ==
          undefined
        ) {
          seatName = "";
        } else
          seatName =
            this.rowNames[Math.floor((seatNumber - 1) / this.rowLength)].text;

        const seatGroupItem = new fabric.Group([seatCircle, seatLabel], {
          top: yOffset,
          left: xOffset,
          selectable: false,
          seatNumber,
          booked: false,
          taken: false,
          color: this.seatColor,
          seatType: "Seating",
          ticketType: sg[seatNumber - 1].ticketType,
          blockName: this.blockName,
          seatName: `${seatName} ${seat + 1}`,
        });

        this.grpArr.push(seatGroupItem);
        this.seatGroups.push(seatGroupItem);

        seatGroupItem.on("mousedown", () => {
          // this.toggleBookTickets(seatGroupItem);
        });
      }
      radius -= this.seatRadius + this.seatSpacing + 10;
      centerY -= this.seatSpacing + this.seatRadius;
    }

    this.addBlockHead();

    let idx = 0;
    for (let i = 0; i < rn.length; i++, idx += this.rowLength) {
      const top = this.seatGroups[idx].top;
      const left = this.seatGroups[idx].left;
      const text = new fabric.Text(rn[i].text, {
        fontSize: this.seatRadius,
        fill: "black",
        top: top + this.seatSpacing,
        left: left - this.seatRadius,
        selectable: false,
      });
      this.rowNames.push(text);
      this.grpArr.push(text);
    }

    this.angle = this.angle === 0 ? -90 : this.angle;

    this.isArcAdded = true;
    this.groupSeats();
  }

  groupSeats() {
    const grpTop = this.group ? this.group.top : this.canvas.height / 2;
    const grpLeft = this.group ? this.group.left : this.canvas.width / 2;
    this.canvas.remove(this.group);
    this.group = new fabric.Group(this.grpArr, {
      top: grpTop,
      left: grpLeft,
      originX: "center",
      originY: "center",
      id: this.id, // Use passed uuid
      angle: this.angle,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
    });
    this.canvas.add(this.group);
    this.groupAdded = true;
    this.canvas.renderAll();
  }

  ungroupSeats() {
    this.canvas.remove(this.group);
    this.grpArr.forEach((seatGroupItem) => {
      seatGroupItem.booked = false;
      seatGroupItem.id = this.id; // Use passed uuid
      this.canvas.add(seatGroupItem);
    });

    this.groupAdded = false;
    this.canvas.renderAll();
  }

  handleRemoveSeat(id) {
    this.grpArr[id - 1].item(0).set({
      fill: this.seatColor,
    });
    this.seatGroups[id - 1].set({
      booked: !this.seatGroups[id - 1].booked,
    });
    this.canvas.renderAll();
  }

  bookAll() {
    this.seatGroups.forEach((seat) => {
      if (seat.booked) {
        seat.item(0).set({
          fill: DEFAULT_DISABLED_COLOR,
        });
      }
    });
    this.canvas.renderAll();
  }

  // save() {
  //   const { rowLength, rows, seatGroups } = this;

  //   let i = 0;
  //   let newObj = [];
  //   for (let row = 0; row < rows; row++) {
  //     for (let seat = 0; seat < rowLength; seat++, i++) {
  //       if (seatGroups[i].booked) {
  //         seatGroups[i].taken = true;
  //         newObj.push({
  //           id: `s-${this.id}-${i}`, // Check this id is it created right
  //           taken: true,
  //           ticketType: seatGroups[i].ticketType,
  //         });
  //       }
  //     }
  //   }
  //   return newObj;
  // }
}

export default BookSeatsClass;
