import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useMemo } from 'react';
import { openMaps, shareFeature } from '../../utils';
import config from '../../config';
import { Flex, Share } from '../../styling/globalStyling';
import moment from 'moment';
import { dateTimeFormat } from '../../pages/myShows/myShows';
import { dateFormat } from '../../constants';
import { ITicketProps } from '../../pages/myTickets/myTickets';
import { TicketComponent } from './Ticket';
import { useQuery } from '@tanstack/react-query';
import { getStreamingCode } from '../../pages/createShow/showService';
import Spinner from '../Spinner/Spinner';

const getStreamingCodeQuery = (props) => {
  const { queryKey } = props;
  return getStreamingCode(queryKey[1]);
};

export const Ticket = (props: { ticket: ITicketProps }) => {
  const history = useHistory();
  const lang = useSelector((state: any) => state.home.language);
  const { ticket: t } = props;

  const { data, isLoading, error, isFetched }: any = useQuery(
    ['streaming', t.invalidationId],
    getStreamingCodeQuery
  );

  const share = (e) => {
    e.stopPropagation();
    shareFeature(`${config.appUrl}/#/ticket/${t.invalidationId}`);
  };
  const goToShow = () => {
    if (t.show) {
      history.push(`/show/${t.show.id}`);
    }
  };

  const isTicketInvalid = useMemo(() => {
    const showDate = moment(t.show.endDate).add(1, 'd');
    return moment().isAfter(showDate) || t.invalidationsLeft === 0;
  }, [t]);

  return (
    <TicketComponent className={'ticketComponent'}>
      {isTicketInvalid && <div className={'invalidated'} />}
      <Flex gap={'16px'} style={{ alignItems: 'flex-start' }}>
        <img onClick={goToShow} src={t?.show.eventImage} alt={'show-poster'} />
        <Flex className={'column fg1'}>
          <Flex className={'space-between'}>
            <div className={'fw600 title'} onClick={goToShow}>
              {t?.show.showTitle}
            </div>
            <Share style={{ marginTop: '2px' }}>
              <i className={'icon-share'} onClick={(e) => share(e)} />
            </Share>
          </Flex>
          <div className={'fs14 link'} onClick={(e) => openMaps(e, t.show)}>
            {t?.show.location.title}
          </div>
          <div className={'fs14'}>
            {moment(t?.show.showDate).format(dateTimeFormat)}
          </div>
          <div className={'fs14'}>
            <span className={'fw600'}>{t?.ticketType.title}</span> -{' '}
            {t?.ticketType.description}
          </div>
          <div className={'fs14 gray'}>
            {lang.purchased} {moment(t?.purchaseDate).format(dateFormat)}
          </div>
        </Flex>
      </Flex>
      <div className={'mt2'}>
        {isLoading && <Spinner className={'mt2'} />}
        {error && (
          <div className={'text-danger fs12 text-center'}>{error?.message}</div>
        )}
        {data?.data && !isLoading && (
          <div
            dangerouslySetInnerHTML={{ __html: data?.data?.streamingCode }}
          />
        )}
        {isFetched && !data?.data?.streamingCode && (
          <div className={'text-center gray'}>No streaming yet.</div>
        )}
      </div>
    </TicketComponent>
  );
};

export default Ticket;
