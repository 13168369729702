import React, { useEffect, useState, useCallback } from 'react';
import styled from 'styled-components';
import Heading from '../../components/heading/heading';
import GoBack from '../../components/goBack/goBack';
import Input, { InputWrapper } from '../../components/input/input';
import {
  debounce,
  validateConfirmPass,
  validateEmail,
  validateName,
  validatePass,
  validateUsername,
} from './utils';
import authService from './authService';
import * as constants from '../../constants';
import { Container, Flex, NarrowWrapper } from '../../styling/globalStyling';
import { useHistory } from 'react-router-dom';
import { setUserData } from '../profile/profileActions';
import { store } from '../../index';
import { redirectIfLoggedIn } from '../../utils';
import FSwitch from '../../components/Switch/FSwitch';
import { LoginButtonWrapper } from './styledComponetns';
import { GoogleLogin } from 'react-google-login';
import { useLang } from 'src/components/useLang';
import { CheckoutCheckboxes } from 'src/components/CheckoutCheckboxes';
import { useForm } from 'react-hook-form';

const GOOGLE_CLIENT_ID = process.env.REACT_APP_G_CLIENT_ID;

const Register = () => {
  const lang = useLang();
  const history = useHistory();

  const form = useForm();

  const [formData, setFormData] = useState({
    fullName: '',
    name: '', // stage name/company name
    username: '',
    email: '',
    password: '',
    repeatPassword: '',
    isBusiness: false,
    companyId: '',
    bankName: '',
    bankAccount: '',
  });

  const [usernameState, setUsernameState] = useState({
    username: '',
    usernameDesc: null,
    usernameIsValid: undefined,
    usernameSpinner: false,
  });
  const [emailState, setEmailState] = useState({
    email: '',
    emailDesc: null,
    emailIsValid: undefined,
    emailSpinner: false,
  });
  const [signupError, setSignupError] = useState('');

  const [passEyeIcon, setPassEyeIcon] = useState(false);
  const [repPassEyeIcon, setRepPassEyeIcon] = useState(false);

  useEffect(() => {
    redirectIfLoggedIn(history);
  }, [history]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    if (!email) {
      setFormData((prev) => ({ ...prev, email: '' }));
      setEmailState((prev) => ({
        ...prev,
        emailDesc: null,
        emailIsValid: undefined,
      }));
      return;
    }

    setFormData((prev) => ({ ...prev, email }));
    const emailIsValid = validateEmail(email);
    if (emailIsValid === 'success') {
      checkEmailDebounced(email);
    } else {
      setEmailState((prev) => ({
        ...prev,
        emailIsValid,
      }));
    }
  };

  const handleUsernameChange = (e) => {
    const username = e.target.value;
    if (!username) {
      setFormData((prev) => ({ ...prev, username: '' }));
      setUsernameState((prev) => ({
        ...prev,
        usernameDesc: null,
        usernameIsValid: undefined,
      }));
      return;
    }

    setFormData((prev) => ({ ...prev, username }));
    const usernameIsValid = validateUsername(username);
    if (usernameIsValid === 'success') {
      checkUsernameDebounced(username);
    } else {
      setUsernameState((prev) => ({
        ...prev,
        usernameIsValid,
      }));
    }
  };

  const checkEmailDebounced = useCallback(
    debounce((email) => {
      setEmailState((prev) => ({ ...prev, emailSpinner: true }));
      authService
        .checkEmail(email)
        .then((res) => {
          if (res.data) {
            setEmailState((prev) => ({
              ...prev,
              emailDesc: <Available />,
              emailIsValid: 'success',
              emailSpinner: false,
            }));
          } else {
            setEmailState((prev) => ({
              ...prev,
              emailDesc: <Unavailable />,
              emailIsValid: 'error',
              emailSpinner: false,
            }));
          }
        })
        .catch((e) => {
          console.log('e', e);
        });
    }, 1000),
    []
  );

  const checkUsernameDebounced = useCallback(
    debounce((username) => {
      setUsernameState((prev) => ({ ...prev, usernameSpinner: true }));
      authService
        .checkUsername(username)
        .then((res) => {
          if (res.data) {
            setUsernameState((prev) => ({
              ...prev,
              usernameDesc: <Available />,
              usernameIsValid: 'success',
              usernameSpinner: false,
            }));
          } else {
            setUsernameState((prev) => ({
              ...prev,
              usernameDesc: <Unavailable />,
              usernameIsValid: 'error',
              usernameSpinner: false,
            }));
          }
        })
        .catch((e) => {
          console.log('e', e);
        });
    }, 2000),
    []
  );

  const handleSuccessLogin = (res) => {
    localStorage.setItem(constants.SESSION_KEY, JSON.stringify(res.data));
    setUserData(store.dispatch, res.data.userProfile);
    if (history.location.state && history.location.state.from) {
      history.push(history.location.state.from.pathname);
    } else {
      history.push('/home');
    }
  };

  const createAccount = () => {
    const {
      email,
      username,
      password,
      fullName,
      name,
      isBusiness,
      companyId,
      bankName,
      bankAccount,
    } = formData;
    const data = {
      email,
      fullName,
      name,
      password,
      username: username,
      isBusiness,
      companyId,
      bankName,
      bankAccount,
    };
    setSignupError('');
    authService
      .signup(data)
      .then(handleSuccessLogin)
      .catch((e) => {
        setSignupError(e.message);
      });
  };

  const onGoogleLoginSuccess = (res) => {
    setSignupError('');
    const data = {
      contactInformationType: 'GOOGLE',
      value: res.tokenId,
    };

    authService
      .socialLogin(data)
      .then(handleSuccessLogin)
      .catch((e) => {
        setSignupError(e.message);
      });
  };

  const onGoogleLoginFaluire = (res) => {
    setSignupError('Failed to register using Google account.');
  };

  const disabledContinue = !(
    validateUsername(formData.username) === 'success' &&
    validateEmail(formData.email) === 'success' &&
    validatePass(formData.password) === 'success' &&
    validateConfirmPass(formData.password, formData.repeatPassword) ===
      'success'
  );
  const disabledRegister =
    !form.formState.isValid ||
    disabledContinue ||
    (formData.isBusiness &&
      (!formData.name ||
        !formData.fullName ||
        !formData.companyId ||
        !formData.bankName ||
        !formData.bankAccount));

  return (
    <Container>
      <Heading title={lang.registration} />
      <GoBack />

      <Container className={'p016'}>
        <NarrowWrapper>
          <LoginButtonWrapper className='mt2 mb2'>
            <GoogleLogin
              clientId={GOOGLE_CLIENT_ID}
              buttonText='Continue with Google'
              onSuccess={onGoogleLoginSuccess}
              onFailure={onGoogleLoginFaluire}
              cookiePolicy='single_host_origin'
              isSignedIn={true}
            />
          </LoginButtonWrapper>
          <div className='text-size-xl fw500 text-center mt1 mb1'>
            {lang.or}
          </div>
          <Input
            type={'email'}
            label={lang.label_email}
            name={'email'}
            value={formData.email}
            description={emailState.emailIsValid && emailState.emailDesc}
            onChange={handleEmailChange}
            isvalid={emailState.emailIsValid}
            autoComplete='none'
          />
          <Input
            type={'text'}
            label={lang.label_username}
            name={'username'}
            value={formData.username}
            description={
              usernameState.usernameIsValid && usernameState.usernameDesc
            }
            onChange={handleUsernameChange}
            isvalid={usernameState.usernameIsValid}
            autoComplete='none'
          />

          <Input
            type={passEyeIcon ? 'text' : 'password'}
            label={lang.label_password}
            name={'password'}
            value={formData.password}
            description={'Minimum 8 characters, 1 number'}
            onChange={handleChange}
            icon={
              passEyeIcon ? 'icon-password_visible' : 'icon-password_hidden'
            }
            iconClickHandler={() => setPassEyeIcon(!passEyeIcon)}
            isvalid={validatePass(formData.password)}
            autoComplete={'none'}
          />
          <Input
            className={'mb2'}
            type={repPassEyeIcon ? 'text' : 'password'}
            label={lang.label_verify_password}
            name={'repeatPassword'}
            value={formData.repeatPassword}
            onChange={handleChange}
            icon={
              repPassEyeIcon ? 'icon-password_visible' : 'icon-password_hidden'
            }
            iconClickHandler={() => setRepPassEyeIcon(!repPassEyeIcon)}
            isvalid={validateConfirmPass(
              formData.password,
              formData.repeatPassword
            )}
            autoComplete={'none'}
          />

          <InputWrapper>
            <label>
              {lang.professional_acc}{' '}
              <span> ({lang.register_to_earn_money})</span>
            </label>
            <FSwitch
              checked={formData.isBusiness}
              onChange={(value) =>
                setFormData((prev) => ({ ...prev, isBusiness: value }))
              }
            />
          </InputWrapper>

          {/** IS BUSINESS FORM */}
          {formData.isBusiness && (
            <>
              <Input
                type='text'
                label={lang.register_company_name}
                name='name'
                value={formData.name}
                onChange={handleChange}
              />

              <Input
                type='text'
                label={lang.register_company_director}
                name='fullName'
                value={formData.fullName}
                onChange={handleChange}
                isvalid={validateName(formData.fullName)}
              />
              <Input
                type='text'
                label={lang.register_company_id}
                name='companyId'
                value={formData.companyId}
                onChange={handleChange}
                isvalid={validateName(formData.companyId)}
              />
              <Input
                type='text'
                label={lang.register_company_bank}
                name='bankName'
                value={formData.bankName}
                onChange={handleChange}
                isvalid={validateName(formData.bankName)}
              />
              <Input
                type={'text'}
                label={lang.label_bank_account}
                name={'bankAccount'}
                placeholder={'3751232122323121323'}
                value={formData.bankAccount}
                onChange={handleChange}
              />
            </>
          )}

          <CheckoutCheckboxes className='mt2' form={form} />

          <Flex className='center'>
            <RegisterButton onClick={createAccount} disabled={disabledRegister}>
              {lang.register}
            </RegisterButton>
          </Flex>
          <SignupError className={'text-normal text-size-s text-danger'}>
            {signupError}
          </SignupError>
        </NarrowWrapper>
      </Container>
    </Container>
  );
};

export default Register;

const RegisterButton = styled.button`
  margin-top: 32px;
  margin-bottom: 12px;
  width: 100%;
`;

const SignupError = styled.div`
  text-align: center;
`;

export const SocNet = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.secondBackground};
  padding: 10px 20px 10px 12px;
  border-radius: 4px;
  position: relative;
  a {
    color: ${({ theme }) => theme.text};
    text-decoration: unset;
  }
  img {
    width: 24px;
    flex-basis: 24px;
  }

  .title {
    margin-left: 12px;
    flex-grow: 1;
    overflow: hidden;
  }

  i {
    cursor: pointer;
    margin-left: 12px;
  }
  &.small {
    height: 60px;
    width: 60px;
    display: inline-flex;
    margin-right: 15px;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    a {
      margin-left: 0;
    }
    img {
      height: 24px;
    }
  }
`;

const Available = () => <span className={'text-primary'}>Available</span>;
const Unavailable = () => <span className={'text-danger'}>Unavailable</span>;
