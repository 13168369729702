import React, { useEffect, useState } from 'react';
import { TwitterPicker, ColorResult } from 'react-color';
import styles from './ShapeEditSection.module.scss';
import { DEFAULT_TABLE_COLOR } from '../../constants';
import { useLang } from 'src/components/useLang';
import Input from 'src/components/input/input';

interface Shape {
  name: string;
  shapeColor: string;
  colorChange: (color: string) => void;
  editName: (name: string) => void;
  delete: () => void;
}

interface ShapeEditSectionProps {
  shape: Shape;
  cancel: () => void;
}

const ShapeEditSection: React.FC<ShapeEditSectionProps> = ({
  shape,
  cancel,
}) => {
  const lang = useLang();
  const [shapeName, setShapeName] = useState<string>(shape?.name);
  const [shapeColor, setShapeColor] = useState<string>(DEFAULT_TABLE_COLOR);

  useEffect(() => {
    setShapeName(shape?.name);
    setShapeColor(shape?.shapeColor);
  }, [shape]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (
        e.key === 'Backspace' &&
        (e.target as HTMLElement).tagName.toLowerCase() !== 'input'
      ) {
        handleDeleteSection();
      }
    };

    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [shape]);

  const handleShapeColorChange = (newColor: ColorResult) => {
    setShapeColor(newColor.hex);
    shape.colorChange(newColor.hex);
  };

  const handleShapeName = (value: string) => {
    setShapeName(value);
    shape.editName(value);
  };

  const handleDeleteSection = () => {
    shape.delete();
    cancel();
  };

  const handleCancelBtn = () => {
    cancel();
  };

  return (
    <div className={styles.shape_edit_section}>
      <div className={styles.edit_section_header}>
        <div className={styles.edit_section_title}>{lang.map_editor_shes}</div>
        <div
          className={styles.edit_section_cancel_btn}
          onClick={handleCancelBtn}
        >
          {lang.cancel}
        </div>
      </div>
      <div className={styles.name}>
        <label htmlFor='shapeName'>{lang.map_editor_shes_name}:</label>
        <Input
          wrapperstyle={{ margin: 0 }}
          type='text'
          id='shapeName'
          value={shapeName}
          onChange={(e) => handleShapeName(e.target.value)}
        />
      </div>
      <div className={styles.shape_color_picker}>
        <div className={styles.p_color_picker}>
          <div>{lang.map_editor_shes_color}:</div>
          <div className={styles.shape_color_display}>
            <div
              className={styles.p_inner_color_display}
              style={{ backgroundColor: shapeColor }}
            ></div>
          </div>
        </div>
        <div className={styles.p_colors}>
          <TwitterPicker
            className={styles.c}
            color={shapeColor}
            onChange={handleShapeColorChange}
            width='220px'
            triangle='top-left'
          />
        </div>
      </div>
      <div className={styles.edit_section_footer}>
        <div className={styles.del_sec_btn} onClick={handleDeleteSection}>
          {lang.map_delete_object}
        </div>
      </div>
    </div>
  );
};

export default ShapeEditSection;
