import { useState } from 'react';
import CanvasContext from './canvasContext';
import { Link, useParams } from 'react-router-dom';
import AssignEditor from './assignEditor/AssignEditor';
import AssignSetter from './assignSetter/AssignSetter';
import { useQuery } from '@tanstack/react-query';
import { AxiosError, AxiosResponse } from 'axios';
import { ShowType } from 'src/models';

import { getShowDetails } from '../createShow/showService';
import styles from './CreateSeatingMap.module.scss';
import Heading from 'src/components/heading/heading';
import GoBack from 'src/components/goBack/goBack';
import { NeedBigScreen } from 'src/components/NeedBigScreen';

const AssignTicketsContent = () => {
  const { showId } = useParams<{ showId: string }>();
  const [canvasVal, setCanvasVal] = useState();
  const { data: show } = useQuery<
    AxiosResponse<ShowType>,
    AxiosError,
    AxiosResponse<ShowType>,
    string[]
  >(['show'], () => getShowDetails(showId), { enabled: !!showId });

  const setCanvas = function (canv) {
    setCanvasVal(canv);
  };

  return (
    <div className={styles.editWrapper}>
      <CanvasContext.Provider
        value={{
          canvas: canvasVal,
          setCanvas,
        }}
      >
        <div className={styles.edit}>
          <Heading title={show?.data.showTitle} />
          <GoBack />
          <div className={styles.edit_body}>
            <AssignSetter />
            <AssignEditor />
          </div>
        </div>
      </CanvasContext.Provider>
    </div>
  );
};

const AssignTickets = () => {
  return (
    <NeedBigScreen>
      <AssignTicketsContent />
    </NeedBigScreen>
  );
};

export default AssignTickets;
