import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import BiletFooterContainer from 'src/components/Footer';
import PaymentLogotips from 'src/components/paymentLogotips';
import Popup from 'src/components/popup/popup';
import Portal from 'src/components/portal/portal';
import Tabs from 'src/components/tabs/tabs';
import { editorContentToHtml } from 'src/components/textEditor/utils';
import { useUserData } from 'src/hooks/useUserData';
import styled from 'styled-components';
import config from '../../config';
import {
  BREAKING_POINT,
  Button,
  CONTENT_WIDTH,
  Share,
} from '../../styling/globalStyling';
import {
  filterDraftTicketTypes,
  openMaps,
  shareFeature,
  sortById,
} from '../../utils';
import { ITicketType } from '../createShow/createShow';
import { getPublicShow } from '../createShow/showService';
import { dateTimeFormat } from '../myShows/myShows';
import TicketRow from './TicketRow/TicketRow';
import BookTickets from '../createSeatingMap/bookTickets/BookTickets';
import { UserForm } from './UserForm';
import { CheckoutCheckboxes } from 'src/components/CheckoutCheckboxes';
import { TotalAmount } from 'src/components/TotalAmount';

const Show = () => {
  const { showId } = useParams<{ showId: string }>();
  const history = useHistory();
  const lang = useSelector((state: any) => state.home.language);
  const userData = useUserData();

  const { data } = useQuery(['show', showId], ({ queryKey }) =>
    getPublicShow(queryKey[1])
  );

  const [qty, setQty] = useState<any>({});
  const [total, setTotal] = useState(0);
  const show = data?.data || {};
  const location = useLocation();
  const [showUserFormPopup, setShowUserFormPopup] = useState(false);
  const form = useForm({ mode: 'all' });

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
  });

  useEffect(() => {
    const getTotal = () => {
      let total = 0;
      for (let key in qty) {
        const ticket = show.ticketTypes.find(
          (t: ITicketType) => t.id === parseInt(key)
        );
        if (ticket) {
          total += qty[key] * ticket.ticketPrice;
        }
      }

      return total;
    };

    setTotal(getTotal());
  }, [qty, show.ticketTypes]);

  const getData = () => {
    const tickets = show.ticketTypes
      .filter((t) => !!qty[t.id])
      .map((t) => ({ ...t, numberOfTickets: parseInt(qty[t.id]) }));

    return {
      tickets,
      currency: show.currency || 'EUR',
      showId: show.id,
      showTitle: show.showTitle,
      userName: null,
      userEmail: null,
    };
  };

  const checkout = () => {
    ReactGA.event({
      action: 'Click',
      category: 'Button',
      label: 'checkout_tickets',
      value: show.showTitle,
    });

    if (!userData.id) {
      return setShowUserFormPopup(true);
    }

    const data = getData();
    history.push({ pathname: '/wspay', state: { data } });
    // history.push({ pathname: '/ticketspay', state: { data } });
  };

  const share = (e) => {
    e.stopPropagation();
    shareFeature(`${config.appUrl}/#/show/${show.id}`);
  };

  const descriptionHtml = editorContentToHtml(show?.description);

  // Check if description has actual content
  const hasDescription = show?.description?.blocks?.[0]?.text?.length > 0;

  const onGuestBuy = () => {
    ReactGA.event({
      action: 'Click',
      category: 'Button',
      label: 'guest_checkout',
      value: show.showTitle,
    });

    const data = getData();
    const formValues = form.getValues();
    if (!formValues.name || !formValues.email) {
      return;
    }
    data.userName = formValues.name;
    data.userEmail = formValues.email;
    history.push({ pathname: '/wspay', state: { data } });
  };

  return (
    <Page>
      <ShareShow>
        <i className={'icon-share'} onClick={(e) => share(e)} />
      </ShareShow>
      <EventContent>
        <EventTitle>{show.showTitle}</EventTitle>
        <EventPosterWrapper>
          {show.eventImage && (
            <EventPoster src={show.eventImage} alt={show.showTitle} />
          )}
          <EventDetailsContainer>
            <EventDetails>
              <div className='details-row'>
                <i className='fas fa-calendar' />
                <div className='label'>{lang.label_date}:</div>
                <div className='value'>
                  {moment(show?.showDate).format(dateTimeFormat)}
                </div>
              </div>

              <div className='details-row'>
                <i className='fas fa-location-dot' />
                <div className='label'>{lang.label_location}:</div>
                <div className='value link' onClick={(e) => openMaps(e, show)}>
                  {show?.location?.title}
                </div>
              </div>
            </EventDetails>

            <EventDetails>
              <div
                className='details-row organizer-row'
                onClick={() => history.push(`/public/${show.celebrityDto?.id}`)}
              >
                <img
                  src={show.celebrityDto?.profilePhoto}
                  alt={show.celebrityDto?.name}
                  className='profile-photo'
                />
                <div className='organizer-info'>
                  <div className='name'>{show.celebrityDto?.name}</div>
                  <div className='email'>{show.celebrityDto?.email}</div>
                  {show.celebrityDto?.bio && (
                    <div className='bio'>{show.celebrityDto.bio}</div>
                  )}
                </div>
              </div>
            </EventDetails>
          </EventDetailsContainer>
        </EventPosterWrapper>

        {hasDescription && (
          <div className='desc'>
            <span
              dangerouslySetInnerHTML={{
                __html: descriptionHtml || '',
              }}
            />
          </div>
        )}

        <SectionTitle>{lang.tickets}</SectionTitle>
        {show.seatingMap && show.seatingMap !== 'null' ? (
          <SeatingMapWrapper>
            <BookTickets show={show} />
          </SeatingMapWrapper>
        ) : (
          <TicketsContainer>
            <Tickets>
              {show?.ticketTypes
                ?.filter(filterDraftTicketTypes)
                .sort(sortById)
                .map((t: ITicketType, i) => {
                  return (
                    <TicketRow
                      key={t.id}
                      ticket={t}
                      qty={qty}
                      setQty={setQty}
                      show={show}
                    />
                  );
                })}
              <TotalAmount
                className='mt1'
                total={total}
                currency={show.currency}
              />
              <CheckoutCheckboxes form={form} />
              <div className={'pullRight'}>
                <Button
                  disabled={!total || !form.formState.isValid}
                  onClick={checkout}
                >
                  {lang.checkout}
                </Button>
              </div>
            </Tickets>
          </TicketsContainer>
        )}
        <BiletFooterContainer />
        <PaymentLogotips />
      </EventContent>
      <Tabs active={3} lang={lang} />
      {showUserFormPopup && (
        <Portal>
          <Popup
            title={lang.user_form_title}
            content={<UserForm form={form} />}
            submitAction={form.handleSubmit(onGuestBuy)}
            submitText={lang.checkout}
            submitDisabled={!form.formState.isValid}
            cancelAction={() => setShowUserFormPopup(false)}
            cancelText={lang.close}
            trackingTitle={'show_publish'}
            style={{ padding: '16px' }}
          />
        </Portal>
      )}
    </Page>
  );
};

export default Show;

export const ShowDetails = styled.div`
  padding: 0;

  .date {
    padding: 2px 4px;
  }
  .location {
    padding: 2px 4px;
  }

  i {
    margin-right: 5px;
    text-align: center;
    min-width: 16px;
  }

  .small {
    font-size: 14px;
  }

  .desc {
    padding: 2px 4px;
    text-align: justify;
  }

  .link,
  a {
    color: ${({ theme }) => theme.secondary};
    text-decoration: underline;
  }
`;

const SectionTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 8px;
  margin-top: 16px;
  text-align: center;
`;

const TicketsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Tickets = styled.div`
  padding: 0 12px;
  min-width: 600px;
  .pullRight {
    text-align: right;
  }
  @media (max-width: 600px) {
    min-width: 100%;
  }
`;

export const ShowOwner = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  gap: 10px;
  padding: 6px 5px 6px 6px;
  background: rgba(255, 255, 255, 0.1);

  img.userImage {
    width: 20px;
    height: 20px;
    border-radius: 100%;
  }

  .owner {
    cursor: pointer;
  }
`;

// EVENT COMPONENTS

const Page = styled.div`
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const EventContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 32px;
  gap: 32px;
  max-width: ${CONTENT_WIDTH};
  width: 100%;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const EventTitle = styled.div`
  font-size: 38px;
  font-style: normal;
  font-weight: 700;
  -webkit-font-smoothing antialiased;
`;

const EventPosterWrapper = styled.div`
  display: flex;
  gap: 34px;
  @media (max-width: ${BREAKING_POINT}) {
    flex-direction: column;
    gap: 16px;
  }
`;

const EventPoster = styled.img`
  width: 66%;
  height: auto;
  overflow-clip-margin: content-box;
  overflow: clip;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
  @media (max-width: ${BREAKING_POINT}) {
    width: 100%;
  }
`;

const EventDetailsContainer = styled.div`
  width: 34%;
  float: right;
  padding-left: 30px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 16px;
  @media (max-width: ${BREAKING_POINT}) {
    width: 100%;
    padding-left: 0;
  }
`;

const EventDetails = styled.div`
  border: 1px solid ${({ theme }) => theme.boxBorderColor};
  padding: 30px;
  box-sizing: border-box;
  border-radius: 10px;
  width: 100%;
  flex: none;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;

  .details-row {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    flex-wrap: wrap;

    i {
      font-size: 20px;
      color: ${({ theme }) => theme.secondary};
    }

    .label {
      font-weight: 600;
      min-width: 80px;
    }

    .value {
      color: ${({ theme }) => theme.textColor};
    }
  }

  .details-row.organizer-row {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    cursor: pointer;

    .profile-photo {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      object-fit: cover;
    }

    .organizer-info {
      flex: 1;

      .name {
        font-weight: 600;
        font-size: 16px;
        margin-bottom: 4px;
      }

      .email {
        font-size: 14px;
        color: ${({ theme }) => theme.textColor};
        margin-bottom: 4px;
      }

      .bio {
        font-size: 14px;
        color: ${({ theme }) => theme.textColor};
        margin-top: 8px;
      }
    }
  }
`;

const SeatingMapWrapper = styled.div`
  display: flex;
  justify-content: center;
  height: fit-content;
  max-height: 660px;
  width: 100%;
`;

const ShareShow = styled(Share)`
  position: absolute;
  top: 20px;
  right: 16px;
  z-index: 1000;
`;
