import { TICKET_FEE } from 'src/constants';

const translation = {
  id: 'MK',
  yes: 'Да',
  no: 'Не',
  ok: 'В ред',
  for: 'за',
  in: 'во',
  cancel: 'Откажи',
  delete: 'Избриши',
  close: 'Затвори',
  verify: 'Верифицирај',
  send: 'Испрати',
  city: 'Град',
  address: 'Адреса',
  optional: 'Опционално',
  description: 'Опис',
  verification: 'Верификација',
  tabs_fangouts: 'Fangout requests',
  tabs_posts: 'Постови',
  tabs_requests: 'Барања',
  tabs_profile: 'Профил',
  tabs_account: 'Сметка',
  tabs_users: 'Корисници',
  fangouts_search: 'Пребарај',
  fangouts_bill: 'Сметка',
  fangouts_fans: 'Фанови',
  fangouts_type: 'Тип',
  fangouts_time: 'Време',
  fangouts_complete: 'Потврди',
  CASUAL: 'Кежуал',
  casual_description: '(Пијачка, вечера, прошетка, дружење...)',
  EDUCATION: 'Едукација',
  education_description: '(Пренос на знаење од секаков вид)',
  PERFORMANCE: 'Настап/Услуга',
  performance_description: '(Свирка, глума, стендап...)',
  FAN_PAYS: 'Плаќа Фанот',
  SPLIT: 'Сметката се дели',
  CELEBRITY_PAYS: 'Плаќа Познатиот',
  bill: 'Сметката',
  EUR: '€',
  USD: '$',
  most_popular: 'Најпопуларно',
  most_active: 'Најактивно',
  latest_fangouts: 'Најнови Fangouts',
  unavailable_atm: 'Корисникот не е достапен',
  requests_title: 'Барања',
  under_construction: 'Во изградба...',
  profile_title: 'Профил',
  profile_status: 'Статус',
  profile_share_refferal:
    'Поканете пријател и заработете (кликнете да споделите)',
  profile_settings: 'Поставки',
  profile_personal_details: 'Профил',
  profile_payment_history: 'Историја на плаќања',
  profile_referrals: 'Реферали',
  profile_logout: 'Одјавете се',
  profile_remove_account: 'Избриши сметка',
  profile_remove_account_message1:
    'Дали сте сигурни дека сакате да ја избришете сметката?',
  profile_remove_account_message2: 'Оваа постапка не е реверзибилна',
  profile_help_title: 'Зошто да се верифицирате?',
  profile_help_message1:
    'За да обезбедиме високо ниво на безбедност потребно е да се верифицирате со лична карта или пасош.',
  profile_help_message2:
    'Можете да бидете спокојни и безбедни, по верификацијата ние го чуваме само бројот на документот, фотографијата ќе биде трајно избришана.',
  profile_help_ref_title: 'Линк за реферали',
  profile_help_ref_message1:
    'Заработете 3% за секој реферал преку вашиот линк, и 2% од рефералите на вашиот реферал.',
  profile_help_ref_message2:
    'Вашата заработка од 3% и 2% е на сметка на платформата, не одзема од заработувачката на корисниците регистрирани преку вас.',
  increase_credibility:
    'Со додавање на вашите социјални профили ја зголемувате вашата видливост',
  settings_title: 'Поставки',
  settings_enable_dark_mode: 'Темен мод',
  settings_language: 'Јазик',
  settings_currency: 'Валута',
  referrals_title: 'Реферали',
  createFangout_title: 'Нов Fangout',
  editFangout_title: 'Уреди Fangout',
  requestFangout_title: 'Побарај Fangout',
  requestFangout_user_is_not_available:
    'Корисникот не е достапен на тој датум или на таа локација',
  details_title: 'Детали',
  payment_title: 'Плаќање',
  label_title: 'Назив на настанот',
  label_location: 'Локација',
  label_date: 'Почеток',
  label_end_date: 'Крај',
  label_time: 'Време',
  label_type: 'Тип',
  label_bill: 'Сметка',
  label_audience: 'Фанови/Публика',
  label_price: 'Fangout цена',
  label_set_price: 'Постави цена',
  label_request: 'Барање',
  label_create: 'Креирај',
  label_edit: 'Ажурирај',
  label_save: 'Сними',
  label_from: 'Од',
  label_to: 'До',
  label_celebrity: 'Celebrity',
  label_my_fangouts: 'Мои Fangouts',
  label_duration: 'Траење',
  label_profession: 'Професија',
  label_not_visible_to_others: '(visible only by admin)',
  label_full_name: 'Име и Презиме',
  label_stage_name: 'Уметничко име',
  label_bio: 'Биографија',
  label_soc_networks: 'Друштвени мрежи',
  bank_info: 'Додадете сметка во банка (за заработка)',
  label_bank_country: 'Држава во која е Банката',
  label_bank_name: 'Име на банката',
  label_bank_account: 'Банкарска сметка',
  attention_user_is_not_verified: '(Внимание, корисникот не е верифициран)',
  requests_received: 'Примени',
  requests_sent: 'Претени',
  requests_history: 'Завршени',
  requests_confirm_title: 'Потврди',
  requests_confirm_complete_content: (
    <div className={'content'}>
      Дали потврдувате дека услугата Ви е{' '}
      <div>
        <span className={'text-primary text-bold'}>Complete</span> пружена?
      </div>
    </div>
  ),
  requests_confirm_content: function (approve) {
    return (
      <div className={'content'}>
        Дали сте сигурни дека сакате да го{' '}
        {approve === 0 ? (
          <div>
            <span className={'text-primary text-bold'}>Одобрите</span> ова
            барање?
          </div>
        ) : (
          <div>
            <span className={'text-danger text-bold'}>Одбиете</span> ова
            барањеt?
          </div>
        )}
      </div>
    );
  },
  requests_dispute_help_title: 'Спор',
  requests_dispute_help_content:
    'Ако мислите дека не ви е пружена услугата во целост, може да поведете спор. Администраторот наскоро ќе ве контактира.',
  requests_dispute_submitAction: 'Спор',
  requests_dispute_content:
    'Ве молиме опишите што се случило (највеќе 2000 карактери).',
  requests_dispute_confirmation: 'Ви благодариме. Ќе бидете контактирани.',
  requests_dispute_notification:
    'Спорот е во тек. Администраторот ќе ве контактира.',
  has_set_the_price: 'поставил цена за Fangout ',
  price_is_set_note:
    'Цената е одредена, ве молиме почекајте фанот да плати или да го откаже барањетоt.',
  pay: 'Плати',
  message_placeholder: 'Прати порака',
  message_noConversation: 'Договорете детали преку пораки',
  click_to_verify: 'кликни за верификацијаy',
  create_post: 'Креирај пост',
  new_post_title: 'Нов пост',
  post_caption: 'Впиши опис',
  upload: 'Закачи',
  save: 'Зачувај',
  toggle_push_notifications: 'Пуш нотификации',
  fan_has_paid: 'Платено',
  remove: 'Избриши',
  sent: 'Пратено',
  help_new_fangout1:
    'Fangout е настан кој ги поврзува Познатите и фановите на одреден начин.',
  help_new_fangout2:
    'Може да биде од професионален, образовен или необавезен тип.',
  help_new_fangout3:
    'Fangout може да креира било кој, создавањето на настан е бесплатно. Може да креирате неограничен број на настани',
  help_new_fangout4:
    'При креирањето на настан треба да внесете некои детали како време, место, траење и цена. Останатите детали може да се договорат преку четот.',
  help_new_fangout5:
    'По креирањето на Fangout настан препорачано е да го споделите со вашите фанови за да биде што побрзо продаден.',
  help_new_fangout6:
    'При формирањето на цената за Fangout ве молиме да ја врачунате провизијата на платформата која изнесува 20%.',
  support: 'Поддршка',
  deleted: 'Избришано',
  verify_message1:
    'Заради безбедност, само верифицираните корисници може да ги користат сите опции.',
  verify_message2:
    'За да го верифицирате вашиот профил ве молиме прикачете скенирана лична карта или пасош.',
  profile_unverified: 'НЕВЕРИФИЦИРАН профил',
  verify_first:
    'Заради безбедност, само верифицирани корисници може да ја користат платформата во целост. Ве молиме верифицирајте се.',
  verify_be_patient:
    'Вашиот профил наскоро ќе биде верифициран. Благодариме за стрпливоста.',
  reset_pass_title: 'Новa лозинка',
  reset_pass_summary: 'Внесете ја вашата нова лозинка.',
  reset_pass_new_password: 'Нова лозинка',
  reset_pass_repeat_password: 'Потврдете ја лозинката',
  forget_pass_heading: 'Забравена лозинка',
  forget_pass_summary:
    'Ве молиме да ја внесете вашата мејл адреса за да ви пратиме инструкции за креирање нова лозинка.',
  forget_pass_title: 'Проверете ја вашата пошта',
  forget_pass_message:
    'Ви испративме инструкции за враќање на вашата лозинка на вашиот мејл.',
  publicProfile_subscribe: 'Заследи',
  publicProfile_subscribed: 'Следите',
  publicProfile_subscribe_title: 'Заследи го корисникот',
  publicProfile_subscribe_help:
    'Добијте известување кога корисникот е достапен или кога ќе креира Fangout настан.',
  publicProfile_unsubscribe: 'Одследи',
  subscriptions_title: 'Следења',
  subscriptions_title_help: 'Корисници и локации кои ги следите.',
  availability_title: 'Достапност',
  availability_isAvailable: 'Достапен за Fangout барања',
  availability_newAvailability: 'Додади нов период на достапност',
  availability_help:
    'Доколку чекирате дека сте достапни за Fangout настани а не наведете специфичи периоди, значи дека сте достапни во се��ое време.',
  user_availability: 'Достапност на корисникот',
  myagent_title: 'Fangout агент',
  myagent_on: 'Aктивен',
  myagent_off: 'Неактивен',
  myagent_title_short: 'F-Agent',
  myagent_title_help:
    'наместите Fangout агент кој автоматски ќе креира цена на основа на видот на Fangout барањето.',
  myagent_help_performance:
    'Moжете да наместите максимална бројка на учесници и да наплаќате според траењето на настанот.',
  myagent_help_education:
    'Moжете да наместите максимална бројка на учесници на предавањето и да наплаќате по час.',
  myagent_help_casual:
    'Moжете да наместите максимална бројка на учесници, максимално траење и да наплаќате за првиот и секој друг започнат час.',
  myagent_help_additinal:
    'Ако барањто за Fangout не влегува во зададените параметри тогаш тоа нема да биде автоматски одговорено и ќе можете да одговорот да го наместите рачно.',
  myagent_h: 'час',
  myagent_h_n: '.',
  myagent_h_first: '.',
  myagent_h_second: '.',
  myagent_h_third: '.',
  myagent_add_next_hour: 'Следниот час',
  myagent_h_evry_next: 'Секој следен час',
  myagent_max_audience: 'Максимален број на учесници',
  myagent_max_duration: 'Максимално траење',
  myagent_h_per_person: '1ч по личност',
  fout_autocompletion_label1: 'Авто потврда за',
  fout_autocompletion_label2: 'Fangout-от ќе биде потврден автоматски.',
  confirm_are_you_sure: 'Дали сте сигурни?',
  premium_feature: 'Premium feature',
  premium_buy_year: 'Купи Premium план',
  premium_activate: 'Активирај',
  premium_type_code: 'Користи код за активација',
  premium_desc_availability: (
    <div>
      <b>Достапност</b> ви овозможува да поставите датуми и места кога сте
      достапни. <br />
      <br />
      Ова ќе ги ограничи корисниците да ви праќаат Fangout барања само за места
      и време кога сте достапни.
    </div>
  ),
  premium_desc_myagent: (
    <div>
      <b>F-Agent</b> е вашиот агент и веднаш ќе постави цена на примените
      Fangout барања, доколку одговараат на вашите F-Agent поставки. <br />
      <br />
      Ова ќе ви заштеди време за преговарање. Поставете го F-Agent, вратете се
      назад и оставете го да работи за вас.
    </div>
  ),
  switch_to_professional: 'Switch to Professional Account',
  switch_to_regular: 'Switch to Regular Account',
  premium_expires: 'Premium active to: ',
  premium_buy_description:
    'Premium овозможува две дополнителни опции кои драстично заштедуваат време и ја зголемуваат вашата ефикасност',
  buy: 'Купи',
  premium_label_months: 'Premium времетраење',
  premium_label_months_desc: '(месеци)',
  premium_price_1: 'Price: 50',
  premium_price_2: '/month',
  premium_price_discount_1: '(for 12+ months 40',
  premium_price_discount_2: 'monthly)',
  premium_to_pay: 'To pay:',
  premium_months: 'month(s)',
  premium_payment: 'Premium payment',
  event: 'event',
  SHOW: 'Event',
  ONLINE_SHOW: 'Online event',
  HYBRID_SHOW: 'Hybrid event',
  show_desc: 'Regular live event. Only tickets for the show are available.',
  online_desc:
    'Online only event. Only online streaming tickets are available.',
  hybrid_desc:
    'Live show with online streaming. Both, the show and online tickets are available.',
  show_budget: 'Budget',
  show_budget_desc:
    'Necessary cost of the event (artists, place, transport etc.)',
  show_confirmation_point: 'Confirmation point',
  show_confirmation_point_desc:
    'This is a sum that includes the Budget + 6% Fangout fee.\n' +
    'If the confirmation point is not reached, all buyers\n' +
    'automatically get refund and event is get canceled.',
  show_funding_until: 'Funding until',
  show_funding_until_desc:
    'If the Budget is not reached up to this date event will \n' +
    'be canceled and funds will be refunded.',
  show_image: 'Show image',
  show_upload_image: 'Upload image',
  show_event_image_size: '(1200x1600 recommended)',
  show_tickets: 'Ticket types',
  show_streaming_ticket: 'Streaming ticket',
  show_streaming_ticket_price: 'Streaming ticket price',
  show_streaming_ticket_desc: 'Streaming ticket description',
  show_ticket_title: 'Ticket type title',
  show_ticket_desc: 'Ticket type description',
  show_tickets_number: 'Num. of tickets',
  show_tickets_price: 'Price per ticket',
  show_add_ticket: 'Add ticket type',
  show_count_profit: `Your profit`,
  show_count_profit_desc: `(goal - ${TICKET_FEE}% platform fee)`,
  show_count_total_tickets: 'Total tickets',
  show_count_funding_goal: 'Funding goal',
  show_age_restriction: 'Age restriction',
  show_no_restriction: 'No restriction',
  show_is_private: 'Private event',
  show_private_event: 'Access via URL only',
  show_publish: 'Publish',
  show_publish_message: (
    <div>
      Are you sure you want to publish this Eevent.
      <br /> Check the settings again, future edit will be limited for some
      fields.
    </div>
  ),
  show_delete_desc: 'Are you sure?',
  show_ticket_soldout: 'Sold out',
  show_sold: 'Sold',
  show_earned: 'Earned',
  show_select_seating_map: 'Select seating map',
  show_edit_seating_map: 'Change seating map',
  show_save_to_select_seating_map: 'Save to select seating map',
  show_no_seating_map: 'Seating map is not selected',
  show_seating_map: 'Seating map',
  ticket: 'Ticket',
  tickets: 'Tickets',
  label_shows: 'Events',
  label_my_shows: 'My Events',
  more: 'Повеке',
  less: 'Помалко',
  invalidator: 'Invalidator',
  invalidated: 'Invalidated',
  invalidate: 'Invalidate',
  invalidation_success: 'Invalidated successfully!',
  invalidation_invalid_ticket: 'Ticket is invalidated',
  reporter: 'Reporter',
  report: 'Report',
  report_success: 'Reported successfully!',
  share_the_reporter: 'Share the Reporter',
  default_qr_scanner_message: 'No result',
  share_the_invalidator: 'Share the Invalidator',
  show_go_to_public_page: 'Go to public page',
  statistics: 'Statistics',
  total: 'Total',
  ticket_type: 'Ticket type',
  purchased: 'Purchased',
  need_login_show_message: 'To buy tickets please login first.',
  ticket_payment: 'Tickets payment',
  ticket_payment_ticketsTotal: 'Tickets total',
  ticket_payment_discount: 'discount',
  ticket_payment_bankFee: 'Service fee',
  ticket_order: 'Order list',
  show_cannot_cancel:
    'NOTE: If event starts within next 5 days, once published it can not be canceled.',
  no_streaming: 'No streaming yet.',
  no_tickets: 'No tickets found',
  no_requests: 'No received requests.',
  no_requests_sent: 'No sent requests.',
  no_requests_history: 'No history.',
  no_following: 'No following anyone.',
  no_following_locations: 'Add the location to be notified about any change.',
  or: 'или',
  qty: 'ком',
  checkout: 'Checkout',
  welcome_note: 'Лек против организациски главоболки за 21. век',
  welcome_subnote: '100% природно решение за стрес при организација на настани',
  welcome_pharma_notes: [
    'без несакани дејства и контраиндикации',
    'Не е потребен рецепт. Консултирајте го вашиот организатор.',
    '100% природно решение за стрес при организација на настани.',
    'Може да се користи превентивно и во акутни случаи на хаос.',
    'Нема контраиндикации со други организациски алатки.',
  ],
  register: 'Регистрирај се',
  welcome_has_account: 'Имате налог?',
  login: 'Пријава',
  login_dont_have_account: 'Don’t have an account?',
  register_acceptance: 'Регистрирајќи се прифаќате',
  register_terms_of_use: 'Услови на користење',
  accept: 'Прифаќам',
  register_comply: 'Согласен сум со',
  register_privacy_policy: 'Политика на приватност',
  label_public: 'Public',
  label_private: 'Private',
  label_username: 'Username',
  label_email: 'E-mail',
  label_password: 'Password',
  label_verify_password: 'Verify Password',
  registration: 'Регистрација',
  register_to_earn_money: 'To earn money',
  continue: 'Continue',
  show_allowed_invalidations: 'Дозволено пати да се влезе',
  show_allowed_invalidations_help_title: 'Allowed entrances',
  show_allowed_invalidations_help_message:
    'When ticket is meaned to be used multiple days `Allowed entrances` has to be set for number of days we want to allow users to enter the event.',
  ticketInvalidatedInLast12Hrs:
    'Ticket has been already invalidated in last 12 hours',
  tickets_price: 'Tickets price',
  live: 'Live',
  map: 'Map',
  seating_maps: 'Seating maps',
  create_seating_map: 'Create Seating Map',
  no_maps: 'No saved maps',
  bigger_screen: 'Please use bigger screen to edit Seating maps!',
  new_map: 'New Map',
  ticket_assign_selected_object: 'Selected object',
  show_seating_map_description:
    'If you have enumerated seats, tables or custom places on the event, think about assigning the seating map.',
  ticket_assign_button_save: 'Assign map to',
  seats_editing_disable: 'Save seats',
  map_delete_object: 'Delte object',
  is_draft: 'Is draft',
  user_form_title: 'User details',
  table: 'Sto',
  price_per_seat: 'Цена по билет',
  minimum_tickets_to_buy: 'Минимален број на билети за купување',
  number_of_tickets_selected: 'Број на билети',
  seat: 'Седиште',
  ticket_price: 'Цена',
  no_tickets_selected: 'Нема избрано билети',
  contact: 'Контакт',
  about_company: 'Компанија',
  delivery_methods: 'Начин на достава',
  payment_methods: 'Начин на плаќање',
  terms_of_use: 'Услови на користење',
  privacy_policy: 'Политика на приватност',
  reklamacija: 'Рекламација',
  conversion_statement: (
    <div>
      <div>
        Сите цени на оваа веб-страница се прикажани во денари. ДДВ е вклучен во
        цената.
      </div>
      <div>
        Вашите билети ќе бидат испратени на вашата е-пошта, а доколку сте
        регистриран корисник, ќе бидат достапни и во вашиот е-паричник на
        https://app.bilet.rs
      </div>
    </div>
  ),
  register_company_name: 'Име на компанијата',
  register_company_director: 'Име и презиме на директорот',
  register_company_id: 'Матичен број на компанијата',
  register_company_bank: 'Банка',
  register_company_account: 'Број на сметка',
  // Setter section
  map_editor_title: 'Уредувач на мапа',
  map_editor_create_map: 'Креирај мапа',
  map_editor_create_button_label: 'Креирај',
  map_editor_add_button_label: 'Додади',
  map_editor_name_label: 'Име на мапа',
  map_editor_add_seating: 'Додади седишта',
  map_editor_add_seats_name: 'Име на блок',
  map_editor_add_seats_rows: 'Редови',
  map_editor_add_seats_seats: 'Седишта',
  map_editor_add_table: 'Додади маси',
  map_editor_add_table_name: 'Име',
  map_editor_add_table_seats: 'Седишта',
  map_editor_add_table_min_tickets: 'Мин. билети',
  map_editor_add_text: 'Додади текст',
  map_editor_add_text_text: 'Текст',
  // Text edit section
  map_editor_tes: 'Уредувач на текст',
  map_editor_tes_text: 'Текст',
  map_editor_tes_color: 'Боја',
  map_editor_tes_font_size: 'Големина',
  map_editor_tes_font_weight: 'Дебелина',
  // Seating edit section
  map_editor_ses: 'Уредувач на седишта',
  map_editor_ses_seat_color: 'Боја',
  map_editor_ses_arc: 'Лак',
  map_editor_ses_reverse_arc: 'Обратен лак',
  map_editor_ses_arc_deg: 'Агол',
  map_editor_ses_arc_radius: 'Радиус',
  map_editor_ses_row_space: 'Растојание меѓу редови',
  seats_editing_enable: 'Избриши одредени седишта',
  map_editor_ses_rows_name: 'Имиња на редови',
  map_editor_ses_row: 'Ред',
  // Table edit section
  map_editor_tbes: 'Уредувач на маса',
  map_editor_tbes_name: 'Име на маса',
  map_editor_tbes_min_tickets: 'Минимум билети',
  map_editor_tbes_seat_spacing: 'Растојание меѓу седишта',
  map_editor_tbes_seat_size: 'Големина на седиште',
  map_editor_tbes_table_size: 'Големина на маса',
  map_editor_tbes_seat_color: 'Боја на седиште',
  map_editor_tbes_table_color: 'Боја на маса',
  // Shape edit section
  map_editor_shes: 'Уредувач на форми',
  map_editor_shes_name: 'Име на форма',
  map_editor_shes_color: 'Боја',
  and: 'и',
  assign_setter_select_ticket_type: 'Изберете билет',
  add: 'Додади',
  assign_setter_no_select_object: 'No selected object',
  assign_setter_no_ticket_type_allocated: 'No ticket type allocated',
  assign_setter_create_new_map: 'Креирај нова мапа',
  payment_success_title: 'Плаќање успешно',
  payment_failed_title: 'Плаќање неуспешно',
  payment_success_message: 'Билети ќе бидат испратени на вашата е-пошта.',
  payment_success_message_etickets:
    'Билети ќе бидат достапни и во вашиот е-паричник на https://app.bilet.rs',
  payment_redirection_message: 'Ќе бидете пренасочени скоро',
  need_big_screen:
    'Уредување на мапа на седишта е можно само на поголем екран.',
  go_to_home: 'Оди на почетна страница',
  user: 'Податоци за корисникот',
  order_details: 'Податоци за нарачка',
  transaction_details: 'Податоци за трансакција',
  delivery_date: 'Датум на трансакција',
  tax: 'ДДВ',
  payment_results_title: 'Плаќање',
  payment_results_status: 'Статус на трансакција',
  payment_results_success: 'Успешно',
  payment_results_subtitle: '(рачун на платна картица дебитиран)',
  payment_results_loading_message: 'Генерирање на билети, ве молам сакајте...',
  payment_results_error_message: 'Грешка при генерирање на билети',
  payment_error:
    'Грешка при плаќање, проверете податоците и обидете се повторно.',
};
export default translation;
