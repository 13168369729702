import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import { Container, TextContainer } from 'src/styling/globalStyling';

const PolitikaPrivatnosti = () => {
  return (
    <Container>
      <GoBack />
      <Heading title='Politika privatnosti' />
      <TextContainer>
        <p>
          <em>Shodno odredbi člana 23 Zakona o za&scaron;titi podataka</em>{' '}
          <em>o ličnosti </em>
          <em>("</em>
          <em>Sl. glasnik RS</em>
          <em>"</em>
          <em>, br. 87/2018</em>
          <em>, </em>
          <em>u daljem &nbsp;&nbsp;&nbsp;&nbsp;</em>
          <em>tekstu: </em>
          <em>"</em>
          <em>Zakon</em>
          <em>"</em>
          <em>
            ) i shodno Op&scaron;toj uredbi o za&scaron;titi podataka br. 20
            76/679
          </em>
          <em>, </em>
          <em>dalje:</em>
        </p>
        <p>
          <em>(,,</em>
          <em>GDPR</em>
          <em>") </em>
          <em>Rukovalac Bilet Solutions DOO Niš</em>
          <em>, </em>
          <em>matični broj:</em>
          <strong>
            <em> 22061011</em>
          </strong>
          <em>, </em>
          <em>PIB:</em>
          <strong>
            <em> 114721552</em>
          </strong>
          <em>, </em>
          <em>čiji je &nbsp;</em>
          <em>zakonski zastupnik Božidar Zečević, direktor, (dalje</em>
          <em>: "</em>
          <em>Rukovalac</em>
          <em>"</em>
          <em>), izdaje sledeće</em>
          <em>:</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>OBAVESTENJE O OBRADI PODATAKA O LIČNOSTI</strong>
        </p>
        <p>("Obave&scaron;tenje")</p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          <em>Svrha ovog Obave&scaron;tenja je da Rukovalac</em>
          <em>, </em>
          <em>
            osigura ostvarivanje i za&scaron;titu prava na privatnost lica koja
            su izvr&scaron;ila kupovinu ulaznica za događaje kod Rukovaoca:{' '}
            <strong>("kupci/korisnici</strong>
          </em>
          <strong>
            <em>'')</em>
          </strong>
          <strong>
            <em>, </em>
          </strong>
          <em>kao i da </em>
          <em>ih informi&scaron;e </em>o{' '}
          <em>njihovim pravima u vezi sa obradom podataka </em>o{' '}
          <em>ličnosti.</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>Podaci o ličnosti koji se obrađuju</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          ime, prezime, adresa isporuke, broj telefona i Va&scaron;a e-mail
          adresa.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            Svrha nameravane obrade podataka i pravni osnov za obradu podataka
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Podaci se obrađuju u svrhu zaključenja i izvr&scaron;enja ugovora o
          prodaji ulaznica za događaj, kao i u svrhu slanja obave&scaron;tenja o
          događajima (npr. promena termina održavanja, otkazivanje i sl.)
        </p>
        <p>&nbsp;</p>
        <p>
          Pravni osnov obrade jeste: obrada je neophodna za izvr&scaron;enje
          ugovora zaključenog sa licem na koje se podaci odnose ili za
          preduzimanje radnji, na zahtev lica na koje se podaci odnose, pre
          zaključenja ugovora.
        </p>
        <p>&nbsp;</p>
        <p>
          Davanje podataka o ličnosti kao ugovorna obaveza i neophodan uslov za
          zakljucenje ugovora.
        </p>
        <p>&nbsp;</p>
        <p>
          Predmetni podaci predstavljaju neophodan uslov za zaključenje ugovora
          o kupovini ulaznica za određeni događaj.
        </p>
        <p>&nbsp;</p>

        <strong>Podaci o kompaniji koji se obrađuju</strong>
        <p>&nbsp;</p>
        <p>
          e-mail, naziv kompanije, matični broj, PIB, broj računa i naziv banke.
        </p>
        <strong>&nbsp;</strong>
        <p>
          <strong>Obrađivac</strong> podataka
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>Bilet Solutions DOO</p>
        <p>&nbsp;</p>
        <p>
          <strong>lznosenje</strong> <strong>podataka</strong>{' '}
          <strong>o licnosti u drugu drzavu ili me</strong>
          <strong>đ</strong>
          <strong>unarodnu organizaciju</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>Podaci se ne izvoze iz Republike Srbije</p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>
            Rok čuvanja podataka i kriterijum za njegovo odredivanje
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>Podaci se čuvaju najvi&scaron;e 10 godina.</p>
        <p>&nbsp;</p>
        <p>
          <strong>Prava lica čiji se podaci o ličnosti obrađuju</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Lice ima pravo pristupa, pravo na ispravku, dopunu, brisanje,
          ograničenje i prenosivost podataka, &nbsp;&nbsp;&nbsp;&nbsp;kao i
          pravo na prigovor i automatizovano dono&scaron;enje pojedinačnih
          odluka.
        </p>
        <p>&nbsp;</p>
        <p>
          Lice ima pravo na opoziv pristanka u bilo koje vreme, pri čemu opoziv
          pristanka ne utiče na dopu&scaron;tenost obrade pre opoziva.
        </p>
        <p>&nbsp;</p>
        <p>
          U slučaju opoziva pristanka, Rukovalac neće obrađivati podatke lica na
          koje se opoziv odnosi, osim ukoliko Rukovalac ima zakonsko
          ovla&scaron;ćenje za obradu.
        </p>
        <p>&nbsp;</p>
        <p>
          Navedene zahteve Lica dostavljaju e-mailom na e-mail adresu
          prodaja@bilet.rs.
        </p>
        <p>&nbsp;</p>
        <p>
          Rukovalac će na svaki zahtev odgovoriti u najkraćem mogućem roku, a u
          svakom slučaju najkasnije &nbsp;u roku od 30 dana od prijema zahteva u
          skladu sa članom 21 Zakona i članom 12 GDPR.
        </p>
        <p>&nbsp;</p>
        <p>
          Lice može podneti pritužbu u vezi sa obradom svojih podataka o
          ličnosti pred Poverenikom za &nbsp;informacije od javnog značaja i
          za&scaron;titu podataka o ličnosti.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            <em>
              Davanje podataka o ličnosti kao ugovorna obaveza i neophodan uslov
              za zaključenje ugovora
            </em>
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>
            Tehničke i organizacione mere radi za&scaron;tite podataka o
            ličnosti
          </strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Rukovalac i obrađivac su sproveli tehničke i organizacione mere
          za&scaron;tite radi sigurnosti i integriteta podataka o ličnosti,
          za&scaron;tite podataka od neovla&scaron;cenih pristupa, zlonamernih
          postupaka, gubitka ili otkrivanja podataka neovla&scaron;ćenim trećim
          licima.
        </p>
        <p>&nbsp;</p>
        <p>
          Prihvatanjem ovog Obave&scaron;tenja lice je&nbsp; svesno i saglasano
          da se njegovi podaci o ličnosti naveden i u ovom Obave&scaron;tenju,
          obrađuju od strane Rukovaoca i Obradivaca, kao i da navedenim podacima
          mogu pristupiti zaposlena i angažovana lica kod Rukovaoca i
          Obrađivača. Lica koja pristupaju podacima o ličnosti su obavezna da
          podatke čuvaju kao poverljive i na siguran način, a od njih se zahteva
          da koriste podatke o ličnosti samo u gore pomenute svrhe.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Rukovalac</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <strong>Politika privatnosti</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>Bilet Solutions DOO</p>
        <p>&nbsp;</p>
        <p>Romanijska 7, Niš</p>
        <p>&nbsp;</p>
        <p>
          Delatnost i &scaron;ifra delatnosti: 7990 Ostale usluge rezervacije i
          delatnosti povezane s njima
        </p>
        <p>Maticni broj: 22061011</p>
        <p>PIB: 114721552</p>
        <p>
          Web adresa: <a href='http://bilet.rs'>bilet.rs</a>
        </p>
        <p>
          E-mail: <a href='mailto:office@bilet.rs'>office@bilet.rs</a>
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Kriptovane porudžbine</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Svi Va&scaron;či lični podaci ce biti transferisani SSL (Secure Socket
          Layer) metodom u 128-bitnoj enkripciji (RSA s 1024bita) i zbog toga ne
          mogu biti iskori&scaron;ćene od strane trećih osoba. Ovo je trenutno
          najnoviji standard sigurnosti na internetu.
        </p>
        <p>&nbsp;</p>
        <p>
          <strong>Cookies / kolačići</strong>
        </p>
        <p>
          <strong>&nbsp;</strong>
        </p>
        <p>
          Kolačići su alfanumerički paketi informacija sa varijabilnim sadržajem
          koje &scaron;alje veb server koji se čuvaju na računaru korisnika i
          čuvaju u unapred određenom roku važenja. Upotreba kolačića omogućava
          da se ispituju neki podaci posetioca veb stranice i da se prati
          njihovo korisćenje na internetu. Kolačići pomažu da se prate
          interesovanja korisnika, obrasci upotrebe interneta i istorija poseta
          veb stranici kako bi se osiguralo da je iskustvo kupovine korisnika
          optimalno. Budući da se kolačići koriste kao vrsta oznake koja
          omogućava veb stranici da prepozna posetioca koji se vraća na
          stranicu, pomoću njih se takođe mogu sačuvati važeće korisnićko ime i
          lozinka za tu veb lokaciju. Ako pregledač po&scaron;alje prethodno
          sačuvani kolacic, provajder servisa koji obrađuje kolačić &nbsp;ima
          mogućnost povezivanja trenutne posete korisnika sa prethodnom, ali
          samo u vezi sa njihovim sopstvenim sadržajem.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          Informacije koje kolačići &scaron;alju olak&scaron;avaju prepoznavanje
          veb pretraživača, pa korisnici mogu primiti relevantan i
          &bdquo;personalizovan&ldquo; sadržaj. Kolačići čine pregledavanje
          praktičnijim, uključujući potrebe za bezbedno&scaron;ću podataka na
          mreži i relevantno ogla&scaron;avanje. Uz pomoć kolačića, provajder
          servisa takođe može kreirati anonimne statistike o navikama gledalaca
          stranica, tako da može bolje prilagoditi izgled i sadržaj
          stranice.&nbsp;
        </p>
        <p>Veb lokacija dobavljača usluge koristi dve vrste kolačića:&nbsp;</p>
        <p>
          - Privremeni kolačići (<em>obavezni</em>)&ndash; kolačići za upotrebu
          sesije (session-id) neophodni za upotrebu veb stranice. Njihova
          upotreba je od su&scaron;tinskog značaja za navigaciju i za
          funkcionisanje veb stranice. Bez njih, sajt ili njegovi delovi neće
          biti prikazani, pregledavanje postaje ometano, stavljanje ulaznica u
          korpu ili bankovno plaćanje ne mogu se pravilno sprovesti.&nbsp;
        </p>
        <p>
          - Trajni kolačići (<em>analitički i marketing/ogla&scaron;avanje</em>
          )&nbsp; - koji će ostati na uređaju, ovisno o pode&scaron;avanjima veb
          pretraživača, dugo ili dok ih korisnik ne obri&scaron;e. Unutar njih
          postoje interni i eksterni kolačići. Interni kolačići se stvaraju ako
          server provajdera servisa instalira kolačić i podaci se proslede u
          njegovu sopstvenu bazu podataka. Ako je kolačić instalirao server
          provajdera servisa, ali se podaci prosleđuju spoljnom provajderu,
          koristi se spoljni kolačić. Kolačići treće strane koje je treća strana
          postavila u pregledač korisnika (Google Analitics, Facebook Pixel) su
          spoljni kolačići. Oni se stavljaju u pretraživač ako posećena veb
          lokacija koristi usluge koje pruža treća strana. Svrha stalnih
          kolačića je da se osigura da veb lokacija funkcioni&scaron;e na
          najvi&scaron;em nivou u cilju povećanja korisničkog iskustva.&nbsp;
        </p>
        <p>&nbsp;</p>
        <p>
          Prilikom posete veb stranici Korisnik može dati svoju saglasnost za
          čuvanje trajnih kolačića sačuvanih na svom računaru kojima može
          pristupiti provajder servisa klikom na dugme upozorenja o kolačićima
          na stranici za prijavu.&nbsp;
        </p>
        <p>
          Korisnik može konfigurisati i sprečiti aktivnosti vezane za kolačiće
          pomoću programa pretraživača, međutim, imajte na umu da je bez
          upotrebe kolačića moguće da korisnik neće moći da koristi svaku uslugu
          koju nudi veb lokacija, a naročito opcije plaćanja.&nbsp;
        </p>
        <p>
          Svrha obrade podataka: obavljanje platnih transakcija s pružateljem
          platnih usluga, identifikacija i razlikovanje korisnika,
          identifikacija korisnikove trenutne sesije, čuvanje podataka stvorenih
          tokom sesije, sprečavanje gubitka podataka, identifikacija i praćenje
          korisnika, veb analitika.&nbsp;
        </p>
        <p>
          Obim obrađenih podataka: identifikacioni broj, datum, vreme i
          prethodno posećena veb stranica.&nbsp;
        </p>
        <p>
          Period obrade podataka: privremeni kolačići se čuvaju dok se ne
          zatvore sve veb lokacije iste vrste.&nbsp;
        </p>
        <p>
          Trajni kolačići se čuvaju na računaru korisnika godinu dana ili dok ih
          korisnik ne izbri&scaron;e.&nbsp;
        </p>
        <p>
          Moguće posledice neuspeha u pružanju podataka: nedostupnost određenih
          servisa veb stranice, neuspe&scaron;ne platne transakcije, netačnosti
          u analitici.
        </p>
        <p>
          &nbsp;
          <br /> <strong>E-Mail&nbsp;</strong>
        </p>
        <p>&nbsp;</p>
        <p>
          Bilet Solutions DOO koristi va&scaron;u e-mail adresu samo za postupak
          poručivanja ulaznica, kao i dostavljanja važnih obave&scaron;tenja o
          događajima za koji ste kupili ulaznicu (kao &scaron;to su promena
          termina, otkazivanje događaja ili sl).&nbsp;
        </p>
        <p>
          Bilet Solutions DOO će vam obave&scaron;tenja o važnim
          pobolj&scaron;anjima na&scaron;e web stranice / webshopa / postupka
          kupovine dostavljati isključivo uz prethodno pribavljenu va&scaron;u
          saglasnost.
        </p>
        <p>
          Bilet Solutions DOO će oglasne poruke dostavljati isključivo uz
          prethodno pribavljenu va&scaron;u saglasnost .<br /> <br />{' '}
          <strong>Ugovor&nbsp;</strong>
          <br /> <br /> Na&scaron; online servis podrazumeva sporazumno
          kori&scaron;ćenje i čuvanje Va&scaron;ih podataka. Sve izmene u
          na&scaron;em poslovanju koje se odnose na bezbednost i poverljivost
          podataka biće objavljene na na&scaron;oj web stranici.
        </p>
        <p>
          <strong>
            <br /> <strong>Za&scaron;tita ličnih podataka</strong>
          </strong>
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Registracija, prikupljanje i upotreba informacija</em>
        </p>
        <p>
          Tokom procesa kupovine / registracije skupljamo podatke kao &scaron;to
          su&nbsp;
          <em>
            <strong>
              ime, prezime, adresa isporuke, broj telefona i Va&scaron;a e-mail
              adresa.&nbsp;
            </strong>
          </em>
          U slučaju kada se korisnik registruje putem Facebook ili Google
          naloga, ti podaci se koriste u skladu sa Facebook ili Google politikom
          privatnosti, sa kojom je korisnk saglasan. Va&scaron;e podatke
          koristićemo samo u svrhu izvr&scaron;enja na&scaron;e usluge i
          plaćanja. Ime, prezime i email adresa se ispisuju na e-ulaznici kako
          bi se sprečila moguća zloupotreba.
        </p>
        <p>
          Da biste primali na&scaron; newsletter, potrebna nam je Va&scaron;a
          e-mail adresa. Da biste dobili vesti, potrebno je da se prijavite na
          na&scaron; newsletter i potvrdite da ste saglasni da vam isti bude
          dostavljen putem mail-a. Po&scaron;tujući Va&scaron;u privatnost,
          omogućili smo vam da se u svakom trenutku sami odjavite sa ove liste.
          Pogledajte odeljak "Odjavljivanje / isključivanje" ovog dokumenta.
          Bilet Solutions DOOje ekskluzivni vlasnik podataka prikupljenih na web
          sajtu bilet.rs
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Svrha nameravane obrade podataka</em>
        </p>
        <p>
          Svrha obrade podataka jeste izvr&scaron;avanje ugovornih obaveza u
          vezi sa kupovinom ulaznica za događaj.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Pravni osnov obrade podataka</em>
        </p>
        <p>
          Pravni osnov obrade jeste: obrada je neophodna za izvr&scaron;enje
          ugovora zaključenog sa licem na koje se podaci odnose ili za
          preduzimanje radnji, na zahtev lica na koje se podaci odnose, pre
          zaključenja ugovora.
        </p>
        <p>
          Davanje podataka o ličnosti kao ugovorna obaveza i neophodan uslov za
          zaključenje ugovora.
        </p>
        <p>
          Predmetni podaci predstavljaju neophodan uslov za zaključenje ugovora
          o kupovini ulaznica za događaj.
        </p>
        <p>&nbsp;&nbsp;</p>
        <p>
          <em>Rok čuvanja podataka:</em>
        </p>
        <p>Podaci se čuvaju 10 godina.</p>
        <p>&nbsp;</p>
        <p>
          <em>Narudžbine</em>
        </p>
        <p>
          Morate uneti podatke o kontaktu (kao &scaron;to su Va&scaron;e ime,
          adresa e-po&scaron;te, broj telefona i adresa isporuke) i informacije
          o plaćanju (kao &scaron;to su broj platne kartice, datum roka i ime
          platne kartice ako se odlučite da platite platnom karticom).{' '}
          <em>
            Bilet Solutions DOO nema uvid o podacima vezanim za platnu karticu,
            ve
          </em>
          <em>ć</em>
          <em> se proces unosa tih podataka vr</em>
          <em>&scaron;</em>
          <em>i na platformi provajdera pla</em>
          <em>ć</em>
          <em>anja.</em>
        </p>
        <p>
          Mi ćemo koristiti ove informacije za izdavanje fakture i ispunjenje
          Va&scaron;eg naloga. U zavisnosti od ličnih podataka koje pružate,
          biće vam poslata faktura sa identifikacionim brojem kupovine i svim
          ostalim potrebnim informacijama. Ako dođe do problema pri popunjavanju
          Va&scaron;eg naloga, koristićemo te informacije da Vas kontaktiramo.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Odnosi s kupcima</em>
        </p>
        <p>
          Lične informacije koje unesete će nam pomoći da stupimo u kontakt sa
          Vama, odgovorimo na Va&scaron;a pitanja, pružimo usluge koje ste
          izabrali i upravljate nalogom. Ako je potrebno, kontaktiraćemo Vas
          putem e-maila ili telefona.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Profil</em>
        </p>
        <p>
          Sakupljamo informacije koje primamo putem kolačića i datoteka
          dnevnika, ali mi ne stvaramo nikakav "profil" Va&scaron;ih
          preferencija. Povezaćemo Va&scaron;e lične podatke sa Va&scaron;im
          kupovinama, ali ih nećemo deliti sa trećim licima.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Ankete i takmičenja</em>
        </p>
        <p>
          Povremeno ćete imati priliku da učestvujete na takmičenjima ili
          anketama koje će biti objavljene na na&scaron;oj web stranici. Ako
          odlučite da učestvujete, moraćete da unesete određene lične
          informacije. Učestvovanje u ovakvim događajima je potpuno dobrovoljno
          i sami odlučujete da li želite da unesete ove informacije. U većini
          slučajeva biće potrebni kontakt podaci (kao &scaron;to su ime i adresa
          za isporuku) i demografske informacije (kao &scaron;to je
          po&scaron;tanski broj).
        </p>
        <p>
          Koristićemo ove informacije da informi&scaron;emo pobednike i dodelimo
          nagrade, kontroli&scaron;emo posećenost sajta i ponudimo učesnicima
          unos na na&scaron;oj e-mail listi. Nećemo otkriti lične informacije
          koje ćete uneti tokom takmičenja ili istraživanja trećim licima.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Kori&scaron;ćenje i objavljivanje podataka</em>
        </p>
        <p>
          U slučaju da bilo koja treća strana koja nije na&scaron; agent ili
          pružalac usluga, traži informacije, bićete obave&scaron;teni, a Vi
          ćete imati mogućnost da odlučite da li želite da date svoje lične
          podatke ovom klijentu.
        </p>
        <p>
          Banka&nbsp;<strong>prihvatilac plaćanja</strong>&nbsp;ne učestvuje u
          prikupljanju ovih ličnih podataka (npr. JMBG),&nbsp;
          <strong>niti se bavi obradom ovih podataka</strong>, te se svi podaci
          isključivo distribuiraju Organizatoru manifestacije.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Odricanje</em>
        </p>
        <p>
          Zadržavamo pravo otkrivanja Va&scaron;ih ličnih podataka ako to
          zahteva zakon i ako verujemo da je obelodanjivanje neophodno za
          za&scaron;titu na&scaron;ih prava i / ili za postupanje u skladu sa
          sudskom istragom, sudskim nalogom ili pravnim postupkom.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Odjavljivanje / isključivanje</em>
        </p>
        <p>
          Na zahtev za unos Va&scaron;ih ličnih podataka, takođe imate mogućnost
          da "odbijete" upotrebu ovih informacija u određene svrhe.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Datoteke dnevnika</em>
        </p>
        <p>
          U skladu sa pravilima većine web lokacija, automatski prikupljamo
          određene informacije i čuvamo ih u log fajlovima. Ove informacije
          uključuju Internet protokol (IP) adrese, tip pretraživača, Internet
          Service Provider (ISP) stranica sa kojih ste pristupili sajtu i
          stranicu na koju se ide iz na&scaron;eg sistema, datum i vreme
          pristupa, i tok podataka po kliku.
        </p>
        <p>
          Ova informacija, koja ne identifikuje pojedinačne korisnike, se
          koristi za analizu trendova, administraciju sajta, praćenje
          korisničkog kretanja i prikupljanje demografskih podataka o
          na&scaron;im korisnicima uop&scaron;te.
        </p>
        <p>Ne povezujemo ove podatke sa ličnim podacima.</p>
        <p>
          <em>
            <br /> <em>Linkovi na druge stranice</em>
          </em>
        </p>
        <p>
          Ova stranica sadrži linkove na web lokacije koje nisu u
          vlasni&scaron;tvu ili kontrolisane od strane Bilet Solutions DOO.
          Nismo odgovorni za politiku privatnosti ovih stranica. Preporučujemo
          da pročitate politiku privatnosti za svaku lokaciju koja prikuplja
          lične podatke i da budete pažljivi kada napustite na&scaron;u web
          lokaciju. Na&scaron;a politika privatnosti se odnosi samo na
          informacije koje sakupljamo na ovoj web lokaciji.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Pristup ličnim informacijama</em>
        </p>
        <p>
          Ako se Va&scaron;e lične informacije menjaju, možete ih ispraviti ili
          ažurirati uno&scaron;enjem promena u polju "Korisnički nalog" ili
          putem e-maila prodaja@bilet.rs ili poslati pismo na Bilet Solutions
          DOO, Romanijska 7, Ni&scaron;, 18000.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Sigurnost</em>
        </p>
        <p>
          Sigurnost Va&scaron;ih ličnih podataka je važna za nas. Svi
          Va&scaron;i lični podaci će biti transferisani SSL (Secure Socket
          Layer) metodom u 128-bitnoj enkripciji (RSA s 1024bita) i zbog toga ne
          mogu biti iskori&scaron;ćene od strane trećih osoba. Ovo je trenutno
          najnoviji standard sigurnosti na internetu. Bilet Solutions DOO je
          sertifikovan od strane AlphaSSL (https://www.alphassl.com/).
          Ovla&scaron;ćeni overilac sertifikata garantuje siguran prenos
          Va&scaron;ih podataka i autentičnost na&scaron;eg servera.&nbsp;
        </p>
        <p>
          Za za&scaron;titu Va&scaron;ih ličnih podataka koristimo industrijski
          prihvaćene standarde, kako tokom samog prenosa tako i kada dođu do
          nas. Međutim, nikakav način slanja podataka preko Interneta ili
          elektronskog skladi&scaron;ta nije 100% siguran. Stoga, uprkos
          upotrebi op&scaron;te prihvaćenih standarda i metoda za&scaron;tite
          Va&scaron;ih ličnih podataka, ne možemo garantovati njihovu apsolutnu
          sigurnost. Da biste saznali vi&scaron;e o bezbednosti na na&scaron;em
          sajtu možete nam poslati e-mail na prodaja@bilet.rs ili po&scaron;tom
          na Bilet Solutions DOO, Romanijska 7, Niš, 18000.
        </p>
        <p>
          <em>
            <br /> <em>Promena u poslovanju</em>
          </em>
        </p>
        <p>
          Ako Bilet Solutions DOO prolazi kroz poslovne promene, kao &scaron;to
          su spajanja, sticanja druge kompanije ili prodaje veći deo svoje
          imovine Va&scaron;i lični podaci će verovatno biti deo prodate
          imovine. Bićete obave&scaron;teni o bilo kakvoj većoj promeni
          vlasni&scaron;tva ili kontroli Va&scaron;ih ličnih podataka putem
          e-po&scaron;te ili putem hitnog obave&scaron;tenja na na&scaron;oj web
          lokaciji 30 dana pre promene.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Izmene Izjave o privatnosti</em>
        </p>
        <p>
          Ako odlučimo da promenimo na&scaron;u politiku privatnosti, objavićemo
          ove izmene na na&scaron;oj web lokaciji i na drugim sajtovima za koje
          smatramo da su prikladni. Ovo će vam pomoći da znate kako se
          sakupljaju i koriste podaci, kao i okolnosti pod kojima se mogu
          otkriti.
        </p>
        <p>
          Zadržavamo pravo izmene ove Izjave o privatnosti, tako da je redovno
          proveravate. Ovde ćete biti obave&scaron;teni o promenama na&scaron;e
          politike privatnosti, ili putem e-po&scaron;te ili putem
          obave&scaron;tenja na na&scaron;oj web stranici.&nbsp;
        </p>
        <p>
          <em>
            <br /> <em>Prava lica čiji se podaci obrađuju</em>
          </em>
        </p>
        <p>
          Lice ima pravo pristupa, pravo na ispravku, dopunu, brisanje,
          ograničenje i prenosivost podataka, kao i pravo na prigovor i
          automatizovano dono&scaron;enje pojedinačnih odluka.
        </p>
        <p>
          Lice ima pravo na opoziv pristanka u bilo koje vreme, pri čemu opoziv
          pristanka ne utiče na dopu&scaron;tenost obrade pre opoziva.
        </p>
        <p>
          U slučaju opoziva pristanka, Rukovalac neće obrađivati podatke Lica na
          koje se opoziv odnosi, osim ukoliko Rukovalac ima zakonsko
          ovla&scaron;ćenje za obradu.
        </p>
        <p>
          Navedene zahteve Lica dostavljaju e-mailom na e- mail adresu
          prodaja@bilet.rs
        </p>
        <p>
          Rukovalac će na svaki zahtev odgovoriti u najkraćem mogućem roku, a u
          svakom slučaju najkasnije u roku od 30 dana od prijema zahteva u
          skladu sa članom 21 Zakona i članom 12 GDPR.
        </p>
        <p>
          Lice može podneti pritužbu u vezi sa obradom svojih podataka o
          ličnosti pred Poverenikom za informacije od javnog značaja i
          za&scaron;titu podataka o ličnosti.
        </p>
        <p>&nbsp;</p>
        <p>
          <em>Kontaktirajte nas</em>
        </p>
        <p>
          Ako imate pitanja ili sugestije u vezi sa na&scaron;om politikom
          privatnosti, možete nam poslati e-mail na prodaja@bilet.rs ili
          po&scaron;tom na Bilet Solutions DOO, Romanijska 7, Niš, 18000.
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
        <p>
          <em>Verzija: 1.0</em>
        </p>
        <p>
          <em>&nbsp;</em>
        </p>
      </TextContainer>
    </Container>
  );
};

export default PolitikaPrivatnosti;
