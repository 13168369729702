import { fabric } from 'fabric';
import { getSeatingData, dispatchSeatingData } from '../seatingMapReducer';
import {
  DEFAULT_SEAT_COLOR,
  DEFAULT_TABLE_COLOR,
  FONT_FAMILY,
  DEFAULT_TABLE_DIMENSIONS,
} from '../constants';

class TableObject {
  constructor(options, canvas, uuid, values) {
    console.log(DEFAULT_TABLE_DIMENSIONS);
    this.noOfSeats = options.noOfSeats;
    this.seatRadius = options.seatRadius || DEFAULT_TABLE_DIMENSIONS.seatRadius;
    this.seatSpacing =
      options.seatSpacing || DEFAULT_TABLE_DIMENSIONS.seatSpacing;
    this.tableRadius =
      options.tableRadius || DEFAULT_TABLE_DIMENSIONS.tableRadius;
    this.minNoOfTickets = options.minNoOfTickets;
    this.seatObjects = [];
    this.grpArr = [];
    this.tableGroup = null;
    this.canvasRef = canvas;
    this.id = uuid; // Use passed uuid
    this.objectSubtype = 'table';
    this.tableName = options.tableName;
    this.groupAdded = true;
    this.seatColor = DEFAULT_SEAT_COLOR;
    this.tableColor = values.tableColor;
    this.angle = values.angle;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.isBooking = false;
    this.currTop = 0;
    this.currLeft = 0;
  }

  createTable() {
    let { noOfSeats, seatRadius, tableRadius, seatSpacing, tableName } = this;

    let n = Number(noOfSeats) / 10;
    tableRadius = Math.max(15, tableRadius + (n - 1) * 50);
    this.tableRadius = tableRadius;
    const tableCircle = new fabric.Circle({
      radius: tableRadius,
      fill: DEFAULT_TABLE_COLOR,
    });

    const tableLabel = new fabric.Text(tableName, {
      fontSize: tableRadius / 2,
      fontFamily: FONT_FAMILY,
      fill: 'black',
      left: tableRadius,
      top: tableRadius,
      originX: 'center',
      originY: 'center',
    });

    const tableGroupItem = new fabric.Group([tableCircle, tableLabel], {
      id: this.id,
      objectSubtype: 'table',
      selectable: false,
    });

    this.grpArr.push(tableGroupItem);

    const angleStep = (2 * Math.PI) / noOfSeats;

    for (let i = 0; i < noOfSeats; i++) {
      const angle = i * angleStep;
      const seatNumber = i + 1;

      const seatLeft =
        tableRadius + (tableRadius + seatSpacing) * Math.cos(angle);
      const seatTop =
        tableRadius + (tableRadius + seatSpacing) * Math.sin(angle);

      const seatCircle = new fabric.Circle({
        radius: seatRadius,
        fill: DEFAULT_SEAT_COLOR,
        left: seatLeft - seatRadius,
        top: seatTop - seatRadius,
      });

      const seatLabel = new fabric.Text(`${i + 1}`, {
        fontSize: seatRadius,
        fontFamily: FONT_FAMILY,
        fill: 'black',
        left: seatLeft,
        top: seatTop,
        originX: 'center',
        originY: 'center',
      });

      const seatGroupItem = new fabric.Group([seatCircle, seatLabel], {
        selectable: false,
        ticketType: null,
        seatNumber: seatNumber,
        booked: false,
        blockName: this.tableName,
        seatName: `${i + 1}`,
      });

      seatGroupItem.on('mousedown', () => {
        this.toggleSeatSelection(seatGroupItem);
        this.modifySeatNums(seatNumber);
      });

      this.seatObjects.push(seatGroupItem);
      this.grpArr.push(seatGroupItem);
    }
    this.groupTable();
    // this.save();
  }

  groupTable() {
    const grpTop = this.tableGroup
      ? this.tableGroup.top
      : this.canvasRef.height / 2;
    const grpLeft = this.tableGroup
      ? this.tableGroup.left
      : this.canvasRef.width / 2;
    this.canvasRef.remove(this.tableGroup);
    this.tableGroup = new fabric.Group(this.grpArr, {
      top: grpTop,
      left: grpLeft,
      originX: 'center',
      originY: 'center',
      id: this.id, // Use passed uuid
      objectSubtype: this.objectSubtype,
      angle: this.angle,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      ticketType: '',
    });
    this.groupAdded = true;
    this.canvasRef.add(this.tableGroup);
    this.save();
    this.canvasRef.renderAll();
  }

  ungroupTable() {
    this.canvasRef.remove(this.tableGroup);
    this.grpArr.map((seatGroupItem) => {
      this.canvasRef.add(seatGroupItem);
    });

    this.groupAdded = false;
    this.canvasRef.renderAll();
  }

  clearUngroupSeats() {
    this.canvasRef.remove(this.tableGroup);
    this.grpArr.map((seatGroupItem) => {
      this.canvasRef.remove(seatGroupItem);
    });

    this.groupAdded = false;
    this.canvasRef.renderAll();
  }

  editName(value) {
    this.grpArr[0]._objects[1].set({
      text: value,
    });
    this.tableName = value;
    this.save();
    this.canvasRef.renderAll();
  }

  adjustSize(value) {
    this.tableRadius = value.tableRadius;
    this.seatRadius = value.seatRadius;
    this.seatSpacing = value.seatSpacing;

    this.angle = this.tableGroup.angle;
    this.scaleX = this.tableGroup.scaleX;
    this.scaleY = this.tableGroup.scaleY;

    let { noOfSeats, seatRadius, tableRadius, seatSpacing } = this;

    this.clearUngroupSeats();

    let newGrpArr = [];
    let newSeatObjects = [];

    const tableCircle = new fabric.Circle({
      radius: tableRadius,
      fill: this.tableColor,
    });

    const tableLabel = new fabric.Text(this.tableName, {
      fontSize: tableRadius / 2,
      fontFamily: FONT_FAMILY,
      fill: 'black',
      left: tableRadius,
      top: tableRadius,
      originX: 'center',
      originY: 'center',
    });

    const tableGroupItem = new fabric.Group([tableCircle, tableLabel], {
      selectable: false,
    });

    newGrpArr.push(tableGroupItem);

    const angleStep = (2 * Math.PI) / noOfSeats;

    for (let i = 0; i < noOfSeats; i++) {
      const angle = i * angleStep;
      const seatNumber = i + 1;

      const seatLeft =
        tableRadius + (tableRadius + seatSpacing) * Math.cos(angle);
      const seatTop =
        tableRadius + (tableRadius + seatSpacing) * Math.sin(angle);

      const seatCircle = new fabric.Circle({
        radius: seatRadius,
        fill: this.grpArr[i + 1]._objects[0].fill,
        left: seatLeft - seatRadius,
        top: seatTop - seatRadius,
      });

      const seatLabel = new fabric.Text(
        `${this.grpArr[i + 1]._objects[1].text}`,
        {
          fontSize: seatRadius,
          fontFamily: FONT_FAMILY,
          fill: 'black',
          left: seatLeft,
          top: seatTop,
          originX: 'center',
          originY: 'center',
        }
      );

      const seatGroupItem = new fabric.Group([seatCircle, seatLabel], {
        selectable: false,
        seatNumber: i + 1,
        ticketType: this.seatObjects[i].ticketType,
      });

      seatGroupItem.on('mousedown', () => {
        this.toggleSeatSelection(seatGroupItem);
        this.modifySeatNums(seatNumber);
      });

      newSeatObjects.push(seatGroupItem);
      newGrpArr.push(seatGroupItem);
    }

    this.grpArr = newGrpArr;
    this.seatObjects = newSeatObjects;
    this.seatEditing();
    this.groupTable();
    this.save();
  }

  seatEditing() {
    if (this.groupAdded) {
      this.canvasRef.remove(this.tableGroup);
      this.ungroupTable();
    } else {
      this.canvasRef.add(this.tableGroup);
      this.groupTable();
    }
    this.save();
    this.canvasRef.renderAll();
  }

  toggleSeatSelection(seatGroupItem) {
    if (this.isBooking) {
      this.toggleBookTableSeats(seatGroupItem);
      return;
    }
    const seatCircle = seatGroupItem.item(0);
    const isSelected = !seatCircle.get('selected');

    seatCircle.set({
      selected: isSelected,
      fill: isSelected ? '#ffffff' : DEFAULT_SEAT_COLOR,
    });

    this.save();
  }

  toggleBookTableSeats(seatGroupItem) {
    const seatCircle = seatGroupItem.item(0);
    const isSelected = !seatCircle.get('selected');

    for (let i = 1; i <= this.noOfSeats; i++) {
      if (!this.tableGroup._objects[i].booked) {
        this.tableGroup._objects[i].item(0).set({
          fill: this.seatColor,
        });
      }
    }

    if (!seatGroupItem.booked) {
      seatCircle.set({
        fill: 'lightGreen',
      });
    } else {
      seatCircle.set({
        fill: this.seatColor,
      });
    }
    this.save();
  }

  bookAll() {
    for (let i = 1; i <= this.noOfSeats; i++) {
      if (this.tableGroup._objects[i].booked) {
        this.tableGroup._objects[i].item(0).set({
          fill: '#ccc',
        });
      }
    }
    this.canvasRef.renderAll();
  }

  modifySeatNums(seatNumber) {
    if (this.isBooking) return;
    const seatLabel = this.grpArr[seatNumber].item(1);
    if (seatLabel.text === '') {
      this.addSeats(seatNumber);
    } else {
      this.removeSeats(seatNumber);
    }
  }

  removeSeats(seatNumber) {
    for (let i = seatNumber + 1; i < this.grpArr.length; i++) {
      let prevText = this.grpArr[i]._objects[1].text;
      if (prevText === '') continue;
      prevText = Number(prevText);
      prevText--;
      this.grpArr[i]._objects[1].set({
        text: `${prevText}`,
      });
    }
    this.grpArr[seatNumber]._objects[1].set({ text: '' });
    this.save();
  }

  addSeats(seatNumber) {
    for (let i = 1; i < this.grpArr.length; i++) {
      let prevText = this.grpArr[i]._objects[1].text;
      if (i === seatNumber) {
        this.grpArr[i]._objects[1].set({
          text: `${i}`,
        });
        continue;
      }
      if (prevText === '') continue;
      this.grpArr[i]._objects[1].set({
        text: `${i}`,
      });
    }
    this.save();
  }

  delete() {
    this.canvasRef.remove(this.tableGroup);
    this.canvasRef.renderAll();
    this.grpArr = [];

    const seatingData = getSeatingData();
    seatingData.objects.delete(this.id);

    seatingData.canvasJSON = this.canvasRef.toJSON();
    dispatchSeatingData(seatingData);
  }

  save() {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    let seatGroups = [];
    for (let i = 1; i <= this.noOfSeats; i++) {
      const y = {
        seatCircle: {
          fill: this.grpArr[i]._objects[0].fill,
        },
        seatLabel: {
          text: this.grpArr[i]._objects[1].text,
        },
        ticketType: this.seatObjects[i - 1].ticketType,
      };
      seatGroups.push(y);
    }

    const dataOptions = {
      id: this.id,
      objectSubtype: this.objectSubtype,
      noOfSeats: this.noOfSeats,
      seatRadius: this.seatRadius,
      seatSpacing: this.seatSpacing,
      tableRadius: this.tableRadius,
      tableName: this.tableName,
      minNoOfTickets: this.minNoOfTickets,
    };

    const dataValues = {
      sg: seatGroups,
      top: this.tableGroup.top,
      left: this.tableGroup.left,
      tableColor: this.tableColor,
      angle: this.tableGroup.angle,
      scaleX: this.tableGroup.scaleX,
      scaleY: this.tableGroup.scaleY,
      ticketType: this.ticketType,
    };
    this.currTop = this.tableGroup.top;
    this.currLeft = this.tableGroup.left;

    seatingData.updatedAt = formattedDate;

    seatingData.objects.set(this.id, {
      dataOptions,
      dataValues,
    });

    seatingData.canvasJSON = this.canvasRef.toJSON();
    dispatchSeatingData(seatingData);
  }

  copy() {
    let sg = [];
    for (let i = 1; i <= this.noOfSeats; i++) {
      const y = {
        seatCircle: {
          fill: this.grpArr[i]._objects[0].fill,
        },
        seatLabel: {
          text: this.grpArr[i]._objects[1].text,
        },
        ticketType: this.seatObjects[i - 1].ticketType,
      };
      sg.push(y);
    }

    const dataOptions = {
      id: this.id,
      objectSubtype: this.objectSubtype,
      noOfSeats: this.noOfSeats,
      seatRadius: this.seatRadius,
      seatSpacing: this.seatSpacing,
      tableRadius: this.tableRadius,
      tableName: this.tableName,
      minNoOfTickets: this.minNoOfTickets,
    };

    const dataValues = {
      sg: sg,
      top: this.currTop,
      left: this.currLeft,
      tableColor: this.tableColor,
      angle: this.tableGroup.angle,
      scaleX: this.tableGroup.scaleX,
      scaleY: this.tableGroup.scaleY,
    };

    const copiedObject = {
      type: 'table object',
      dataOptions: dataOptions,
      dataValues: dataValues,
    };

    return copiedObject;
  }

  loadTable(valuesJSON) {
    let { noOfSeats, seatRadius, tableRadius, seatSpacing, tableName } = this;
    const { angle, left, top, scaleX, scaleY, sg, tableColor } = valuesJSON;

    this.angle = angle;
    this.scaleX = scaleX;
    this.scaleY = scaleY;
    this.tableColor = tableColor;

    this.seatRadius = seatRadius;
    this.seatSpacing = seatSpacing;
    this.tableRadius = tableRadius;
    this.tableName = tableName;

    const tableCircle = new fabric.Circle({
      radius: tableRadius,
      fill: this.tableColor,
    });

    const tableLabel = new fabric.Text(tableName, {
      fontSize: tableRadius / 2,
      fontFamily: FONT_FAMILY,
      fill: 'black',
      left: tableRadius,
      top: tableRadius,
      originX: 'center',
      originY: 'center',
    });

    const tableGroupItem = new fabric.Group([tableCircle, tableLabel], {
      selectable: false,
    });

    this.grpArr.push(tableGroupItem);

    const angleStep = (2 * Math.PI) / noOfSeats;

    for (let i = 0; i < noOfSeats; i++) {
      const angle = i * angleStep;
      const seatNumber = i + 1;

      const seatLeft =
        tableRadius + (tableRadius + seatSpacing) * Math.cos(angle);
      const seatTop =
        tableRadius + (tableRadius + seatSpacing) * Math.sin(angle);

      const seatCircle = new fabric.Circle({
        radius: seatRadius,
        fill: sg[i].seatCircle.fill,
        left: seatLeft - seatRadius,
        top: seatTop - seatRadius,
      });

      const seatLabel = new fabric.Text(`${sg[i].seatLabel.text}`, {
        fontSize: seatRadius,
        fontFamily: FONT_FAMILY,
        fill: 'black',
        left: seatLeft,
        top: seatTop,
        originX: 'center',
        originY: 'center',
      });

      const seatGroupItem = new fabric.Group([seatCircle, seatLabel], {
        selectable: false,
        ticketType: valuesJSON.sg[i].ticketType,
        seatNumber: seatNumber,
        booked: false,
        blockName: this.tableName,
        seatName: `${i + 1}`,
        seatType: 'Table',
        minNoOfTickets: this.minNoOfTickets,
      });

      seatGroupItem.on('mousedown', () => {
        this.toggleSeatSelection(seatGroupItem);
        this.modifySeatNums(seatNumber);
      });

      this.seatObjects.push(seatGroupItem);
      this.grpArr.push(seatGroupItem);
    }

    this.canvasRef.remove(this.tableGroup);
    this.tableGroup = new fabric.Group(this.grpArr, {
      id: this.id,
      objectSubtype: this.objectSubtype,
      top,
      left,
      originX: 'center',
      originY: 'center',
    });
    this.canvasRef.add(this.tableGroup);
    this.groupAdded = true;
    this.tableGroup.set({
      angle: this.angle,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
    });

    this.save();
    this.canvasRef.renderAll();
  }

  updateDimensions(obj) {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    let sg = [];
    for (let i = 1; i <= this.noOfSeats; i++) {
      const y = {
        seatCircle: {
          fill: this.grpArr[i]._objects[0].fill,
        },
        seatLabel: {
          text: this.grpArr[i]._objects[1].text,
        },
        ticketType: this.seatObjects[i - 1].ticketType,
      };
      sg.push(y);
    }

    const dataOptions = {
      id: this.id,
      objectSubtype: this.objectSubtype,
      noOfSeats: this.noOfSeats,
      seatRadius: this.seatRadius,
      seatSpacing: this.seatSpacing,
      tableRadius: this.tableRadius,
      tableName: this.tableName,
      minNoOfTickets: this.minNoOfTickets,
    };

    const dataValues = {
      sg: sg,
      top: obj.top,
      left: obj.left,
      tableColor: this.tableColor,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      angle: obj.angle,
    };

    seatingData.updatedAt = formattedDate;

    seatingData.objects.set(this.id, {
      dataOptions: dataOptions,
      dataValues: dataValues,
    });

    dispatchSeatingData(seatingData);
  }

  tableColorChange(color) {
    this.tableGroup._objects[0]._objects[0].set({
      fill: color,
    });
    this.tableColor = color;
    this.save();
    this.canvasRef.renderAll();
  }

  seatColorChange(color) {
    for (let i = 1; i <= this.noOfSeats; i++) {
      this.tableGroup._objects[i]._objects[0].set({
        fill: color,
      });
    }
    this.seatColor = color;
    this.save();
    this.canvasRef.renderAll();
  }

  setMinNoOfTickets(value) {
    this.minNoOfTickets = value;
  }

  setSelectable(value) {
    this.tableGroup.set({
      selectable: value,
    });
    this.canvasRef.renderAll();
  }

  assignAll(value) {
    this.tableGroup.ticketType = value;
    this.ticketType = value;
    for (let i = 1; i <= this.noOfSeats; i++) {
      this.tableGroup._objects[i].set({
        ticketType: value,
      });
    }
    this.save();
    this.canvasRef.renderAll();
  }

  bringToFront() {
    this.canvasRef.bringToFront(this.tableGroup);
    const seatingData = getSeatingData();
    const element = seatingData.objects.get(this.id);
    seatingData.objects.delete(this.id);
    seatingData.objects.set(this.id, element);

    seatingData.canvasJSON = this.canvasRef.toJSON();
    dispatchSeatingData(seatingData);
  }
}

export default TableObject;
