import React, { useState, JSX } from 'react';
import { ColorResult, TwitterPicker } from 'react-color';
import { useSelector } from 'react-redux';
import Input from 'src/components/input/input';
import InputNew from 'src/components/inputNew/InputNew';
import { useLang } from 'src/components/useLang';
import { Button, Flex } from 'src/styling/globalStyling';
import { ARC_CONSTANTS, DEFAULT_TABLE_COLOR } from '../../constants';
import { dispatchIsSeatEditing } from '../../seatingMapReducer';

import styles from './SeatingEditSection.module.scss';

type ArcSettings = {
  isEnabled: boolean;
  degree: number;
  radius: number;
  seatSize: number;
  yPadding: number;
  reversed: boolean;
};

interface Seating {
  blockName: string;
  rowNames: string[];
  rowLength: number;
  rows: number;
  seatColor: string;
  arcRadius: number;
  arcAngle: number;
  colorChange: (color: string) => void;
  seatEditing: () => void;
  adjustSize: (radius: number, spacing: number) => void;
  addRowNames: (index: number, name: string) => void;
  editBlockHead: (name: string) => void;
  createArc: () => void;
  delete: () => void;
  updateArcSettings: (settings: ArcSettings) => void;
  arcSettings?: ArcSettings;
}

interface EditSectionProps {
  seating: Seating;
  cancel: () => void;
}

const EditSection: React.FC<EditSectionProps> = ({ seating, cancel }) => {
  const lang = useLang();
  const [sectionName, setSectionName] = useState<string>(seating.blockName);
  const [seatNames, setSeatNames] = useState<string[]>(
    seating.rowNames.map((row: any) => row?.text)
  );

  const [color, setColor] = useState<string>(
    seating.seatColor || DEFAULT_TABLE_COLOR
  );
  const [colorPopup, setColorPopup] = useState<boolean>(false);
  const [arcSettings, setArcSettings] = useState({
    isEnabled: seating.arcSettings?.isEnabled || ARC_CONSTANTS.isEnabled,
    degree: seating.arcSettings?.degree || ARC_CONSTANTS.degree,
    radius: seating.arcSettings?.radius || ARC_CONSTANTS.radius,
    seatSize: seating.arcSettings?.seatSize || ARC_CONSTANTS.seatSize,
    yPadding: seating.arcSettings?.yPadding || ARC_CONSTANTS.yPadding,
    reversed: seating.arcSettings?.reversed || ARC_CONSTANTS.reversed,
  });

  const isSeatEditing = useSelector(
    (state: any) => state.seatingMapReducer.isSeatEditing
  );

  // useEffect(() => {
  //   const handleKeyDown = (e: KeyboardEvent) => {
  //     if (
  //       e.key === "Backspace" &&
  //       (e.target as HTMLElement).tagName.toLowerCase() !== "input"
  //     ) {
  //       handleDeleteSection();
  //     }
  //   };

  //   document.addEventListener("keydown", handleKeyDown);

  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, [seating]);

  const handleColorChange = (newColor: ColorResult) => {
    setColor(newColor.hex);
    seating.colorChange(newColor.hex);
  };

  const handleSeatEditing = () => {
    seating.seatEditing();
    dispatchIsSeatEditing(!isSeatEditing);
    // setSeatEditingEnabled((prevState) => !prevState);
  };

  const handleCancelBtn = () => {
    cancel();
  };

  const handleSeatNameChange = (i: number, value: string) => {
    const newSeatNames = [...seatNames];
    newSeatNames[i] = value;
    setSeatNames(newSeatNames);
    seating.addRowNames(i, value);
  };

  const handleSectionName = (value: string) => {
    seating.editBlockHead(value);
    setSectionName(value);
  };

  let seatingNamesDivs: JSX.Element[] = [];
  for (let i = 1; i <= seating.rows; i++) {
    seatingNamesDivs.push(
      <InputNew
        key={i}
        label={`${lang.map_editor_ses_row} ${i}`}
        type='text'
        id={`row${i}`}
        placeholder='Name'
        value={seatNames[i - 1] || ''}
        onChange={(e) => handleSeatNameChange(i - 1, e.target.value)}
      />
    );
  }

  const handleDeleteSection = () => {
    seating.delete();
    cancel();
  };

  return (
    <div className={styles.edit_section}>
      <div className={styles.edit_section_header}>
        <div className={styles.edit_section_title}>{lang.map_editor_ses}</div>
        <div
          className={styles.edit_section_cancel_btn}
          onClick={handleCancelBtn}
        >
          {lang.cancel}
        </div>
      </div>
      <div className={styles.edit_section_size}>
        <div className={styles.name}>
          <label htmlFor='sectionName'>{lang.map_editor_add_seats_name}:</label>
          <Input
            wrapperstyle={{ margin: 0 }}
            type='text'
            id='sectionName'
            value={sectionName}
            onChange={(e) => handleSectionName(e.target.value)}
          />
        </div>
        <div className={styles.seat_color_picker}>
          <div className={styles.color_picker}>
            <label>{lang.map_editor_ses_seat_color}:</label>
            <div
              className={styles.color_display}
              onClick={() => setColorPopup(!colorPopup)}
            >
              <div
                className={styles.inner_color_display}
                style={{ backgroundColor: color }}
              ></div>
            </div>
          </div>
          <div
            className={styles.colors}
            style={{ display: colorPopup ? 'flex' : 'none' }}
          >
            <TwitterPicker
              className={styles.c}
              color={color}
              onChange={handleColorChange}
              width='220px'
              triangle='top-right'
            />
          </div>
        </div>
        <div className={styles.arcSettings}>
          <div className={styles.archCheckboxGroup}>
            <label htmlFor='arcEnabled' className={styles.labelMain}>
              {lang.map_editor_ses_arc}:
            </label>
            <input
              type='checkbox'
              id='arcEnabled'
              checked={arcSettings.isEnabled}
              onChange={(e) => {
                const newSettings = {
                  ...arcSettings,
                  isEnabled: e.target.checked,
                };
                setArcSettings(newSettings);
                seating.updateArcSettings(newSettings);
              }}
            />
          </div>

          {arcSettings.isEnabled && (
            <>
              <div className={styles.archCheckboxGroup}>
                <label>{lang.map_editor_ses_reverse_arc}:</label>
                <input
                  type='checkbox'
                  checked={arcSettings.reversed}
                  onChange={(e) => {
                    const newSettings = {
                      ...arcSettings,
                      reversed: e.target.checked,
                    };
                    setArcSettings(newSettings);
                    seating.updateArcSettings(newSettings);
                  }}
                />
              </div>
              <Flex className='column'>
                <label>{lang.map_editor_ses_arc_deg}:</label>
                <input
                  type='range'
                  min={0}
                  max={90}
                  value={arcSettings.degree}
                  onChange={(e) => {
                    const newSettings = {
                      ...arcSettings,
                      degree: Number(e.target.value),
                    };
                    setArcSettings(newSettings);
                    seating.updateArcSettings(newSettings);
                  }}
                />
              </Flex>

              <Flex className='column'>
                <label>{lang.map_editor_ses_arc_radius}:</label>
                <input
                  type='range'
                  min={200}
                  max={1000}
                  value={arcSettings.radius}
                  onChange={(e) => {
                    const newSettings = {
                      ...arcSettings,
                      radius: Number(e.target.value),
                    };
                    setArcSettings(newSettings);
                    seating.updateArcSettings(newSettings);
                  }}
                />
              </Flex>

              <Flex className='column'>
                <label>{lang.map_editor_ses_row_space}:</label>
                <input
                  type='range'
                  min={2}
                  max={20}
                  value={arcSettings.yPadding}
                  onChange={(e) => {
                    const newSettings = {
                      ...arcSettings,
                      yPadding: Number(e.target.value),
                    };
                    setArcSettings(newSettings);
                    seating.updateArcSettings(newSettings);
                  }}
                />
              </Flex>
            </>
          )}
        </div>
        <div
          onClick={handleSeatEditing}
          className={styles.activate_seat_editing_btn}
        >
          <Button className={`slim ${isSeatEditing ? '' : 'neutral'}`}>
            {isSeatEditing
              ? lang.seats_editing_disable
              : lang.seats_editing_enable}
          </Button>
        </div>
      </div>
      <Flex className='column' gap='4px'>
        <label>{lang.map_editor_ses_rows_name}:</label>
        {seatingNamesDivs}
      </Flex>
      <div className={styles.edit_section_footer}>
        <div className={styles.del_sec_btn} onClick={handleDeleteSection}>
          {lang.map_delete_object}
        </div>
      </div>
    </div>
  );
};

export default EditSection;
