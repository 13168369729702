import { useCallback, useContext, useEffect, useState, useRef } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { ShowType } from 'src/models';
import CanvasContext from '../canvasContext';
import { DEFAULT_DISABLED_COLOR } from '../constants';
import AddTextObject from '../Objects/addTextObject';
import {
  BookCustomShapesClass,
  BookPolygonsClass,
  BookSeatsClass,
  BookShapesClass,
  BookTablesClass,
} from '../Objects/booking';
import { convertSeatingDataToMaps } from '../seatingMapReducer';
import ShapeTicketControls from './components/ShapeTicketsControls';
import TableTicket from './components/TableTicket';

import styles from './BookTicketsSetter.module.scss';
import { SeatTicket } from './components/SeatTicket';
import { useLang } from 'src/components/useLang';
import { ZeroStateSelectedTickets } from './components/ZeroStateSelectedTickets';
import { useForm } from 'react-hook-form';
import { CheckoutCheckboxes } from 'src/components/CheckoutCheckboxes/CheckoutCheckboxes';
import { TotalAmount } from 'src/components/TotalAmount';
import Portal from 'src/components/portal/portal';
import Popup from 'src/components/popup/popup';
import { UserForm } from 'src/pages/show/UserForm';
import { useUserData } from 'src/hooks/useUserData';

type SeatGroupItem = {
  id: String;
  seatType: string;
  booked: boolean;
  taken: boolean;
  seatNumber: number;
  opacity: string | number;
  group: { id: string };
  maxNoOfTickets?: number;
  minNoOfTickets?: number;
};

type BookingArr = {
  id: string;
  busy?: boolean;
  minSeats: number;
  maxSeats: number;
  ownerId: number;
  seatsTaken: number;
}[];

type Ticket = {
  objectName?: string;
  seatName?: string;
  ticketTypeInfo: {
    allowedInvalidations: number;
    currency: string | null;
    description: string;
    id: number;
    isStreaming: boolean;
    numberOfTickets: number;
    soldPaperTickets: number | null;
    soldTicketsOnline: number | null;
    ticketPrice: number;
    title: string;
  };
  ticketPrice?: string;
  type?: string;
  color?: string;
  id?: number;
  objId: string;
  uid: number;
  numberOfTickets?: number;
  noOfTableSeats?: string;
  minSeats?: number;
  maxSeats?: number;
};

const BookTicketsSetter = ({ show }: { show: ShowType }) => {
  const history = useHistory();
  const { showId } = useParams<{ showId: string }>();
  const { canvas } = useContext(CanvasContext);
  const lang = useLang();
  const userData = useUserData();

  const [objects, setObjects] = useState<any>({});
  const [objectName, setObjectName] = useState('');
  const [ticketTypeInfo, setTicketTypeInfo] = useState('Not Selected');
  const [ticketPrice, setTicketPrice] = useState(0);
  const [seatName, setSeatName] = useState(0);
  const [selectedTickets, setSelectedTickets] = useState<Ticket[]>([]);
  const [currentSeat, setCurrentSeat] = useState<SeatGroupItem>();
  const [ticketView, setTicketView] = useState('rest');
  const [minSeats, setMinSeats] = useState(1);
  const [maxSeats, setMaxSeats] = useState(10);
  const [noOfTableSeats, setNoOfTableSeats] = useState(1);
  const [seatColor, setSeatColor] = useState('');
  const [tableColor, setTableColor] = useState('');
  const [shapeColor, setShapeColor] = useState('');

  const [seatToggle, setSeatToggle] = useState(0);
  const [bookingArr, setBookingArr] = useState<BookingArr>([]);

  const [showUserFormPopup, setShowUserFormPopup] = useState(false);

  const form = useForm({ mode: 'all' });

  // Add ref for the container
  const ticketsContainerRef = useRef<HTMLDivElement>(null);

  // Keep track of previous length
  const prevTicketsLengthRef = useRef(selectedTickets.length);

  // Modified effect to scroll smoothly
  useEffect(() => {
    const currentLength = selectedTickets.length;
    const prevLength = prevTicketsLengthRef.current;

    if (currentLength > prevLength && ticketsContainerRef.current) {
      ticketsContainerRef.current.scrollTo({
        top: ticketsContainerRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }

    prevTicketsLengthRef.current = currentLength;
  }, [selectedTickets]);

  // useOnUnmount(() => dispatchSeatingData(initialState.seatingData));

  // const { data: show } = useQuery<
  //   AxiosResponse<ShowType>,
  //   AxiosError,
  //   AxiosResponse<ShowType>,
  //   string[]
  // >(["show"], () => getShowDetails(showId), {
  //   enabled: !!showId,
  //   onSuccess: ({ data }) => {
  //     if (data.bookingData) {
  //       setBookingArr(() => JSON.parse(data.bookingData!));
  //     }

  //     if (data.seatingMap) {
  //       const mapObj = JSON.parse(data.seatingMap);
  //       const arraysToMaps = convertSeatingDataToMaps(mapObj);
  //       // dispatchSeatingData(arraysToMaps);
  //       loadMapData(arraysToMaps);
  //     }
  //   },
  // });

  // Render booked data
  useEffect(() => {
    const renderBooking = () => {
      if (bookingArr.length === 0) return;
      bookingArr.forEach((bookingObj) => {
        const splitted = bookingObj.id.split('#');

        if (splitted.length > 1) {
          if (splitted[0] === 'seating') {
            const objectId = splitted[1];
            const i = parseInt(splitted[2]);
            objects[objectId].seatGroups[i].taken = true;
            objects[objectId].seatGroups[i].item(0).fill =
              DEFAULT_DISABLED_COLOR;
            canvas?.renderAll();
          }

          if (splitted[0] === 'table') {
            const object = objects[splitted[1]];
            object.taken = true;
            object.tableGroup.taken = true;
            object.grpArr.forEach((seat) => {
              seat.item(0).set({
                fill: DEFAULT_DISABLED_COLOR,
              });
            });
            canvas?.renderAll();
          }

          // TODO: improve disabling of shapes depending if ticket limit is reached
          // if (splitted[0] === "shape") {
          //   const object = objects[splitted[1]];
          //   object.shapeGroup?._objects[0].set({
          //     fill: DEFAULT_DISABLED_COLOR,
          //   });
          //   object.polygon?.set({
          //     fill: DEFAULT_DISABLED_COLOR,
          //   });
          //   if (object.shapeGroup) {
          //     object.shapeGroup.taken = true;
          //   }
          //   if (object.polygon) {
          //     object.polygon.taken = true;
          //   }
          //   object.taken = true;
          //   canvas?.renderAll();
          // }
        }
      });
    };

    if (bookingArr.length > 0 && Object.keys(objects).length > 0) {
      renderBooking();
    }
  }, [canvas, bookingArr, objects]);

  const getTicketPrice = useCallback(
    (value) => {
      if (!show) {
        return 0;
      }
      const { ticketTypes } = show;
      for (let i = 0; i < ticketTypes.length; i++) {
        if (ticketTypes[i].title === value.title) {
          return ticketTypes[i].ticketPrice;
        }
      }
      return 0;
    },
    [show]
  );

  useEffect(() => {
    let mouseDownTime: number;
    let startX: number;
    let startY: number;
    let moveDetected = false;
    let moveTimeout: NodeJS.Timeout;

    const handleMouseDown = (e) => {
      mouseDownTime = Date.now();
      startX = e.pointer.x;
      startY = e.pointer.y;
      moveDetected = false;

      // After 500ms, we stop checking for movement
      moveTimeout = setTimeout(() => {
        mouseDownTime = 0;
      }, 500);
    };

    const handleMouseMove = (e) => {
      if (!mouseDownTime) return;

      // Check if we've moved more than a small threshold (e.g., 5 pixels)
      const deltaX = Math.abs(e.pointer.x - startX);
      const deltaY = Math.abs(e.pointer.y - startY);

      if (deltaX > 5 || deltaY > 5) {
        moveDetected = true;
      }
    };

    const handleMouseUp = (e) => {
      clearTimeout(moveTimeout);
      if (!mouseDownTime || moveDetected) return; // Ignore if moved

      if (!e.target || !e.target.ticketType || e.target.discarded) return;

      if (e.target.seatType === 'Table') {
        setMinSeats(e.target.minNoOfTickets);
        setMaxSeats(e.target.maxNoOfTickets);
        setNoOfTableSeats(e.target.minNoOfTickets);
        setTableColor(e.target.color);
        setTicketView('table');
      } else if (e.target.seatType === 'Seating') {
        setTicketView('rest');
        setSeatColor(e.target.color);
        setSeatName(e.target.seatName);
      } else if (e.target.seatType === 'shape') {
        setTicketView('shape');
        setShapeColor(e.target.color);
        setTicketPrice(getTicketPrice(e.target.ticketType));
        setTicketTypeInfo(e.target.ticketType);
      }
      setObjectName(e.target.blockName);
      setTicketTypeInfo(e.target.ticketType);
      setTicketPrice(getTicketPrice(e.target.ticketType));
      setCurrentSeat(e.target);
      setSeatToggle((state) => (state === 0 ? -1 : 0));
    };

    if (canvas) {
      canvas.on('mouse:down', handleMouseDown);
      canvas.on('mouse:move', handleMouseMove);
      canvas.on('mouse:up', handleMouseUp);
    }

    return () => {
      if (canvas) {
        canvas.off('mouse:down', handleMouseDown);
        canvas.off('mouse:move', handleMouseMove);
        canvas.off('mouse:up', handleMouseUp);
        clearTimeout(moveTimeout);
      }
    };
  }, [canvas, getTicketPrice, showId]);

  useEffect(() => {
    handleAddTickets();
  }, [seatToggle]);

  const handleAddTickets = () => {
    if (!currentSeat || currentSeat.taken) return;
    let ticketInfo: any = {};
    let currBooked = false;

    // @ts-ignore
    const object = objects[currentSeat.id];
    if (currentSeat.seatType === 'Seating') {
      currBooked = object.seatGroups[currentSeat.seatNumber - 1].booked;
    } else {
      currBooked = object.booked;
    }

    if (!currBooked) {
      if (ticketView === 'rest') {
        ticketInfo = {
          objectName: objectName,
          seatName: seatName,
          ticketTypeInfo: ticketTypeInfo,
          ticketPrice: ticketPrice,
          type: 'seating',
          color: seatColor,
          id: selectedTickets.length,
          uid: currentSeat.seatNumber,
          objId: currentSeat.group?.id,
        };
      } else if (ticketView === 'table') {
        ticketInfo = {
          objectName: objectName,
          noOfTableSeats: noOfTableSeats,
          ticketTypeInfo: ticketTypeInfo,
          ticketPrice: ticketPrice,
          type: 'table',
          color: tableColor,
          id: selectedTickets.length,
          objId: currentSeat.id,
          minSeats: currentSeat.minNoOfTickets,
          maxSeats: currentSeat.maxNoOfTickets,
        };
      } else if (ticketView === 'shape') {
        ticketInfo = {
          objectName: objectName,
          seatName: 'Shape',
          ticketTypeInfo: ticketTypeInfo,
          ticketPrice: ticketPrice,
          numberOfTickets: 1,
          type: 'shape',
          color: shapeColor,
          id: selectedTickets.length,
          objId: currentSeat.id,
        };
      }
    }

    if (currentSeat.seatType === 'Seating') {
      object.toggleBookTickets(object.seatGroups[currentSeat.seatNumber - 1]);
    } else {
      object.toggleBooked();
    }

    if (currBooked) {
      removeTicket();
      return;
    }

    const newArr = [...selectedTickets];
    newArr.push(ticketInfo);
    setSelectedTickets(newArr);
  };

  const removeTicket = () => {
    const newArr: Ticket[] = [];
    for (let i in selectedTickets) {
      if (
        selectedTickets[i].type === 'table' &&
        selectedTickets[i].objId === currentSeat?.id
      ) {
        continue;
      } else if (
        selectedTickets[i].type === 'seating' &&
        selectedTickets[i].uid === currentSeat?.seatNumber &&
        selectedTickets[i].objId === currentSeat?.group.id
      ) {
        continue;
      } else if (
        selectedTickets[i].type === 'shape' &&
        selectedTickets[i].objId === currentSeat?.id
      ) {
        continue;
      }
      // TODO Fix this and maybe use array.filter on this one
      newArr.push(selectedTickets[i]);
    }
    setSelectedTickets(newArr);
  };

  const handleRemoveBtn = (item) => {
    if (item.type === 'table') {
      handleRemoveTable(item);
    } else if (item.type === 'seating') {
      handleRemoveSeat(item);
    } else {
      handleRemoveShape(item);
    }
  };

  const handleRemoveShape = (item) => {
    const newArr: Ticket[] = [];
    for (let i in selectedTickets) {
      if (
        selectedTickets[i].type === 'shape' &&
        selectedTickets[i].id === item.id &&
        selectedTickets[i].objId === item.objId
      ) {
        continue;
      }
      newArr.push(selectedTickets[i]);
    }
    const object = objects[item.objId];
    object.toggleShape();

    setSelectedTickets(newArr);
  };

  const handleRemoveTable = (item) => {
    const newArr: Ticket[] = [];
    for (let i in selectedTickets) {
      if (
        selectedTickets[i].type === 'table' &&
        selectedTickets[i].id === item.id &&
        selectedTickets[i].objId === item.objId
      ) {
        continue;
      }
      newArr.push(selectedTickets[i]);
    }
    setSelectedTickets(newArr);
    objects[item.objId].booked = false;
    objects[item.objId].handleRemoveTable();
  };

  const handleRemoveSeat = (item) => {
    const newArr: Ticket[] = [];
    for (let i in selectedTickets) {
      if (
        selectedTickets[i].type === 'seating' &&
        selectedTickets[i].objId === item.objId &&
        selectedTickets[i].uid === item.uid
      ) {
        continue;
      }
      newArr.push(selectedTickets[i]);
    }
    objects[item.objId].handleRemoveSeat(item.uid);

    setSelectedTickets(newArr);
  };

  let totalPrice = 0;

  selectedTickets.forEach((item) => {
    if (item.type === 'table' && item.noOfTableSeats && item.ticketTypeInfo) {
      totalPrice +=
        item.ticketTypeInfo.ticketPrice * parseInt(item.noOfTableSeats);
    } else if (item.type === 'shape') {
      totalPrice +=
        item.ticketTypeInfo.ticketPrice * (item?.numberOfTickets || 0);
    } else {
      totalPrice += item.ticketTypeInfo.ticketPrice;
    }
  });

  const handleSelectedTicketsInput = (id, value) => {
    selectedTickets[id].noOfTableSeats = value;
    const newArr = [...selectedTickets];
    newArr[id].noOfTableSeats = value;
    setSelectedTickets(newArr);
  };

  const prepareFormData = () => {
    const tickets = selectedTickets.map((ticket) => {
      if (ticket.type === 'seating') {
        const objId = `${ticket.type}#${ticket.objId}#${ticket.uid - 1}`;

        return {
          ...ticket,
          ...ticket.ticketTypeInfo,
          numberOfTickets: 1,
          objId,
        };
      }

      const objId = `${ticket.type}#${ticket.objId}`;
      if (ticket.type === 'table') {
        return {
          ...ticket,
          ...ticket.ticketTypeInfo,
          numberOfTickets: ticket.noOfTableSeats,
          objId,
        };
      }
      if (ticket.type === 'shape') {
        return {
          ...ticket,
          ...ticket.ticketTypeInfo,
          numberOfTickets: ticket.numberOfTickets,
          objId,
        };
      }

      return {
        ...ticket,
        ...ticket.ticketTypeInfo,
        objId,
      };
    });

    return {
      tickets,
      currency: show.currency || 'EUR',
      showId: show.id,
      showTitle: show.showTitle,
    };
  };

  const handleBuyTickets = () => {
    if (!userData.id) {
      return setShowUserFormPopup(true);
    }

    const data = prepareFormData();

    history.push({ pathname: '/wspay', state: { data } });

    setSelectedTickets([]);
  };

  const onGuestBuy = () => {
    const data: any = prepareFormData();
    const formValues = form.getValues();
    if (!formValues.name || !formValues.email) {
      return;
    }
    data.userName = formValues.name;
    data.userEmail = formValues.email;
    history.push({ pathname: '/wspay', state: { data } });
  };

  const loadMapData = useCallback(
    (seatingData) => {
      const newObjects = {};

      seatingData.objects.forEach(({ dataOptions, dataValues }) => {
        let object;
        switch (dataOptions.objectSubtype) {
          case 'seating-group':
            object = new BookSeatsClass(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.loadSeats(dataValues);
            object.isBooking = true;
            object.ungroupSeats();
            break;
          case 'table':
            object = new BookTablesClass(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.loadTable(dataValues);
            object.isBooking = true;
            break;
          case 'Circle':
          case 'Rectangle':
          case 'Triangle':
          case 'Square':
            object = new BookShapesClass(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.create();
            break;
          case 'custom-shape':
            object = new BookCustomShapesClass(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.load(dataValues);
            break;
          case 'polygon':
            object = new BookPolygonsClass(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.load(dataValues);
            break;
          case 'text':
            object = new AddTextObject(
              dataOptions,
              canvas,
              dataOptions.id,
              dataValues
            );
            object.create();
            object.setSelectable(false);
            break;
        }

        if (object) {
          newObjects[object.id] = object;
        }
      });

      setObjects(() => newObjects);

      // renderBooking(
      //   seatingArr,
      //   tableArr,
      //   shapeArr,
      //   customShapeArr,
      //   addTextArr,
      //   polygonArr
      // );
    },
    [canvas]
  );

  useEffect(() => {
    if (canvas) {
      if (show.bookingData) {
        setBookingArr(() => JSON.parse(show.bookingData!));
      }

      if (show.seatingMap) {
        const mapObj = JSON.parse(show.seatingMap);
        const arraysToMaps = convertSeatingDataToMaps(mapObj);
        // dispatchSeatingData(arraysToMaps);
        loadMapData(arraysToMaps);
      }
    }
  }, [loadMapData, show, canvas]);

  return (
    <div className={styles.bookTicketsSetterWrapper}>
      <div ref={ticketsContainerRef} className={styles.bookTicketsSetterBody}>
        {selectedTickets.map((item, index) =>
          item.type === 'table' ? (
            <TableTicket
              key={index}
              item={item}
              minSeats={minSeats}
              maxSeats={maxSeats}
              currency={show.currency}
              handleRemoveBtn={handleRemoveBtn}
              handleSelectedTicketsTableInput={handleSelectedTicketsInput}
            />
          ) : item.type === 'seating' ? (
            <SeatTicket
              key={index}
              show={show}
              item={item}
              handleRemoveBtn={handleRemoveBtn}
            />
          ) : (
            <ShapeTicketControls
              key={index}
              item={item}
              currency={show.currency}
              handleRemoveBtn={handleRemoveBtn}
              handleSelectedTicketsInput={handleSelectedTicketsInput}
            />
          )
        )}
      </div>
      {selectedTickets.length === 0 ? (
        <ZeroStateSelectedTickets />
      ) : (
        <div className={styles.bookTicketsSetterFooter}>
          <TotalAmount total={totalPrice} currency={show.currency} />
          <CheckoutCheckboxes form={form} />
          <button
            className={styles.buyTicketsBtn}
            onClick={handleBuyTickets}
            disabled={!form.formState.isValid}
          >
            {lang.checkout}
          </button>
        </div>
      )}
      {showUserFormPopup && (
        <Portal>
          <Popup
            title={lang.user_form_title}
            content={<UserForm form={form} />}
            submitAction={form.handleSubmit(onGuestBuy)}
            submitText={lang.checkout}
            submitDisabled={!form.formState.isValid}
            cancelAction={() => setShowUserFormPopup(false)}
            cancelText={lang.close}
            trackingTitle={'show_publish'}
            style={{ padding: '16px' }}
          />
        </Portal>
      )}
    </div>
  );
};

export default BookTicketsSetter;
