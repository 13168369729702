import { useEffect } from 'react';
import Tabs from '../../components/tabs/tabs';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import { setShows } from './fangoutsActions';
import { filterShows } from '../../utils';
import ReactGA from 'react-ga4';
import logo from '../../assets/images/logo 16x16.svg';
import PaymentLogotips from 'src/components/paymentLogotips';
import BiletFooterContainer from 'src/components/Footer';
import { ShowTile } from 'src/components/ShowTile';
import { CONTENT_WIDTH } from 'src/styling/globalStyling';

const Fangouts = ({ location }) => {
  const dispatch = useDispatch();
  const shows = useSelector((state) => state.fangouts.shows);
  const lang = useSelector((state) => state.home.language);

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });

    if (shows.length === 0) {
      setShows(dispatch);
    }
  }, [location.pathname, shows.length, dispatch]);

  return (
    <HomeContainer id={'homePage'}>
      <MainHeadingLine>
        <img className='logo' src={logo} alt='bliet.rs' />
        <div>BILET.RS</div>
        {process.env.REACT_APP_ENV === 'development' && <span>Dev</span>}
      </MainHeadingLine>
      <HomePageContent>
        {shows?.filter(filterShows).length > 0 && (
          <ShowsContainer>
            {shows.filter(filterShows).map((s) => (
              <ShowTile key={s.id} show={s} />
            ))}
          </ShowsContainer>
        )}
      </HomePageContent>
      <BiletFooterContainer />
      <PaymentLogotips />
      <Tabs active={0} lang={lang} />
    </HomeContainer>
  );
};

export default Fangouts;

// Create a grid of 3 columns, with 34px gap between each row
const ShowsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  row-gap: 50px;
  column-gap: 24px;
  margin-bottom: 24px;
  padding-bottom: 24px;
  overflow-y: auto;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 450px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

const HomePageContent = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  padding: 32px;
  gap: 32px;
  max-width: ${CONTENT_WIDTH};
  width: 100%;
  @media (max-width: 768px) {
    padding: 16px;
  }
`;

const HomeContainer = styled.div`
  max-width: 100%;
  // flex: 1 1;
  // display: flex;
  // flex-direction: column;
  // height: auto;
  // overflow-y: auto;

  // .fangoutsContainer {
  //   flex-grow: 1;
  //   padding-top: 16px;
  //   padding-bottom: 24px;
  // }

  // .heading {
  //   font-size: 20px;
  //   font-weight: 600;
  //   margin-bottom: 12px;
  // }
`;

const MainHeadingLine = styled.div`
  display: flex;
  padding: 15px 16px 14px;
  justify-content: center;
  align-items: center;
  height: 56px;
  gap: 16px;
  padding-right: 6px;
  position: relative;
  font-weight: 500;
  font-size: 24px;

  img.logo {
    position: absolute;
    left: 8px;
    top: 8px;
    background-color: #000;
    border-radius: 10px;
    width: 40px;
  }

  .icon-logo {
    font-size: 120px;
    margin-top: -42px;
  }
`;
