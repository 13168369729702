import React, { useEffect, useState } from 'react';
import { useLang } from '../../../../components/useLang';
import { RemoveTicketBtn, IconButton } from 'src/styling/globalStyling';
import { TicketWrapper, TicketHeader, TicketInfo } from './styledComponents';

const TableTicket = ({
  item,
  minSeats,
  maxSeats,
  handleRemoveBtn,
  handleSelectedTicketsTableInput,
  currency,
}) => {
  const lang = useLang();
  const [noOfTableSeats, setNoOfTableSeats] = useState(item.noOfTableSeats);
  const [isDecDisabled, setIsDecDisabled] = useState(
    noOfTableSeats === minSeats
  );
  const [isIncDisabled, setIsIncDisabled] = useState(
    noOfTableSeats === maxSeats
  );

  useEffect(() => {
    setIsDecDisabled(noOfTableSeats === minSeats);
    setIsIncDisabled(noOfTableSeats === maxSeats);
  }, [noOfTableSeats, minSeats, maxSeats]);

  const handleDecTableSeats = () => {
    if (noOfTableSeats > minSeats) {
      const val = noOfTableSeats - 1;
      setNoOfTableSeats(val);
      handleSelectedTicketsTableInput(item.id, val);
    }
  };

  const handleIncTableSeats = () => {
    if (noOfTableSeats < maxSeats) {
      const val = noOfTableSeats + 1;
      setNoOfTableSeats(val);
      handleSelectedTicketsTableInput(item.id, val);
    }
  };

  return (
    <TicketWrapper>
      <TicketHeader>
        <div className='section-name'>
          {lang.table}: {item.objectName}
        </div>
        <RemoveTicketBtn onClick={() => handleRemoveBtn(item)}>
          <i className='fa fa-trash'></i>
        </RemoveTicketBtn>
      </TicketHeader>
      <div className='ticket-info-card-body'>
        <div className='table-gen-info'>
          {/* TODO add currency */}
          {lang.price_per_seat}: {item.ticketTypeInfo.ticketPrice} {currency}
        </div>
        <TicketInfo>
          <span>{lang.number_of_tickets_selected}: </span>
          <span>{noOfTableSeats}</span>
          <IconButton onClick={handleDecTableSeats} disabled={isDecDisabled}>
            -
          </IconButton>
          <IconButton onClick={handleIncTableSeats} disabled={isIncDisabled}>
            +
          </IconButton>
        </TicketInfo>
      </div>
    </TicketWrapper>
  );
};

export default TableTicket;
