import styled from 'styled-components';
import biletLogo from '../../assets/images/logo 16x16.svg';

const Spinner = (props) => {
  return props.fullscreen ? (
    <SpinnerFullscreen>
      {/* <PulseLoader color={"#cccccc"} loading={true} size={15} /> */}
      <Logo className='fout-spin' src={biletLogo} alt='spinner' />
      {props.text && <div>{props.text}</div>}
    </SpinnerFullscreen>
  ) : (
    <div
      className={`text-center ${props.className}`}
      style={{ ...props.style, width: '100%' }}
    >
      {/* <i
        style={{ display: "inline-block", fontSize: props.fontSize || 20 }}
        className={(props.icon || "icon-logo_glass") + " fout-spin"}
      /> */}
      <Logo className='fout-spin' src={biletLogo} alt='spinner' />
    </div>
  );
};

export default Spinner;

const SpinnerFullscreen = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(2px);
  flex-direction: column;
  font-size: 18px;
  font-weight: 600;
  gap: 1rem;
  color: white;
`;

const Logo = styled.img`
  width: 40px;
  height: auto;
  border-radius: 8px;
  background: ${({ theme }) => theme.spinnerBackground};
`;
