import styled from 'styled-components';
import moment from 'moment';
import { useHistory } from 'react-router-dom';
import { dateTimeFormat } from 'src/pages/myShows/myShows';

export const ShowTile = (props) => {
  const { show: s, onClick } = props;
  const history = useHistory();

  return (
    <ShowTileWrapper
      onClick={() => (onClick ? onClick() : history.push(`/show/${s.id}`))}
    >
      <ShowTilePoster src={s.eventImage} alt={'show-poster'} />
      <ShowTileTitle>{s.showTitle}</ShowTileTitle>

      <ShowTileDetail>
        <i className={'fa-regular fa-calendar'} />
        {moment(s.showDate).format(dateTimeFormat)}
      </ShowTileDetail>
      <ShowTileDetail>
        <i className={'far fa-map'} />
        {s?.location?.title}
      </ShowTileDetail>
    </ShowTileWrapper>
  );
};

const ShowTileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  padding: 16px;
  &:hover {
    box-shadow: rgba(0, 0, 0, 0.12) 0px 6px 16px;
  }
`;

const ShowTileTitle = styled.div`
  font-size: 20px;
  font-weight: 700;
`;

const ShowTilePoster = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
`;

const ShowTileDetail = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 15px;
  i {
    font-size: 20px;
  }
`;
