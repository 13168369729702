import { ButtonHTMLAttributes } from 'react';
import styled from 'styled-components';

const AddFobButton = (props: ButtonHTMLAttributes<HTMLButtonElement>) => {
  return (
    <AddFobButtonStyled {...props}>
      <i className='icon-add' />
    </AddFobButtonStyled>
  );
};

const AddFobButtonStyled = styled.button`
  position: relative;
  height: 20px;
  width: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: unset;

  i.icon-add {
    display: flex;
    font-size: 18px;
    position: relative;
    top: unset;
    left: unset;
  }
`;

export default AddFobButton;
