import styled from 'styled-components';
import { useLang } from 'src/components/useLang';

const IzjavaOKonverziji = () => {
  const lang = useLang();
  return <Container>{lang.conversion_statement}</Container>;
};

export default IzjavaOKonverziji;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  font-size: 10px;
`;
