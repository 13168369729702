import { useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import { useHistory } from 'react-router-dom';
import Spinner from 'src/components/Spinner/Spinner';
import Heading from 'src/components/heading/heading';
import config from '../../config';
import { Button, Container, Link } from 'src/styling/globalStyling';
import styled from 'styled-components';
import { useLang } from 'src/components/useLang';
import moment from 'moment';
import { serbianFormatter } from 'src/utils';

type Transaction = {
  shopId: null | string | number;
  shoppingCartId: string;
  success: string;
  approvalCode: string;
  amount: number;
  signature: string;
  customerEmail: string;
  errorMessage: string;
  wsPayOrderId: string;
};

type Product = {
  code: 0;
  name: string;
  description: null | string;
  quantity: number;
  amount: number;
};

type ResultResponse = {
  transactionDetails: Transaction | null;
  user?: { name: string; email: string };
  productList?: Product[];
  deliveryDate: string;
  tax: number;
  buyTicketsRequestDTO: {
    showId: number;
  };
};

export const PaymentResults = () => {
  const history = useHistory();
  const lang = useLang();
  const tid = new URLSearchParams(history.location.search).get('tid');

  const { data, isLoading, isError } = useQuery<
    AxiosResponse<any>,
    AxiosError,
    AxiosResponse<ResultResponse>
  >(
    ['payment_results'],
    () => {
      const url = `${config.baseApi}/v1/public/wspay/status/${tid}`;
      return axios.get(url);
    },
    {
      enabled: !!tid,
      refetchInterval: (data) =>
        !data?.data?.transactionDetails ? 5000 : false,
      refetchIntervalInBackground: true,
    }
  );

  if (!tid) {
    history.push('/home');
  }

  if (isLoading || data?.data.transactionDetails === null) {
    return <Spinner fullscreen text={lang.payment_results_loading_message} />;
  }

  return (
    <Container>
      <Heading title={lang.payment_results_title} showLogo />
      {data?.data.transactionDetails?.success === '1' && !isError && (
        <Wrapper>
          <div className='text-center'>
            <h4>
              Transakcija je uspešna. Račun vaše platne kartice je zadužen.
            </h4>
          </div>
          <UserData>
            <b>{lang.user}:</b>
            <div>{data?.data.user?.name}</div>
            <div>{data?.data.user?.email}</div>
          </UserData>
          <UserData style={{ maxWidth: '300px' }}>
            <b>{lang.order_details}:</b>
            <div>
              ID narudžbine: {data.data.transactionDetails.shoppingCartId}
            </div>
            <ProductsTable>
              <thead>
                <td>Naziv</td>
                <td>Kol</td>
                <td>PDV</td>
                <td>Cena sa PDV-om</td>
              </thead>
              <tbody>
                {data.data.productList?.map((ticket) => {
                  return (
                    <tr>
                      <td>
                        {ticket.name === 'Troskovi obrade'
                          ? 'Troškovi obrade'
                          : ticket.name}
                      </td>
                      <td>{ticket.quantity}</td>
                      <td>10%</td>
                      <td>{serbianFormatter.format(ticket.amount)}RSD</td>
                    </tr>
                  );
                })}
              </tbody>
            </ProductsTable>
            <div style={{ fontWeight: 'bold' }}>
              {lang.total}:{' '}
              {serbianFormatter.format(data.data.transactionDetails.amount)} RSD
            </div>
          </UserData>
          <UserData>
            <b>{lang.transaction_details}:</b>
            <div>
              Broj narudžbine: {data.data.transactionDetails.wsPayOrderId}
            </div>
            <div>
              Autorizacioni kod: {data.data.transactionDetails.approvalCode}
            </div>
            <div>
              {lang.payment_results_status}: {lang.payment_results_success}
            </div>

            <div>
              {lang.delivery_date}:{' '}
              {moment(data.data.deliveryDate).format('HH:mm DD.MM.YYYY.')}
            </div>
            <div>
              Iznos transakcije:{' '}
              {serbianFormatter.format(data.data.transactionDetails.amount)} RSD
            </div>
          </UserData>

          <UserData>
            <b>Podaci o Trgovcu:</b>
            <div>Naziv pravnog lica: BILET SOLUTION DOO NIš</div>
            <div>PIB: 114721552</div>
            <div>Adresa: Romanijska 7, 18000 Niš</div>
          </UserData>

          <div>
            Ulaznice će biti generisane i poslate na Vaš email, a ako ste
            registrovani biće Vam dostupne i u e-novčaniku.
          </div>
          <div>
            Prilikom ulaska na događaj ulaznice možete pokazati direktno sa
            telefona, pokazati printscreen QR koda ili pokazati odštampanu
            ulaznicu koju ste dobili na e-mail.
          </div>
          <div>
            Ulaznice čuvajte. Jednom iskorišćenu ulaznicu nije moguće ponovo
            upotrebiti.
          </div>
          <Button
            className='small mt1'
            onClick={() => history.push('/mytickets')}
          >
            Ulaznice
          </Button>
          <Link className='mt1 mb3' onClick={() => history.push('/')}>
            Nazad na kupovinu ulaznica
          </Link>
        </Wrapper>
      )}
      {(data?.data.transactionDetails?.success !== '1' || isError) && (
        <Wrapper>
          <div className={'mt1 mb2 text-size-xl text-center'}>
            Transakcija je neuspešna. Račun vaše platne kartice nije zadužen.
          </div>
          <div>
            Najčešći uzrok je pogrešno unet broj kartice, datum isteka ili
            sigurnosni kod. Pokušajte ponovo, a u slučaju uzastopnih grešaka
            pozovite Vašu banku.
          </div>
          <Button
            onClick={() =>
              history.push(`/show/${data?.data.buyTicketsRequestDTO.showId}`)
            }
          >
            Pokušajte ponovo
          </Button>
        </Wrapper>
      )}
    </Container>
  );
};

const Wrapper = styled.div`
  display: flex;
  gap: 12px;
  flex-direction: column;
  padding: 0 16px;
  h4,
  h5 {
    margin: 0;
  }
`;

const UserData = styled.div`
  display: flex;
  flex-direction: column;
  div {
    font-size: 12px;
  }
`;

const ProductsTable = styled.table`
  font-size: 12px;
  thead {
    font-weight: bold;
  }
`;
