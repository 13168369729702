import { useMutation, useQuery } from '@tanstack/react-query';
import axios, { AxiosError, AxiosResponse } from 'axios';
import Spinner from 'src/components/Spinner/Spinner';
import GoBack from 'src/components/goBack/goBack';
import Heading from 'src/components/heading/heading';
import { useLang } from 'src/components/useLang';
import config from 'src/config';
import {
  Button,
  Comment,
  Container,
  MiddleWrapper,
} from 'src/styling/globalStyling';
import { useHistory } from 'react-router-dom';
import { TopFab } from '../myFangouts/MyFangouts';
import AddFobButton from 'src/components/AddFobButton';
import { useState } from 'react';
import { RemoveItemRelative } from '../createShow/styledComponents';
import Portal from 'src/components/portal/portal';
import Popup from 'src/components/popup/popup';

import style from './SeatingsMaps.module.scss';
import Tabs from 'src/components/tabs/tabs';

type Map = {
  id: number;
  map: string;
  mapName: string;
};

const SeatingMaps = () => {
  const lang = useLang();
  const history = useHistory();

  const [confirmRemoveMap, setConfirmRemoveMap] = useState<Map | null>(null);

  const goToCreateMap = () => {
    history.push(`/seatingMaps/new`);
  };

  const { mutate: deleteMap, isLoading: isDeleting } = useMutation(
    (id: number) => {
      return axios.delete(`${config.baseApi}/v1/maps/${id}`);
    }
  );

  const {
    data,
    isLoading,
    error,
    isRefetching,
    refetch: refetchMaps,
  } = useQuery<AxiosResponse<any>, AxiosError, AxiosResponse<any>, string[]>(
    ['seatingMaps'],
    () => axios.get(`${config.baseApi}/v1/maps`)
  );

  const handleRemoveMap = () => {
    if (confirmRemoveMap?.id) {
      deleteMap(confirmRemoveMap?.id, {
        onSuccess: () => {
          setConfirmRemoveMap(null);
          refetchMaps();
        },
        onSettled: () => setConfirmRemoveMap(null),
      });
    }
  };

  return (
    <Container>
      <MiddleWrapper>
        {(isLoading || isRefetching || isDeleting) && <Spinner fullscreen />}
        {error && (
          <div className='text-center text-danger'>{error.message}</div>
        )}
        <GoBack customAction={() => history.push('/profile')} />
        <Heading title={lang.seating_maps} />
        <TopFab onClick={goToCreateMap}>
          <AddFobButton />
        </TopFab>
        {!isLoading && !error && data?.data.length === 0 && (
          <div className='flex align-center justify-center flex-direction-column'>
            <Comment className='mt3'>{lang.no_maps}</Comment>
            <Button className='second fitContent mt3' onClick={goToCreateMap}>
              {lang.create_seating_map}
            </Button>
          </div>
        )}

        <div className='mt2'>
          {data?.data.length > 0
            ? data?.data.map((seatingMap) => (
                <div key={seatingMap.id} className={style.mapRow}>
                  <div
                    onClick={() =>
                      history.push(`/seatingMaps/${seatingMap.id}`)
                    }
                    className={style.mapName}
                  >
                    {seatingMap.mapName}
                  </div>
                  <RemoveItemRelative
                    className={'icon-remove'}
                    onClick={() => setConfirmRemoveMap(seatingMap)}
                  />
                </div>
              ))
            : null}
        </div>
        {confirmRemoveMap && (
          <Portal>
            <Popup
              title={`${lang.delete} ${confirmRemoveMap.mapName}`}
              content={lang.show_delete_desc}
              submitAction={handleRemoveMap}
              submitText={lang.delete}
              cancelAction={() => setConfirmRemoveMap(null)}
              cancelText={lang.close}
              trackingTitle={'map_remove'}
            />
          </Portal>
        )}
      </MiddleWrapper>
      <Tabs active={4} />
    </Container>
  );
};

export default SeatingMaps;
