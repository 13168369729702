import React from 'react';
import Heading from '../../components/heading/heading';
import GoBack from '../../components/goBack/goBack';
import Input, { Description } from '../../components/input/input';
import { Container, Label } from '../../styling/globalStyling';
import AsyncSelect from 'react-select/async';
import Select from 'react-select';
import { colourStyles } from '../Login/utils';
import { withTheme } from 'styled-components';
import { getLocationObject, handleLoadAddressOptions } from '../../utils';
import styled from 'styled-components';
import moment from 'moment';
import fangoutService from './createFangoutService';
import {
  billObject,
  billValues,
  dateFormat,
  getTypeDescriptions,
  getTypeObject,
  getTypeValues,
  timeFormat,
} from '../../constants';
import { setFangouts } from '../fangouts/fangoutsActions';
import { connect } from 'react-redux';
import ReactGA from 'react-ga4';
import NeedVerification from '../../components/NeedVerification';
import { TextArea } from '../../components/fangoutComponent/requestFangoutComponent';
import { getMyFangouts } from '../myFangouts/myFangoutsActions';

export function validation() {
  let disabled = false;
  if (
    !this.state.addressObject.label ||
    !this.state.addressObject.value ||
    this.state.audience < 1 ||
    this.state.duration < 1 ||
    this.state.price < 30 ||
    !this.state.type.label ||
    !this.state.bill.label
  ) {
    disabled = true;
  }
  const timeArray = this.state.time.split(':');
  const mdate = moment(this.state.date)
    .hours(timeArray[0])
    .minutes(timeArray[1]);
  const isInPast = moment().isAfter(mdate);
  if (isInPast) {
    disabled = true;
  }
  return disabled;
}

export class CreateFangout extends React.Component {
  constructor(props) {
    super(props);
    const now = new moment().add(1, 'h');
    this.state = {
      addressObject: { label: `${this.props.lang.city}...`, value: null },
      addressOptions: [],
      date: now.format(dateFormat),
      time: now.format(timeFormat),
      audience: 1,
      price: 30,
      type: getTypeValues(this.props.lang)[0],
      bill: billValues(this.props.lang)[0],
      error: '',
      disabled: false,
      duration: 1,
      loading: false,
      description: '',
      sponsor: '',
    };
  }

  componentDidMount() {
    ReactGA.send({ hitType: 'pageview', page: this.props.location.pathname });
    if (
      this.props.match.params.id &&
      this.props.location.state &&
      this.props.location.state.fan === null
    ) {
      const { state } = this.props.location;
      this.setState({
        addressObject: { label: state.location.city, value: state.location },
        date: new moment(state.date).format(dateFormat),
        time: new moment(state.date).format(timeFormat),
        duration: state.duration,
        price: state.price,
        type: getTypeObject(this.props.lang)[state.fangoutType],
        audience: state.numOfAttendees,
        bill: billObject(this.props.lang)[state.billType],
        fId: state.id,
        description: state.description || '',
        sponsor: state.sponsor === null ? '' : state.sponsor,
      });
    } else if (this.props.location.pathname.includes('editfangout')) {
      this.props.history.push('/');
    }
  }

  getPrepareObject = () => {
    const {
      bill,
      audience,
      price,
      type,
      addressObject,
      date,
      time,
      duration,
      description,
      sponsor,
    } = this.state;
    const timeArray = time.split(':');
    const mdate = moment(date).hours(timeArray[0]).minutes(timeArray[1]);
    const utcTime = mdate.utc().format();
    const data = {
      billType: bill.value,
      location: getLocationObject(addressObject.value),
      currency: 'EUR',
      date: utcTime,
      fangoutType: type.value,
      numOfAttendees: parseInt(audience),
      price: parseInt(price),
      duration: parseInt(duration),
      description: description,
      sponsor: sponsor,
    };
    return data;
  };

  createFangout = () => {
    const data = this.getPrepareObject();
    this.setState({ error: '', disabled: true, loading: true });

    fangoutService
      .createFangout(data)
      .then((res) => {
        this.props.setFangouts().then(() => this.props.history.push('/home'));
      })
      .catch((err) => this.setState({ error: err.message }))
      .finally(() => {
        this.setState({ disabled: false, loading: false });
        this.props.getMyFangouts();
      });
  };

  saveFangout = () => {
    const data = this.getPrepareObject();
    // this.setState({error: '', disabled: true})

    fangoutService
      .updateFangout(data, this.state.fId)
      .then((res) => {
        this.props.setFangouts().then(() => this.props.history.goBack());
      })
      .catch((err) => this.setState({ error: err.message }))
      .finally(() => {
        this.setState({ disabled: false });
        this.props.getMyFangouts();
      });
  };

  render() {
    const timeArray = this.state.time.split(':');
    const mdate = moment(this.state.date)
      .hours(timeArray[0])
      .minutes(timeArray[1]);
    const isInPast = moment().isAfter(mdate);
    const selectStyles = colourStyles(this.props.theme);
    const isRequest = this.props.location.pathname.includes('requestfangout');
    const isEditFangout =
      this.props.location.pathname.includes('editfangout') &&
      this.props.location.state;
    const lang = this.props.lang;
    return this.props.userData.verified === 'VERIFIED' ? (
      <Container>
        <GoBack />
        <Heading
          title={
            isRequest
              ? lang.requestFangout_title
              : isEditFangout
              ? lang.editFangout_title
              : lang.createFangout_title
          }
          help={[
            lang.help_new_fangout1,
            lang.help_new_fangout2,
            lang.help_new_fangout3,
            lang.help_new_fangout4,
            lang.help_new_fangout5,
            lang.help_new_fangout6,
          ]}
          trackingModalTitle={'help_new_fout'}
        />
        <Content className={'p016 noscroll'}>
          <Input
            value={this.state.sponsor}
            label={lang.label_title}
            type={'text'}
            descriptionPositionUp
            description={`(${lang.optional})`}
            onChange={(e) => this.setState({ sponsor: e.target.value })}
            isvalid={this.state.sponsor?.length > 30 ? 'error' : 'success'}
          />
          <Label>{lang.label_location}</Label>
          <AsyncSelect
            id={'address'}
            value={this.state.addressObject}
            onChange={(location) => this.setState({ addressObject: location })}
            loadOptions={handleLoadAddressOptions}
            defaultOptions={this.state.addressOptions}
            className={'address'}
            styles={selectStyles}
            cacheOptions
            placeholder={lang.city + '...'}
          />
          <InlineInputsContainer className={''}>
            <InlineInput>
              <Input
                label={lang.label_date}
                type={'date'}
                value={this.state.date}
                inputstyle={{
                  minWidth: '-webkit-fill-available',
                  paddingRight: '5px',
                  paddingLeft: '8px',
                }}
                onChange={(e) => this.setState({ date: e.target.value })}
                min={moment().format(dateFormat)}
                isvalid={isInPast ? 'error' : 'success'}
              />
            </InlineInput>
            <InlineInput>
              <Input
                label={lang.label_time}
                type={'time'}
                value={this.state.time}
                inputstyle={{
                  minWidth: '-webkit-fill-available',
                  paddingRight: '5px',
                  paddingLeft: '8px',
                }}
                onChange={(e) => this.setState({ time: e.target.value })}
                isvalid={isInPast ? 'error' : 'success'}
              />
            </InlineInput>
          </InlineInputsContainer>
          <Input
            value={this.state.duration}
            label={lang.label_duration}
            type={'number'}
            icon={'fas fa-watch'}
            descriptionPositionUp
            description={'(h)'}
            min={1}
            onChange={(e) => this.setState({ duration: e.target.value })}
            isvalid={this.state.duration < 1 ? 'error' : 'success'}
          />
          <div className={'relative'}>
            <Label>{lang.label_type}</Label>
            <Select
              styles={selectStyles}
              value={this.state.type}
              options={getTypeValues(lang)}
              defaultValue={getTypeValues(lang)[0]}
              onChange={(e) => this.setState({ type: e })}
            />
            <Description>
              {getTypeDescriptions(lang)[this.state.type.value]}
            </Description>
          </div>
          {this.state.type.value === 'CASUAL' && (
            <div>
              <Label>{lang.label_bill}</Label>
              <Select
                styles={selectStyles}
                value={this.state.bill}
                defaultValue={billValues(lang)[0]}
                options={billValues(lang)}
                onChange={(e) => this.setState({ bill: e })}
              />
            </div>
          )}
          <Input
            value={this.state.audience}
            label={lang.label_audience}
            type={'number'}
            description={'(UP TO)'}
            descriptionPositionUp
            min={1}
            max={100}
            isvalid={this.state.audience < 1 ? 'error' : 'success'}
            onChange={(e) => this.setState({ audience: e.target.value })}
          />
          <Input
            value={this.state.price}
            label={lang.label_price}
            type={'number'}
            icon={'fas fa-euro-sign'}
            descriptionPositionUp
            description={'(MIN 30)'}
            onChange={(e) => this.setState({ price: e.target.value })}
            min={30}
            isvalid={this.state.price < 30 ? 'error' : 'success'}
          />
          <Label>{lang.description}</Label>
          <TextArea
            value={this.state.description}
            onChange={(e) => this.setState({ description: e.target.value })}
          />
          <div
            className={'text-danger mt2 text-center'}
            style={{ height: '22px' }}
          >
            {this.state.error}
          </div>
          <button
            disabled={validation.call(this) || this.state.disabled}
            style={{ marginTop: '12px', marginBottom: '32px' }}
            onClick={isEditFangout ? this.saveFangout : this.createFangout}
          >
            {this.props.match && this.props.match.params.userId
              ? lang.label_request
              : isEditFangout
              ? lang.label_save
              : lang.label_create}{' '}
            Fangout
          </button>
        </Content>
      </Container>
    ) : (
      <NeedVerification
        openModalDefault={true}
        cancelAction={() => this.props.history.push('/')}
      />
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.home.language,
    userData: state.profile.userData,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    setFangouts: () => setFangouts(dispatch),
    getMyFangouts: () => getMyFangouts(dispatch),
  };
}

export default withTheme(
  connect(mapStateToProps, mapDispatchToProps)(CreateFangout)
);

export const Content = styled.div`
  overflow: scroll;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

export const InlineInputsContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InlineInput = styled.div`
  width: calc(50% - 10px);
  max-width: calc(50% - 10px);
  input {
    font-family: ${({ theme }) => theme.fontFamily};
    &::-webkit-calendar-picker-indicator {
      font-size: 14px;
      outline: unset;
      margin-left: 2px;
      &:active,
      &:focus {
        opacity: 0.7;
      }
    }
  }
`;
