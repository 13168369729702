import { fabric } from "fabric";
import { DEFAULT_DISABLED_COLOR, DEFAULT_SEAT_COLOR } from "../../constants";

class BookTablesClass {
  constructor(options, canvas, uuid, values) {
    this.noOfSeats = options.noOfSeats;
    this.seatRadius = options.seatRadius;
    this.seatSpacing = options.seatSpacing;
    this.tableRadius = options.tableRadius;
    this.minNoOfTickets = options.minNoOfTickets;
    this.name = options.name;
    this.seatObjects = [];
    this.grpArr = [];
    this.tableGroup = null;
    this.canvasRef = canvas;
    this.id = uuid; // Use passed uuid
    this.objectSubtype = "table";
    this.tableName = options.tableName;
    this.groupAdded = true;
    this.seatColor = DEFAULT_SEAT_COLOR;
    this.tableColor = values.tableColor;
    this.angle = values.angle;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.taken = false;
    this.booked = false;
    this.isBooking = false;
  }

  loadTable(valuesJSON) {
    let { noOfSeats, seatRadius, tableRadius, seatSpacing, tableName } = this;
    const tableCircle = new fabric.Circle({
      radius: tableRadius,
      fill: this.taken ? DEFAULT_DISABLED_COLOR : this.tableColor,
    });

    const tableLabel = new fabric.Text(tableName, {
      fontSize: tableRadius / 2,
      fill: "black",
      left: tableRadius,
      top: tableRadius,
      originX: "center",
      originY: "center",
    });

    const tableGroupItem = new fabric.Group([tableCircle, tableLabel], {
      selectable: false,
    });

    this.grpArr.push(tableGroupItem);

    const angleStep = (2 * Math.PI) / noOfSeats;

    for (let i = 0; i < noOfSeats; i++) {
      const angle = i * angleStep;
      const seatNumber = i + 1;

      const seatLeft =
        tableRadius + (tableRadius + seatSpacing) * Math.cos(angle);
      const seatTop =
        tableRadius + (tableRadius + seatSpacing) * Math.sin(angle);

      const seatCircle = new fabric.Circle({
        radius: seatRadius,
        fill: this.taken
          ? DEFAULT_DISABLED_COLOR
          : valuesJSON.sg[i].seatCircle.fill,
        left: seatLeft - seatRadius,
        top: seatTop - seatRadius,
      });

      const seatLabel = new fabric.Text(`${valuesJSON.sg[i].seatLabel.text}`, {
        fontSize: seatRadius,
        fill: "black",
        left: seatLeft,
        top: seatTop,
        originX: "center",
        originY: "center",
      });

      const seatGroupItem = new fabric.Group([seatCircle, seatLabel], {
        selectable: false,
        seatNumber: seatNumber,
        seatName: `${i + 1}`,
      });

      this.seatObjects.push(seatGroupItem);
      this.grpArr.push(seatGroupItem);
    }

    const grpTop = valuesJSON.top;
    const grpLeft = valuesJSON.left;
    this.canvasRef.remove(this.tableGroup);
    this.tableGroup = new fabric.Group(this.grpArr, {
      top: grpTop,
      left: grpLeft,
      originX: "center",
      originY: "center",
      id: this.id, // Use passed uuid
      objectSubtype: this.objectSubtype,
      angle: this.angle,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      selectable: false,
      ticketType: valuesJSON.sg[0].ticketType,
      seatType: "Table",
      minNoOfTickets: this.minNoOfTickets,
      maxNoOfTickets: this.noOfSeats,
      booked: this.booked,
      taken: this.taken,
      blockName: this.tableName,
      color: this.tableColor,
    });
    // this.tableGroup.on("mousedown", () => {
    //   this.toggleSeatColor();
    // });
    this.groupAdded = true;
    this.canvasRef.add(this.tableGroup);
    this.canvasRef.renderAll();
  }

  bookAll() {
    if (!this.booked || this.taken) return;

    this.grpArr.forEach((seat) => {
      seat.item(0).set({
        fill: DEFAULT_DISABLED_COLOR,
      });
    });

    this.canvasRef.renderAll();
  }

  handleRemoveTable() {
    this.toggleSeatColor();
  }

  toggleBooked() {
    this.booked = !this.booked;
    this.toggleSeatColor();
  }

  toggleSeatColor() {
    // if (this.taken || !this.ticketType) return;
    if (this.booked) {
      this.grpArr.forEach((seat) => {
        seat.item(0).set({
          fill: "#666666",
          opacity: 0.5,
        });
      });
    } else {
      this.grpArr[0].item(0).set({
        fill: this.tableColor,
        opacity: 1,
      });
      this.grpArr.slice(1).forEach((seat) => {
        seat.item(0).set({
          fill: this.seatColor,
          opacity: 1,
        });
      });
    }
    this.canvasRef.renderAll();
  }

  save() {
    if (!this.booked || this.taken) return;

    const newObj = {
      id: this.id,
      taken: true,
      ticketType: this.tableGroup.ticketType,
    };

    this.tableGroup.taken = true;
    this.taken = true;

    return newObj;
  }
}

export default BookTablesClass;
