import moment from 'moment';
import React from 'react';
import ReactGA from 'react-ga4';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled, { withTheme } from 'styled-components';
import emptyAvatar from '../../assets/images/empty-avatar.jpg';
import FangoutComponent from '../../components/fangoutComponent/fangoutComponent';
import GoBack from '../../components/goBack/goBack';
import Heading from '../../components/heading/heading';
import Popup from '../../components/popup/popup';
import Portal from '../../components/portal/portal';
import Tabs from '../../components/tabs/tabs';
import config from '../../config';
import { availDateFormat } from '../../constants';
import { Container, Flex, Share } from '../../styling/globalStyling';
import { extractErrorMessage } from '../../tools';
import { shareFeature, sortAvByDate, sortByDate } from '../../utils';
import AvailabilityService from '../availability/availabilityService';
import fangoutsPublicService from '../fangouts/fangoutsPublicService';
import { SocNet } from '../Login/Register';
import { socNetMap } from '../personalDetails/personalDetails';
import { getSubscriptions } from '../profile/profileActions';
import ShowItem from '../showItem/showItem';
import SubscriptionService from '../subscriptions/subscriptionService';
import publicProfileService from './publicProfileService';

class PublicProfile extends React.Component {
  state = {
    user: {},
    session: null,
    subscribeHelp: false,
    error: '',
    availability: {
      isAvailable: null,
      availability: [],
    },
    unsubscribeModal: false,
    availableFouts: [],
    shows: [],
    width: 300,
    paddingTop: 16,
  };

  ref = React.createRef();
  wrapRef = React.createRef();

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
    if (this.props.location.user) {
      this.setState({ user: this.props.location.user });
      ReactGA.send({
        hitType: 'pageview',
        page: this.props.location.pathname,
        value: 'public ' + this.props.location?.user?.username,
      });
      this.getAvailability(this.props.location.user.id);
      this.setUserFangouts(this.props.location.user.name);
      this.getShows(this.props.location.user.id);
    } else {
      publicProfileService
        .getUserData(this.props.match.params.id)
        .then((res) => {
          // res.data.socialNetworks = getSocNetworks(res.data.socialNetworks)
          this.setState({ user: res.data });
          ReactGA.send({
            hitType: 'pageview',
            page: this.props.location.pathname,
          });
          this.getAvailability(res.data.id);
          this.setUserFangouts(res.data.name);
          this.getShows(res.data.id);
        });
    }

    try {
      const session = JSON.parse(localStorage.getItem('session'));
      this.setState({ session });
    } catch (e) {
      console.log('no session');
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll = () => {
    const a = this.ref.current.getBoundingClientRect();

    let minWidth = 100;
    let width;
    let move = a.y - 55;
    width = Math.max(minWidth, 300 + move * 2.5);
    this.setState({
      width,
      paddingTop: -move > 67 ? 66.5 : `${-move}px`,
    });
  };

  getShows = (userId) => {
    publicProfileService
      .getUsersShows(userId)
      .then((res) => this.setState({ shows: res.data }))
      .catch((e) => this.setState({ error: extractErrorMessage(e) }));
  };

  setUserFangouts = (name) => {
    fangoutsPublicService
      .findFangouts(
        {
          name,
        },
        true
      )
      .then((res) => {
        const fouts = res.data.filter((fang) => {
          const mdate = moment(fang.date);
          const isInPast = moment().isAfter(mdate);
          return fang.status === 'AVAILABLE' && !isInPast;
        });
        this.setState({ availableFouts: fouts });
      });
  };

  getAvailability = (id) => {
    AvailabilityService.getAvailabilitiesById(id).then((res) =>
      this.setState({ availability: res.data })
    );
  };

  requestFangout = () => {
    this.props.history.push('/requestfangout/' + this.state.user.id);
  };

  subscribe = () => {
    this.setState({ error: '' });
    const data = {
      subject: this.state.user.id,
    };
    SubscriptionService.subscribeToUser(data)
      .then(() => {
        this.props.getSubscriptions();
      })
      .catch((e) => {
        this.setState({ error: e.message });
      });
  };

  unsubscribe = () => {
    this.setState({ error: '' });
    const subscription = this.props.subscriptions.users[this.state.user.id];
    if (subscription) {
      SubscriptionService.unsubscribe(subscription.id)
        .then(() => {
          this.props.getSubscriptions();
        })
        .catch((e) => {
          this.setState({ error: e.message });
        })
        .finally(() => {
          this.setState({ unsubscribeModal: false });
        });
    }
  };

  render() {
    const lang = this.props.lang;
    const { user } = this.state;
    const isAvailable = this.state.availability.isAvailable;
    const availability = this.state.availability.availability;
    const shows = this.state.shows;

    window.el = this.wrapRef;

    return (
      <PublicProf onScroll={this.handleScroll} ref={this.wrapRef}>
        <Header>
          <GoBack defaultRoute={'/home'} />
          <Heading title={'@' + user.username} />
        </Header>
        <Container>
          <div ref={this.ref} />

          <UserDetails className={'p016'}>
            <div
              className={'imgContainer relative'}
              style={{ paddingTop: this.state.paddingTop }}
            >
              <img
                style={{ width: this.state.width }}
                src={user.profilePhoto || emptyAvatar}
                alt={'avatar'}
              />
            </div>
            <Flex>
              <div className={'username'}>
                <div className={'name'}>{user.name}</div>
                <div className={'profession'}>{user.profession}</div>
                <div className={'bio'}>{user.bio || ''}</div>
              </div>
              <Share style={{ marginLeft: 'auto', marginRight: '16px' }}>
                <i
                  className={'icon-share'}
                  onClick={() =>
                    shareFeature(
                      `${config.baseApi + config.shareApi}/profiles/${
                        this.state.user.id
                      }`
                    )
                  }
                />
              </Share>
            </Flex>
          </UserDetails>

          {this.state.error && (
            <div className={'text-danger text-center'}>{this.state.error}</div>
          )}

          <div className={'p016'}>
            {this.state.user.socialNetworks &&
              this.state.user.socialNetworks.map((sn) => {
                const key = sn.type;
                const name = socNetMap[key].name;
                return sn.url ? (
                  <SocNet className={'small'} key={key}>
                    <a href={sn.url} target={'_blank'} rel='noreferrer'>
                      <img src={socNetMap[key].icon} alt={name} />
                    </a>
                  </SocNet>
                ) : null;
              })}
          </div>

          {isAvailable && availability?.length && (
            <Availabilities className={'p016 mt1'}>
              <Title>{lang.user_availability}</Title>
              {availability.sort(sortAvByDate).map((av) => {
                return (
                  <Avail key={av.id}>
                    <div>
                      {moment(av.start).format(availDateFormat)} -{' '}
                      {moment(av.end).format(availDateFormat)}
                    </div>
                    <div>
                      {av.location
                        ? `${av.location.city} (${av.location.countryName})`
                        : ''}
                    </div>
                  </Avail>
                );
              })}
            </Availabilities>
          )}
          {this.state.shows.length > 0 && (
            <div className={'p016 mt1'}>
              <Title>{lang.label_shows}</Title>
              {shows.map((s) => (
                <ShowItem key={s.id} show={s} />
              ))}
            </div>
          )}
          {this.state.availableFouts.length > 0 && (
            <div className={'mt1 p016'}>
              <Title>Fangouts</Title>
              {this.state.availableFouts
                .sort((a, b) => sortByDate(a.date, b.date))
                .map((f) => (
                  <FangoutComponent f={f} key={f.id} withShare />
                ))}
            </div>
          )}
          <div className={'pb200'} />
          <div className={'pb200'} />
          <div className={'pb200'} />
        </Container>
        <Tabs lang={lang} />
        {this.state.subscribeHelp && (
          <Portal>
            <Popup
              title={lang.publicProfile_subscribe}
              content={lang.publicProfile_subscribe_help}
              cancelAction={() => this.setState({ subscribeHelp: false })}
              cancelText={lang.close}
              trackingTitle={'publicProfile_subscribe_help'}
            />
          </Portal>
        )}
        {this.state.unsubscribeModal && (
          <Portal>
            <Popup
              title={lang.publicProfile_subscribed}
              submitAction={this.unsubscribe}
              submitText={lang.publicProfile_unsubscribe}
              cancelAction={() => this.setState({ unsubscribeModal: false })}
              submitStyle={{ background: this.props.theme.gray, color: '#fff' }}
              cancelText={lang.close}
              trackingTitle={'publicProfile_subscribe_help'}
            />
          </Portal>
        )}
      </PublicProf>
    );
  }
}

function mapStateToProps(state) {
  return {
    lang: state.home.language,
    userData: state.profile.userData,
    subscriptions: state.home.subscriptions,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    getSubscriptions: () => getSubscriptions(dispatch),
  };
}

export default withTheme(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(PublicProfile))
);

export const PublicProf = styled.div`
  height: 100%;
`;

export const UserDetails = styled.div`
  padding-top: 16px;
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
  background: linear-gradient(
    ${({ theme }) => theme.secondBackground},
    ${({ theme }) => theme.background}
  );
  // min-height: 90px;
  .imgContainer {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  img {
    border-radius: 8px;
    margin-bottom: 12px;
    box-shadow: 0 0 12px #aaa;
  }

  .username {
  }

  .name {
    font-size: 16px;
    font-weight: 700;
  }
  .bio {
    font-size: 12px;
  }
`;

export const Unsubscribe = styled.div`
  color: ${({ theme }) => theme.lightGray};
`;

const Availabilities = styled.div`
  margin-top: 4px;
`;
const Title = styled.div`
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 6px;
`;
const Avail = styled.div`
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  margin-bottom: 4px;
  color: ${({ theme }) => theme.lightGray};
`;

const Header = styled.div`
  z-index: 1;
  width: 100%;
  background: ${({ theme }) => theme.secondBackground};
`;
