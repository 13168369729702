import { createContext, Dispatch, SetStateAction } from "react";
import { fabric } from "fabric";

interface CanvasContextType {
  canvas: fabric.Canvas | undefined;
  setCanvas: Dispatch<SetStateAction<fabric.Canvas | undefined>>;
}

const CanvasContext = createContext<CanvasContextType>({
  canvas: undefined,
  setCanvas: () => {}
});

export default CanvasContext;