import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import React, { useMemo, useState } from 'react';
import { openMaps, shareFeature } from '../../utils';
import config from '../../config';
import { Flex, Share } from '../../styling/globalStyling';
import moment from 'moment';
import { dateTimeFormat } from '../../pages/myShows/myShows';
import { dateFormat } from '../../constants';
import { QRCodeSVG } from 'qrcode.react';
import styled from 'styled-components';
import { ITicketProps } from '../../pages/myTickets/myTickets';

interface ITicketComponentProps {
  ticket: ITicketProps;
  expandable?: boolean;
  defaultExpanded?: boolean;
}

export const Ticket = (props: ITicketComponentProps) => {
  const history = useHistory();
  const lang = useSelector((state: any) => state.home.language);
  const { ticket: t, expandable = true, defaultExpanded = false } = props;
  const [expanded, setExpanded] = useState(defaultExpanded);

  const share = (e) => {
    e.stopPropagation();
    shareFeature(`${config.appUrl}/#/ticket/${t.invalidationId}`);
  };
  const goToShow = () => {
    if (t.show) {
      history.push(`/show/${t.show.id}`);
    }
  };

  const watchTheStreaming = (e) => {
    e.stopPropagation();
    window.open(`${config.appUrl}/#/ticket/${t.invalidationId}`);
  };

  const isTicketInvalid = useMemo(() => {
    const showDate = moment(t.show.endDate).add(1, 'd');
    return moment().isAfter(showDate) || t.invalidationsLeft === 0;
  }, [t]);

  return (
    <TicketComponent
      className={'ticketComponent'}
      onClick={() => expandable && setExpanded(!expanded)}
    >
      {isTicketInvalid && <div className={'invalidated'} />}
      <Flex gap={'16px'} style={{ alignItems: 'flex-start' }}>
        <img onClick={goToShow} src={t?.show.eventImage} alt={'show-poster'} />
        <Flex className={'column fg1'}>
          <Flex className={'space-between'}>
            <div className={'fw600 title'} onClick={goToShow}>
              {t?.show.showTitle}
            </div>
            <Share style={{ marginTop: '2px' }}>
              <i className={'icon-share'} onClick={(e) => share(e)} />
            </Share>
          </Flex>
          <div className={'fs14'}>
            <span className={'fw600'}>{t?.ticketType.title}</span> -{' '}
            {t?.ticketType.description}
          </div>
          {t.bookingData &&
            (t.bookingData.id.startsWith('table') ||
              t.bookingData.id.startsWith('seating')) && (
              <div className={'fs14'}>
                <span className={'fw600'}>{t.bookingData.title}</span>
              </div>
            )}
          <div className={'fs14 link'} onClick={(e) => openMaps(e, t.show)}>
            {t?.show.location.title}
          </div>
          <div className={'fs14'}>
            {moment(t?.show.showDate).format(dateTimeFormat)}
          </div>

          <Flex className={'space-between'}>
            <div className={'fs14 gray'}>
              {lang.purchased} {moment(t?.purchaseDate).format(dateFormat)}
            </div>
            <i className={'fas fa-qrcode'} />
          </Flex>
        </Flex>
      </Flex>
      <div className={`qrcode ${expanded ? 'expanded' : ''}`}>
        {t?.ticketType?.isStreaming ? (
          <div>
            <button onClick={watchTheStreaming}>Watch the streaming</button>
          </div>
        ) : (
          <>
            <QRCodeSVG
              value={t?.invalidationId}
              size={expanded ? 270 : 270}
              includeMargin={true}
            />
            <div className={'text-size-s'}>{t?.invalidationId}</div>
          </>
        )}
      </div>
    </TicketComponent>
  );
};

export default Ticket;

export const TicketComponent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  padding: 6px 10px;
  background: ${({ theme }) => theme.secondBackground};
  border-radius: 8px;
  cursor: pointer;

  .gray {
    color: ${({ theme }) => theme.gray};
  }

  .invalidated {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${({ theme }) => theme.red};
    opacity: 0.3;
  }

  .link {
    color: ${({ theme }) => theme.primary};
    width: fit-content;
  }

  .qrcode {
    height: auto;
    max-height: 0;
    transition: max-height 0.2s;
    overflow: hidden;
    text-align: center;

    &.expanded {
      max-height: 600px;
      padding: 12px 0;
    }
  }

  i.fa-qrcode {
    color: ${({ theme }) => theme.primary};
    font-size: 18px;
    &:hover {
      opacity: 0.9;
    }
  }

  .fs14 {
    font-size: 14px;
  }

  .fw600 {
    font-weight: 600;
  }

  img {
    width: 60px;
    max-height: 100px;
  }

  .title {
    font-weight: 500;
    width: fit-content;
  }
`;
