import React, { useState, useEffect } from 'react';
import Heading from '../../components/heading/heading';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import emptyAvatar from '../../assets/images/empty-avatar.jpg';
import Input from '../../components/input/input';
import { validateFullname, validateName, validateBio } from '../Login/utils';
import {
  getUserData,
  saveUserDetails,
  setPTR,
} from '../profile/profileActions';
import facebook from '../../assets/images/fb_icon.png';
import instagram from '../../assets/images/instagram_logo.png';
import twitter from '../../assets/images/twitter_logo.png';
import onlyfans from '../../assets/images/onlyfans_logo.png';
import linkedin from '../../assets/images/linkedin_logo.png';
import tiktok from '../../assets/images/tiktok_logo.png';
import youtube from '../../assets/images/youtube_logo.png';
import GoBack from '../../components/goBack/goBack';
import {
  Button,
  Container,
  Label,
  MiddleWrapper,
} from '../../styling/globalStyling';
import ReactGA from 'react-ga4';
import { TextArea } from '../../components/fangoutComponent/requestFangoutComponent';
import { useStore } from 'react-redux';

export const socNetMap = {
  fb: {
    name: 'Facebook',
    icon: facebook,
  },
  insta: {
    name: 'Instagram',
    icon: instagram,
  },
  tw: {
    name: 'Twitter',
    icon: twitter,
  },
  yt: {
    name: 'Youtube channel',
    icon: youtube,
  },
  tiktok: {
    name: 'TikTok',
    icon: tiktok,
  },
  onlyf: {
    name: 'OnlyFans',
    icon: onlyfans,
  },
  ln: {
    name: 'Linkedin',
    icon: linkedin,
  },
};

const PersonalDetails = ({ theme }) => {
  const history = useHistory();
  const location = useLocation();
  const { dispatch } = useStore();

  const {
    language: lang,
    profile: { userData: reduxUserData },
  } = useSelector((state) => ({
    language: state.home.language,
    profile: state.profile,
  }));

  const [userData, setUserData] = useState({
    name: '',
    fullName: '',
    bio: '',
    bankAccount: '',
    bankName: '',
    companyId: '',
  });

  useEffect(() => {
    ReactGA.send({ hitType: 'pageview', page: location.pathname });
    setPTR(dispatch, false);

    if (!reduxUserData.email) {
      getUserData(dispatch).then((res) => {
        setUserData(res.data);
      });
    } else {
      setUserData(reduxUserData);
    }

    return () => {
      setPTR(dispatch, true);
    };
  }, [dispatch, location.pathname, reduxUserData]);

  const save = () => {
    const updatedUserData = {
      ...userData,
    };
    delete updatedUserData.id;

    saveUserDetails(updatedUserData).then((res) => {
      getUserData(dispatch);
      history.push('/profile');
    });
  };

  const avatarSrc = userData.profilePhoto || emptyAvatar;

  const saveDisabled =
    !userData.name ||
    !userData.fullName ||
    !userData.bankName ||
    !userData.companyId ||
    !userData.bankAccount ||
    validateFullname(userData.fullName) === 'error' ||
    validateName(userData.name) === 'error' ||
    validateName(userData.bankName) === 'error' ||
    validateName(userData.companyId) === 'error';

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <Container>
      <Heading title={'Profile'} />
      <GoBack customAction={() => history.push('/profile')} />
      <ContainerExtended className={'p016'}>
        <MiddleWrapper>
          <div className={'headerContent mt2'}>
            <ImageContainer>
              <img className={'avatar'} src={avatarSrc} alt={'avatar'} />
            </ImageContainer>

            <div className={'userDetails'}>
              <div className={'name'}>{userData.name}</div>
              <div className={'username'}>@{userData.username}</div>
              <div className={'username'}>{userData.email}</div>
            </div>
          </div>

          <div className={'controlsWrapper'}>
            <Input
              disabled={userData.verified === 'VERIFIED'}
              type={'text'}
              label={lang.register_company_name}
              name={'name'}
              value={userData.name}
              onChange={handleChange}
              isvalid={validateName(userData.name)}
            />
            <Label>{lang.label_bio}</Label>
            <TextArea
              value={userData.bio || ''}
              name='bio'
              className={validateBio(userData.bio)}
              onChange={handleChange}
            />

            <Input
              disabled={userData.verified === 'VERIFIED'}
              type={'text'}
              label={lang.register_company_director}
              name={'fullName'}
              value={userData.fullName}
              onChange={handleChange}
              isvalid={validateFullname(userData.fullName)}
              description={lang.label_not_visible_to_others}
              descriptionPositionUp
            />

            <Input
              disabled={userData.verified === 'VERIFIED'}
              type={'text'}
              label={lang.register_company_id}
              name={'companyId'}
              value={userData.companyId}
              onChange={handleChange}
              isvalid={validateName(userData.companyId)}
              description={lang.label_not_visible_to_others}
              descriptionPositionUp
            />

            <Input
              type={'text'}
              label={lang.label_bank_name}
              name={'bankName'}
              placeholder={'US Bank'}
              value={userData.bankName}
              onChange={handleChange}
              isvalid={validateName(userData.bankName)}
              description={lang.label_not_visible_to_others}
              descriptionPositionUp
            />

            <Input
              type={'text'}
              label={lang.label_bank_account}
              name={'bankAccount'}
              placeholder={'3751232122323121323'}
              value={userData.bankAccount}
              onChange={handleChange}
              description={lang.label_not_visible_to_others}
              descriptionPositionUp
            />

            <div className='text-right pb200'>
              <Button
                style={{ marginBottom: '32px' }}
                className='mt3 mb3'
                onClick={save}
                disabled={saveDisabled}
              >
                Save
              </Button>
            </div>
          </div>
        </MiddleWrapper>
      </ContainerExtended>
    </Container>
  );
};

export default PersonalDetails;

export const CropContainer = styled.div`
  position: absolute;
  top: 0;
  left: 16px;
  max-width: calc(100% - 32px);
  z-index: 1;
  .uploadImage {
    position: absolute;
    width: 32px;
    bottom: -5px;
    right: -10px;
    z-index: 999;
  }
`;

const ContainerExtended = styled(Container)`
  align-items: center;
  .headerContent {
    display: flex;
    align-self: flex-start;
  }
  .userDetails {
    display: flex;
    flex-direction: column;
  }
  .avatar {
    width: 100%;
    border-radius: 10px;
  }
  .name {
    font-size: 16px;
    font-weight: 600;
  }
  .username,
  .fullName {
    font-size: 12px;
    font-weight: 600;
  }
  .controlsWrapper {
    width: 100%;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 60px;
  height: 65px;
  display: block;
  border-radius: 10px;
  margin-right: 16px;
  .uploadImage {
    position: absolute;
    width: 32px;
    bottom: -5px;
    right: -10px;
    z-index: 999;
  }
`;
