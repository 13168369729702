import React, { useEffect, useState } from 'react';
import Setter from './Setter/Setter';
import Editor from './Editor/Editor';
import styles from './CreateSeatingMap.module.scss';
import CanvasContext from './canvasContext';
import { fabric } from 'fabric';
import { useDispatch } from 'react-redux';
import { ActionTypes } from '../homeReducer';
import Heading from 'src/components/heading/heading';
import GoBack from 'src/components/goBack/goBack';
import { useLang } from 'src/components/useLang';
import { NeedBigScreen } from 'src/components/NeedBigScreen';

interface CanvasContextType {
  canvas: fabric.Canvas | undefined;
  setCanvas: (canvas: fabric.Canvas) => void;
}

const CreateSeatingMapEditor: React.FC = () => {
  const dispatch = useDispatch();
  const lang = useLang();
  const [canvasVal, setCanvas] = useState<fabric.Canvas | undefined>();

  useEffect(() => {
    dispatch({ type: ActionTypes.SET_IS_PULLABLE, payload: false });
  }, []);

  return (
    <CanvasContext.Provider
      value={{
        canvas: canvasVal,
        setCanvas,
      }}
    >
      <div className={styles.editWrapper}>
        <div className={styles.edit}>
          <Heading title={lang.map_editor_title} />
          <GoBack />
          <div className={styles.edit_body}>
            <Setter />
            <Editor />
          </div>
        </div>
      </div>
    </CanvasContext.Provider>
  );
};

const CreateSeatingMap = () => {
  return (
    <NeedBigScreen>
      <CreateSeatingMapEditor />
    </NeedBigScreen>
  );
};

export default CreateSeatingMap;
