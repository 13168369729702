import styled from "styled-components";
import { useLang } from "src/components/useLang";

export const ZeroStateSelectedTickets = () => {
  const lang = useLang();
  return (
    <ZeroStateSelectedTicketsComponent>
      {lang.no_tickets_selected}
    </ZeroStateSelectedTicketsComponent>
  );
};

const ZeroStateSelectedTicketsComponent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  flex: 1;
`;
