import InputNew from 'src/components/inputNew/InputNew';
import styled from 'styled-components';
import {
  InlineInput,
  InlineInputsContainer,
} from '../createFangout/createFangout';
import moment from 'moment';
import {
  currencySelectObject,
  dateFormat,
  getShowTypeObject,
  timeFormat,
} from 'src/constants';
import { Flex, ReactTextInput } from 'src/styling/globalStyling';
import { parseContent } from 'src/components/textEditor/utils';
import Portal from 'src/components/portal/portal';
import Popup from 'src/components/popup/popup';
import { useState } from 'react';

export const TICKET_DESCRIPTION_MAX_LENGTH = 2000;

export const EventImage = styled.img`
  cursor: pointer;
  width: 100%;
`;

export const RemoveItem = styled.i`
  position: absolute;
  right: 45px;
  top: 20px;
  color: ${({ theme }) => theme.red};
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
export const RemoveItemRelative = styled.i`
  right: 45px;
  top: 20px;
  color: ${({ theme }) => theme.red};
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
export const Count = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
  gap: 2px;

  border: 1px solid ${({ theme }) => theme.lightGray};
  border-radius: 6px;
  padding: 8px;
  .countInput {
    border-bottom: 1px solid ${({ theme }) => theme.gray};

    &:last-child {
      border-bottom: none;
    }
  }
`;

export const RemoveTicketType = styled.i`
  width: 40px;
  min-width: 30px;
  max-width: 30px;
  display: flex;
  color: ${({ theme }) => theme.red};
  font-size: 20px;
  position: absolute;
  right: -5px;
  top: 10px;
  z-index: 1;
`;
export const AddTicketType = styled.div`
  display: flex;
  margin-bottom: 1rem;

  i {
    margin-right: 5px;
  }
`;

export const CreateTicketType = styled.div`
  position: relative;
  padding: 8px;
  margin-bottom: 1rem;
  z-index: 0;
  display: flex;
  flex-direction: column;
  gap: 2px;
  border: 1px solid ${({ theme }) => theme.lightGray};
  border-radius: 6px;
`;

export const LabelDesc = styled.span`
  font-size: 12px;
  font-weight: 500;
  text-transform: initial;
`;

export const thousandsSeparatorSymbol = ',';
export const decimalSymbol = '.';

export const CurrencyInput = (props) => {
  return <ReactTextInput thousandSeparator {...props} />;
};

const sanitizeCurrencyValue = (value: string) => {
  if (!value) return '';
  return value.replaceAll(thousandsSeparatorSymbol, '');
};

export const TicketTypeComponent = (props) => {
  const {
    lang,
    i,
    removeTicketType,
    handleTicketChange,
    validateLength,
    t,
    removable,
    currencyIcon,
  } = props;

  const [helpModal, setHelpModal] = useState(false);
  return (
    <CreateTicketType>
      {removable && (
        <RemoveTicketType
          className={'fas fa-times'}
          onClick={() => removeTicketType(i)}
        />
      )}
      <Flex className='column' gap='2px'>
        <InputNew
          type={'text'}
          label={lang.show_ticket_title}
          name={'title'}
          value={t.title}
          onChange={(e) => handleTicketChange(e.target.value, i, 'title')}
          isvalid={validateLength(t.title?.length > 1, t.title)}
        />
        <InputNew
          type={'text'}
          label={lang.show_ticket_desc}
          name={'description'}
          value={t.description}
          onChange={(e) => handleTicketChange(e.target.value, i, 'description')}
          isvalid={validateLength(
            t.description?.length < TICKET_DESCRIPTION_MAX_LENGTH,
            t.description
          )}
        />
        {!t.isStreaming && (
          <InputNew
            label={lang.show_allowed_invalidations}
            min={1}
            style={{ flexGrow: 1 }}
            type={'number'}
            name={'allowedInvalidations'}
            value={t.allowedInvalidations}
            onChange={(e) =>
              handleTicketChange(e.target.value, i, 'allowedInvalidations')
            }
            icon={
              <i
                onClick={() => setHelpModal(true)}
                className={'fas fa-question-circle clickable'}
              />
            }
          />
        )}
      </Flex>
      <InlineInputsContainer className={''}>
        <InlineInput style={{ width: '50%', maxWidth: '50%' }}>
          <InputNew
            min={1}
            style={{ flexGrow: 1 }}
            type={'number'}
            label={lang.show_tickets_number}
            name={'numberOfTickets'}
            value={t.numberOfTickets}
            onChange={(e) =>
              handleTicketChange(e.target.value, i, 'numberOfTickets')
            }
          />
        </InlineInput>
        <InlineInput style={{ width: '50%', maxWidth: '50%' }}>
          <InputNew
            min={1}
            style={{ flexGrow: 1 }}
            type={'number'}
            label={lang.show_tickets_price}
            name={'ticketPrice'}
            value={t.ticketPrice}
            icon={currencyIcon}
            onChange={(e) =>
              handleTicketChange(
                sanitizeCurrencyValue(e.target.value),
                i,
                'ticketPrice'
              )
            }
            inputComponent={CurrencyInput}
          />
        </InlineInput>
      </InlineInputsContainer>
      {helpModal && (
        <Portal>
          <Popup
            title={lang.show_allowed_invalidations_help_title}
            content={
              <div className={'mt1 text-justify'}>
                <div className={'mb1'}>
                  {lang.show_allowed_invalidations_help_message}
                </div>
              </div>
            }
            cancelAction={() => setHelpModal(false)}
            cancelText={lang.close}
            trackingTitle={'help_profile_verify'}
          />
        </Portal>
      )}
    </CreateTicketType>
  );
};

export const prepareShowObject = (show, lang) => ({
  ...show,
  date: moment(show.showDate).format(dateFormat),
  time: moment(show.showDate).format(timeFormat),
  endDate: moment(show.endDate).format(dateFormat),
  endTime: moment(show.endDate).format(timeFormat),
  location: show?.location
    ? { label: show?.location.city, value: show?.location }
    : undefined,
  showType: getShowTypeObject(lang)[show.showType],
  currency: currencySelectObject[show.currency],
  ageRestriction:
    show.ageRestriction === 0
      ? {
          label: lang.show_no_restriction,
          value: 0,
        }
      : { label: show.ageRestriction, value: show.ageRestriction },
  description: parseContent(show.description),
  seatingMap: show.seatingMap ? JSON.parse(show.seatingMap) : null,
});

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
