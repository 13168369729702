import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import Portal from '../portal/portal';
import Popup from '../popup/popup';
import VerifyProfile from '../verifyProfile/verifyProfile';
import { useHistory } from 'react-router-dom';

const NeedVerification = (props) => {
  const { openModalDefault, cancelAction, style, className } = props;
  const { userData, lang } = useSelector((state) => ({
    userData: state.profile.userData,
    lang: state.home.language,
  }));
  const history = useHistory();
  const [openModal, setOpenModal] = useState(openModalDefault);
  const [openVerifyModal, setOpenVerifyModal] = useState(false);

  const click = (e) => {
    if (userData.id && userData.verified !== 'VERIFIED') {
      e.stopPropagation();
      e.preventDefault();
      setOpenModal(true);
    }
  };

  const verify = () => {
    setOpenModal(false);
    history.push('/personalDetails');
    // setOpenVerifyModal(true);
  };

  const getMessage = () => {
    if (userData.verified === 'PENDING') {
      return lang.verify_be_patient;
    } else if (userData.verified === 'UNVERIFIED') {
      return lang.verify_first;
    }
  };

  return (
    <div className={className} style={style}>
      <VerifyProfile
        openVerifyModal={openVerifyModal}
        closeModal={() =>
          cancelAction ? cancelAction() : setOpenVerifyModal(false)
        }
      />
      <div style={{ position: 'relative' }} onClickCapture={click}>
        {props.children}
      </div>
      {openModal && (
        <Portal>
          <Popup
            title={lang.profile_unverified}
            content={getMessage()}
            cancelAction={() =>
              cancelAction ? cancelAction() : setOpenModal(false)
            }
            submitAction={userData.verified === 'UNVERIFIED' ? verify : null}
            submitText={
              userData.verified === 'UNVERIFIED'
                ? lang.profile_personal_details
                : ''
            }
            trackingTitle={'verify_modal'}
          />
        </Portal>
      )}
    </div>
  );
};

export default NeedVerification;
