import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  Container,
  Flex,
  Label,
  SoftButton,
} from '../../styling/globalStyling';
import Heading from '../../components/heading/heading';
import GoBack from '../../components/goBack/goBack';
import styled from 'styled-components';
import {
  IExtTicket,
  IInvalidateTicketData,
  ITicketDetails,
  fetchExternalTicketData,
  fetchTicketData,
  invalidateExternalTicket,
  invalidateTicket,
} from './invalidatorService';
import { useLang } from '../../components/useLang';
import { toast } from 'react-hot-toast';
import { extractErrorMessage } from '../../tools';
import { shareFeature } from '../../utils';
import config from '../../config';
import { useQuery } from '@tanstack/react-query';
import { checkShowByShowCode } from '../createShow/showService';
import moment from 'moment';
import Spinner from '../../components/Spinner/Spinner';
import Html5QrcodePlugin from './Html5QrcodePlugin';
import Portal from 'src/components/portal/portal';
import Popup from 'src/components/popup/popup';

const TicketInvalidator = () => {
  const lang = useLang();
  const { showCode } = useParams<{ showCode: string }>();
  const defaultQrScannerMessage = '';

  const { data, isLoading } = useQuery(['showCode', showCode], ({ queryKey }) =>
    checkShowByShowCode(queryKey[1])
  );

  const [invData, setInvData] = useState<any>(defaultQrScannerMessage);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [invalidatedTickets, setInvalidatedTickets] = useState<string[]>([]);
  const [isValidShowCode, setIsValidShowCode] = useState(false);
  const [ticketData, setTicketData] = useState<ITicketDetails>();
  const [extTicketData, setExtTicketData] = useState<IExtTicket>();

  useEffect(() => {
    const show = data?.data;
    if (!isLoading && show) {
      const isValid =
        !show.isDraft &&
        show.showType !== 'ONLINE_SHOW' &&
        moment(show?.endDate).add(1, 'd').isAfter(moment());
      if (!isValid) {
        toast.error('Show is invalid');
      }
      setIsValidShowCode(
        !show.isDraft &&
          show.showType !== 'ONLINE_SHOW' &&
          moment(show?.endDate).add(1, 'd').isAfter(moment())
      );
    } else if (!isLoading && !show) {
      setIsValidShowCode(false);
      setError('Show code is invalid.');
    }
  }, [data?.data, isLoading]);

  const invalidate = () => {
    const data: IInvalidateTicketData = {
      showCode,
      ticketInvalidationId: invData,
    };
    setLoading(true);
    setError('');
    invalidateTicket(data)
      .then((res) => {
        setInvalidatedTickets([...invalidatedTickets, invData]);
        setTicketData(undefined);
        toast.success(lang.invalidation_success);
      })
      .catch((e) => setError(extractErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  const invalidateExternal = () => {
    if (!extTicketData) {
      return;
    }
    setLoading(true);
    setError('');
    const data: IInvalidateTicketData = {
      ticketInvalidationId: extTicketData.invalidationId,
      showCode,
    };
    invalidateExternalTicket(data)
      .then((res) => {
        setInvalidatedTickets([...invalidatedTickets, invData]);
        setExtTicketData(undefined);
        toast.success(lang.invalidation_success);
      })
      .catch((e) => setError(extractErrorMessage(e)))
      .finally(() => setLoading(false));
  };

  const shareInvalidator = () => {
    const url = `${config.appUrl}/#/invalidator/${showCode}`;
    shareFeature(url);
  };

  useEffect(() => {
    const getTicketData = async (ticketToken: string) => {
      if (!ticketToken) {
        return;
      }

      setError('');

      try {
        const tokenNumber = Number(ticketToken);
        if (isNaN(tokenNumber)) {
          const { data: ticketData } = await fetchTicketData(ticketToken);
          setTicketData(ticketData);
          setExtTicketData(undefined);
        } else {
          const { data: ticketData } = await fetchExternalTicketData({
            ticketInvalidationId: ticketToken,
            showCode,
          });
          setTicketData(undefined);
          setExtTicketData(ticketData);
        }
      } catch (e) {
        setError(e?.response?.data?.generalError?.message || e?.message);
        setTicketData(undefined);
        setExtTicketData(undefined);
      }
    };
    getTicketData(invData);
  }, [invData, showCode]);

  const getTicketContent = () => (
    <>
      {/** Internal ticket data */}
      {ticketData?.id && (
        <div
          className={data?.data.id !== ticketData?.show.id ? 'text-danger' : ''}
        >
          <ShowTitle>{data?.data.showTitle}</ShowTitle>
          <TicketTitle>{ticketData.ticketType.title}</TicketTitle>
          {ticketData.bookingData &&
            (ticketData.bookingData.id.startsWith('table') ||
              ticketData.bookingData.id.startsWith('seating')) && (
              <TicketTitle>{ticketData.bookingData?.title}</TicketTitle>
            )}
          <div className='text-center'>{ticketData.ticketType.description}</div>
        </div>
      )}

      {/** External ticket data */}
      {extTicketData?.id && (
        <div
          className={
            data?.data.id !== extTicketData?.show.id ? 'text-danger' : ''
          }
        >
          <TicketTitle>{extTicketData.ticketType}</TicketTitle>
        </div>
      )}
      {(ticketData?.invalidationsLeft === 0 ||
        extTicketData?.invalidationsLeft === 0) && (
        <InvlidTicket>{lang.invalidation_invalid_ticket}</InvlidTicket>
      )}

      {error && (
        <div className={'text-danger fw600 text-center mt1'}>
          {lang[error] || error}
        </div>
      )}
      {/* Internal ticket invalidation */}
      {!!invData &&
        ticketData?.invalidationsLeft! > 0 &&
        data?.data.id === ticketData?.show.id && (
          <Flex className={'fullWidth column align-center mt1'}>
            <InvalidateButton
              disabled={invData === defaultQrScannerMessage || loading}
              onClick={invalidate}
            >
              {lang.invalidate}
            </InvalidateButton>

            {/* {invData && invData !== defaultQrScannerMessage && (
              <CancelButton
                onClick={() => {
                  setInvData(defaultQrScannerMessage);
                  setTicketData(undefined);
                  setError('');
                }}
              >
                {lang.cancel}
              </CancelButton>
            )} */}
          </Flex>
        )}

      {/* External ticket invalidation */}
      {!!invData &&
        extTicketData?.invalidationsLeft! > 0 &&
        data?.data.id === extTicketData?.show.id && (
          <Flex className={'fullWidth column align-center mt1'}>
            <InvalidateButton
              disabled={invData === defaultQrScannerMessage || loading}
              onClick={invalidateExternal}
            >
              {lang.invalidate}
            </InvalidateButton>

            {/* {invData && invData !== defaultQrScannerMessage && (
              <CancelButton
                onClick={() => {
                  setInvData(defaultQrScannerMessage);
                  setExtTicketData(undefined);
                  setError('');
                }}
              >
                {lang.cancel}
              </CancelButton>
            )} */}
          </Flex>
        )}
    </>
  );

  const closeInvalidationModal = () => {
    setInvData(defaultQrScannerMessage);
    setTicketData(undefined);
    setExtTicketData(undefined);
    setError('');
  };

  return (
    <Invalidator>
      <GoBack />
      <Heading title={data?.data.showTitle || 'Invalidator'} />
      <Container className={'p016'}>
        {isLoading && <Spinner fullscreen />}
        {isValidShowCode ? (
          <>
            <div className={'text-center'}>
              <ShareInvalidator onClick={shareInvalidator}>
                {lang.share_the_invalidator}
              </ShareInvalidator>
            </div>

            <QrReaderWrapper>
              <Html5QrcodePlugin
                fps={30}
                qrbox={250}
                disableFlip={true}
                showTorchButtonIfSupported
                showZoomSliderIfSupported
                qrCodeSuccessCallback={(val) => setInvData(val)}
              />
            </QrReaderWrapper>
          </>
        ) : null}
        {error && (
          <div className={'text-danger fw600 text-center mt1'}>
            {lang[error] || error}
          </div>
        )}

        {invalidatedTickets.length > 0 && (
          <>
            <Label>
              {lang.invalidated} {lang.tickets}
            </Label>
            {invalidatedTickets.map((t) => (
              <InvalidatedTicketRow key={t}>{t}</InvalidatedTicketRow>
            ))}
          </>
        )}
      </Container>
      {!!invData && (ticketData || extTicketData) && (
        <Portal>
          <Popup
            position={'top'}
            content={getTicketContent()}
            cancelAction={closeInvalidationModal}
            cancelText={lang.close}
          />
        </Portal>
      )}
    </Invalidator>
  );
};

export default TicketInvalidator;

const QrReaderWrapper = styled.div`
  position: relative;
  .background {
    position: absolute;
    width: 60%;
    height: 60%;
    top: 20%;
    left: 20%;
    z-index: 1;

    background: linear-gradient(
          to right,
          rgba(200, 0, 0, 0.7) 4px,
          transparent 4px
        )
        0 0,
      linear-gradient(to right, rgba(200, 0, 0, 0.7) 4px, transparent 4px) 0
        100%,
      linear-gradient(to left, rgba(200, 0, 0, 0.7) 4px, transparent 4px) 100% 0,
      linear-gradient(to left, rgba(200, 0, 0, 0.7) 4px, transparent 4px) 100%
        100%,
      linear-gradient(to bottom, rgba(200, 0, 0, 0.7) 4px, transparent 4px) 0 0,
      linear-gradient(to bottom, rgba(200, 0, 0, 0.7) 4px, transparent 4px) 100%
        w 0,
      linear-gradient(to top, rgba(200, 0, 0, 0.7) 4px, transparent 4px) 0 100%,
      linear-gradient(to top, rgba(200, 0, 0, 0.7) 4px, transparent 4px) 100%
        100%;

    background-repeat: no-repeat;
    background-size: 20px 20px;
  }
`;

const ShareInvalidator = styled(SoftButton)`
  max-width: 250px;
  margin-bottom: 8px;
  margin-top: 0;
`;

const InvalidatedTicketRow = styled.div`
  font-size: 12px;
`;

const InvalidateButton = styled.button`
  width: 100%;
`;

const ShowTitle = styled.div`
  font-size: 24px;
  font-weight: 600;
  text-align: center;
`;

const TicketTitle = styled.div`
  font-size: 30px;
  font-weight: 600;
  text-align: center;
`;

const InvlidTicket = styled.div`
  color: ${({ theme }) => theme.red};
  font-size: 30px;
  font-weight: 600;
  text-align: center;
`;

const Invalidator = styled(Container)`
  #html5qr-code-full-region__scan_region img {
    filter: invert(1);
  }
  .showInvalidationCode {
    align-items: center;

    button {
      border-radius: 5px;
      height: 50px;
      width: 50px;
      padding: 0;
    }

    i {
      font-size: 20px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }

      &:active {
        opacity: 1;
      }
    }
  }
`;
