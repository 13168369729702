import { fabric } from "fabric";
import { getSeatingData, dispatchSeatingData } from "../seatingMapReducer";

class ShapeObject {
  constructor(options, canvas, id, values) {
    this.objectSubtype = options.objectSubtype;
    this.text = options.text;
    this.name = options.name || "";
    this.canvasRef = canvas;
    this.id = id;
    this.grpArr = [];
    this.shapeGroup = null;
    this.shapeColor = values.shapeColor;
    this.scaleX = values.scaleX;
    this.scaleY = values.scaleY;
    this.angle = values.angle;
    this.grpTop = values.top;
    this.grpLeft = values.left;
    this.fontSize = values.fontSize;
    this.fontWeight = values.fontWeight;
    this.textColor = values.textColor;
    this.ticketType = values.ticketType;
    this.t = 0;
    this.l = 0;
  }

  create() {
    if (this.objectSubtype === "Circle") this.createCircle();
    else if (this.objectSubtype === "Square") this.createSquare();
    else if (this.objectSubtype === "Triangle") this.createTriangle();
    else if (this.objectSubtype === "Rectangle") this.createRectangle();
    this.save();
  }

  createCircle() {
    const circle = new fabric.Circle({
      radius: 20,
      fill: this.shapeColor,
    });

    const circleLabel = new fabric.Text(``, {
      left: 20,
      top: 20,
      originX: "center",
      originY: "center",
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
    });

    this.shapeGroup = new fabric.Group([circle, circleLabel], {
      id: this.id,
      objectSubtype: this.objectSubtype,
      left: this.grpLeft,
      top: this.grpTop,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      originX: "center",
      originY: "center",
      ticketType: this.ticketType,
    });

    this.grpArr.push(this.shapeGroup);
    this.canvasRef.add(this.shapeGroup);
  }

  createSquare() {
    const square = new fabric.Rect({
      width: 40,
      height: 40,
      fill: this.shapeColor,
    });

    const squareLabel = new fabric.Text(``, {
      left: 20,
      top: 20,
      originX: "center",
      originY: "center",
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
    });

    this.shapeGroup = new fabric.Group([square, squareLabel], {
      left: this.grpLeft,
      top: this.grpTop,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      originX: "center",
      originY: "center",
      id: this.id,
      objectSubtype: this.objectSubtype,
      ticketType: this.ticketType,
    });

    this.grpArr.push(this.shapeGroup);
    this.canvasRef.add(this.shapeGroup);
  }

  createTriangle() {
    const triangle = new fabric.Triangle({
      width: 40,
      height: 40,
      fill: this.shapeColor,
    });

    const triangleLabel = new fabric.Text(``, {
      left: 20,
      top: 20,
      originX: "center",
      originY: "center",
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
    });

    this.shapeGroup = new fabric.Group([triangle, triangleLabel], {
      left: this.grpLeft,
      top: this.grpTop,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      originX: "center",
      originY: "center",
      id: this.id,
      objectSubtype: this.objectSubtype,
      ticketType: this.ticketType,
    });

    this.grpArr.push(this.shapeGroup);
    this.canvasRef.add(this.shapeGroup);
  }

  createRectangle() {
    const rectangle = new fabric.Rect({
      width: 60,
      height: 40,
      fill: this.shapeColor,
    });

    const rectangleLabel = new fabric.Text(``, {
      left: 20,
      top: 20,
      originX: "center",
      originY: "center",
      fill: this.textColor,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
    });

    this.shapeGroup = new fabric.Group([rectangle, rectangleLabel], {
      left: this.grpLeft,
      top: this.grpTop,
      scaleX: this.scaleX,
      scaleY: this.scaleY,
      angle: this.angle,
      originX: "center",
      originY: "center",
      id: this.id,
      objectSubtype: this.objectSubtype,
      ticketType: this.ticketType,
    });

    this.grpArr.push(this.shapeGroup);
    this.canvasRef.add(this.shapeGroup);
  }

  editText(value) {
    this.shapeGroup._objects[1].set({
      text: "",
    });
    this.text = value;
    this.save();
    this.canvasRef.renderAll();
  }

  editName(value) {
    this.name = value;
    this.save();
  }

  setFontSize(value) {
    this.shapeGroup._objects[1].set({
      fontSize: value,
    });
    this.fontSize = value;
    this.save();
    this.canvasRef.renderAll();
  }

  setFontWeight(value) {
    this.shapeGroup._objects[1].set({
      fontWeight: value,
    });
    this.fontWeight = value;
    this.save();
    this.canvasRef.renderAll();
  }

  colorChange(color) {
    this.shapeGroup._objects[0].set({
      fill: color,
    });
    this.shapeColor = color;
    this.save();
    this.canvasRef.renderAll();
  }

  textColorChange(color) {
    this.shapeGroup._objects[1].set({
      fill: color,
    });
    this.textColor = color;
    this.save();
    this.canvasRef.renderAll();
  }

  save() {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const data = {
      dataOptions: {
        text: this.text,
        name: this.name,
        id: this.id,
        objectSubtype: this.objectSubtype,
      },
      dataValues: {
        top: this.shapeGroup.top,
        left: this.shapeGroup.left,
        scaleX: this.shapeGroup.scaleX,
        scaleY: this.shapeGroup.scaleY,
        angle: this.shapeGroup.angle,
        shapeColor: this.shapeColor,
        textColor: this.textColor,
        ticketType: this.ticketType,
        fontSize: this.fontSize,
        fontWeight: this.fontWeight,
      },
    };

    this.t = this.shapeGroup.top;
    this.l = this.shapeGroup.left;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    seatingData.objects.set(this.id, data);

    seatingData.canvasJSON = this.canvasRef.toJSON();

    dispatchSeatingData(seatingData);
  }

  copy() {
    const dataOptions = {
      objectSubtype: this.objectSubtype,
      text: "",
      name: this.name,
    };

    const dataValues = {
      top: this.t,
      left: this.l,
      scaleX: this.shapeGroup.scaleX,
      scaleY: this.shapeGroup.scaleY,
      angle: this.shapeGroup.angle,
      shapeColor: this.shapeColor,
      textColor: this.textColor,
      ticketType: this.ticketType,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
    };

    const copiedObject = {
      type: "shape object",
      dataOptions: dataOptions,
      dataValues: dataValues,
      canvasJSON: this.canvasRef.toJSON(),
    };

    return copiedObject;
  }
  delete() {
    this.canvasRef.remove(this.shapeGroup);
    this.canvasRef.renderAll();
    this.grpArr = [];

    const seatingData = getSeatingData();
    seatingData.objects.delete(this.id);

    seatingData.canvasJSON = this.canvasRef.toJSON();
    dispatchSeatingData(seatingData);
  }

  setSelectable(value) {
    this.shapeGroup.set({
      selectable: value,
    });
    this.canvasRef.renderAll();
  }

  updateDimensions(obj) {
    const currentDate = new Date();
    const formattedDate = `${currentDate.getDate()}-${
      currentDate.getMonth() + 1
    }-${currentDate.getFullYear()} ${currentDate.getHours()}:${currentDate.getMinutes()}:${currentDate.getSeconds()}`;

    const seatingData = getSeatingData();
    seatingData.updatedAt = formattedDate;

    const dataOptions = {
      objectSubtype: this.objectSubtype,
      text: this.text,
      name: this.name,
    };

    const dataValues = {
      top: obj.top,
      left: obj.left,
      scaleX: obj.scaleX,
      scaleY: obj.scaleY,
      angle: obj.angle,
      shapeColor: this.shapeColor,
      textColor: this.textColor,
      ticketType: this.ticketType,
      fontSize: this.fontSize,
      fontWeight: this.fontWeight,
    };

    this.t = obj.top;
    this.l = obj.left;

    seatingData.updatedAt = formattedDate;
    seatingData.objects.set(this.id, {
      dataOptions: dataOptions,
      dataValues: dataValues,
    });

    dispatchSeatingData(seatingData);
  }

  assignAll(value) {
    this.shapeGroup.set({
      ticketType: value,
    });
    this.ticketType = value;
    this.save();
    this.canvasRef.renderAll();
  }

  bringToFront() {
    this.canvasRef.bringToFront(this.shapeGroup);
    const seatingData = getSeatingData();

    const element = seatingData.objects.get(this.id);
    seatingData.objects.delete(this.id);
    seatingData.objects.set(this.id, element);

    seatingData.canvasJSON = this.canvasRef.toJSON();
    dispatchSeatingData(seatingData);
  }
}

export default ShapeObject;
