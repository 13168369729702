import axios from 'axios';
import config from '../../config';
import profileService from './profileService';
import { ActionTypes } from '../homeReducer';
import SubscriptionService from '../subscriptions/subscriptionService';

export const ProfileActionTypes = {
  SET_USER_DATA: 'SET_USER_DATA',
  SET_REFERRALS: 'SET_REFERRALS',
};

export function setPTR(dispatch, state) {
  dispatch({
    type: ActionTypes.SET_IS_PULLABLE,
    payload: state,
  });
}

export function getUserData(dispatch) {
  return profileService.getMyData().then((res) => {
    dispatch && setUserData(dispatch, res.data);
    return res;
  });
}

export function getReferrals(dispatch) {
  return profileService.getReferrals().then((res) => {
    dispatch({
      type: ProfileActionTypes.SET_REFERRALS,
      payload: res.data,
    });
    return res;
  });
}

export function setUserData(dispatch, userData) {
  dispatch({
    type: ProfileActionTypes.SET_USER_DATA,
    payload: userData,
  });
}

export function getSubscriptions(dispatch) {
  SubscriptionService.getSubscriptions().then((res) => {
    const subs = res.data.reduce(
      (acc, item) => {
        if (item.subject) {
          acc.users[item.subject.id] = item;
        } else {
          acc.locations.push(item);
        }
        return acc;
      },
      { users: {}, locations: [] }
    );
    dispatch({
      type: ActionTypes.SET_SUBSCRIPTIONS,
      payload: subs,
    });
  });
}

export function saveUserDetails(data) {
  const url = config.baseApi + '/v1/users/profile/me';
  return axios.put(url, data);
}

export function uploadImage(photo) {
  const url = config.baseApi + '/v1/users/profile/photo';
  return axios.put(url, { photo });
}

export function saveShareProfilePhoto(photo) {
  const split = photo.split(',')[1];
  const url = config.baseApi + '/v1/users/profile/share_photo';
  return axios.put(url, { photo: split });
}

// Profile Reducer
const initialState = {
  userData: {},
  referrals: null,
};

export function profileReducer(state = initialState, action) {
  switch (action.type) {
    case ProfileActionTypes.SET_USER_DATA:
      return Object.assign({}, state, { userData: action.payload });
    case ProfileActionTypes.SET_REFERRALS:
      return Object.assign({}, state, { referrals: action.payload });
    default:
      return state;
  }
}
